import React from "react";
import Gotravel from "../../Assets/user-imgs/Home-imgs/Gotravel.png";
import places from "../../Assets/user-imgs/Home-imgs/places.png";
import guide from "../../Assets/user-imgs/Home-imgs/tourGuide.png";
import vehicle from "../../Assets/user-imgs/Home-imgs/vehicles.png";
import clients from "../../Assets/user-imgs/Home-imgs/clients.png";

function GoTravel() {
    return (
        <div className="mx-4 sm:mx-6 md:mx-12 lg:mx-16 my-6 md:my-12 lg:my-16 relative">
            <div className="bg-[#0791BE] text-white relative p-6 sm:p-8 md:p-12 lg:p-16 flex flex-col justify-center lg:ml-32 xl:ml-48 rounded-lg">
                <div className="lg:ml-72 xl:ml-56">
                    <p
                        className="capitalize font-serif text-base md:text-lg mb-2"
                        style={{ fontFamily: "'Times New Roman', serif" }}
                    >
                        Call back for more
                    </p>
                    <h1 className="text-xl sm:text-2xl md:text-4xl xl:text-5xl font-bold mb-4 leading-snug">
                        Go Travel. Discover. Remember Us !!!
                    </h1>
                    <p className="opacity-70 w-full lg:w-[80%] xl:w-[60%] mb-6 sm:mb-8 tracking-wide text-sm sm:text-base">
                        We would love to help you realize your travel dreams. Take the first steps towards making your dream a reality.
                    </p>
                    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4">
                        {/* Stunning Places */}
                        <div className="flex flex-col md:flex-row gap-2 md:gap-4 items-center">
                            <img src={places} alt="" className="w-12 h-12 md:w-14 md:h-14 lg:w-16 lg:h-16" />
                            <div className="flex flex-col items-center md:items-start">
                                <h1 className="text-xl md:text-2xl font-bold">100+</h1>
                                <p className="text-sm md:text-base text-center md:text-start">Stunning Places</p>
                            </div>
                        </div>

                        {/* Tour Guide */}
                        <div className="flex flex-col md:flex-row gap-2 md:gap-4 items-center">
                            <img src={guide} alt="" className="w-12 h-12 md:w-14 md:h-14 lg:w-16 lg:h-16" />
                            <div className="flex flex-col items-center md:items-start">
                                <h1 className="text-xl md:text-2xl font-bold">50+</h1>
                                <p className="text-sm md:text-base text-center md:text-start">Tour Guides</p>
                            </div>
                        </div>

                        {/* Vehicles */}
                        <div className="flex flex-col md:flex-row gap-2 md:gap-4 items-center">
                            <img src={vehicle} alt="" className="w-12 h-12 md:w-14 md:h-14 lg:w-16 lg:h-16" />
                            <div className="flex flex-col items-center md:items-start">
                                <h1 className="text-xl md:text-2xl font-bold">50+</h1>
                                <p className="text-sm md:text-base text-center md:text-start">Vehicles</p>
                            </div>
                        </div>

                        {/* Clients */}
                        <div className="flex flex-col md:flex-row gap-2 md:gap-4 items-center">
                            <img src={clients} alt="" className="w-12 h-12 md:w-14 md:h-14 lg:w-16 lg:h-16" />
                            <div className="flex flex-col items-center md:items-start">
                                <h1 className="text-xl md:text-2xl font-bold">50+</h1>
                                <p className="text-sm md:text-base text-center md:text-start">Happy Clients</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <img
                src={Gotravel}
                alt="Go Travel"
                className="absolute top-4 left-2 md:top-12 md:left-6 lg:top-16 lg:-left-2 xl:top-7 xl:left-4 hidden lg:block rounded-lg shadow-xl lg:h-[70%] xl:h-[85%]"
            />
        </div>
    );
}

export default GoTravel;
















// import React from "react";
// import Gotravel from "../../Assets/user-imgs/Home-imgs/Gotravel.png";
// import places from '../../Assets/user-imgs/Home-imgs/places.png';
// import guide from '../../Assets/user-imgs/Home-imgs/tourGuide.png';
// import vehicle from '../../Assets/user-imgs/Home-imgs/vehicles.png';
// import clients from '../../Assets/user-imgs/Home-imgs/clients.png';
// // import call from '../../Assets/TourDetails/call.png';

// function GoTravel() {
//     return (
//         <div className="mx-6 md:mx-16 my-6 md:my-16 relative">
//             <div className="bg-[#0791BE] text-white relative p-8 xl:p-16 flex flex-col justify-center lg:ml-40 xl:ml-60 rounded-lg">
//                 <div className="lg:ml-80 xl:ml-56 ">
//                     <p className="capitalize font-serif text-lg mb-2" style={{ fontFamily: "'Times New Roman', serif" }}>Call back for more</p>
//                     <h1 className="text-2xl md:text-5xl md:leading-snug w-full xl:w-[60%] font-bold mb-4">Go Travel. Discover. Remember Us !!!</h1>
//                     <p className="opacity-70 w-full lg:w-[80%] mb-8 tracking-wide">We would love to help you realize your travel dreams, ,to start a new. Take the first steps towards making your dream a reality,We would love to help you realize your travel dreams, ,to start a new. Take the first steps towards making your dream a reality.</p>
//                     <div className="grid grid-cols-2 gap-4">
//                         <div className="flex flex-col lg:flex-row gap-2 lg:gap-4 items-center ">
//                             <img src={places} alt="" />
//                             <div className="flex flex-col items-center lg:items-start">
//                                 <h1 className="text-2xl font-bold">100+</h1>
//                                 <p className="text-md text-center md:text-start ">Stunning Places</p>
//                             </div>
//                         </div>

//                         <div className="flex flex-col lg:flex-row gap-2 lg:gap-4 items-center">
//                             <img src={guide} alt="" />
//                             <div className="flex flex-col items-center lg:items-start">
//                                 <h1 className="text-2xl font-bold">50+</h1>
//                                 <p className="text-md text-center md:text-start">Tour Guide</p>
//                             </div>
//                         </div>

//                         <div className="flex flex-col lg:flex-row gap-2 lg:gap-4 items-center">
//                             <img src={vehicle} alt="" />
//                             <div className="flex flex-col items-center lg:items-start">
//                                 <h1 className="text-2xl font-bold">50+</h1>
//                                 <p className="text-md text-center md:text-start">Vehicles</p>
//                             </div>
//                         </div>

//                         <div className="flex flex-col lg:flex-row gap-2 lg:gap-4 items-center">
//                             <img src={clients} alt="" />
//                             <div className="flex flex-col items-center lg:items-start">
//                                 <h1 className="text-2xl font-bold">50+</h1>
//                                 <p className="text-md text-center md:text-start">Tour Guide</p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <img
//                 src={Gotravel}
//                 alt="img"
//                 className="absolute lg:top-16 lg:-left-2 xl:top-7 xl:left-4 hidden lg:block rounded-lg shadow-xl lg:h-[80%] xl:h-[90%]"
//             />
//         </div>
//     );
// }

// export default GoTravel;
