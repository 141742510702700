import React from 'react'
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa'

const OfficeDetails = () => {
    return (
        <div className="md:mx-16 mx-6 pb-10">
            <h2 className="text-3xl font-bold text-gray-900 text-center tracking-wider mb-0 md:mb-7">
                Office Details
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 tracking-wide">
                {/* Head Office */}
                <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h3 className="text-2xl font-semibold text-[#F94E05] mb-4">
                        Head Office
                    </h3>
                    <div className="space-y-4">
                        <div className="flex items-center">
                            <FaEnvelope className="text-blue-600 text-lg mr-4" />
                            <span className="text-gray-800 text-sm md:text-lg">
                                info@weekendbhraman.com
                            </span>
                        </div>
                        <div className="flex items-center">
                            <FaPhone className="text-blue-600 text-lg mr-4" />
                            <span className="text-gray-800 text-lg">
                                +91-8178958591, +91-9310366002
                            </span>
                        </div>
                        <div className="flex items-center">
                            <FaMapMarkerAlt className="text-blue-600 text-lg mr-4" />
                            <span className="text-gray-800 text-lg">
                                A-37 Sachdeva Complex, Madhu Vihar I.P Ext. New Delhi-92
                            </span>
                        </div>
                    </div>
                </div>

                {/* Branch Office */}
                <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h3 className="text-2xl font-semibold text-[#F94E05] mb-4">
                        Branch Office
                    </h3>
                    <div className="space-y-4">
                        <div className="flex items-center">
                            <FaMapMarkerAlt className="text-blue-600 text-lg mr-4" />
                            <span className="text-gray-800 text-lg">
                                Haridwar: Dudhadhari Chowk, Near Rajput, Dharmsala, Bhupatwala, Haridwar-249410
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OfficeDetails
