import React, { useEffect, useState } from "react";
import axios from "axios";
import { BiSolidQuoteAltRight } from "react-icons/bi";
import { IoStar, IoStarHalf } from "react-icons/io5";
import defaultImg from "../../../Assets/Common/profile.png";
import SkeletonLoader from "../../../components/homeComponents/SkeletonLoader";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import { MdDelete } from "react-icons/md";
import toast from "react-hot-toast";
const AllFeedbacksAdmin = () => {
  const [loading, setLoading] = useState(true);
  const [AllFeedBack, setFeedBack] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  const totalPages = Math.ceil(AllFeedBack.length / itemsPerPage);
  const feedbackCategory = AllFeedBack.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePagination = (event, page) => {
    setCurrentPage(page);
  };
  const getAllFeekdBack = () => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/feedback/allFeedback`,
      headers: { "Content-Type": "application/json" },
    };
    axios
      .request(config)
      .then((response) => setFeedBack(response.data.allFeedback))
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getAllFeekdBack();
  }, []);

  const handleDelete = (id) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/feedback/deleteFeedback/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        toast.success("Successfully Delete Feedbacks!");
        getAllFeekdBack();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <React.Fragment>
      {loading ? (
        <div className="grid lg:grid-cols-2 xl:grid-cols-4 gap-6 md:grid-cols-2 grid-cols-1 md:mx-16 mx-6 mt-8 pb-6">
          {Array.from({ length: 8 }).map((_, index) => (
            <SkeletonLoader key={index} />
          ))}
        </div>
      ) : (
        <div className="grid lg:grid-cols-2 xl:grid-cols-4 gap-6 md:grid-cols-2 grid-cols-1">
          {feedbackCategory.map((testimonial, index) => (
            <div
              className="flex flex-col justify-between h-full bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105"
              key={index}
            >
              <div>
                <div className="flex justify-end px-6 pt-4 text-[#02276E]">
                  <BiSolidQuoteAltRight size={28} />
                </div>
                <div className="text-[#bbb829] mb-4 flex items-center gap-1 px-6">
                  {[...Array(4)].map((_, i) => (
                    <IoStar key={i} size={16} />
                  ))}
                  <IoStarHalf size={16} />
                </div>
                <p
                  className="text-gray-600 mb-4 text-justify tracking-wide overflow-hidden px-6"
                  style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 6,
                    overflow: "hidden",
                  }}
                  title={testimonial.feedbackReview}
                >
                  {testimonial.feedbackReview || "No review provided."}
                </p>
              </div>

              <div className="flex items-center gap-4 bg-[#f6f6d9] rounded-b-lg px-6 py-3">
                <img
                  className="w-14 h-14 rounded-full border-2 border-[#bbb829]"
                  src={testimonial.userPic || defaultImg}
                  alt="user-avatar"
                  onError={(e) => {
                    e.target.src = defaultImg;
                    e.target.onerror = null;
                  }}
                />
                <h3 className="text-orange-500 text-md tracking-wider font-semibold">
                  {testimonial.name}
                </h3>
                <MdDelete
                  className="text-blacks ml-0 cursor-pointer"
                  size={25}
                  onClick={() => handleDelete(testimonial._id)}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="flex justify-center items-center">
        <Stack spacing={2} className="mt-8 flex justify-center">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePagination}
            variant="outlined"
            shape="rounded"
            color="primary"
          />
        </Stack>
      </div>
    </React.Fragment>
  );
};

export default AllFeedbacksAdmin;
