import React, { useEffect, useState } from 'react';
import { MdOutlineArrowOutward } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const OurCategories = () => {
    const navigate = useNavigate();
    const [tourCategories, setTourCategories] = useState([]);
    const [locationCategories, setLocationCategories] = useState([]);

    useEffect(() => {
        // Fetch tour categories
        axios.get('https://weekend-bhraman-sy15.onrender.com/tours-categories/get-all-categories')
            .then(response => {
                setTourCategories(response.data);
            })
            .catch(error => {
                console.log("Error fetching tour categories", error);
            });

        // Fetch location categories
        axios.get('https://weekend-bhraman-sy15.onrender.com/location-categories/get-all-location-categories')
            .then(response => {
                setLocationCategories(response.data);
            })
            .catch(error => {
                console.log("Error fetching location categories", error);
            });
    }, []);

    const handleFilter = (categoryFilter, locationCategoryFilter) => {
        const queryParams = new URLSearchParams({
            search: "",
            price: "",
            location: "",
            type: "",
            category: categoryFilter || "",
            locationCategory: locationCategoryFilter || "",
        }).toString();

        // Navigate to the filtered DestinationsTours page
        navigate(`/filtered-tours-fron-home?${queryParams}`);
        window.scrollTo(0, 0);
    };

    return (
        <div className='md:mx-16 mx-6'>
            <div>
                <div className="text-[#F94E05] text-lg text-center pt-10 pb-6"
                    style={{ fontFamily: "'Times New Roman', serif" }}>
                    <h2 className="text-orange-500 text-lg tracking-wide">
                        Tour Categories
                    </h2>
                    <h1
                        className="text-2xl md:text-4xl tracking-wide font-bold text-[#051836] mt-2"
                        style={{ fontFamily: "'Be Vietnam Pro', sans-serif" }}>
                        Holiday Destinations
                    </h1>
                </div>

                {/* Tours Category */}
                <div className="grid grid-cols-1 md:grid-cols-5 gap-2 md:gap-6">
                    {tourCategories.map((category, index) => (
                        <div
                            key={index}
                            onClick={() => handleFilter(category.category_name, '')}
                            className="relative md:col-start-0 group cursor-pointer">
                            <img
                                src={category?.img_url}  // Display random image
                                alt={category.category_name} className="w-full h-full object-cover rounded-lg"
                            />
                            <div className="absolute bottom-4 left-4 text-white md:text-xl font-semibold tracking-wider">
                                {category.category_name.charAt(0).toUpperCase() + category.category_name.slice(1)} Tours
                            </div>
                            <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 rounded-lg transition-opacity duration-300">
                                <div className="absolute top-4 right-4 text-white">
                                    <MdOutlineArrowOutward className="text-2xl" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="text-[#F94E05] text-lg text-center pt-10"
                    style={{ fontFamily: "'Times New Roman', serif" }}>
                    <h2 className="text-orange-500 text-lg tracking-wide">
                        Tours By Location
                    </h2>
                    <h1
                        className="text-2xl md:text-4xl tracking-wide font-bold text-[#051836] mt-2"
                        style={{ fontFamily: "'Be Vietnam Pro', sans-serif" }}>
                        Travel Hotspots
                    </h1>
                </div>

                {/* Location Category */}
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mt-6">
                    {locationCategories.map((location, index) => (
                        <div
                            key={index}
                            onClick={() => handleFilter('', location.location_name)}
                            className="bg-white rounded-lg shadow p-3 flex space-x-6 items-center cursor-pointer">
                            <img
                                src={location?.img_url}  // Display random image for location
                                alt={location.location_name}
                                className="w-24 h-24 rounded-full mr-4"
                            />
                            <div>
                                <h2 className="capitalize text-xl font-bold">{location.location_name}</h2>
                                <p className='capitalize'>{location.applied_for}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default OurCategories;