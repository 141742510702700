import React, { useState } from "react";
import { Loading } from "./Loading";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const RazorPays = ({ bookingData, amount, PriceDetails }) => {
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState("");
  const [payment, setPayment] = useState(null);
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;
  const RAZORPAY_KEY_ID = process.env.REACT_APP_RAZORPAY_KEY_ID;

  const sendMail = async () => {
    const emailData = [
      {
        email: bookingData?.formData?.email || "",
        userName: bookingData?.formData?.name,
        send_amount: amount,
        customerName: bookingData?.formData?.name,
        customerEmail: bookingData?.formData?.email,
        customerPhone: bookingData?.formData?.phone,
        departureDate: bookingData?.tourData?.arrival_date,
        numberOfAdults: bookingData?.formData?.adultsCount,
        numberOfChildren: bookingData?.formData?.childrenCount,
        packageName: bookingData?.tourData?.name,
        typeOfPackage: bookingData?.selectedPackageType,
        departureTime: bookingData?.tourData?.departure_time,
        food: bookingData?.tourData?.meals,
        duration: bookingData?.tourData?.tour_duration,
        totalPrice: PriceDetails?.Total_price,
        gst: PriceDetails?.gst,
        totalMemberPrice: PriceDetails?.Total_Member_Price,
        totalAdultPrice: PriceDetails?.Total_Adult_Price,
        totalChildrentPrice: PriceDetails?.Total_Childrent_Price,
      },
      {
        email: "tripathysatyaranjan3@gmail.com",
        userName: "admin",
        received_amount: amount,
        ...bookingData?.formData,
        ...bookingData?.tourData,
        totalPrice: PriceDetails?.Total_price,
        gst: PriceDetails?.gst,
        totalMemberPrice: PriceDetails?.Total_Member_Price,
        totalAdultPrice: PriceDetails?.Total_Adult_Price,
        totalChildrentPrice: PriceDetails?.Total_Childrent_Price,
      },
    ];

    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiUrl}/email/sendPaymentEmails`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(emailData),
      };

      const response = await axios.request(config);
      setLoading(false);

      if (response?.data) {
        navigate("/paymentSuccess");
      } else {
        navigate("/paymentFailure");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      navigate("/paymentFailure");
    }
  };

  const saveUserData = async () => {
    const userData = {
      userName: bookingData?.formData?.name || "",
      email: bookingData?.formData?.email || "",
      contact: bookingData?.formData?.phone || "",
      country_code: "IN",
      city: bookingData?.formData?.departureCity || "",
      comment: "Order payment",
      selectPackage: bookingData?.tourData?._id || "",
      packageCategory_id: bookingData?.tourData?._id || "",
    };

    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiUrl}/user/create`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(userData),
      };

      await axios.request(config);
      sendMail();
    } catch (error) {
      console.error("Error saving user data:", error);
    }
  };

  const initiatePayment = async () => {
    const localPackageData = JSON.parse(localStorage.getItem("packageData")) || {};

    try {
      const paymentData = {
        amount,
        currency: "INR",
        name: localPackageData?.packageCategory?.packageName,
        description: localPackageData?.packageCategory?.packageName,
        email: bookingData?.formData?.email,
        number: bookingData?.formData?.phone,
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiUrl}/razorpay/orders`,
        headers: {
          "Content-Type": "application/json",
        },
        data: paymentData,
      };

      const response = await axios.request(config);
      return response?.data?.order;
    } catch (error) {
      console.error("Error initiating payment:", error);
      return null;
    }
  };

  const loadRazorpayScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => reject(false);
      document.body.appendChild(script);
    });
  };
  
  const openRazorpayCheckout = async () => {
    setLoadingData("Opening Razorpay...");
    setLoading(true);
  
    const isLoaded = await loadRazorpayScript();
    if (!isLoaded) {
      console.error("Failed to load Razorpay script");
      alert("Unable to load Razorpay. Please try again.");
      setLoading(false);
      return;
    }
  
    const apiKey = RAZORPAY_KEY_ID;
    const order_id = await initiatePayment();
  
    if (order_id && window.Razorpay) {
      console.log("Razorpay script loaded, initializing checkout...");
      const options = {
        key: apiKey,
        amount,
        currency: "INR",
        name: bookingData?.formData?.name,
        description: `Purchase of ${bookingData?.tourData?.name}`,
        order_id,
        prefill: {
          name: bookingData?.formData?.name,
          email: bookingData?.formData?.email,
          contact: bookingData?.formData?.phone,
        },
        handler: async (response) => {
          try {
            const config = {
              method: "post",
              url: `http://localhost:5001/razorpay/payment-callback`,
              data: response,
            };
  
            const paymentResponse = await axios.request(config);
  
            if (paymentResponse?.data?.payment_status === true) {
              setPayment(paymentResponse?.data);
              setLoadingData("Processing payment...");
              saveUserData();
            }
          } catch (error) {
            console.error("Error handling payment callback:", error);
          }
        },
        modal: {
          ondismiss: () => {
            if (!window.confirm("Are you sure you want to close the payment form?")) {
              openRazorpayCheckout();
            }
          },
        },
      };
  
      setLoading(false);
      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } else {
      alert("Unable to create order. Please try again.");
      setLoading(false);
    }
  };
  
  return (
    <>
      {loading && <Loading data={loadingData} />}

      <button
        onClick={openRazorpayCheckout}
        disabled={loading}
        className="tracking-wide font-semibold mt-4 w-full px-6 py-2 text-lg text-white bg-[#ff783e] hover:bg-[#d4531c] transition ease-in-out hover:duration-300 rounded-full shadow-lg"
      >
        {loading ? "Processing..." : "Pay & Book Now"}
      </button>

      {payment && (
        <div className="mt-4 text-center">
          <h2 className="text-lg font-bold text-green-600">Payment Successful!</h2>
          <p className="text-sm text-gray-500">Payment ID: {payment.razorpay_payment_id}</p>
        </div>
      )}
    </>
  );
};
