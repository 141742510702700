import React from 'react';
import SimpleBanner from '../../components/common/SimpleBanner';
import DestinationsBanner from '../../Assets/user-imgs/Home-imgs/FeedbackBanner.png';
import FilterDestinationsTours from '../../components/homeComponents/FilterDestinationsTours';

const FilteredTours = () => {
    return (
        <div className='bg-white'>
            <SimpleBanner
                image={DestinationsBanner}
                name="Filtered Tours"
                path="Home / Filtered Tours"
            />
            <FilterDestinationsTours />
        </div>
    )
}

export default FilteredTours