import React from "react";
import AllHotels from "../../components/HotelsAdmin/AllHotels";
import { useNavigate } from "react-router-dom";

const Hotels = () => {
  const navigate = useNavigate();
  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-5">
        <h1 className="lg:text-3xl sm:text-2xl text-xl font-semibold mb-2">
          Hotels List
        </h1>
        <button
          onClick={() => navigate("/add-new-hotel")}
          className="bg-gradient-to-r sm:text-lg text-md flex items-center from-yellow-400 to-orange-500 hover:bg-yellow-800 text-white md:px-5 px-3 py-2 tracking-wide rounded-md hover:bg-gradient-to-r hover:to-yellow-400 hover:from-orange-500"
        >
          Add New Hotel
        </button>
      </div>

      <AllHotels />
    </div>
  );
};

export default Hotels;
