import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import halfstar from "../../Assets/user-imgs/hotels/half-star.svg";
import fullstar from "../../Assets/user-imgs/hotels/star.png";

const AllHotelsList = ({ filteredHotels, likedItems, toggleHeart }) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      {filteredHotels.map((hotel, index) => (
        <div
          key={index}
          className="container mx-auto mb-6 py-2 shadow-md border-[1px] border-gray-400 rounded-md"
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-7 lg:grid-cols-7 gap-1 ">
            <div className="col-span-2 md:col-span-2 lg:col-span-2 ">
              <div className="p-4 h-auto w-auto relative">
                <img src={hotel.images[0]} alt="" className="rounded-lg" />

                {/* <div
                                    className={`absolute top-7 right-8 p-1 rounded-full shadow-md cursor-pointer ${likedItems[index] ? "bg-red-500" : "bg-white"
                                        }`}
                                    onClick={() => toggleHeart(index)}
                                >
                                    <CiHeart
                                        className={`text-2xl ${likedItems[index] ? "text-white" : "text-gray-500"
                                            }`}
                                    />
                                </div> */}
              </div>
            </div>

            <div className="col-span-3 md:col-span-3 lg:col-span-3 w-full flex flex-col justify-center xl:gap-6 sm:gap-4 gap-0 items-start px-4 sm:px-0">
              <div className="flex flex-col justify-start items-start xl:gap-1 gap-0">
                <h1 className="capitalize xl:text-xl text-lg text-[#2D87D0] font-bold mt-1 tracking-wide">
                  {hotel.name}
                </h1>

                <div className="capitalize flex justify-start gap-4 text-[#797c80] text-sm">
                  <p>
                    {hotel.address}
                    {", "} {hotel.city}
                  </p>
                </div>
              </div>

              <div className="flex justify-start items-start tracking-wide xl:gap-1 gap-0 flex-col">
                <p className="font-bold capitalize">{hotel.type}</p>
                <p>
                  {hotel?.description?.length > 100
                    ? hotel?.description?.substring(0, 100) + "..."
                    : hotel?.description}
                </p>

                <div className="text-sm font-semibold text-red-600 my-2">
                  Hurry up.. Very few rooms are left.
                </div>
              </div>
            </div>

            <div className="col-span-2 md:col-span-2 lg:col-span-2 flex flex-col md:justify-around justify-center  sm:items-end pr-4 items-start sm:pb-0 pl-4 sm:pl-0">
              <div className="flex justify-center items-center gap-2 mt-1">
                <div>Customers Ratings</div>

                <div className="bg-[#2a7225] rounded-lg text-xs px-3 py-1 text-white font-semibold">
                  {hotel.rating.toString().includes(".")
                    ? hotel.rating
                    : hotel.rating + ".0"}
                </div>
              </div>

              <div className="flex justify-start items-center gap-1 text-yellow-500 text-xl">
                {Array.from(
                  { length: Math.floor(hotel.rating) },
                  (_, index) => (
                    <span key={index}>
                      <img
                        className="h-5 w-auto"
                        src={fullstar}
                        alt="fullstar"
                      />
                    </span>
                  )
                )}
                {hotel.rating % 1 !== 0 && (
                  <span key={index}>
                    <img className="h-5 w-auto" src={halfstar} alt="halfstar" />
                  </span>
                )}
              </div>

              <div className="flex justify-start sm:items-end items-start flex-col xl:gap-1 gap-0">
                <p className=" font-bold">
                  {/* <del className="text-[#D3042D] xl:text-md text-sm">
                                        ₹ 2,999
                                    </del>{" "} */}
                  <span className="xl:text-xl text-lg text-[#000000]">
                    ₹ {hotel.basePrice} /-
                  </span>
                </p>
                <p className="mb-2 lg:text-[13px] text-[12px]">
                  Including Taxes and Charges
                </p>
                <button
                  className="bg-[#2D87D0] xl:px-5 px-4 xl:py-2 py-1.5 flex justify-around items-center text-white rounded-md gap-3"
                  onClick={() => navigate(`/hotel-details/${hotel._id}`)}
                >
                  Details <FaArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

export default AllHotelsList;
