import React from 'react'
import img from '../../Assets/user-imgs/Home-imgs/Commitments.png'
const OurCommitments = () => {
    return (
        <div className='grid grid-cols-1 md:grid-cols-2 md:mx-16 mx-6 items-center'>
            <div className='flex flex-col space-y-3 tracking-wide'>
                <h2 className="text-[#F94E05] tracking-wide md:text-lg mt-4 md:mt-0"
                style={{ fontFamily: "'Times New Roman', serif" }}>
                    Features
                </h2>
                <h1 className="md:text-4xl text-2xl tracking-wide font-bold text-[#051836] mt-2"
                style={{ fontFamily: "'Be Vietnam Pro', sans-serif" }}>
                   Our Commitments !
                </h1>
                <p className="text-gray-700 md:text-lg text-justify leading-relaxed tracking-wide">
                    Peaceful stay and a WEEKEND TOUR PLANNER helps you to make your weekend memorable in UTTRAKHAND without any hurdles .
                </p>
                <ul className='list-disc space-y-2 text-gray-700 md:text-lg text-justify leading-relaxed tracking-wide'>
                    <li>We don't fight price, we only fight quality, service, and after-sales.</li>
                    <li>We can't guarantee the lowest price, but we can assure you the highest quality, accommodation, transportation, and all kinds of services during the journey.</li>
                    <li>We only do repeat business, not one-time business. Free shopping.</li>
                    <li>Trust us! Choose us! We will not disappoint your trust and expectations.</li>
                    <li>The same price compares quality, the same quality compares price, the same price compares service, and the same service compares after-sales.</li>
                </ul>
            </div>
            <div>
                <img src={img} alt='img' className='mx-auto' />
            </div>
        </div>
    )
}

export default OurCommitments