import React from "react";
import Gallery1 from "../../Assets/user-imgs/Home-imgs/gallery1.png";
import Gallery2 from "../../Assets/user-imgs/Home-imgs/gallery2.png";
import Gallery3 from "../../Assets/user-imgs/Home-imgs/gallery3.png";
import Gallery4 from "../../Assets/user-imgs/Home-imgs/gallery4.png";

const OurTourGallery = () => {
  return (
    <div className="md:mx-16 mx-6 mt-10 grid grid-cols-1 gap-4 xl:gap-6">
      <div className="grid grid-cols-1 lg:grid-cols-5 gap-4 items-center">
        <div className="flex flex-col lg:col-span-2 p-2 lg:p-8 md:p-0">
          <h2 className="text-[#F94E05] text-lg tracking-wide"
            style={{ fontFamily: "'Times New Roman', serif" }}>
            Our Tour Gallery
          </h2>
          <h1
            className="font-bold text-[#051836] text-2xl md:text-4xl tracking-wide md:leading-tight mb-4"
            style={{ fontFamily: "'Be Vietnam Pro', sans-serif" }}
          >
            Traveler's <br />
            Shared Photo
          </h1>
          <p className="text-[#54575B] font-semibold tracking-wide leading-relaxed w-[90%]" style={{ fontFamily: "'Be Vietnam Pro', sans-serif" }}>
            Here we are showing top traveler’s shared photo & videos. All tour
            photos are in gallery page. You can check there for more.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:col-span-3 ">
          <img src={Gallery1} alt="" className="object-cover w-full h-full rounded-md" />
          <img src={Gallery2} alt="" className="object-cover w-full h-full rounded-md" />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6">
        <div className="md:col-span-1 lg:col-span-2">
          <img src={Gallery3} alt="" className=" object-cover h-full w-full rounded-md" />
        </div>
        <div className="md:col-span-2 lg:col-span-3">
          <img src={Gallery4} alt="" className="object-cover h-full w-full rounded-md" />
        </div>
      </div>
    </div>
  );
};

export default OurTourGallery;
