import React, { useEffect, useState } from "react";
import { ImLocation2 } from "react-icons/im";

import Cab_booking_faqs from "../../components/Booking/Cab_booking_faqs";
import Aminities from "../../components/Booking/Aminities";
import SimpleBanner from "../../components/common/SimpleBanner";
import Carbanner from "../../Assets/Common/CarBooking.png";
import { useParams } from "react-router-dom";
import axios from "axios";
import { MdLocationOn } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { FaArrowAltCircleRight } from "react-icons/fa";

const CabDetails = () => {
  const { id } = useParams();
  const [cabData, setCabData] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [address, setAddress] = useState("");
  const [destination, setDestination] = useState("");
  const [updatedData, setUpdatedData] = useState("");
  const [error, setError] = useState({});
  useEffect(() => {
    handleCabsbyID();
  }, []);

  const navigate = useNavigate();

  const colors = [
    "#F94E05",
    "#3498DB",
    "#2ECC71",
    "#9B59B6",
    "#34495E",
    "#F1C40F",
    "#95A5A6",
  ];

  const handleCabsbyID = () => {
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/cabs/get-cab-by-id/${id}`,
      headers: {},
      data: data,
    };
    setIsloading(true);

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setCabData(response.data);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsloading(false);
      });
  };
  console.log("cabData", cabData);

  const handleFormSubmit = () => {
    let formErrors = {};

    if (!startDate) {
      formErrors.startDate = "Please select a start date.";
    }
    if (!endDate) {
      formErrors.endDate = "Please select an end date.";
    }
    if (!address) {
      formErrors.address = "Please enter the starting location.";
    }
    if (!destination) {
      formErrors.destination = "Please enter the destination.";
    }

    setError(formErrors);

    if (Object.keys(formErrors).length > 0) {
      setTimeout(() => setError({}), 3000);
      return;
    }
    const formData = {
      cabName: cabData.model,
      carType: cabData.type,
      cabRating: cabData.rating,
      cabState: cabData.avail_in_state,
      cabCity: cabData.avail_in_city,
      cabPrice: cabData.farePerKm,
      cabID: cabData._id,
      cabColor: cabData.color,
      startDate,
      endDate,
      address,
      destination,
    };

    localStorage.setItem("formData", JSON.stringify(formData));

    console.log("Form Data:", formData);
    navigate("/cab-booking-form");
  };

  console.log("start date", startDate);
  console.log("end date", endDate);
  console.log("From", address);
  console.log("destination", destination);

  return (
    <div>
      <SimpleBanner
        image={Carbanner}
        name="Cab Booking"
        path="Cabs / Cab list"
      />
      {isloading ? (
        <div className="col-span-7">
          <SkeletonLoader />
        </div>
      ) : (
        <>
          <div className="flex justify-center items-start  bg-white sm:px-5 px-4 font-be-vietnam lg:flex-row flex-col">
            <div className="lg:w-[70%] w-full">
              <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center py-5 gap-4 lg:gap-0 sm:px-5 bg-white font-be-vietnam">
                <div className="w-full px-2">
                  <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center">
                    <h1 className="text-lg lg:text-xl font-bold tracking-wide">
                      {cabData.model}({cabData.type})
                    </h1>

                    <div className="flex justify-end items-center gap-2 mt-2 lg:mt-0">
                      <p className="text-gray-600 text-sm lg:text-base tracking-wide">
                        Customer ratings
                      </p>
                      <div className="bg-[#2a7225] rounded-lg text-xs px-3 py-1 text-white font-semibold">
                        {cabData?.rating % 1 === 0
                          ? `${cabData.rating}.0`
                          : cabData.rating}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row justify-between items-start mt-2 gap-2 ">
                    <div className="">
                      <p className="font-medium tracking-wide text-gray-700">
                        Capacity:{cabData?.capacity}
                      </p>
                      <p className="tracking-wide text-md text-gray-700">
                        Price:₹{cabData?.farePerKm}/km
                      </p>
                      {/* <div className="flex justify-start items-center gap-3">
                    <MdLocationOn size={20} className="text-gray-600" />
                    <p className="text-md text-gray-600">
                      {cabData.avail_in_state},{cabData.avail_in_city}
                    </p>
                  </div> */}
                    </div>
                    <div className="flex flex-col item-start md:items-end tracking-wide">
                      <p className="tracking-wide text-md text-gray-700">
                        Manufacturer:{cabData?.manufacturer}
                      </p>
                      <p className="tracking-wide text-md text-gray-700">
                        Color:{cabData?.color}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sm:px-7">
                <div className="col-span-2">
                  <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
                    <div className="sm:col-span-3 w-full">
                      <img
                        src={cabData?.imagesUrls?.[0] || null}
                        alt="Main Hotel Room"
                        className="xl:w-[100%] md:w-[770px] h-[250px] sm:h-[300px] md:h-[320px] object-cover rounded-lg"
                      />
                    </div>
                    <div className="grid grid-rows-2 gap-4">
                      <img
                        src={cabData?.imagesUrls?.[1] || null}
                        alt="Hotel Room"
                        className="w-full h-[120px] sm:h-[140px] md:h-[150px] object-cover rounded-lg"
                      />
                      <img
                        src={cabData?.imagesUrls?.[2] || null}
                        alt="Hotel Room"
                        className="w-full h-[120px] sm:h-[140px] md:h-[150px] object-cover rounded-lg"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 mt-4">
                    <img
                      src={cabData?.imagesUrls?.[3] || null}
                      alt="Additional Room 1"
                      className="w-full h-[120px] sm:h-[140px] md:h-[150px] object-cover rounded-lg"
                    />
                    <img
                      src={cabData?.imagesUrls?.[4] || null}
                      alt="Additional Room 2"
                      className="w-full h-[120px] sm:h-[140px] md:h-[150px] object-cover rounded-lg"
                    />
                    <img
                      src={cabData?.imagesUrls?.[5] || null}
                      alt="Additional Room 3"
                      className="w-full h-[120px] sm:h-[140px] md:h-[150px] object-cover rounded-lg"
                    />
                    <img
                      src={cabData?.imagesUrls?.[6] || null}
                      alt="Additional Room 4"
                      className="w-full h-[120px] sm:h-[140px] md:h-[150px] object-cover rounded-lg"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:px-7 mt-5">
                <h1 className="text-lg sm:text-xl font-bold pt-5 font-be-vietnam">
                  Features
                </h1>
                <div className="flex flex-wrap flex-col sm:flex-row justify-between gap-5 lg:gap-10 sm:items-center items-start text-sm sm:text-base pt-4">
                  {cabData?.extraFeatures?.map((feature, index) => (
                    <div
                      key={index}
                      className="col-span-1 flex justify-start items-center gap-2"
                    >
                      <FaArrowAltCircleRight
                        className="text-[#F94E05] text-sm md:text-base lg:text-lg xl:text-xl"
                        style={{ color: colors[index % colors.length] }}
                      />
                      <span className="sm:text-base xl:text-md text-[#606060] ml-2 tracking-wide">
                        {feature}
                      </span>
                    </div>
                  ))}
                </div>

                <p className="sm:text-base xl:text-md text-justify leading-normal pt-5 tracking-wide capitalize">
                  {cabData?.description}
                </p>
              </div>

              <div className="sm:px-7">
                <div className="xl:col-span-3 lg:col-span-2">
                  <Cab_booking_faqs />
                </div>
              </div>
            </div>
            <div className="lg:w-[30%] w-full">
              <div className="lg:col-span-2 lg:mt-4 flex justify-start flex-col lg:pr-7 sm:px-4 lg:px-0">
                <div className="col-span-1">
                  <div className="text-xl font-semibold tracking-wide mb-2">
                    Pick your date
                  </div>
                  <div className=" w-full flex justify-center flex-col items-start">
                    <div className="w-full">
                      <label
                        htmlFor="start-date"
                        className="block  text-md font-semibold text-gray-700 mb-1"
                      >
                        Start Date:
                      </label>
                      <input
                        id="start-date"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className={`w-full border-2 text-sm rounded-md p-2 focus:outline-none ${
                          error.startDate
                            ? "border-red-500"
                            : "focus:border-blue-300"
                        }`}
                      />
                      {error.startDate && (
                        <p className="text-red-500 text-sm mt-1">
                          {error.startDate}
                        </p>
                      )}
                    </div>
                    <div className="w-full mt-2">
                      <label
                        htmlFor="end-date"
                        className="block  text-md font-semibold text-gray-700 mb-1"
                      >
                        End Date:
                      </label>
                      <input
                        id="end-date"
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className={`w-full border-2 text-sm rounded-md p-2 focus:outline-none ${
                          error.endDate
                            ? "border-red-500"
                            : "focus:border-blue-300"
                        }`}
                      />
                      {error.endDate && (
                        <p className="text-red-500 text-sm mt-1">
                          {error.endDate}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="mt-3">
                    <label
                      htmlFor="firstName"
                      className="block text-md font-semibold text-gray-700"
                    >
                      From:
                    </label>
                    <input
                      type="text"
                      id="address"
                      placeholder="Enter the place where you want to start.."
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className={`mt-1 block w-full h-9 border-[1px] px-2 ${
                        error.address ? "border-red-500" : "border-black"
                      } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                    />
                    {error.address && (
                      <p className="text-red-500 text-sm mt-1">
                        {error.address}
                      </p>
                    )}
                  </div>

                  <div className="mt-3">
                    <label
                      htmlFor="firstName"
                      className="block text-md font-semibold text-gray-700"
                    >
                      Destination:
                    </label>
                    <input
                      type="text"
                      id="destination"
                      placeholder="Enter your destination.."
                      value={destination}
                      onChange={(e) => setDestination(e.target.value)}
                      className={`mt-1 block w-full h-9 border-[1px] px-2 ${
                        error.destination ? "border-red-500" : "border-black"
                      } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                    />
                    {error.destination && (
                      <p className="text-red-500 text-sm mt-1">
                        {error.destination}
                      </p>
                    )}
                  </div>

                  <div className="flex items-center bg-gray-100 p-3 rounded-md shadow-md text-gray-700 text-sm mb-6 tracking-wide mt-4">
                    <span className="text-3xl mr-2">🚗</span>
                    <span className="text-justify capitalize">
                      Enter the above details to make your ride Joyful..
                    </span>
                  </div>

                  <div className="text-center mt-4">
                    <button
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        handleFormSubmit();
                      }}
                      className="w-full sm:py-3 py-2 px-6 tracking-wide bg-[#F94E05] text-white font-semibold rounded-lg hover:bg-[#D24E02] transition duration-300"
                    >
                      Book Cab
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CabDetails;

const SkeletonLoader = () => {
  return (
    <>
      <div className="flex justify-between items-start lg:flex-row flex-col gap-6 px-4 sm:px-0 lg:px-3 bg-white">
        <div className=" lg:w-[70%] rounded-lg w-full  ">
          <div className="flex justify-between md:flex-row flex-col items-center gap-3">
            <div className="h-[300px] md:w-[75%] w-full bg-gray-300 rounded-lg"></div>
            <div className="h-[300px] md:w-[25%] w-full flex flex-col justift-start items-start gap-3">
              <div className="h-[150px] w-full bg-gray-300 rounded-lg"></div>
              <div className="h-[150px] w-full bg-gray-300 rounded-lg"></div>
            </div>
          </div>
          <div className="flex justify-between items-center md:flex-row flex-col mt-3 gap-3 ">
            <div className="h-[150px] w-full bg-gray-300 rounded-lg "></div>
            <div className="h-[150px] w-full bg-gray-300 rounded-lg"></div>
            <div className="h-[150px] w-full bg-gray-300 rounded-lg"></div>
            <div className="h-[150px] w-full bg-gray-300 rounded-lg"></div>
          </div>
        </div>
        <div className="lg:w-[30%] w-full h-auto rounded-lg   animate-pulse  gap-3  ">
          <div className="h-7 w-[50%] bg-gray-300 rounded-lg"></div>
          <div className="h-4 w-full mt-3 bg-gray-300 rounded-lg"></div>
          <div className="h-5 w-[50%] bg-gray-300 rounded-lg mt-3"></div>
          <div className="h-7 w-full mt-3 bg-gray-300 rounded-lg"></div>
          <div className="h-7 w-[150px] mt-3 bg-gray-300 rounded-lg"></div>
          <div className="h-7 w-full mt-3 bg-gray-300 rounded-lg"></div>
          <div className="h-7 w-[150px] mt-3 bg-gray-300 rounded-lg"></div>
          <div className="h-7 w-full mt-3 bg-gray-300 rounded-lg"></div>
          <div className="h-7 w-[150px] mt-3 bg-gray-300 rounded-lg"></div>
          <div className="h-7 w-full mt-3 bg-gray-300 rounded-lg"></div>

          <div className="flex justify-between items-center w-full mt-4 gap-3">
            <div className="lg:h-[50px] h-[30px] w-[50px] rounded-xl bg-gray-300"></div>
            <div className="lg:h-[50px] h-[30px] w-full  bg-gray-300 rounded-lg"></div>
          </div>
        </div>
      </div>
    </>
  );
};
