import React, { useState } from "react";
import enjoy from "../../Assets/user-imgs/Home-imgs/enjoy.svg";
import img1 from '../../Assets/user-imgs/Home-imgs/enjoylocation.png';
import HomePageFilter from "./HomePageFilter";

const EnjoyTheBest = () => {
  function DateFieldWithPlaceholder() {
    const [inputType, setInputType] = useState("text");
    const [date, setDate] = useState("");

    const handleFocus = () => {
      setInputType("date");
    };

    const handleBlur = () => {
      if (!date) {
        setInputType("text");
      }
    };

    return (
      <input
        type={inputType}
        value={date}
        onChange={(e) => setDate(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        id="date"
        className="w-full p-2 border-none focus:outline-none rounded placeholder:text-black"
        placeholder="Select date"
      />
    );
  }

  return (
    <React.Fragment>
      <div className="md:mx-16 mx-6 mt-10">
        <HomePageFilter />
      </div>
    </React.Fragment>
  );
};

export default EnjoyTheBest;
