import React from "react";

const TourSkeletonLoader = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-5">
      {Array.from({ length: 6 }).map((_, index) => (
        <div
          className="bg-white rounded-lg shadow-lg overflow-hidden animate-pulse"
          key={index}
        >
          <div className="w-full h-72 bg-gray-200"></div>
          <div className="p-4 space-y-4">
            <div className="h-6 bg-gray-200 rounded"></div>
            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
            <div className="h-12 bg-gray-200 rounded"></div>
            <div className="h-10 bg-gray-200 rounded w-1/2"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TourSkeletonLoader;
