import React from 'react';
import img1 from '../../Assets/user-imgs/Home-imgs/aboutus.png';
import call from '../../Assets/user-imgs/Home-imgs/callapp.svg';

const WhoWeAre = () => {
    return (
        <div className='grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-16 mx-4 sm:mx-8 lg:mx-16 mt-10'>
            {/* Image Section */}
            <img 
                src={img1} 
                alt='About Us' 
                className='w-full h-auto rounded-md mx-auto' 
            />

            {/* Text Content */}
            <div className='flex flex-col space-y-6'>
                <h2 
                    className="text-[#F94E05] tracking-wide text-lg sm:text-xl mt-4 md:mt-0"
                    style={{ fontFamily: "'Times New Roman', serif" }}
                >
                    About Us
                </h2>
                <h1 
                    className="text-2xl md:text-4xl font-bold tracking-wide text-gray-800"
                    style={{ fontFamily: "'Be Vietnam Pro', sans-serif" }}
                >
                    Who We Are?
                </h1>
                <p className="text-gray-700 text-sm sm:text-base md:text-lg text-justify leading-relaxed tracking-wide">
                    WEEKEND BHRAMAN TOUR PLANNER offers a variety of Private Tours ideal for families and independent travelers seeking flexibility route, we offer the strongest destination knowledge and best value quality tours in Uttarakhand Himachal or others.
                </p>
                <p className="text-gray-700 text-sm sm:text-base md:text-lg text-justify leading-relaxed tracking-wide">
                    Travel your next vacation with us and enjoy our amazing experiences in the “Land of God”! With our huge knowledge in the field, we are in a position to offer you an in-depth collection of exciting journeys, which are not available elsewhere. We reveal the many hidden secrets and unexplored havens in this colorful and enticing land, along with some more familiar and well-known sights. India has a vast variety of glorious palace hotels, small family-run forts, and people with an overall desire to please and be hospitable.
                </p>

                {/* Experience and Contact Section */}
                <div className='flex flex-col md:flex-row items-center md:space-x-10 space-y-4 md:space-y-0'>
                    {/* Experience Box */}
                    <div className='bg-[#101F46] text-center text-white py-5 px-8 rounded-lg'>
                        <span className="text-4xl font-bold">10+</span>
                        <br />
                        <span 
                            className='text-[#FF4A52] text-sm sm:text-base' 
                            style={{ fontFamily: "'Times New Roman', serif" }}
                        >
                            Years of<br />Experience
                        </span>
                    </div>
                    
                    {/* Contact Info */}
                    <div className='flex items-center space-x-4 md:space-x-6'>
                        <img 
                            src={call} 
                            alt='Call' 
                            className='w-12 md:w-16' 
                        />
                        <div className='text-center md:text-left'>
                            <h1 className='text-base sm:text-lg md:text-xl font-semibold'>
                                We Always Ready For You
                            </h1>
                            <p className='text-[#FF4A52] text-sm sm:text-base md:text-lg'>
                                +91 8178958591
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhoWeAre;















// import React from 'react'
// import img1 from '../../Assets/user-imgs/Home-imgs/aboutus.png'
// import call from '../../Assets/user-imgs/Home-imgs/callapp.svg'
// const WhoWeAre = () => {
//     return (
//         <div className='grid grid-cols-1 md:grid-cols-2 md:mx-16 mx-6 mt-10'>
//             <img src={img1} alt='img' className=' mx-auto' />

//             <div className='flex flex-col space-y-4'>
//                 <h2 className="text-[#F94E05] tracking-wide md:text-lg mt-4 md:mt-0"
//                     style={{ fontFamily: "'Times New Roman', serif" }}
//                 >
//                     About Us
//                 </h2>
//                 <h1 className="text-2xl md:text-4xl font-bold tracking-wide text-gray-800"
//                     style={{ fontFamily: "'Be Vietnam Pro', sans-serif" }}
//                 >
//                     Who We Are ?
//                 </h1>
//                 <p className="text-gray-700 md:text-lg text-justify leading-relaxed tracking-wide">
//                     WEEKEND BHRAMAN TOUR PLANNER offers a variety of Private Tours ideal for families and independent travelers seeking flexibility route, we offer the strongest destination knowledge and best value quality tours in Uttarakhand Himachal or others.
//                 </p>
//                 <p className="text-gray-700 md:text-lg text-justify leading-relaxed tracking-wide">
//                     Travel your next vacation with us and enjoy our amazing experiences in the “Land of God”! With our huge knowledge in the field, we are in a position to offer you an in depth collection of exciting journeys, which are not available elsewhere. We reveal the many hidden secrets and unexplored havens in this colorful and enticing land, along with some more familiar and well known sights. India has a vast variety of glorious palace hotels, small family run forts and people with an overall desire to please and be hospitable.
//                 </p>
//                 <div className='flex md:flex-row flex-col space-y-2 space-x-10'>
//                     <div className='bg-[#101F46] text-2xl py-3 px-10 rounded-md text-white tracking-wide text-center'>
//                         10+<br />
//                         <span className='text-[#FF4A52]' style={{ fontFamily: "'Times New Roman', serif" }}>Years of<br /> Experience</span>
//                     </div>
//                     <div className='flex items-center justify-center tracking-wide space-x-7'>
//                         <img src={call} alt='img' className='w-16' />
//                         <div>
//                             <h1>We Always Ready For You</h1>
//                             <p className='text-[#FF4A52]'>+91 8178958591</p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default WhoWeAre