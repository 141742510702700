import React from "react";

const SkeletonLoaderForHistory = () => {
  return (
    <>
      <div className="overflow-x-auto ">
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th className="px-3 py-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider   w-auto ">
                <h1 className="w-[50px]">S.l no</h1>
              </th>
              <th className="px-3 py-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider  w-auto ">
                <h1>Client</h1>
              </th>
              <th className="px-3 py-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider  w-auto ">
                Phone
              </th>

              <th className="px-3 py-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider  w-auto ">
                Hotel
              </th>
              <th className="px-3 py-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider  w-auto ">
                Rooms
              </th>
              <th className="px-3 py-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider  w-auto ">
                Date
              </th>
              <th className="px-3 py-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider  w-auto ">
                Persons
              </th>
              <th className="px-3 py-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider  w-auto  ">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="animate-pulse">
            <tr>
              <td className="px-3 pl-3 py-4 border-b bg-white ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-5 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b  text-start">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  text-start border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
            </tr>
            <tr>
              <td className="px-3 pl-3 py-4 border-b bg-white ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-5 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b  text-start">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  text-start border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
            </tr>
            <tr>
              <td className="px-3 pl-3 py-4 border-b bg-white ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-5 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b  text-start">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  text-start border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
            </tr>
            <tr>
              <td className="px-3 pl-3 py-4 border-b bg-white ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-5 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b  text-start">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  text-start border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
            </tr>
            <tr>
              <td className="px-3 pl-3 py-4 border-b bg-white ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-5 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b  text-start">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  text-start border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
            </tr>
            <tr>
              <td className="px-3 pl-3 py-4 border-b bg-white ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-5 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b  text-start">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  text-start border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
            </tr>
            <tr>
              <td className="px-3 pl-3 py-4 border-b bg-white ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-5 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b  text-start">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  text-start border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
            </tr>
            <tr>
              <td className="px-3 pl-3 py-4 border-b bg-white ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-5 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b  text-start">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white border-b ">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  text-start border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
              <td className="px-3 py-4 bg-white  border-b">
                <div className="h-5 bg-gray-300 rounded-md w-full"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SkeletonLoaderForHistory;
