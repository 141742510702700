import React, { useEffect, useState } from "react";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { FaArrowRight } from "react-icons/fa";
import { PiWarningCircleDuotone } from "react-icons/pi";

import SkeletonLoaderForCabDetails from "./SkeletonLoaderForCabDetails";

const CabsDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [cabsData, setCabsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);

  const cabsDataById = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/cabs/get-cab-by-id/${id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setCabsData(response?.data);
      })
      .catch((error) => {
        console.error("Error fetching cab details:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    cabsDataById();
  }, []);

  const handleDelete = (cabId) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/cabs/delete-cab/${cabId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Delete response:", response.data);
        setDeleteModal(false);
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error deleting cab:", error);
      });
  };

  return (
    <div className="p-8 font-be-vietnam">
      {loading ? (
        <SkeletonLoaderForCabDetails />
      ) : (
        <>
          <div className="mb-5">
            <div className="flex justify-between sm:items-center items-start flex-col sm:flex-row ">
              <div className="flex justify-center items-center gap-2">
                <MdArrowBackIos
                  className="cursor-pointer"
                  onClick={() => navigate(-1)}
                  size={24}
                />
                <h1 className="text-2xl font-semibold ">
                  {cabsData?.model || "Cab Details"}
                </h1>
              </div>
              <div className="flex justify-center items-center gap-4 text-[15px]">
                <button
                  onClick={() => navigate(`/cab-edit/${cabsData?._id}`)}
                  className="bg-gray-300 text-black py-2 px-5 rounded-md"
                >
                  Edit
                </button>
                <button
                  className="bg-gradient-to-r from-yellow-400 to-orange-500 text-white py-2 px-5 rounded-md"
                  // onClick={() => handleDelete(hotelData?._id)}
                  //   onClick={() => {
                  //     openModal(hotelData?._id);
                  //   }}
                  onClick={() => setDeleteModal(true)}
                >
                  Delete
                </button>
              </div>
            </div>

            <div className="flex flex-col mt-3">
              {cabsData?.imagesUrls?.length > 0 && (
                <>
                  <div className="flex justify-between items-center sm:flex-row flex-col gap-3 sm:gap-0 h-[25%] ">
                    <img
                      src={cabsData?.imagesUrls[0]}
                      alt=""
                      className="xl:h-[200px] xl:w-[250px] lg:h-[120px] lg:w-[180px] sm:h-[150px] sm:w-[170px] h-[200px] w-full  rounded-lg"
                    />
                    <img
                      src={cabsData?.imagesUrls[1]}
                      alt=""
                      className="xl:h-[200px] xl:w-[250px] lg:h-[120px] lg:w-[180px] sm:h-[150px] sm:w-[170px] h-[200px] w-full rounded-lg"
                    />
                    <img
                      src={cabsData?.imagesUrls[2]}
                      alt=""
                      className="xl:h-[200px] xl:w-[250px] lg:h-[120px] lg:w-[180px] sm:h-[150px] sm:w-[170px] h-[200px] w-full rounded-lg"
                    />
                    <img
                      src={cabsData?.imagesUrls[3]}
                      alt=""
                      className="xl:h-[200px] xl:w-[250px] lg:h-[120px] lg:w-[180px] sm:h-[150px] sm:w-[170px] h-[200px] w-full rounded-lg"
                    />
                  </div>
                  <div className="flex justify-center gap-3 items-center sm:flex-row flex-col h-[25%] mt-3">
                    <img
                      src={cabsData?.imagesUrls[4]}
                      alt=""
                      className="xl:h-[200px] xl:w-[250px] lg:h-[120px] lg:w-[180px] sm:h-[150px] sm:w-[170px] h-[200px] w-full rounded-lg"
                    />
                    <img
                      src={cabsData?.imagesUrls[5]}
                      alt=""
                      className="xl:h-[200px] xl:w-[250px] lg:h-[120px] lg:w-[180px] sm:h-[150px] sm:w-[170px] h-[200px] w-full rounded-lg"
                    />
                    <img
                      src={cabsData?.imagesUrls[6]}
                      alt=""
                      className="xl:h-[200px] xl:w-[250px] lg:h-[120px] lg:w-[180px] sm:h-[150px] sm:w-[170px] h-[200px] w-full rounded-lg"
                    />
                  </div>
                </>
              )}
            </div>

            <div className="mt-3">
              <h1 className="text-xl font-bold">Features</h1>
              <div className="flex justify-between items-start flex-col sm:flex-row tracking-wide text-[15px] mt-3 ">
                {cabsData?.extraFeatures?.map((data) => (
                  <p className="flex justify-start items-center gap-2">
                    <FaArrowRight className="text-yellow-400" />
                    <span>{data}</span>
                  </p>
                ))}
              </div>
            </div>

            {/* Description */}
            <div className="mt-3">
              <h1 className="text-xl font-bold ">Description</h1>
              <div className="flex justify-start items-center text-[16px] mt-3 ">
                <p className="text-justify tracking-wide leading-relaxed">
                  {cabsData?.description || "No description available."}
                </p>
              </div>
            </div>

            {/* Cancellation Policy */}
            <div className="mt-3">
              <h1 className="text-xl font-bold">Manufacturer</h1>
              <div className="flex justify-start items-center text-md mt-3 gap-2">
                <p>
                  <FaArrowRight size={16} />
                </p>
                <p className="tracking-wide text-[15px]">
                  {cabsData?.manufacturer || "No manufacturer available."}
                </p>
              </div>
            </div>

            {/* Additional Information */}
            <div className="bg-[#f1f0ed] rounded-lg p-4 mt-4 shadow-md">
              <h1 className="text-xl tracking-wide font-semibold  ">
                Additional Information
              </h1>
              <div className="flex justify-between items-start sm:flex-row flex-col text-justify tracking-wide text-[15px] mt-3">
                <div className="flex flex-col justify-center items-start gap-2">
                  {/* <p>
                    <span className="font-bold">RegistrationNumber:</span>{" "}
                    {cabsData?.registrationNumber || "N/A"}
                  </p> */}
                  <p>
                    <span className="font-bold">Type:</span>{" "}
                    {cabsData?.type || "N/A"}
                  </p>
                  <p>
                    <span className="font-bold">Model:</span>{" "}
                    {cabsData?.model || "N/A"}
                  </p>
                  <p>
                    <span className="font-bold">FarePerKm:</span>{" "}
                    {cabsData?.farePerKm || "N/A"}
                  </p>
                  <p>
                    <span className="font-bold">State:</span>{" "}
                    {cabsData?.avail_in_state || "N/A"}
                  </p>
                </div>
                <div className="flex flex-col items-start gap-2">
                  <p>
                    <span className="font-bold">Capacity:</span>{" "}
                    {cabsData?.capacity || "N/A"}
                  </p>
                  <p>
                    <span className="font-bold">Color:</span>{" "}
                    {cabsData?.color || "N/A"}
                  </p>
                  <p>
                    <span className="font-bold">Type:</span>{" "}
                    {cabsData?.type || "N/A"}
                  </p>
                  <p>
                    <span className="font-bold">City:</span>{" "}
                    {cabsData?.avail_in_city || "N/A"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {deleteModal && (
            <DelTourModal
              setDeleteModal={setDeleteModal}
              handleDelete={handleDelete}
              cabID={cabsData?._id}
              cabName={cabsData?.model}
            />
          )}
        </>
      )}
    </div>
  );
};

const DelTourModal = ({ setDeleteModal, handleDelete, cabID, cabName }) => (
  <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center font-be-vietnam">
    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-xs">
      <div className="flex justify-center text-red-700 mb-4">
        <PiWarningCircleDuotone size={60} />
      </div>

      <p className="text-center tracking-wide text-lg">
        Are you sure to delete this{" "}
        <span className="font-semibold">{cabName}</span>
      </p>

      <div className="flex tracking-wide justify-center items-center space-x-4 mt-5">
        <button
          onClick={() => setDeleteModal(false)}
          className="py-2 px-4 text-gray-800 border border-gray-300 rounded-lg hover:bg-gray-200"
        >
          Cancel
        </button>
        <button
          className="py-2 px-4 text-white bg-orange-500 rounded-lg hover:bg-orange-700"
          onClick={() => handleDelete(cabID)}
        >
          Yes, Delete
        </button>
      </div>
    </div>
  </div>
);

export default CabsDetails;
