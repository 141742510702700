import React, { useState, useEffect } from "react";
import PaymentsForm from "../../components/Booking/PaymentsForm";
import BookingDetails from "../../components/Booking/BookingDetails";
import { useParams } from "react-router-dom";

const PaymentSummary = () => {
  const [packageData, setPackageData] = useState({});
  const [userData, setUserData] = useState({});
  const [bookingData, setBookingData] = useState({});
  const { id } = useParams();

//   console.log("json", JSON.parse(localStorage.getItem('bookingData'))); 

  useEffect(() => {
    setBookingData(JSON.parse(localStorage.getItem('bookingData')));
  }, []);

  return (
    <div className="relative mx-auto w-full bg-white">
      <div className="grid min-h-screen grid-cols-10">
        <BookingDetails bookingData={bookingData} />
        <PaymentsForm bookingData={bookingData} />
      </div>
    </div>
  );
};

export default PaymentSummary;









// import React, { useState, useEffect } from "react";
// import PaymentsForm from "../../components/Booking/PaymentsForm";
// import BookingDetails from "../../components/Booking/BookingDetails";
// import { useParams } from "react-router-dom";


// const PaymentSummary = () => {
//     const [packageData, setPackageData] = useState({});
//     const [userData, setUserData] = useState({});
//     const [BookingDetails, setBookingDetails] = useState();
//     const { id } = useParams();
//     console.log("order_id", id)

//     useEffect(() => {
//         setPackageData(JSON.parse(localStorage.getItem("packageData")) || {});
//         setUserData(JSON.parse(localStorage.getItem("userData")) || {});
//         setBookingDetails(JSON.parse(localStorage.getItem("bookingData")) || {});
//     }, []);

//     console.log("BookingDetails", BookingDetails);
//     // console.log("userData", userData);
//     return (
//         <React.Fragment>
//             <div class="relative mx-auto w-full bg-white">
//                 <div class="grid min-h-screen grid-cols-10">
//                     <BookingDetails packageData={packageData} userData={userData} BookingDetails={BookingDetails} />
//                     <PaymentsForm packageData={packageData} userData={userData} order_id={id} />
//                 </div>
//             </div>
//         </React.Fragment>
//     );
// };

// export default PaymentSummary;
