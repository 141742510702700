import Login from "../screens/nonAuth/Login";
import Register from "../screens/nonAuth/Register";
import Dashboard from "../screens/auth/Dashboard";
import Home from "../screens/users/Home";
import About from "../screens/users/About";
import Contact from "../screens/users/Contact";
import ForgotPassword from "../screens/nonAuth/ForgotPassword";
import Tours from "../screens/auth/Tours";
import Profile from "../screens/auth/Profile";
import Gallery from "../screens/users/Gallery";
import Feedback from "../screens/users/Feedback";
import Feedbacks from "../screens/auth/Feedbacks";
import Destinations from "../screens/users/Destinations";
import HotelBooking from "../screens/users/HotelBooking";
import CabBooking from "../screens/users/CabBooking";
import AddNewTours from "../components/AdminSideComponents/Tours/AddNewTours";
import TourDetails from "../screens/users/TourDetails";
import UpdateTours from "../components/AdminSideComponents/Tours/UpdateTours";
import PaymentSummary from "../screens/users/PaymentSummary";
import FilteredTours from "../screens/users/FilteredTours";
import ToursLocCategories from "../screens/auth/ToursLocCategories";
import HotelsList from "../screens/users/HotelsList";
import HotelDetails from "../screens/users/HotelDetails";
import Hotels from "../screens/auth/Hotels";
import AddNewHotel from "../screens/auth/AddNewHotel";
import HotelDetailsAdmin from "../screens/auth/HotelDetailsAdmin";
import BookingForm from "../screens/users/BookingForm";
import EditHotel from "../screens/auth/EditHotel";
import CabsList from "../screens/users/CabsList";
import CabDetails from "../screens/users/CabDetails";
import RoomDetails from "../components/HotelsAdmin/RoomDetails";
import ClientDetails from "../screens/auth/ClientDetails";
import HotelsBookingHistory from "../screens/auth/AllBookingHistory";
import Cabs from '../screens/auth/Cabs'
import AddCabs from "../components/Cabs/AddCabs";
import EditCabs from "../components/Cabs/EditCabs";
import BookingSuccess from "../components/common/BookingSuccess";
import CabDetailsForm from "../screens/users/CabDetailsForm";
import CabBookingForm from "../screens/users/CabBookingForm";
import CabsDetails from "../components/Cabs/CabsDetails";
import CabsBookingHistory from "../screens/auth/CabsBookingHistory";
import CabClientDetails from "../screens/users/CabClientDetails";

export const authRoutes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/tours",
    component: <Tours />,
  },
  {
    path: "/feedbacks",
    component: <Feedbacks />,
  },
  {
    path: "/tours&loc-categories",
    component: <ToursLocCategories />,
  },
  {
    path: "/profile",
    component: <Profile />,
  },
  {
    path: "/addNewTours",
    component: <AddNewTours />,
  },
  {
    path: "/updateTours/:id",
    component: <UpdateTours />,
  },
  {
    path: "/hotel",
    component: <Hotels />,
  },
  {
    path: "/add-new-hotel",
    component: <AddNewHotel />,
  },
  {
    path: "/add-cabs",
    component: <AddCabs />,
  },
  {
    path: "/admin-hotel-details/:id",
    component: <HotelDetailsAdmin />,
  },
  {
    path: "/edit-hotel/:id",
    component: <EditHotel />,
  },
  {
    path: "/room-details/:id/:title",
    component: <RoomDetails />,
  },
  {
    path: "/hotel-booking-history",
    component: <HotelsBookingHistory />,
  },
  {
    path: "/client-details/:id",
    component: <ClientDetails />,
  },
  {
    path: "/cab",
    component: <Cabs />,
  },
  {
    path: "/cab-edit/:id",
    component: <EditCabs />,
  },
  {
    path: "/cabs-details/:id",
    component: <CabsDetails />,
  },
  {
    path: "/cab-booking-history",
    component: <CabsBookingHistory />,
  },
  {
    path: "/cabs-client-details/:id",
    component: <CabClientDetails/>,
  }
];


export const nonAuthRoutes = [
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/register",
    component: <Register />,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
  },
];

export const userRoutes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/destinations",
    component: <Destinations />,
  },
  {
    path: "/filtered-tours-fron-home",
    component: <FilteredTours />,
  },
  {
    path: "/about",
    component: <About />,
  },
  {
    path: "/contact",
    component: <Contact />,
  },
  {
    path: "/gallery",
    component: <Gallery />,
  },
  {
    path: "/feedback",
    component: <Feedback />,
  },
  {
    path: "/hotel-booking",
    component: <HotelBooking />,
  },
  {
    path: "/cab-booking",
    component: <CabBooking />,
  },
  {
    path: "/cab-list",
    component: <CabsList />,
  },
  {
    path: "/tour-details",
    component: <TourDetails />,
  },
  {
    path: "/paymentSummary",
    component: <PaymentSummary />,
  },
  {
    path: "/hotel-list",
    component: <HotelsList />,
  },
  {
    path: "/hotel-details/:id",
    component: <HotelDetails />,
  },
  {
    path: "/cab-details/:id",
    component: <CabDetails />,
  },
  {
    path: "/booking-form",
    component: <BookingForm />,
  },
  {
    path: "/booking-successful/:bookingType",
    component: <BookingSuccess />,
  },
  {
    path: "/cab-details-form",
    component: <CabDetailsForm />,
  },
  {
    path: "/cab-booking-form",
    component: <CabBookingForm />,
  },
];
