import React, { useState } from 'react';
import OurTours from '../../components/homeComponents/OurTours';
import WhyChooseUs from '../../components/homeComponents/WhyChooseUs';
import OurCategories from '../../components/homeComponents/OurCategories';
import Testimonials from '../../components/homeComponents/Testimonials';
import EnjoyTheBest from '../../components/homeComponents/EnjoyTheBest';
import HeroSection from '../../components/homeComponents/HeroSection';
import GoTravel from '../../components/homeComponents/GoTravel';
import OurTourGallery from '../../components/homeComponents/OurTourGallery';
import Cards from '../../components/homeComponents/Cards';

const Home = () => {
  
  return (
    <div
      className='bg-white'
      style={{ fontFamily: "'Be Vietnam Pro', sans-serif" }}
    >
      <HeroSection />
      <EnjoyTheBest />
      {/* <Cards /> */}
      <OurTours />
      <WhyChooseUs />
      <OurCategories />
      <GoTravel />
      <OurTourGallery />
      <Testimonials />
    </div>
  )
}

export default Home