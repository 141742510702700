import React from 'react';
import { FaBed, FaHotel, FaClock } from 'react-icons/fa';
import hotel1 from '../../Assets/Common/hotel1.png';
import hotel2 from '../../Assets/Common/hotel2.png';
import hotel3 from '../../Assets/Common/hotel3.png';
import hotel4 from '../../Assets/Common/hotel4.png';
import hotel5 from '../../Assets/Common/hotel5.png';
import hotel6 from '../../Assets/Common/hotel6.png';

const TourHotel = ({ tourData }) => {
  // Extracting hotels from tourData
  const { hotels } = tourData;

  // Array of hotel images
  const hotelImages = [
    hotel1,
    hotel2,
    hotel3,
    hotel4,
    hotel5,
    hotel6,
  ];

  // Function to randomly select an image
  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * hotelImages.length);
    return hotelImages[randomIndex];
  };

  return (
    <div className="container mx-auto px-4 py-6">
      {/* Title */}
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-10">Hotel Details</h1>

      {/* Hotels Card Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {hotels.map((hotel, index) => (
          <div
            key={index}
            className="bg-white shadow-lg tracking-wider rounded-lg overflow-hidden border border-gray-200 hover:shadow-xl transition-shadow duration-300"
          >
            {/* Hotel Image */}
            <div className="relative">
              <img
                src={getRandomImage()} // Assign a random image
                alt={`${hotel.place} hotel`}
                className="w-full h-48 object-cover"
              />
              <div className="absolute top-0 left-0 bg-black bg-opacity-50 text-white text-sm px-4 py-1 rounded-br-lg">
                {hotel.place}
              </div>
            </div>

            {/* Card Content */}
            <div className="p-6">
              {/* Hotel Location and Nights */}
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold text-gray-800">{hotel.place}</h2>
                <div className="flex items-center space-x-2 text-sm text-gray-600">
                  <FaClock />
                  <span className='text-[#ff783e]'>{hotel.nights} Nights</span>
                </div>
              </div>

              {/* Standard Hotels */}
              <div className="mb-4">
                <div className="flex items-center space-x-2 text-gray-700">
                  <FaBed />
                  <h3 className="font-medium">Standard Hotels:</h3>
                </div>
                <p className="text-gray-600 pl-6">{hotel.hotel_name_standard}</p>
              </div>

              {/* deluxe Hotels */}
              <div>
                <div className="flex items-center space-x-2 text-gray-700">
                  <FaHotel />
                  <h3 className="font-medium">deluxe Hotels:</h3>
                </div>
                <p className="text-gray-600 pl-6">{hotel.hotel_name_deluxe}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TourHotel;




// same data in tabular format

// import React from 'react';

// const TourHotel = ({ tourData }) => {
//   // Extracting hotels from tourData
//   const { hotels } = tourData;

//   return (
//     <div className="container mx-auto px-4 py-6">
//       {/* Title */}
//       <h1 className="text-3xl font-semibold text-center mb-8">Hotel Details</h1>

//       {/* Hotels Table */}
//       <div className="overflow-x-auto">
//         <table className="table-auto w-full border-collapse border border-gray-200 rounded-lg">
//           <thead>
//             <tr className="bg-gray-100 rounded-t-lg">
//               <th className="border border-gray-300 px-4 py-2 text-left rounded-tl-lg">Place</th>
//               <th className="border border-gray-300 px-4 py-2 text-left">Nights</th>
//               <th className="border border-gray-300 px-4 py-2 text-left">Standard Hotels</th>
//               <th className="border border-gray-300 px-4 py-2 text-left">deluxe Hotels</th>
//             </tr>
//           </thead>
//           <tbody>
//             {hotels.map((hotel, index) => (
//               <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
//                 <td className="border border-gray-300 px-4 py-2">{hotel.place}</td>
//                 <td className="border border-gray-300 px-4 py-2">{hotel.nights}</td>
//                 <td className="border border-gray-300 px-4 py-2">{hotel.hotel_name_standard}</td>
//                 <td className="border border-gray-300 px-4 py-2">{hotel.hotel_name_deluxe}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default TourHotel;
