import React from 'react';
import BottomNavbar from '../userNavbar/BottomNavbar';

const UserNavbar = () => {
    return (
        <div className="sticky top-0 w-full z-50">
            <BottomNavbar />
        </div>
    );
};

export default UserNavbar;