import React from 'react'
import SimpleBanner from '../../components/common/SimpleBanner'
import FeedbackBanner from '../../Assets/user-imgs/Home-imgs/FeedbackBanner.png'
import AllFeedbacks from '../../components/FeedbacksComponents/AllFeedbacks'

const Feedback = () => {
    
    return (
        <div className='bg-white'>
            <SimpleBanner
                image={FeedbackBanner}
                name="Feedback"
                path="Home / Feedback"
            />
            <AllFeedbacks/>
        </div>
    )
}

export default Feedback