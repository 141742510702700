import React, { useEffect } from "react";
import SimpleBanner from "../../components/common/SimpleBanner";
import Carbanner from "../../Assets/Common/CarBooking.png";
import { BiSearchAlt } from "react-icons/bi";
import { FiFilter } from "react-icons/fi";
import { MdOutlineFilterAltOff } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import axios from "axios";
import { FaArrowRight } from "react-icons/fa";
import img1 from "../../../src/Assets/Common/perseonsperseat.png";
import img2 from "../../../src/Assets/Common/TikMark.png";
import halfstar from "../../Assets/user-imgs/hotels/half-star.svg";
import fullstar from "../../Assets/user-imgs/hotels/star.png";

const CabsList = () => {
  const [cabLists, setCabLists] = useState([]);
  const [carModelFilter, setCarModelFilter] = useState("");
  const [capacityFilter, setCapacityFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [stateFilter, setStateFilter] = useState("");
  const [cityFilter, setCityFilter] = useState("");

  const states = [
    "Odisha",
    "Bihar",
    "Chhatishgarh",
    "West bengal",
    "Andhra pradesh",
  ];
  const cities = [
    { id: 1, name: "Bhubaneswar" },
    { id: 2, name: "Patna" },
    { id: 3, name: "Rairangpur" },
    { id: 4, name: "Kolkata" },
    { id: 5, name: "Visakhapatnam" },
  ];

  const carTypes = ["Hatchback", "Sedan", "Suv", "Traveller", "Mini"];

  const passengerCapacities = [
    "4 Passenger Seater",
    "5 Passenger Seater",
    "7 Passenger Seater",
    "10 Passenger Seater",
    "15 Passenger Seater",
  ];

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  useEffect(() => {
    handleCabList();
  }, []);

  const handleCabList = () => {
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: ` ${process.env.REACT_APP_API_URL}/cabs/get-all-cabs`,
      headers: {},
      data: data,
    };
    setLoading(true);

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response?.data));
        setCabLists(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  console.log("cabLists", cabLists);

  const filteredItems = cabLists.filter((item) => {
    // For capacity
    const itemCapacity = item.capacity
      ? parseInt(item.capacity.toString().match(/\d+/)?.[0], 10)
      : null;

    const capacityMatch =
      capacityFilter.length === 0 ||
      (itemCapacity &&
        capacityFilter &&
        capacityFilter.toLowerCase().includes(itemCapacity.toString()));

    // For type
    const typeMatch =
      carModelFilter.length === 0 ||
      (item.type &&
        item.type.toLowerCase().includes(carModelFilter.toLowerCase()));

    // For search bar
    const searchMatch =
      !searchQuery ||
      (item.model &&
        item.model.toLowerCase().includes(searchQuery.toLowerCase()));
    //for state
    const stateMatch =
      !stateFilter ||
      (item.avail_in_state &&
        item.avail_in_state.toLowerCase() === stateFilter.toLowerCase());
    //for city
    const cityMatch =
      !cityFilter ||
      (item.avail_in_city &&
        item.avail_in_city.toLowerCase() === cityFilter.toLowerCase());

    return capacityMatch && typeMatch && searchMatch && stateMatch && cityMatch;
  });

  const clearFilters = () => {
    setCarModelFilter([]);
    setCapacityFilter("");
    setSearchQuery("");
    setStateFilter("");
    setCityFilter("");
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const uniqueStates = [
    ...new Set(cabLists?.map((item) => item.avail_in_state).filter(Boolean)),
  ];
  const city = [
    ...new Set(cabLists?.map((item) => item.avail_in_city).filter(Boolean)),
  ];

  const handleNavigation = (id) => {
    navigate(`/cab-details/${id}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      <SimpleBanner
        image={Carbanner}
        name="Cab Booking"
        path="Cabs / Cab list"
      />
      {loading ? (
        <SkeletonLoader />
      ) : (
        <div className="flex justify-center xl:flex-row flex-col items-start font-be-vietnam bg-white xl:px-16 px-4 lg:px-10">
          <div className="xl:w-[25%] w-full">
            <h1 className="text-2xl font-bold">Filter by:</h1>
            <div className="pt-3 block xl:hidden flex justify-start sm:items-start sm:gap-5 gap-2 sm:flex-row flex-col">
              <div className="w-full">
                <h2 className="text-lg font-bold mb-3">Car Type</h2>
                <select
                  className="w-full border-gray-300 rounded-lg p-2 text-gray-800 border-[1px]"
                  value={carModelFilter}
                  onChange={(e) => setCarModelFilter(e.target.value)}
                >
                  <option value="">All Types</option>
                  {carTypes.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-full">
                <h2 className="text-lg font-bold mb-3">Passenger Capacity</h2>
                <select
                  className="w-full border-gray-300 rounded-lg p-2 text-gray-800 border-[1px]"
                  value={capacityFilter}
                  onChange={(e) => setCapacityFilter(e.target.value)}
                >
                  <option value="">All Capacities</option>
                  {passengerCapacities.map((capacity, index) => (
                    <option key={index} value={capacity}>
                      {capacity}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="pt-4 hidden xl:block">
              <div className="mb-6">
                <h2 className="text-lg font-bold mb-3">Car Type</h2>
                <div className="space-y-1">
                  {carTypes.map((type, index) => (
                    <label key={index} className="flex items-center space-x-3">
                      <input
                        type="checkbox"
                        className="form-checkbox  text-blue-600"
                        checked={carModelFilter.includes(type)}
                        onChange={() => {
                          if (carModelFilter.includes(type)) {
                            setCarModelFilter(
                              carModelFilter.filter((t) => t !== type)
                            );
                          } else {
                            setCarModelFilter([...carModelFilter, type]);
                          }
                        }}
                      />
                      <span className="text-gray-800 text-md">{type}</span>
                    </label>
                  ))}
                </div>
              </div>

              <div>
                <h2 className="text-lg font-bold mb-3">Passenger Capacity</h2>
                <div className="space-y-1">
                  {passengerCapacities.map((capacity, index) => (
                    <label key={index} className="flex items-center space-x-3">
                      <input
                        type="checkbox"
                        className="form-checkbox  text-blue-600"
                        checked={capacityFilter.includes(capacity)}
                        onChange={() => {
                          if (capacityFilter.includes(capacity)) {
                            setCapacityFilter(
                              capacityFilter.filter((c) => c !== capacity)
                            );
                          } else {
                            setCapacityFilter([...capacityFilter, capacity]);
                          }
                        }}
                      />
                      <span className="text-gray-800 text-md">{capacity}</span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:w-[75%] w-full">
            <h1 className="sm:text-2xl text-lg font-bold text-justify mt-3 xl:mt-0">
              One-way trip | Estimated distance: 144 kms | 1 pickup & 1 drop
              included
            </h1>
            <div className="block md:flex items-center md:space-x-4 mb-6 space-y-2 md:space-y-0 mt-6">
              {/* Search Input */}
              <div className="flex items-center border border-[#C1C1C1] rounded-md px-3 py-2 w-full focus-within:border-[#95caed]">
                <input
                  type="text"
                  className="w-full outline-none text-xs md:text-base text-[#606060]"
                  placeholder="Search by name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <BiSearchAlt className="text-[#606060] text-lg" />
              </div>

              <div className="flex gap-4 justify-between sm:flex-row flex-col">
                {/* Filter for State */}
                <div className="flex grow items-center border border-[#C1C1C1] rounded-md px-3 py-2">
                  <FiFilter className="text-[#606060] text-xs md:text-base mr-2" />
                  <select
                    className="outline-none bg-transparent text-xs md:text-base text-[#606060] w-full sm:w-[100px]"
                    value={stateFilter}
                    onChange={(e) => setStateFilter(e.target.value)}
                  >
                    <option value="">State</option>
                    {states?.map((state, index) => (
                      <option key={index} value={state} className="w-[100px]">
                        {state}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Filter for City */}
                <div className="flex grow items-center border border-[#C1C1C1] rounded-md px-3 py-2">
                  <FiFilter className="text-[#606060] text-xs md:text-base mr-2" />
                  <select
                    className="outline-none bg-transparent text-xs md:text-base text-[#606060] w-full sm:w-auto"
                    value={cityFilter}
                    onChange={(e) => setCityFilter(e.target.value)}
                  >
                    <option value="">Select City</option>
                    {cities?.map((city) => (
                      <option key={city.id} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>

                <button
                  onClick={clearFilters}
                  className="flex justify-center items-center gap-2 min-w-40 text-sm font-medium bg-slate-300 hover:bg-slate-500 hover:text-white transition duration-200 tracking-wide rounded-md px-3 py-2"
                >
                  <MdOutlineFilterAltOff size={20} />
                  Clear Filters
                </button>
              </div>
            </div>
            <div className="flex flex-col justify-end items-start gap-5 w-full pt-3">
              {currentItems?.map((i, index) => (
                <div className="container mx-auto mb-6 p-2 shadow-md border-[1px] border-gray-200 rounded-md ">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-7 lg:grid-cols-7 gap-1 pb-1">
                    <div className="col-span-2 md:col-span-2 lg:col-span-2 ">
                      <div className="p-3 h-auto w-auto relative">
                        {i?.imagesUrls?.[0] && (
                          <img
                            src={i?.imagesUrls[0]}
                            alt="Car"
                            className="rounded-lg h-full"
                          />
                        )}

                        {/* <div
                                    className={`absolute top-7 right-8 p-1 rounded-full shadow-md cursor-pointer ${likedItems[index] ? "bg-red-500" : "bg-white"
                                        }`}
                                    onClick={() => toggleHeart(index)}
                                >
                                    <CiHeart
                                        className={`text-2xl ${likedItems[index] ? "text-white" : "text-gray-500"
                                            }`}
                                    />
                                </div> */}
                      </div>
                    </div>

                    <div className="col-span-3 md:col-span-3 lg:col-span-3 w-full flex flex-col justify-center  sm:gap-2 gap-0 items-start px-4 sm:px-0">
                      <div className="flex flex-col justify-start items-start xl:gap-1 gap-0">
                        <h1 className="capitalize xl:text-xl text-lg text-[#2D87D0] font-bold mt-1 tracking-wide">
                          {i?.model}
                        </h1>

                        <div className="capitalize flex justify-start gap-4  text-sm items-center">
                          <p>{i.type}</p>
                          <div className="flex justify-start items-center gap-2">
                            <img src={img1} alt="seats" className="h-6 w-6" />
                            <p>{i?.capacity}</p>
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-start items-start tracking-wide xl:gap-1 gap-0 flex-col">
                        <p>
                          {i?.description?.length > 100
                            ? i?.description?.substring(0, 100) + "..."
                            : i?.description}
                        </p>
                        <ul className="mt-2 text-sm space-y-1">
                          <li className="flex justify-start items-center gap-2">
                            <img src={img2} alt="" className="h-4 w-4" /> 144
                            kms included. After that ₹14.0/km
                          </li>
                          <li className="flex justify-start items-center gap-2">
                            <img src={img2} alt="" className="h-4 w-4" /> Free
                            cancellation until 1 hour before pickup
                          </li>
                          <li className="flex justify-start items-center gap-2">
                            <img src={img2} alt="" className="h-4 w-4" />{" "}
                            Reserve this cab at ₹463 only
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-span-2 md:col-span-2 lg:col-span-2 flex flex-col md:justify-between justify-center  sm:items-end pr-4 items-start sm:pb-0 pl-4 sm:pl-0">
                      <div className="flex justify-center sm:items-end items-start gap-2 mt-1 flex-col">
                        <div className="flex justify-center items-center gap-2 ">
                          <div>Customers Ratings</div>

                          <div className="bg-[#2a7225] rounded-lg text-xs px-3 py-1 text-white font-semibold">
                            {i?.rating?.toString().includes(".")
                              ? i.rating
                              : i.rating + ".0"}
                          </div>
                        </div>
                        <div className="flex justify-start sm:items-center items-start gap-1 text-yellow-500 text-xl">
                          {Array.from(
                            { length: Math.floor(i.rating) },
                            (_, index) => (
                              <span key={index}>
                                <img
                                  className="h-5 w-auto"
                                  src={fullstar}
                                  alt="fullstar"
                                />
                              </span>
                            )
                          )}
                          {i.rating % 1 !== 0 && (
                            <span key={index}>
                              <img
                                className="h-5 w-auto"
                                src={halfstar}
                                alt="halfstar"
                              />
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="flex justify-start sm:items-end items-start flex-col xl:gap-1 gap-0">
                        <div className="flex justify-end items-center gap-1">
                          <p className=" font-bold">
                            <span className="xl:text-xl text-lg text-[#000000]">
                              ₹ {i?.farePerKm} /- km
                            </span>
                          </p>
                        </div>
                        <p className="mb-2 text-[13px]">
                          Including Taxes and Charges
                        </p>
                        <button
                          className="bg-[#2D87D0] xl:px-5 px-4 xl:py-2 py-1.5 flex justify-around items-center text-white rounded-md gap-3"
                          onClick={() => handleNavigation(i._id)}
                        >
                          Details <FaArrowRight />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="pt-4 flex justify-end items-center">
              <Stack spacing={2} className="pagination-container">
                <Pagination
                  count={Math.ceil(filteredItems.length / itemsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  variant="outlined"
                  color="primary"
                />
              </Stack>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CabsList;

const SkeletonLoader = () => {
  return (
    <>
      <div className="flex justify-between items-start gap-3  bg-gray-300 h-auto rounded-lg shadow-md  animate-pulse md:px-14 px-4">
        <div className=" w-[20%] rounded-lg hidden lg:block">
          <div>
            <div className="h-6 w-[60%] bg-gray-400 "></div>
            <div className="h-4 w-[70%] bg-gray-400 mt-4"></div>
            <div className="h-3 w-[40%] bg-gray-400 mt-2"></div>
            <div className="h-3 w-[40%] bg-gray-400 mt-2"></div>
            <div className="h-3 w-[40%] bg-gray-400 mt-2"></div>
            <div className="h-3 w-[40%] bg-gray-400 mt-2"></div>
            <div className="h-3 w-[40%] bg-gray-400 mt-2"></div>
            <div className="h-3 w-[40%] bg-gray-400 mt-2"></div>
          </div>
          <div className="mt-10">
            <div className="h-6 w-[60%] bg-gray-400 "></div>
            <div className="h-4 w-[70%] bg-gray-400 mt-4"></div>
            <div className="h-3 w-[40%] bg-gray-400 mt-2"></div>
            <div className="h-3 w-[40%] bg-gray-400 mt-2"></div>
            <div className="h-3 w-[40%] bg-gray-400 mt-2"></div>
            <div className="h-3 w-[40%] bg-gray-400 mt-2"></div>
            <div className="h-3 w-[40%] bg-gray-400 mt-2"></div>
            <div className="h-3 w-[40%] bg-gray-400 mt-2"></div>
          </div>
        </div>
        <div className="bg-gray-300 lg:w-[80%] w-full h-auto rounded-lg shadow-md  animate-pulse  gap-3 ">
          <div className="p-3 flex justify-between items-center gap-5 md:flex-row flex-col">
            <div className="bg-gray-400 h-full md:w-[25%] w-full  ">
              <div className="bg-gray-400 h-[150px] rounded-lg md:w-auto  ">
                {" "}
              </div>
            </div>
            <div className="h-auto md:w-[50%] w-full">
              <div className="h-6 w-[40%] bg-gray-400  mt-3"></div>
              <div className="h-3 w-[60%] bg-gray-400 mt-3"></div>
              <div className="h-3 mt-6 w-[20%] bg-gray-400"></div>
              <div className="h-3 w-full bg-gray-400 mt-3"></div>
              <div className="h-3 w-[70%] bg-gray-400 mt-3"></div>
            </div>
            <div className="h-auto md:w-[25%] w-full flex flex-col md:justify-end md:items-end justify-start items-start">
              <div className="h-6 w-full flex justify-between items-center gap-3">
                <div className="h-4 w-[70%] bg-gray-400"></div>
                <div className="h-6 w-[20%] bg-gray-400"></div>
              </div>
              <div className="mt-4 flex justify-end items-center gap-1 ">
                <div className="h-5 w-5 rounded-full bg-gray-400"></div>
                <div className="h-5 w-5 rounded-full bg-gray-400"></div>
                <div className="h-5 w-5 rounded-full bg-gray-400"></div>
                <div className="h-5 w-5 rounded-full bg-gray-400"></div>
              </div>
              <div className="h-4 w-[50%] bg-gray-400 mt-4"></div>

              <div className="h-3 w-full bg-gray-400 mt-3"></div>
              <div className="h-7 w-[60%] bg-gray-400 mt-4"></div>
            </div>
          </div>
          <div className="p-3 flex justify-between items-center gap-5 md:flex-row flex-col">
            <div className="bg-gray-400 h-full md:w-[25%] w-full  ">
              <div className="bg-gray-400 h-[150px] rounded-lg md:w-auto  ">
                {" "}
              </div>
            </div>
            <div className="h-auto md:w-[50%] w-full">
              <div className="h-6 w-[40%] bg-gray-400  mt-3"></div>
              <div className="h-3 w-[60%] bg-gray-400 mt-3"></div>
              <div className="h-3 mt-6 w-[20%] bg-gray-400"></div>
              <div className="h-3 w-full bg-gray-400 mt-3"></div>
              <div className="h-3 w-[70%] bg-gray-400 mt-3"></div>
            </div>
            <div className="h-auto md:w-[25%] w-full flex flex-col md:justify-end md:items-end justify-start items-start">
              <div className="h-6 w-full flex justify-between items-center gap-3">
                <div className="h-4 w-[70%] bg-gray-400"></div>
                <div className="h-6 w-[20%] bg-gray-400"></div>
              </div>
              <div className="mt-4 flex justify-end items-center gap-1 ">
                <div className="h-5 w-5 rounded-full bg-gray-400"></div>
                <div className="h-5 w-5 rounded-full bg-gray-400"></div>
                <div className="h-5 w-5 rounded-full bg-gray-400"></div>
                <div className="h-5 w-5 rounded-full bg-gray-400"></div>
              </div>
              <div className="h-4 w-[50%] bg-gray-400 mt-4"></div>

              <div className="h-3 w-full bg-gray-400 mt-3"></div>
              <div className="h-7 w-[60%] bg-gray-400 mt-4"></div>
            </div>
          </div>
          <div className="p-3 flex justify-between items-center gap-5 md:flex-row flex-col">
            <div className="bg-gray-400 h-full md:w-[25%] w-full  ">
              <div className="bg-gray-400 h-[150px] rounded-lg md:w-auto  ">
                {" "}
              </div>
            </div>
            <div className="h-auto md:w-[50%] w-full">
              <div className="h-6 w-[40%] bg-gray-400  mt-3"></div>
              <div className="h-3 w-[60%] bg-gray-400 mt-3"></div>
              <div className="h-3 mt-6 w-[20%] bg-gray-400"></div>
              <div className="h-3 w-full bg-gray-400 mt-3"></div>
              <div className="h-3 w-[70%] bg-gray-400 mt-3"></div>
            </div>
            <div className="h-auto md:w-[25%] w-full flex flex-col md:justify-end md:items-end justify-start items-start">
              <div className="h-6 w-full flex justify-between items-center gap-3">
                <div className="h-4 w-[70%] bg-gray-400"></div>
                <div className="h-6 w-[20%] bg-gray-400"></div>
              </div>
              <div className="mt-4 flex justify-end items-center gap-1 ">
                <div className="h-5 w-5 rounded-full bg-gray-400"></div>
                <div className="h-5 w-5 rounded-full bg-gray-400"></div>
                <div className="h-5 w-5 rounded-full bg-gray-400"></div>
                <div className="h-5 w-5 rounded-full bg-gray-400"></div>
              </div>
              <div className="h-4 w-[50%] bg-gray-400 mt-4"></div>

              <div className="h-3 w-full bg-gray-400 mt-3"></div>
              <div className="h-7 w-[60%] bg-gray-400 mt-4"></div>
            </div>
          </div>
          <div className="p-3 flex justify-between items-center gap-5 md:flex-row flex-col">
            <div className="bg-gray-400 h-full md:w-[25%] w-full  ">
              <div className="bg-gray-400 h-[150px] rounded-lg md:w-auto  ">
                {" "}
              </div>
            </div>
            <div className="h-auto md:w-[50%] w-full">
              <div className="h-6 w-[40%] bg-gray-400  mt-3"></div>
              <div className="h-3 w-[60%] bg-gray-400 mt-3"></div>
              <div className="h-3 mt-6 w-[20%] bg-gray-400"></div>
              <div className="h-3 w-full bg-gray-400 mt-3"></div>
              <div className="h-3 w-[70%] bg-gray-400 mt-3"></div>
            </div>
            <div className="h-auto md:w-[25%] w-full flex flex-col md:justify-end md:items-end justify-start items-start">
              <div className="h-6 w-full flex justify-between items-center gap-3">
                <div className="h-4 w-[70%] bg-gray-400"></div>
                <div className="h-6 w-[20%] bg-gray-400"></div>
              </div>
              <div className="mt-4 flex justify-end items-center gap-1 ">
                <div className="h-5 w-5 rounded-full bg-gray-400"></div>
                <div className="h-5 w-5 rounded-full bg-gray-400"></div>
                <div className="h-5 w-5 rounded-full bg-gray-400"></div>
                <div className="h-5 w-5 rounded-full bg-gray-400"></div>
              </div>
              <div className="h-4 w-[50%] bg-gray-400 mt-4"></div>

              <div className="h-3 w-full bg-gray-400 mt-3"></div>
              <div className="h-7 w-[60%] bg-gray-400 mt-4"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
