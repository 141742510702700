import React, { useState } from 'react';
import bgImg from '../../Assets/auth-imgs/bgImg.png';
import logo from '../../Assets/Logos/logoWhite.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
  const navigate = useNavigate();

  // States to manage form input values and validation errors
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ email: '', password: '' });
  const [apiErr, setApiErr] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation logic
    let formIsValid = true;
    const newErrors = { email: '', password: '' };

    // Email validation
    if (!email) {
      newErrors.email = 'Email is required.';
      formIsValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email is not valid.';
      formIsValid = false;
    }

    // Password validation
    if (!password) {
      newErrors.password = 'Password is required.';
      formIsValid = false;
    } else if (password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters.';
      formIsValid = false;
    }

    setErrors(newErrors);

    if (formIsValid) {
      let data = JSON.stringify({
        "email": email,
        "password": password
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/admin/admin-login`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      setIsLoading(true);
      axios.request(config)
        .then((response) => {
          setIsLoading(false);
          localStorage.setItem('admin-data', response?.data?.admin);
          navigate('/dashboard');
        })
        .catch((error) => {
          console.log("error", error);
          setIsLoading(false);
          setApiErr(error?.response?.data?.message);
          setTimeout(() => {
            setApiErr('')
          }, 3000);
        });
    }
  };

  return (
    <div
      className="flex h-screen w-full items-center justify-center bg-cover bg-center"
      style={{
        backgroundImage: `url(${bgImg})`
      }}
    >
      <div className="w-full max-w-sm sm:max-w-md md:max-w-lg p-6 sm:p-8 bg-gray-800 bg-opacity-70 rounded-2xl shadow-xl backdrop-blur-md">
        <div className="text-white">
          {/* Logo and Header */}
          <div className="mb-8 flex flex-col items-center">
            <img
              src={logo}
              width="100"
              alt="wb-Logo"
              className="h-20 md:h-28 w-auto"
            />
            <h1 className="mb-2 text-2xl sm:text-3xl mt-6 font-semibold tracking-wide">
              Login To Admin Panel
            </h1>
            {
              apiErr
                ?
                <span className="text-red-500 tracking-wider">
                  {apiErr}
                </span>
                :
                <span className="text-gray-300 tracking-wider">
                  Please enter your credentials
                </span>
            }

          </div>

          {/* Form Section */}
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <input
                type="email"
                placeholder="example@gmail.com"
                className="w-full rounded-full bg-yellow-400 bg-opacity-30 tracking-wide px-6 py-3 text-center text-gray-100 placeholder-slate-200 shadow-lg outline-none backdrop-blur-md focus:bg-opacity-50"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && <p className="text-red-500 text-sm mt-2">{errors.email}</p>}
            </div>
            <div className="mb-6">
              <input
                type="password"
                placeholder="*********"
                className="w-full rounded-full bg-yellow-400 bg-opacity-30 tracking-wide px-6 py-3 text-center text-gray-100 placeholder-slate-200 shadow-lg outline-none backdrop-blur-md focus:bg-opacity-50"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {errors.password && <p className="text-red-500 text-sm mt-2">{errors.password}</p>}
            </div>
            <div className="mt-8 flex justify-center">
              {
                isLoading
                ?
                <button
                className="w-full md:w-1/2 tracking-wider rounded-full bg-[#F88F26] font-bold px-10 py-3 text-white shadow-xl backdrop-blur-md transition duration-300 focus:ring-4 focus:ring-yellow-300"
              >
                Please Wait ..
              </button>
              :
              <button
                type="submit"
                className="w-full md:w-1/2 tracking-wider rounded-full bg-[#F88F26] hover:bg-[#FF5901] font-bold px-10 py-3 text-white shadow-xl backdrop-blur-md transition duration-300 focus:ring-4 focus:ring-yellow-300"
              >
                Login
              </button>
              }
            </div>
          </form>

          {/* Additional Links */}
          <div className="mt-6 flex justify-center tracking-wider text-sm">
            <div className="space-x-4">
              <button
                onClick={() => navigate('/')}
                className="hover:text-[#F88F26] transition duration-200"
              >
                Go to Home
              </button>
              {/* <button
                onClick={() => navigate('/forgot-password')}
                className="hover:text-[#F88F26] transition duration-200"
              >
                Forgot Password?
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
