import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import TourSkeletonLoader from "./TourSkeletonLoader";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import locationIcon from "../../Assets/user-imgs/Home-imgs/loction.svg";
import durationIcon from "../../Assets/user-imgs/Home-imgs/days.svg";
import ratingIcon from "../../Assets/user-imgs/Home-imgs/rating.svg";
import HomePageFilter from "./HomePageFilter";

const FilterDestinationsTours = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [allTours, setAllTours] = useState([]);
  const [filteredTours, setFilteredTours] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  // Helper function to fetch tours
  const fetchTours = async () => {
    setIsLoading(true);
    setError("");

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/new-packages/get-all-new-packages`
      );
      setAllTours(data);
      setFilteredTours(data); // Show all tours by default
    } catch (err) {
      setError("Failed to load tours. Please try again later.");
      console.error("Error fetching tours:", err);
    } finally {
      setIsLoading(false);
    }
  };

  // Helper function to filter tours
  const applyFilters = () => {
    const queryParams = new URLSearchParams(search);
    const querySearch = queryParams.get("search") || "";
    const queryPrice = queryParams.get("price") || "";
    const queryLocation = queryParams.get("location") || "";
    const queryType = queryParams.get("type") || "";
    const queryCategory = queryParams.get("category") || "";
    const locCategory = queryParams.get("locationCategory") || "";

    let filtered = [...allTours];

    if (querySearch) {
      filtered = filtered.filter((tour) =>
        tour.name.toLowerCase().includes(querySearch.toLowerCase())
      );
    }

    if (queryPrice) {
      filtered = filtered.filter((tour) => {
        const price = tour.type_of_packages[0]?.price;
        if (queryPrice === "low") return price < 30000;
        if (queryPrice === "high") return price >= 30000;
        return true;
      });
    }

    if (queryLocation) {
      filtered = filtered.filter((tour) =>
        (tour.departure_place || "")
          .toLowerCase()
          .includes(queryLocation.toLowerCase())
      );
    }

    if (queryType) {
      filtered = filtered.filter((tour) =>
        tour.type_of_packages.some(
          (pkg) => pkg.type.toLowerCase() === queryType.toLowerCase()
        )
      );
    }

    if (queryCategory) {
      filtered = filtered.filter((tour) =>
        (tour.tours_category || "")
          .toLowerCase()
          .includes(queryCategory.toLowerCase())
      );
    }
    // console.log('queryParams', queryParams.get("locationCategory"))

    if (locCategory) {
      filtered = filtered.filter((tour) =>
        // console.log('queryParams1', tour.location_category)
        (tour.location_category)
          .toLowerCase()
          .includes(locCategory.toLowerCase())
      );
    }

    setFilteredTours(filtered);
  };

  useEffect(() => {
    fetchTours();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [search, allTours]);

  return (
    <div className="md:mx-16 mx-6 pb-10">
      {isLoading ? (
        <TourSkeletonLoader />
      ) : error ? (
        <p className="text-center text-red-500">{error}</p>
      ) : filteredTours.length === 0
        ?
        (
          <React.Fragment>
            <p className="text-center text-gray-500 mt-6 text-lg font-semibold">
              No tours match your filters. Try adjusting the filters.
            </p>
            <HomePageFilter />
          </React.Fragment>
        )
        :
        (
          <React.Fragment>
            <div className="flex md:flex-row flex-col gap-4 justify-between">
              <div>
                <h2 className="text-[#F94E05] tracking-wide text-lg font-serif">
                  Our Tours
                </h2>
                <h1 className="text-2xl md:text-4xl tracking-wider font-bold text-[#051836] mt-2 font-sans">
                  Explore Our Tour Packages!
                </h1>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-7">
              {filteredTours.map((tour) => (
                <div
                  className="bg-white border border-[#e2e4e8] rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl"
                  key={tour._id}
                >
                  <img
                    src={tour.image_url}
                    alt={`Img of ${tour.name}`}
                    className="w-full h-72 p-4 object-cover rounded-lg"
                  />
                  <div className="px-4 pb-4 space-y-4">
                    <h3 className="text-xl font-semibold text-gray-900 tracking-wider">
                      {tour.name}
                    </h3>
                    <div className="p-2 flex items-center justify-between tracking-wide text-gray-600 border-t-2 border-b-2 border-gray-200">
                      <div className="flex gap-2 items-center">
                        <img src={locationIcon} alt="Location icon" className="w-5 h-5" />
                        <span>{tour.arrival_city}</span>
                      </div>
                      <div className="flex gap-2 items-center">
                        <img src={durationIcon} alt="Duration icon" className="w-5 h-5" />
                        <span>{tour.tour_duration}</span>
                      </div>
                      <div className="flex gap-2 items-center">
                        <img src={ratingIcon} alt="Rating icon" className="w-5 h-5" />
                        <span>5 ⭐ Ratings</span>
                      </div>
                    </div>
                    <div className="text-center mt-4">
                      <button
                        onClick={() => { navigate(`/tour-details/${tour._id}`); window.scrollTo(0, 0); }}
                        className="tracking-wider text-lg gap-2 font-normal rounded-full flex items-center justify-center mx-auto"
                      >
                        <span>Explore Now</span>
                        <IoIosArrowDroprightCircle size={28} color="#ff7951" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </React.Fragment>
        )}
    </div>
  );
};

export default FilterDestinationsTours;