import React from "react";
import SimpleBanner from "../../components/common/SimpleBanner";
import ContactUsBanner from "../../Assets/user-imgs/Home-imgs/contactUsBanner.png";
import Map from "../../components/homeComponents/Map";
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from 'react-icons/fa';

const Contact = () => {
  return (
    <div className="bg-white">
      <SimpleBanner
        image={ContactUsBanner}
        name="Contact Us"
        path="Home / Contact Us"
      />

      <div className="mx-6 md:mx-16 mb-12">
        <div className="flex flex-col lg:flex-row gap-14">
          {/* Left Section */}
          <div className="w-full lg:w-[50%] flex flex-col space-y-4">
            <h2 className="text-[#F9A706] text-md md:text-xl md:font-bold font-semibold tracking-wide">
              WE’D LOVE TO HEAR FROM YOU!
            </h2>
            <h1 className="text-2xl md:text-4xl md:font-bold leading-10 tracking-wide text-gray-800">
              PLAN YOUR <span className="text-[#F9A706]">DREAM GETAWAY</span> WITH US
            </h1>
            <p className="text-gray-700 text-lg text-justify leading-relaxed tracking-wide">
              Have a question about our tour packages, destinations, or special deals? Reach out to us and our travel experts will assist you in planning the perfect vacation, ensuring a hassle-free and memorable experience.
            </p>

            <form action="" className="flex flex-col space-y-4">
              <input
                type="text"
                placeholder="Your Name*"
                className="p-4 bg-transparent border border-gray-800 rounded-lg w-full focus:outline-yellow-600"
              />
              <input
                type="email"
                placeholder="Your Email*"
                className="p-4 bg-transparent border border-gray-800 rounded-lg w-full focus:outline-yellow-600"
              />
              <textarea
                placeholder="Your Message*"
                className="p-4 bg-transparent border border-gray-800 rounded-lg w-full h-32 focus:outline-yellow-600"
              />
              <button className="bg-gradient-to-r from-yellow-400 to-orange-500 hover:bg-yellow-800 text-white px-4 py-2 rounded-md text-md font-bold w-full md:w-32 text-center transition-all duration-300 ease-in-out mt-4">
                Submit
              </button>
            </form>
          </div>

          {/* Right Section */}
          <div className="w-full lg:w-[50%] flex flex-col space-y-4">
            <h2 className="text-[#F9A706] text-xl font-bold tracking-wide">
              NEED ASSISTANCE?
            </h2>
            <p className="text-gray-700 font-bold text-2xl tracking-wide">
              Connect with Us Today!
            </p>
            <p className="text-gray-800 text-lg leading-relaxed tracking-wide text-justify">
              Whether it’s a luxurious international trip or a serene domestic escape, we’re here to guide you. Contact us for tailored travel experiences, exclusive offers, and round-the-clock support.
            </p>

            {/* Location */}
            <div className="flex gap-4 items-start tracking-wide">
              <FaMapMarkerAlt size={32} color="#F9A706" className="text-gray-700" />
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <p className="font-bold text-xl tracking-wide text-[#051836]">HEAD OFFICE</p>
                  <p className="w-full text-gray-700">
                    A-37 Sachdeva Complex, Madhu Vihar I.P Ext., New Delhi-92
                  </p>
                </div>

                {/* Branch Office Section */}
                <div className="flex flex-col gap-2">
                  <p className="font-bold text-xl tracking-wide text-[#051836]">BRANCH OFFICE</p>
                  <p className="w-full text-gray-700">
                    Haridwar: Dudhadhari Chowk Near Rajput Dharmsala, Bhupatwala, Haridwar-249410
                  </p>
                </div>
              </div>
            </div>

            {/* Email */}
            <div className="flex gap-4 items-start tracking-wide">
              <FaEnvelope size={28} color='#F9A706' className="text-gray-700" />
              <div className="flex flex-col gap-2">
                <p className="font-bold text-xl tracking-wide text-[#051836]">EMAIL US</p>
                <p className="text-gray-700">info@weekendbhraman.com</p>
              </div>
            </div>

            {/* Phone */}
            <div className="flex gap-4 items-start tracking-wide">
              <FaPhoneAlt size={28} color='#F9A706' className="text-gray-700" />
              <div className="flex flex-col gap-2">
                <p className="font-bold text-xl tracking-wide text-[#051836]">CALL US</p>
                <p className="text-gray-700">+91-8178958591, +91-9310366002</p>
              </div>
            </div>
          </div>

        </div>
      </div>

      <Map />
    </div>
  );
};

export default Contact;
