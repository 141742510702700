import React, { useEffect, useState } from "react";
import SimpleBanner from "../../components/common/SimpleBanner";
import HeadBanner from "../../Assets/Common/HotelHeader.png";
import axios from "axios";
import { useParams } from "react-router-dom";
import HotelsInfo from "../../components/HotelsUserSide/HotelsInfo";
import RoomSelectForm from "../../components/HotelsUserSide/RoomSelectForm";

const HotelDetails = () => {
  const { id } = useParams();

  const [hotelDetails, setHotelDetails] = useState();
  const [isloading, setIsloading] = useState(false);
  const [hotelData, setHotelData] = useState(null);
  const [bookingDate, setBookingDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  useEffect(() => {
    getHotelDetails();
    hotelsDataById();
  }, []);

  const getHotelDetails = () => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/hotels/hotel-by-id/${id}`,
    };

    setIsloading(true);
    axios
      .request(config)
      .then((response) => {
        setHotelDetails(response?.data);
        setIsloading(false);
      })
      .catch((error) => {
        setIsloading(false);
        console.log(error);
      });
  };

  const hotelsDataById = () => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/hotels/hotel-by-id/${id}`,
    };

    axios
      .request(config)
      .then((response) => {
        setHotelData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="font-be-vietnam bg-white">
      <SimpleBanner
        image={HeadBanner}
        name="Hotel Booking"
        path="Hotels / Hotel Details"
      />

      <div className="grid grid-cols-1 lg:grid-cols-7 sm:px-8 px-5 ">
        {isloading ? (
          <div className="col-span-7">
            <SkeletonLoader />
          </div>
        ) : (
          <React.Fragment>
            <div className="col-span-5">
              <HotelsInfo hotelDetails={hotelDetails} />
            </div>

            <RoomSelectForm
              bookingDate={bookingDate}
              setBookingDate={setBookingDate}
              hotelData={hotelData}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default HotelDetails;

const SkeletonLoader = () => {
  return (
    <>
      <div className="flex justify-between items-start lg:flex-row flex-col gap-6 px-4 sm:px-0 lg:px-3">
        <div className=" lg:w-[70%] rounded-lg w-full  ">
          <div className="flex justify-between md:flex-row flex-col items-center gap-3">
            <div className="h-[300px] md:w-[75%] w-full bg-gray-300 rounded-lg"></div>
            <div className="h-[300px] md:w-[25%] w-full flex flex-col justift-start items-start gap-3">
              <div className="h-[150px] w-full bg-gray-300 rounded-lg"></div>
              <div className="h-[150px] w-full bg-gray-300 rounded-lg"></div>
            </div>
          </div>
          <div className="flex justify-between items-center md:flex-row flex-col mt-3 gap-3 ">
            <div className="h-[150px] w-full bg-gray-300 rounded-lg "></div>
            <div className="h-[150px] w-full bg-gray-300 rounded-lg"></div>
            <div className="h-[150px] w-full bg-gray-300 rounded-lg"></div>
            <div className="h-[150px] w-full bg-gray-300 rounded-lg"></div>
          </div>
        </div>
        <div className="lg:w-[30%] w-full h-auto rounded-lg   animate-pulse  gap-3  ">
          <div className="h-7 w-[50%] bg-gray-300 rounded-lg"></div>
          <div className="h-4 w-full mt-3 bg-gray-300 rounded-lg"></div>
          <div className="h-5 w-[50%] bg-gray-300 rounded-lg mt-3"></div>
          <div className="h-7 w-full mt-3 bg-gray-300 rounded-lg"></div>
          <div className="flex justify-between items-center mt-4">
            <div className="h-[80px] w-[80px] bg-gray-300 rounded-lg"></div>
            <div className="h-[80px] w-[80px] bg-gray-300 rounded-lg"></div>
            <div className="h-[80px] w-[80px] bg-gray-300 rounded-lg"></div>
            <div className="h-[80px] w-[80px] bg-gray-300 rounded-lg"></div>
          </div>
          <div className="flex justify-between items-center mt-4">
            <div className="h-[80px] w-[80px] bg-gray-300 rounded-lg"></div>
            <div className="h-[80px] w-[80px] bg-gray-300 rounded-lg"></div>
            <div className="h-[80px] w-[80px] bg-gray-300 rounded-lg"></div>
            <div className="h-[80px] w-[80px] bg-gray-300 rounded-lg"></div>
          </div>
          <div className="flex justify-between items-center w-full mt-4 gap-3">
            <div className="lg:h-[50px] h-[30px] w-[50px] rounded-xl bg-gray-300"></div>
            <div className="lg:h-[50px] h-[30px] w-full  bg-gray-300 rounded-lg"></div>
          </div>
          <div className="flex justify-between items-center mt-3">
            <div className="lg:h-16 h-8 w-[40%] bg-gray-300 rounded-md"></div>
            <div className="lg:h-16 h-8 w-[15%] bg-gray-300 rounded-lg"></div>
            <div className="lg:h-16 h-8 w-[15%] bg-gray-300 rounded-lg"></div>
            <div className="lg:h-16 h-8 w-[15%] bg-gray-300 rounded-lg"></div>
          </div>
        </div>
      </div>
    </>
  );
};
