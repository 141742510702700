import React, { useState } from 'react';
import ToursCategoriesContent from '../../components/AdminSideComponents/ToursCategoriesContent';
import LocationCategoriesContent from '../../components/AdminSideComponents/LocationCategoriesContent';

const ToursLocCategories = () => {
  const [activeTab, setActiveTab] = useState("First");

  const renderContent = () => {
    if (activeTab === "First") return <ToursCategoriesContent />;
    if (activeTab === "Second") return <LocationCategoriesContent />;
  };

  return (
    <div className="p-6">
      <div className="mb-6 mt-2 flex space-x-4">
        <button
          onClick={() => setActiveTab("First")}
          className={`tracking-wide py-2 px-6 text-center font-medium rounded-lg transition-all ${activeTab === "First"
              ? "bg-gradient-to-r from-yellow-400 to-orange-500 text-white"
              : "bg-white text-gray-600 ring-1 ring-gray-300 hover:bg-gradient-to-r hover:from-orange-500 hover:to-yellow-400 hover:text-white"
            }`}
        >
          Tours Categories
        </button>

        <button
          onClick={() => setActiveTab("Second")}
          className={`tracking-wide py-2 px-6 text-center font-medium rounded-lg transition-all ${activeTab === "Second"
              ? "bg-gradient-to-r from-yellow-400 to-orange-500 text-white"
              : "bg-white text-gray-600 ring-1 ring-gray-300 hover:bg-gradient-to-r hover:from-orange-500 hover:to-yellow-400 hover:text-white"
            }`}
        >
          Location Categories
        </button>
      </div>

      <div>
        {renderContent()}
      </div>
    </div>
  );
};

export default ToursLocCategories;
