import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MdTour } from "react-icons/md";
import { IoIosPricetags } from "react-icons/io";
import { IoLocation } from "react-icons/io5";
import { RiSearchEyeLine } from "react-icons/ri";
import enjoy from "../../Assets/user-imgs/Home-imgs/enjoy.svg";
import { BiReset } from "react-icons/bi";
import { TbZoomReset } from "react-icons/tb";
import axios from "axios";
import { FaGlobe } from 'react-icons/fa';

const HomePageFilter = () => {
    const navigate = useNavigate();

    // Filter states
    const [priceFilter, setPriceFilter] = useState("");
    const [locationFilter, setLocationFilter] = useState("");
    const [typeFilter, setTypeFilter] = useState("");
    const [categoryFilter, setCategoryFilter] = useState("");
    const [locationCategoryFilter, setLocationCategoryFilter] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [categories, setCategories] = useState([]);
    const [locationCategories, setLocationCategories] = useState([]);

    // Fetch categories and location categories on component mount
    useEffect(() => {
        // Fetch Tour Categories
        axios.get(`${process.env.REACT_APP_API_URL}/tours-categories/get-all-categories`)
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.error("Error fetching categories:", error);
            });

        // Fetch Location Categories
        axios.get(`${process.env.REACT_APP_API_URL}/location-categories/get-all-location-categories`)
            .then((response) => {
                setLocationCategories(response.data);
            })
            .catch((error) => {
                console.error("Error fetching location categories:", error);
            });
    }, []);

    const handleFilter = () => {
        const queryParams = new URLSearchParams({
            search: searchQuery || "",
            price: priceFilter || "",
            location: locationFilter || "",
            type: typeFilter || "",
            category: categoryFilter || "",
            locationCategory: locationCategoryFilter || "",
        }).toString();

        navigate(`/filtered-tours-fron-home?${queryParams}`);
        window.scrollTo(0, 0);
    };

    const handleReset = () => {
        setPriceFilter("");
        setLocationFilter("");
        setTypeFilter("");
        setCategoryFilter("");
        setLocationCategoryFilter("");
        setSearchQuery("");
    };

    return (
        <div className="rounded-lg shadow-lg mx-auto w-full">
            {/* Header Section */}
            <div className="bg-gradient-to-t from-blue-500 to-blue-400 flex justify-between items-center px-6 py-5 rounded-t-lg">
                <div>
                    <h1 className="text-2xl font-bold text-yellow-400">Book Your Perfect Tour</h1>
                    <p className="text-gray-100 tracking-wide font-medium mt-1">
                        Select your preferences and explore top tour options.
                    </p>
                </div>
                <img src={enjoy} alt="Enjoy" className="w-14" />
            </div>

            {/* Filter Section */}
            <div className="bg-white p-6 rounded-b-lg">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                    {/* Column 1: Price and Departure */}
                    <div>
                        <div className="mb-4">
                            <label className="flex items-center text-gray-700 mb-2 gap-2">
                                <IoIosPricetags className="text-xl text-orange-600" />
                                Price
                            </label>
                            <select
                                className="w-full p-3 border rounded focus:ring-2 focus:ring-orange-400"
                                value={priceFilter}
                                onChange={(e) => setPriceFilter(e.target.value)}
                            >
                                <option value="">Select Price</option>
                                <option value="low">Under ₹30,000</option>
                                <option value="high">₹30,000 and above</option>
                            </select>
                        </div>
                        <div>
                            <label className="flex items-center text-gray-700 mb-2 gap-2">
                                <IoLocation className="text-xl text-orange-600" />
                                Departure City
                            </label>
                            <select
                                className="w-full p-3 border rounded focus:ring-2 focus:ring-orange-400"
                                value={locationFilter}
                                onChange={(e) => setLocationFilter(e.target.value)}
                            >
                                <option value="">Select Location</option>
                                <option value="Delhi">Delhi</option>
                                <option value="Haridwar">Haridwar</option>
                                <option value="Mumbai">Mumbai</option>
                                <option value="Goa">Goa</option>
                                <option value="Goa">Srinagar</option>
                                <option value="Goa">Uttarakhand</option>
                            </select>
                        </div>
                    </div>

                    {/* Column 2: Package and Category */}
                    <div>
                        <div className="mb-4">
                            <label className="flex items-center text-gray-700 mb-2 gap-2">
                                <FaGlobe className="text-xl text-orange-600" />
                                Package Type
                            </label>
                            <select
                                className="w-full p-3 border rounded focus:ring-2 focus:ring-orange-400"
                                value={typeFilter}
                                onChange={(e) => setTypeFilter(e.target.value)}
                            >
                                <option value="">Select Package</option>
                                <option value="Standard">Standard</option>
                                <option value="deluxe">deluxe</option>
                                <option value="deluxe">Luxury</option>
                            </select>
                        </div>
                        <div>
                            <label className="flex items-center text-gray-700 mb-2 gap-2">
                                <MdTour className="text-xl text-orange-600" />
                                Tour Category
                            </label>
                            <select
                                className="w-full p-3 border rounded focus:ring-2 focus:ring-orange-400"
                                value={categoryFilter}
                                onChange={(e) => setCategoryFilter(e.target.value)}
                            >
                                <option value="">Select Category</option>
                                {categories.map((category) => (
                                    <option key={category._id} value={category.category_name}>
                                        {category.category_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    {/* Column 3: Location Category and Search */}
                    <div>
                        <div className="mb-4">
                            <label className="flex items-center text-gray-700 mb-2 gap-2">
                                <IoLocation className="text-xl text-orange-600" />
                                Location Category
                            </label>
                            <select
                                className="w-full p-3 border rounded focus:ring-2 focus:ring-orange-400"
                                value={locationCategoryFilter}
                                onChange={(e) => setLocationCategoryFilter(e.target.value)}
                            >
                                <option value="">Select Location Category</option>
                                {locationCategories.map((locationCategory) => (
                                    <option key={locationCategory._id} value={locationCategory.location_name}>
                                        {locationCategory.location_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="flex items-center text-gray-700 mb-2 gap-2">
                                <RiSearchEyeLine className="text-xl text-orange-600" />
                                Search
                            </label>
                            <input
                                type="text"
                                placeholder="Search tours..."
                                className="w-full p-3 border rounded focus:ring-2 focus:ring-orange-400"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                    </div>

                    {/* Column 4: Buttons */}
                    <div>
                        <div className="flex flex-col justify-between items-start gap-4">
                            {/* Search Button */}
                            <div className="w-full">
                                <label className="flex items-center text-gray-700 mb-2 gap-2">
                                    <MdTour className="text-xl text-orange-600" />
                                    Want to go for your ideal tour?
                                </label>

                                <div className="w-full bg-gradient-to-r from-orange-500 to-yellow-500 p-[2px] rounded">
                                    <button
                                        onClick={handleFilter}
                                        className="flex items-center bg-white font-semibold px-6 py-2.5 rounded w-full bg-gradient-to-r from-orange-500 to-yellow-500 hover:to-orange-500 hover:from-yellow-500 text-white transition duration-200"
                                    >
                                        <RiSearchEyeLine size={22} className="mr-2" />
                                        Please, Search the Tour
                                    </button>
                                </div>
                            </div>

                            {/* Reset Button */}
                            <div className="w-full">
                                <label className="flex items-center text-gray-700 mb-2 gap-2">
                                    <TbZoomReset className="text-xl text-orange-600" />
                                    Do you want reset selected filters?
                                </label>

                                <div className="w-full bg-gradient-to-r from-gray-500 to-blue-700 p-[2px] rounded">
                                    <button
                                        onClick={handleReset}
                                        className="flex items-center bg-white font-semibold px-6 py-2.5 rounded w-full bg-gradient-to-r from-gray-500 to-blue-700 hover:to-gray-500 hover:from-blue-700 text-white transition duration-200"
                                    >
                                        <BiReset size={22} className="mr-2" />
                                        Yes, Reset the Filters
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default HomePageFilter;
