import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

const TrendUp = ({ onClose, data }) => {
    const [allData, setAllData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [updatedData, setUpdatedData] = useState(null);
    const [loader, setLoader] = useState(false);
    const modalRef = useRef();

    const closeModal = (e) => {
        if (modalRef.current === e.target) {
            onClose();
        }
    };

    const getAllToursById = () => {
        setIsLoading(true);
        const config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/new-packages/new-package-by-id/${data._id}`,
            headers: {},
        };

        axios
            .request(config)
            .then((response) => {
                const tourData = response.data;
                if (tourData.tour_is_on_trend === undefined || tourData.tour_is_on_trend === null) {
                    tourData.tour_is_on_trend = false;
                }
                setAllData(tourData);
                setUpdatedData(tourData);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                toast.error('Failed to load data. Please try again.');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getAllToursById();
    }, []);

    const handleToggle = () => {
        if (!updatedData) return;

        setUpdatedData((prevState) => ({
            ...prevState,
            tour_is_on_trend: !prevState.tour_is_on_trend,
        }));
    };

    const handleSubmit = () => {
        if (!updatedData) return;

        const config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/new-packages/update-new-package/${data._id}`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: updatedData,
        };

        setLoader(true);
        axios
            .request(config)
            .then(() => {
                toast.success('Package updated successfully!');
                setLoader(false);
                onClose();
            })
            .catch((error) => {
                console.error('Error updating data:', error);
                setLoader(false);
                toast.error('An error occurred. Please try again.');
            });
    };

    return (
        <div
            className="fixed inset-0 bg-[black]/10 bg-opacity-50 flex justify-center items-center z-50"
            onClick={closeModal}
            ref={modalRef}
        >
            <div className="w-full max-w-md bg-white rounded-md shadow-lg p-6">
                <div className="flex justify-between items-center border-b pb-3">
                    <h2 className="text-lg font-semibold text-black tracking-wide">
                        Tour Trending Status
                    </h2>
                </div>

                {isLoading ? (
                    <div className="mt-6 space-y-4 animate-pulse">
                        <div className="h-4 bg-gray-300 rounded w-3/4"></div>
                        <div className="h-4 bg-gray-300 rounded w-full"></div>
                        <div className="h-4 bg-gray-300 rounded w-1/2"></div>
                        <div className="flex items-center gap-4 mt-4">
                            <div className="h-6 w-12 bg-gray-300 rounded-full"></div>
                            <div className="h-6 w-12 bg-gray-300 rounded-full"></div>
                        </div>
                        <div className="h-10 bg-gray-300 rounded w-full mt-6"></div>
                    </div>
                ) : (
                    <div className="flex flex-col items-center mt-6">
                        <div className="flex items-center justify-between w-full bg-gray-100 p-4 rounded-md">
                            <span className="text-gray-800 font-medium tracking-wide">
                                {updatedData?.tour_is_on_trend
                                    ? 'Tour is marked as Trending'
                                    : 'Mark this tour as Trending'}
                            </span>
                            <button
                                onClick={handleToggle}
                                className={`w-12 h-6 flex items-center rounded-full p-1 transition-colors ${updatedData?.tour_is_on_trend ? 'bg-green-500' : 'bg-gray-300'
                                    }`}
                            >
                                <div
                                    className={`w-4 h-4 bg-white rounded-full transform transition-transform ${updatedData?.tour_is_on_trend ? 'translate-x-6' : ''
                                        }`}
                                ></div>
                            </button>
                        </div>
                        <button
                            type="button"
                            onClick={handleSubmit}
                            className="mt-6 font-bold tracking-wide text-lg w-full bg-gradient-to-l from-yellow-400 to-orange-500 text-white py-1.5 rounded-md shadow-sm hover:opacity-90 transition-all"
                        >
                            {loader ? "Please Wait.." : 'Ok'}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TrendUp;