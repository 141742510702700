import axios from "axios";
import React, { useEffect, useState } from "react";
import { MdLocationPin } from "react-icons/md";
import { PiWarningCircleDuotone } from "react-icons/pi";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const LocationCategoriesContent = () => {
  const [allLCategories, setAllLCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [editCategoryData, setEditCategoryData] = useState({});
  const [editLoader, setEditLoader] = useState(false);
  const [deletingCategoryId, setDeletingCategoryId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newCategoryData, setNewCategoryData] = useState({
    location_name: "",
    img_url: "",
  });
  const [addLoader, setAddLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [categotyID, setCategoryID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const totalPages = Math.ceil(allLCategories.length / itemsPerPage);
  const displayedLocationCategory = allLCategories.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePagination = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getAllLocationCategories();
  }, []);

  const getAllLocationCategories = () => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/location-categories/get-all-location-categories`,
      headers: {},
    };

    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        setAllLCategories(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching location categories:", error);
      });
  };

  const openModal = (id) => {
    setCategoryID(id);
    setDeleteModal(true);
  };

  const handleEdit = (category) => {
    setEditCategoryId(category._id);
    setEditCategoryData({
      location_name: category.location_name,
      img_url: category.img_url || "", // Set image URL if available
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditCategoryData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = (categoryId) => {
    // Convert location_name to lowercase before sending it
    const updatedData = {
      ...editCategoryData,
      location_name: editCategoryData.location_name.toLowerCase(),
    };

    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/location-categories/update-location-category/${categoryId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(updatedData),
    };

    setEditLoader(true);
    axios
      .request(config)
      .then((response) => {
        setEditCategoryId(null);
        getAllLocationCategories();
        setEditLoader(false);
      })
      .catch((error) => {
        console.error("Error updating location category:", error);
        setEditLoader(false);
      });
  };

  const handleDelete = (categoryId) => {
    const config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/location-categories/delete-location-category/${categoryId}`,
      headers: {},
    };
    {
      setDeletingCategoryId(categoryId);
      axios
        .request(config)
        .then((response) => {
          setDeletingCategoryId(null);
          getAllLocationCategories();
          setDeleteModal(false);
        })
        .catch((error) => {
          setDeletingCategoryId(null);
          console.error("Error deleting location category:", error);
        });
    }
  };

  const handleAddCategory = () => {
    // Convert location_name to lowercase before sending it
    const updatedCategoryData = {
      ...newCategoryData,
      location_name: newCategoryData.location_name.toLowerCase(),
    };

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/location-categories/create-location-category`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(updatedCategoryData),
    };

    setAddLoader(true);
    axios
      .request(config)
      .then((response) => {
        setShowModal(false);
        setNewCategoryData({ location_name: "", img_url: "" });
        getAllLocationCategories();
        setAddLoader(false);
      })
      .catch((error) => {
        console.error("Error adding location category:", error);
        setAddLoader(false);
      });
  };

  const handleCancel = () => {
    setEditCategoryId(null);
  };

  return (
    <div className="p-6 bg-white shadow-md rounded-lg">
      {/* Header */}
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-semibold mb-6 text-gray-800">
          Location Categories Management
        </h1>

        <button
          onClick={() => setShowModal(true)}
          className="bg-gradient-to-r text-lg flex items-center from-yellow-400 to-orange-500 hover:bg-yellow-800 text-white px-4 tracking-wide h-10 rounded-md hover:bg-gradient-to-r hover:to-yellow-400 hover:from-orange-500"
        >
          <MdLocationPin size={22} />
          <span className="ml-1">New Category</span>
        </button>
      </div>

      {/* Category Listing */}
      {isLoading ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {Array(6)
            .fill("")
            .map((_, index) => (
              <div
                key={index}
                className="p-4 bg-gray-100 border border-gray-200 shadow-sm rounded-lg animate-pulse"
              >
                <div className="h-6 bg-gray-300 rounded-md mb-4"></div>
                <div className="h-4 bg-gray-300 rounded-md mb-2"></div>
                <div className="h-4 bg-gray-300 rounded-md mb-4"></div>
                <div className="flex space-x-2">
                  <div className="h-5 w-14 bg-gray-300 rounded-md"></div>
                  <div className="h-5 w-16 bg-gray-300 rounded-md"></div>
                </div>
              </div>
            ))}
        </div>
      ) : allLCategories.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {displayedLocationCategory.map((category) => (
            <div
              key={category._id}
              className="p-4 bg-gray-100 border rounded-lg"
            >
              {editCategoryId === category._id ? (
                <>
                  <input
                    type="text"
                    name="location_name"
                    value={editCategoryData.location_name}
                    onChange={handleInputChange}
                    className="w-full mb-2 p-2 border"
                  />
                  <input
                    type="text"
                    name="img_url"
                    value={editCategoryData.img_url}
                    onChange={handleInputChange}
                    className="w-full mb-2 p-2 border"
                    placeholder="Image URL"
                  />
                  <div className="flex justify-end space-x-2">
                    {editLoader ? (
                      <button className="py-1 px-4 bg-gray-500 text-white rounded">
                        Please Wait...
                      </button>
                    ) : (
                      <>
                        <button
                          onClick={() => handleSave(category._id)}
                          className="py-1 px-4 bg-yellow-500 text-white rounded"
                        >
                          Save
                        </button>
                        <button
                          onClick={handleCancel}
                          className="py-1 px-4 bg-red-500 text-white rounded"
                        >
                          Cancel
                        </button>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="flex items-center">
                    <div>
                      {category.img_url && (
                        <img
                          src={category.img_url}
                          alt={category.category_name}
                          className="w-20 h-20 rounded-full mr-4"
                        />
                      )}
                    </div>

                    <div>
                      <h2 className="text-lg font-medium text-gray-800 mb-2 capitalize">
                        {category.location_name}
                      </h2>
                      <p className="text-sm text-gray-600 mb-2">
                        Applied For:{" "}
                        <span className="font-semibold capitalize">
                          {category.applied_for}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-end space-x-2">
                    <button
                      onClick={() => handleEdit(category)}
                      className="py-1 px-4 bg-orange-500 text-white rounded"
                    >
                      Edit
                    </button>

                    <button
                      onClick={() => {
                        setDeleteModal(true);
                        openModal(category._id);
                      }}
                      className="py-1 px-4 bg-gray-400 text-white rounded"
                    >
                      Delete
                    </button>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center text-gray-600">
          No location categories available.
        </div>
      )}

      {/* Add Category Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-md">
            <h2 className="text-xl font-semibold mb-4">
              Add New Location Category
            </h2>
            <input
              type="text"
              name="location_name"
              value={newCategoryData.location_name}
              onChange={(e) =>
                setNewCategoryData({
                  ...newCategoryData,
                  location_name: e.target.value,
                })
              }
              className="w-full mb-4 p-2 border rounded"
              placeholder="Location Name"
            />
            <input
              type="text"
              name="img_url"
              value={newCategoryData.img_url}
              onChange={(e) =>
                setNewCategoryData({
                  ...newCategoryData,
                  img_url: e.target.value,
                })
              }
              className="w-full mb-4 p-2 border rounded"
              placeholder="Image URL"
            />
            <div className="flex justify-end space-x-4">
              {addLoader ? (
                <button className="py-2 px-4 bg-gray-500 text-white rounded">
                  Adding...
                </button>
              ) : (
                <>
                  <button
                    onClick={() => setShowModal(false)}
                    className="py-2 px-4 text-gray-800 border border-gray-300 rounded-lg hover:bg-gray-200"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleAddCategory}
                    className="py-2 px-4 text-white bg-orange-500 rounded-lg hover:bg-orange-700"
                  >
                    Add
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <>
        <div className="flex justify-center items-center">
          <Stack spacing={2} className="mt-8 flex justify-center">
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePagination}
              variant="outlined"
              shape="rounded"
              color="primary"
            />
          </Stack>
        </div>
      </>
      {deleteModal && (
        <DelLocationModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          handleDelete={handleDelete}
          openModal={openModal}
          categotyID={categotyID}
        />
      )}
    </div>
  );
};

const DelLocationModal = ({
  setDeleteModal,
  openModal,
  handleDelete,
  categotyID,
}) => (
  <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center font-be-vietnam">
    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-xs">
      <div className="flex justify-center text-red-700 mb-4">
        <PiWarningCircleDuotone size={60} />
      </div>

      <p className="text-center tracking-wide text-lg">
        Are you sure to delete this location?
      </p>

      <div className="flex tracking-wide justify-center items-center space-x-4 mt-5">
        <button
          onClick={() => setDeleteModal(false)}
          className="py-2 px-4 text-gray-800 border border-gray-300 rounded-lg hover:bg-gray-200"
        >
          Cancel
        </button>
        <button
          className="py-2 px-4 text-white bg-orange-500 rounded-lg hover:bg-orange-700"
          onClick={() => handleDelete(categotyID)}
        >
          Yes, Delete
        </button>
      </div>
    </div>
  </div>
);

export default LocationCategoriesContent;

// import axios from 'axios';
// import React, { useEffect, useState } from 'react';
// import { MdLocationPin } from 'react-icons/md';

// const LocationCategoriesContent = () => {
//   const [allLCategories, setAllLCategories] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [editCategoryId, setEditCategoryId] = useState(null);
//   const [editCategoryData, setEditCategoryData] = useState({});
//   const [editLoader, setEditLoader] = useState(false);
//   const [deletingCategoryId, setDeletingCategoryId] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [newCategoryData, setNewCategoryData] = useState({ location_name: '' });
//   const [addLoader, setAddLoader] = useState(false);

//   useEffect(() => {
//     getAllLocationCategories();
//   }, []);

//   const getAllLocationCategories = () => {
//     const config = {
//       method: 'get',
//       maxBodyLength: Infinity,
//       url: `${process.env.REACT_APP_API_URL}/location-categories/get-all-location-categories`,
//       headers: {},
//     };

//     setIsLoading(true);
//     axios
//       .request(config)
//       .then((response) => {
//         setAllLCategories(response.data);
//         setIsLoading(false);
//       })
//       .catch((error) => {
//         setIsLoading(false);
//         console.error("Error fetching location categories:", error);
//       });
//   };

//   const handleEdit = (category) => {
//     setEditCategoryId(category._id);
//     setEditCategoryData({
//       location_name: category.location_name,
//     });
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setEditCategoryData((prev) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

//   const handleSave = (categoryId) => {
//     // Convert location_name to lowercase before sending it
//     const updatedData = {
//       ...editCategoryData,
//       location_name: editCategoryData.location_name.toLowerCase(),
//     };

//     const config = {
//       method: 'put',
//       maxBodyLength: Infinity,
//       url: `${process.env.REACT_APP_API_URL}/location-categories/update-location-category/${categoryId}`,
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       data: JSON.stringify(updatedData),
//     };

//     setEditLoader(true);
//     axios
//       .request(config)
//       .then((response) => {
//         setEditCategoryId(null);
//         getAllLocationCategories();
//         setEditLoader(false);
//       })
//       .catch((error) => {
//         console.error("Error updating location category:", error);
//         setEditLoader(false);
//       });
//   };

//   const handleDelete = (categoryId) => {
//     const config = {
//       method: 'delete',
//       maxBodyLength: Infinity,
//       url: `${process.env.REACT_APP_API_URL}/location-categories/delete-location-category/${categoryId}`,
//       headers: {},
//     };

//     if (window.confirm("Are you sure you want to delete this location category?")) {
//       setDeletingCategoryId(categoryId);
//       axios
//         .request(config)
//         .then((response) => {
//           setDeletingCategoryId(null);
//           getAllLocationCategories();
//         })
//         .catch((error) => {
//           setDeletingCategoryId(null);
//           console.error("Error deleting location category:", error);
//         });
//     }
//   };

//   const handleAddCategory = () => {
//     // Convert location_name to lowercase before sending it
//     const updatedCategoryData = {
//       ...newCategoryData,
//       location_name: newCategoryData.location_name.toLowerCase(),
//     };

//     const config = {
//       method: 'post',
//       maxBodyLength: Infinity,
//       url: `${process.env.REACT_APP_API_URL}/location-categories/create-location-category`,
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       data: JSON.stringify(updatedCategoryData),
//     };

//     setAddLoader(true);
//     axios
//       .request(config)
//       .then((response) => {
//         setShowModal(false);
//         setNewCategoryData({ location_name: '' });
//         getAllLocationCategories();
//         setAddLoader(false);
//       })
//       .catch((error) => {
//         console.error("Error adding location category:", error);
//         setAddLoader(false);
//       });
//   };

//   const handleCancel = () => {
//     setEditCategoryId(null);
//   };

//   return (
//     <div className="p-6 bg-white shadow-md rounded-lg">
//       {/* Header */}
//       <div className="flex justify-between items-center">
//         <h1 className="text-3xl font-semibold mb-6 text-gray-800">
//           Location Categories Management
//         </h1>

//         <button
//           onClick={() => setShowModal(true)}
//           className="bg-gradient-to-r text-lg flex items-center from-yellow-400 to-orange-500 hover:bg-yellow-800 text-white px-4 tracking-wide h-10 rounded-md hover:bg-gradient-to-r hover:to-yellow-400 hover:from-orange-500"
//         >
//           <MdLocationPin size={22} />
//           <span className="ml-1">New Category</span>
//         </button>
//       </div>

//       {/* Category Listing */}
//       {isLoading ? (
//         <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
//           {Array(6)
//             .fill("")
//             .map((_, index) => (
//               <div
//                 key={index}
//                 className="p-4 bg-gray-100 border border-gray-200 shadow-sm rounded-lg animate-pulse"
//               >
//                 <div className="h-6 bg-gray-300 rounded-md mb-4"></div>
//                 <div className="h-4 bg-gray-300 rounded-md mb-2"></div>
//                 <div className="h-4 bg-gray-300 rounded-md mb-4"></div>
//                 <div className="flex space-x-2">
//                   <div className="h-5 w-14 bg-gray-300 rounded-md"></div>
//                   <div className="h-5 w-16 bg-gray-300 rounded-md"></div>
//                 </div>
//               </div>
//             ))}
//         </div>
//       ) : allLCategories.length > 0 ? (
//         <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
//           {allLCategories.map((category) => (
//             <div key={category._id} className="p-4 bg-gray-100 border rounded-lg">
//               {editCategoryId === category._id ? (
//                 <>
//                   <input
//                     type="text"
//                     name="location_name"
//                     value={editCategoryData.location_name}
//                     onChange={handleInputChange}
//                     className="w-full mb-2 p-2 border"
//                   />
//                   <div className="flex justify-end space-x-2">
//                     {editLoader ? (
//                       <button className="py-1 px-4 bg-gray-500 text-white rounded">
//                         Please Wait...
//                       </button>
//                     ) : (
//                       <>
//                         <button
//                           onClick={() => handleSave(category._id)}
//                           className="py-1 px-4 bg-yellow-500 text-white rounded"
//                         >
//                           Save
//                         </button>
//                         <button
//                           onClick={handleCancel}
//                           className="py-1 px-4 bg-red-500 text-white rounded"
//                         >
//                           Cancel
//                         </button>
//                       </>
//                     )}
//                   </div>
//                 </>
//               ) : (
//                 <>
//                   <h2 className="text-lg font-medium capitalize">
//                     {category.location_name}
//                   </h2>
//                   <p className="text-sm text-gray-600">
//                     Applied For: <span className="font-semibold capitalize">
//                       {category.applied_for}
//                     </span>
//                   </p>
//                   <div className="flex justify-end space-x-2">
//                     <button
//                       onClick={() => handleEdit(category)}
//                       className="py-1 px-4 bg-orange-500 text-white rounded"
//                     >
//                       Edit
//                     </button>
//                     {deletingCategoryId === category._id ? (
//                       <button className="py-1 px-4 bg-gray-500 text-white rounded">
//                         Please Wait...
//                       </button>
//                     ) : (
//                       <button
//                         onClick={() => handleDelete(category._id)}
//                         className="py-1 px-4 bg-gray-400 text-white rounded"
//                       >
//                         Delete
//                       </button>
//                     )}
//                   </div>
//                 </>
//               )}
//             </div>
//           ))}
//         </div>
//       ) : (
//         <div className="text-center text-gray-600">No location categories available.</div>
//       )}

//       {/* Add Category Modal */}
//       {showModal && (
//         <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
//           <div className="bg-white p-6 rounded shadow-md">
//             <h2 className="text-xl font-semibold mb-4">Add New Location Category</h2>
//             <input
//               type="text"
//               name="location_name"
//               value={newCategoryData.location_name}
//               onChange={(e) =>
//                 setNewCategoryData({ location_name: e.target.value })
//               }
//               className="w-full mb-4 p-2 border rounded"
//               placeholder="Location Name"
//             />
//             <div className="flex justify-end space-x-4">
//               {addLoader ? (
//                 <button className="py-2 px-4 bg-gray-500 text-white rounded">
//                   Adding...
//                 </button>
//               ) : (
//                 <>
//                   <button
//                     onClick={() => setShowModal(false)}
//                     className="py-2 px-4 text-gray-800 border border-gray-300 rounded-lg hover:bg-gray-200"
//                   >
//                     Cancel
//                   </button>
//                   <button
//                     onClick={handleAddCategory}
//                     className="py-2 px-4 text-white bg-orange-500 rounded-lg hover:bg-orange-700"
//                   >
//                     Add
//                   </button>
//                 </>
//               )}
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default LocationCategoriesContent;
