import React from "react";

const CabBooking = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-green-400 to-blue-500 text-white">
      <div className="text-center">
        <h1 className="text-5xl font-bold mb-6 animate__animated animate__fadeIn animate__delay-1s">
          Cab Booking
        </h1>
        <p className="text-xl mb-8">
          Our cab booking service is coming soon. We’re working hard to bring it
          to you!
        </p>

        <div className="bg-white text-gray-800 p-8 rounded-lg shadow-xl max-w-md mx-auto">
          <div className="mb-6">
            <div className="text-center mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-24 h-24 text-gray-600 mx-auto"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 12l4-4m0 0l4 4m-4-4v12m-8-4h16m-12 4H3a2 2 0 01-2-2V5a2 2 0 012-2h14a2 2 0 012 2v12a2 2 0 01-2 2h-6"
                />
              </svg>
            </div>
            <h2 className="text-2xl font-semibold mb-4">Booking Coming Soon</h2>
            <p className="text-sm text-gray-500">
              We’re building a seamless cab booking experience for you. Stay
              tuned for updates!
            </p>
          </div>

          <div className="text-center mt-8">
            <button className="bg-green-500 text-white py-2 px-6 rounded-lg hover:bg-green-700 transition duration-300">
              Notify Me
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CabBooking;
