import React from "react";

const SkeletonLoaderForCabDetails = () => {
  return (
    <div className="animate-pulse px-7">
      <div className="flex justify-between items-center gap-4 w-full">
        <div className="h-[170px] w-1/4 bg-gray-300 border-[1px] border-slate-400 rounded-lg"></div>
        <div className="h-[170px] w-1/4 bg-gray-300 border-[1px] border-slate-400 rounded-lg"></div>
        <div className="h-[170px] w-1/4 bg-gray-300 border-[1px] border-slate-400 rounded-lg"></div>
        <div className="h-[170px] w-1/4 bg-gray-300 border-[1px] border-slate-400 rounded-lg"></div>
      </div>
      <div className="flex justify-center items-center gap-4 mt-3 w-full">
        <div className="h-[170px] w-1/4 bg-gray-300 border-[1px] border-slate-400 rounded-lg"></div>
        <div className="h-[170px] w-1/4 bg-gray-300 border-[1px] border-slate-400 rounded-lg"></div>
        <div className="h-[170px] w-1/4 bg-gray-300 border-[1px] border-slate-400 rounded-lg"></div>
      </div>
      <div className="h-6 bg-gray-300 rounded-sm w-[150px] mt-4"></div>
      <div className="flex justify-between items-center gap-4 mt-3 w-full">
        <div className="h-5 w-[100px] bg-gray-300 border-[1px] border-slate-400 "></div>
        <div className="h-5 w-[100px] bg-gray-300 border-[1px] border-slate-400 "></div>
        <div className="h-5 w-[100px] bg-gray-300 border-[1px] border-slate-400 ">
          {" "}
        </div>
        <div className="h-5 w-[100px] bg-gray-300 border-[1px] border-slate-400 "></div>
        <div className="h-5 w-[100px] bg-gray-300 border-[1px] border-slate-400 "></div>
      </div>
      <div className="h-6 bg-gray-300 rounded-sm w-[150px] mt-4"></div>
      <div className="h-6 bg-gray-300 rounded-sm w-full mt-4"></div>
    </div>
  );
};

export default SkeletonLoaderForCabDetails;
