// import React, { useEffect, useState } from 'react'
// import { useForm } from "react-hook-form";
// import { IoArrowBackCircle } from 'react-icons/io5';
// import { MdDelete, MdFormatListBulletedAdd } from 'react-icons/md';
// import { useNavigate } from 'react-router-dom';
// import toast from 'react-hot-toast';
// import axios from 'axios'
// import { useParams } from 'react-router-dom';
// const EditCabs = () => {
//     const { id } = useParams();
//     const navigate = useNavigate();
//     const [extraFeatures, setExtraFeatures] = useState([])
//     const [imagesUrls, setImagesUrls] = useState([])
//     const [cabs, setCabs] = useState({});

//     const getCabsById = () => {
//         let config = {
//             method: 'get',
//             maxBodyLength: Infinity,
//             url: `${process.env.REACT_APP_API_URL}/cabs/get-cab-by-id/${id}`,
//             headers: {},

//         };
//         axios.request(config)
//             .then((response) => {
//                 console.log(JSON.stringify(response.data));
//                 setCabs(response?.data);
//                 setExtraFeatures(response?.data?.extraFeatures);
//                 setImagesUrls(response?.data?.imagesUrls);
//             })
//             .catch((error) => {
//                 console.log(error);
//             });
//     }

//     const {
//         register,
//         getValues,
//         handleSubmit,
//         reset,
//         resetField,
//         formState: { errors },
//     } = useForm(
//         {
//             defaultValues: {
//                 ...cabs,
//             },
//         }
//     );

//     useEffect(() => {
//         if (cabs && Object.keys(cabs).length > 0) {
//             reset(cabs);
//         }
//     }, [cabs, reset]);

//     useEffect(() => {
//         if (id) {
//             getCabsById(id);
//         }
//     }, [id]);

//     const [isUploading, setIsUploading] = useState(false);
//     const onSubmit = (data) => {
//         const newData = {
//             ...data,
//             imagesUrls: [...imagesUrls],
//             extraFeatures: [...extraFeatures]
//         }
//         console.log('----------->', newData);
//         let config = {
//             method: 'put',
//             maxBodyLength: Infinity,
//             url: `${process.env.REACT_APP_API_URL}/cabs/update-cab/${id}`,
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             data: newData
//         };

//         axios.request(config)
//             .then((response) => {
//                 console.log(JSON.stringify(response.data));
//                 navigate('/cab')
//             })
//             .catch((error) => {
//                 console.log(error);
//             });

//     };

//     const uploadToCloudinary = async (file) => {
//         try {
//             const formData = new FormData();
//             formData.append('file', file);
//             formData.append('upload_preset', 'wjs1zyo5');
//             const response = await fetch(
//                 `https://api.cloudinary.com/v1_1/dnw1ttr02/image/upload`,
//                 {
//                     method: 'POST',
//                     body: formData,
//                 }
//             );
//             const data = await response.json();
//             return data.secure_url;
//         } catch (error) {
//             console.error('Error uploading to Cloudinary:', error);
//             toast.error('Failed to upload image');
//             throw error;
//         }
//     };

//     const handleRemoveImage = (urlToRemove) => {
//         setImagesUrls(imagesUrls.filter((url) => url !== urlToRemove));
//     };

//     const handleMultipleImageUpload = async (e) => {
//         const files = Array.from(e.target.files);
//         if (files.length === 0) return;
//         try {
//             setIsUploading(true);
//             const uploadPromises = files.map(file => uploadToCloudinary(file));
//             const uploadedUrls = await Promise.all(uploadPromises);
//             setImagesUrls(prevUrls => [...prevUrls, ...uploadedUrls]);
//             toast.success('Images uploaded successfully');
//         } catch (error) {
//             toast.error('Failed to upload some images');
//         } finally {
//             setIsUploading(false);
//         }
//     };

//     const handleAddExtraFeatures = () => {
//         const feature = getValues("extra").trim();
//         if (!feature) return;
//         if (extraFeatures.includes(feature)) {
//             alert("Feature already added!");
//             return;
//         }
//         setExtraFeatures((prevFeatures) => [...prevFeatures, feature]);
//         resetField("extra");
//     };

//     const handleRemoveFeature = (index) => {
//         setExtraFeatures((prev) => prev.filter((_, i) => i !== index));
//     };

//     return (
//         <div className="p-4 mt-5">
//             <div className="mb-6 flex justify-between">
//                 <div className=" tracking-wider">
//                     <button
//                         className={`flex-1 py-2 px-3 text-center font-medium transition-all bg-gradient-to-r from-yellow-400 to-orange-500`}
//                     >
//                         All Details
//                     </button>

//                 </div>
//                 <div>
//                     <IoArrowBackCircle size={30} color="#ec7e14" onClick={() => { navigate('/cab') }} />
//                 </div>
//             </div>
//             <form
//                 onSubmit={handleSubmit(onSubmit)}
//                 className="grid md:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3 px-6"
//             >

//                 <div>
//                     <label className="block text-sm font-medium text-gray-700">
//                         Registration Number
//                     </label>
//                     <input
//                         {...register("registrationNumber", { required: "This field is required" })}
//                         type="text"
//                         className="mt-1 block w-full p-2 border border-gray-300 rounded"
//                     />
//                     {errors.registrationNumber && (
//                         <p className="text-red-500 text-sm">{errors.registrationNumber.message}</p>
//                     )}
//                 </div>

//                 <div>
//                     <label className="block text-sm font-medium text-gray-700">Type</label>
//                     <input
//                         {...register("type", { required: "This field is required" })}
//                         type="text"
//                         className="mt-1 block w-full p-2 border border-gray-300 rounded"
//                     />
//                     {errors.type && <p className="text-red-500 text-sm">{errors.type.message}</p>}
//                 </div>

//                 <div>
//                     <label className="block text-sm font-medium text-gray-700">Model</label>
//                     <input
//                         {...register("model", { required: "This field is required" })}
//                         type="text"
//                         className="mt-1 block w-full p-2 border border-gray-300 rounded"
//                     />
//                     {errors.model && <p className="text-red-500 text-sm">{errors.model.message}</p>}
//                 </div>

//                 <div>
//                     <label className="block text-sm font-medium text-gray-700">Manufacturer</label>
//                     <input
//                         {...register("manufacturer", { required: "This field is required" })}
//                         type="text"
//                         className="mt-1 block w-full p-2 border border-gray-300 rounded"
//                     />
//                     {errors.manufacturer && (
//                         <p className="text-red-500 text-sm">{errors.manufacturer.message}</p>
//                     )}
//                 </div>

//                 <div>
//                     <label className="block text-sm font-medium text-gray-700">Capacity</label>
//                     <input
//                         {...register("capacity", {
//                             required: "This field is required",
//                             valueAsNumber: true,
//                         })}
//                         type="number"
//                         className="mt-1 block w-full p-2 border border-gray-300 rounded"
//                     />
//                     {errors.capacity && (
//                         <p className="text-red-500 text-sm">{errors.capacity.message}</p>
//                     )}
//                 </div>

//                 <div>
//                     <label className="block text-sm font-medium text-gray-700">Color</label>
//                     <input
//                         {...register("color", { required: "This field is required" })}
//                         type="text"
//                         className="mt-1 block w-full p-2 border border-gray-300 rounded"
//                     />
//                     {errors.color && <p className="text-red-500 text-sm">{errors.color.message}</p>}
//                 </div>

//                 <div>
//                     <label className="block text-sm font-medium text-gray-700">Rating</label>
//                     <input
//                         {...register("rating", { required: "This field is required" })}
//                         type="number"
//                         className="mt-1 block w-full p-2 border border-gray-300 rounded"
//                     />
//                     {errors.color && <p className="text-red-500 text-sm">{errors.color.message}</p>}
//                 </div>

//                 <div>
//                     <label className="block text-sm font-medium text-gray-700">IsAvailable</label>
//                     <select
//                         {...register("isAvailable", { required: "This field is required" })}
//                         className="mt-1 block w-full p-2 border border-gray-300 rounded"
//                     >
//                         <option value="">Select an option</option>
//                         <option value="true">True</option>
//                         <option value="false">False</option>
//                     </select>
//                     {errors.isAvailable && (
//                         <p className="text-red-500 text-sm">{errors.isAvailable.message}</p>
//                     )}
//                 </div>

//                 <div>
//                     <label className="block text-sm font-medium text-gray-700">Fare Per Km</label>
//                     <input
//                         {...register("farePerKm", {
//                             required: "This field is required",
//                             valueAsNumber: true,
//                         })}
//                         type="number"
//                         step="0.01"
//                         className="mt-1 block w-full p-2 border border-gray-300 rounded"
//                     />
//                     {errors.farePerKm && (
//                         <p className="text-red-500 text-sm">{errors.farePerKm.message}</p>
//                     )}
//                 </div>
//                 <div>
//                     <div>
//                         <label className="block text-sm font-medium text-gray-700">
//                             Cab Image Upload
//                         </label>
//                         <input
//                             type="file"
//                             accept="image/*"
//                             multiple
//                             onChange={handleMultipleImageUpload}
//                             disabled={isUploading}
//                             className="mt-1 block w-full p-2 border border-gray-300 rounded"
//                         />
//                     </div>

//                     <div className="mt-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
//                         {imagesUrls.map((url, index) => (
//                             <div key={index} className="relative">
//                                 <img
//                                     src={url}
//                                     alt={`Uploaded ${index + 1}`}
//                                     className="w-full h-32 object-cover rounded border"
//                                 />
//                                 <button
//                                     onClick={() => handleRemoveImage(url)}
//                                     className="absolute top-1 right-1 bg-red-600 text-white text-xs px-2 py-1 rounded"
//                                 >
//                                     Remove
//                                 </button>
//                             </div>
//                         ))}
//                     </div>
//                 </div>

//                 <div>
//                     <label className="block text-sm font-medium text-gray-700">
//                         Description
//                     </label>
//                     <textarea
//                         {...register("description", { required: "This field is required" })}
//                         className="mt-1 block w-full p-1 border border-gray-300 rounded"
//                         rows="4"
//                     />
//                     {errors.registrationNumber && (
//                         <p className="text-red-500 text-sm">{errors.registrationNumber.message}</p>
//                     )}
//                 </div>

//                 <div className="col-span-2">
//                     <label className="block text-sm font-medium text-gray-700">
//                         Extra Features
//                     </label>
//                     <div className="flex">
//                         <input
//                             {...register("extra")}
//                             type="text"
//                             placeholder="Add a feature"
//                             className="mt-1 block w-full p-2 border border-gray-300 rounded"
//                         />
//                         <button
//                             type="button"
//                             onClick={handleAddExtraFeatures}
//                             className="ml-3 bg-gradient-to-r from-yellow-400 to-orange-500 text-white p-2 rounded"
//                         >
//                             <MdFormatListBulletedAdd size={20} />
//                         </button>
//                     </div>

//                     <div className="mt-4 space-y-2">
//                         {extraFeatures?.map((feature, index) => (
//                             <div
//                                 key={index}
//                                 className="flex items-center justify-between p-2 bg-gray-100 rounded shadow-sm"
//                             >
//                                 <span>{feature}</span>
//                                 <button
//                                     type="button"
//                                     onClick={() => handleRemoveFeature(index)}
//                                     className="text-red-500 hover:text-red-700 text-sm flex items-center"
//                                 >
//                                     <MdDelete size={20} className="mr-1" />
//                                 </button>
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//                 <div className="md:col-span-2">
//                     <button
//                         type="submit"
//                         className="py-2 px-6 bg-gradient-to-l from-yellow-400 to-orange-500 text-white rounded"
//                     >
//                         Submit
//                     </button>
//                 </div>
//             </form>
//         </div>
//     )
// }

// export default EditCabs

import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { IoArrowBackCircle } from "react-icons/io5";
import { MdDelete, MdFormatListBulletedAdd } from "react-icons/md";
import { FaTimes } from "react-icons/fa";
import { useEffect } from "react";
const EditCabs = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [extraFeatures, setExtraFeatures] = useState([]);
  const [imagesUrls, setImagesUrls] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [formData, setFormData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const getCabsById = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/cabs/get-cab-by-id/${id}`,
      headers: {},
    };
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        //  setCabs(response?.data)
        setFormData(response?.data);
        setExtraFeatures(response?.data?.extraFeatures);
        setImagesUrls(response?.data?.imagesUrls);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCabsById();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.registrationNumber)
      newErrors.registrationNumber = "This field is required";
    if (!formData.type) newErrors.type = "This field is required";
    if (!formData.model) newErrors.model = "This field is required";
    if (!formData.manufacturer)
      newErrors.manufacturer = "This field is required";
    if (!formData.capacity) newErrors.capacity = "This field is required";
    if (!formData.color) newErrors.color = "This field is required";
    if (!formData.rating) newErrors.rating = "This field is required";
    if (!formData.isAvailable) newErrors.isAvailable = "This field is required";
    if (!formData.farePerKm) newErrors.farePerKm = "This field is required";
    if (!formData.description) newErrors.description = "This field is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const uploadToCloudinary = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "wjs1zyo5");
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dnw1ttr02/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      return data.secure_url;
    } catch (error) {
      console.error("Error uploading to Cloudinary:", error);
      toast.error("Failed to upload image");
      throw error;
    }
  };

  const handleRemoveImage = (indexToRemove) => {
    setImagesUrls((prevUrls) =>
      prevUrls.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleMultipleImageUpload = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    try {
      setIsUploading(true);
      const uploadPromises = files.map((file) => uploadToCloudinary(file));
      const uploadedUrls = await Promise.all(uploadPromises);
      setImagesUrls((prevUrls) => [...prevUrls, ...uploadedUrls]);
      toast.success("Images uploaded successfully");
    } catch (error) {
      toast.error("Failed to upload some images");
    } finally {
      setIsUploading(false);
    }
  };

  const handleAddExtraFeatures = () => {
    const feature = formData.extraFeaturesInput.trim();
    if (!feature) return;
    if (extraFeatures.includes(feature)) {
      alert("Feature already added!");
      return;
    }
    setExtraFeatures((prevFeatures) => [...prevFeatures, feature]);
    setFormData((prev) => ({ ...prev, extraFeaturesInput: "" }));
  };

  const handleRemoveFeature = (index) => {
    setExtraFeatures((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsSubmitting(true);
    const newData = {
      ...formData,
      imagesUrls: imagesUrls,
      extraFeatures: [...extraFeatures],
    };
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/cabs/update-cab/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: newData,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        navigate("/cab");
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
      });
  };

  return (
    <div className="p-4 mt-5">
      <div className="mb-6 flex justify-between">
        <div className="tracking-wider">
          <button
            className={`flex-1 py-2 px-3 text-center text-white font-medium transition-all bg-gradient-to-r from-yellow-400 to-orange-500 rounded-md`}
          >
            All Details
          </button>
        </div>
        <div>
          <IoArrowBackCircle
            size={30}
            color="#ec7e14"
            onClick={() => {
              navigate("/cab");
            }}
          />
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="grid md:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3 px-6"
      >
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Registration Number
          </label>
          <input
            name="registrationNumber"
            value={formData.registrationNumber}
            onChange={handleChange}
            type="text"
            className="mt-1 block w-full p-2 border border-gray-300 rounded"
          />
          {errors.registrationNumber && (
            <p className="text-red-500 text-sm">{errors.registrationNumber}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Type
          </label>
          <input
            name="type"
            value={formData.type}
            onChange={handleChange}
            type="text"
            className="mt-1 block w-full p-2 border border-gray-300 rounded"
          />
          {errors.type && <p className="text-red-500 text-sm">{errors.type}</p>}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Model
          </label>
          <input
            name="model"
            value={formData.model}
            onChange={handleChange}
            type="text"
            className="mt-1 block w-full p-2 border border-gray-300 rounded"
          />
          {errors.model && (
            <p className="text-red-500 text-sm">{errors.model}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Manufacturer
          </label>
          <input
            name="manufacturer"
            value={formData.manufacturer}
            onChange={handleChange}
            type="text"
            className="mt-1 block w-full p-2 border border-gray-300 rounded"
          />
          {errors.manufacturer && (
            <p className="text-red-500 text-sm">{errors.manufacturer}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Capacity
          </label>
          <input
            name="capacity"
            value={formData.capacity}
            onChange={handleChange}
            type="number"
            className="mt-1 block w-full p-2 border border-gray-300 rounded"
          />
          {errors.capacity && (
            <p className="text-red-500 text-sm">{errors.capacity}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Color
          </label>
          <input
            name="color"
            value={formData.color}
            onChange={handleChange}
            type="text"
            className="mt-1 block w-full p-2 border border-gray-300 rounded"
          />
          {errors.color && (
            <p className="text-red-500 text-sm">{errors.color}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Rating
          </label>
          <input
            name="rating"
            value={formData.rating}
            onChange={handleChange}
            type="number"
            step="0.1"
            min="0"
            max="5"
            className="mt-1 block w-full p-2 border border-gray-300 rounded"
          />
          {errors.rating && (
            <p className="text-red-500 text-sm">{errors.rating}</p>
          )}
        </div>

        {/* Is Available */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Is Available
          </label>
          <select
            name="isAvailable"
            value={formData.isAvailable}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded"
          >
            <option value="">Select availability</option>
            <option value="true">Available</option>
            <option value="false">Not Available</option>
          </select>
          {errors.isAvailable && (
            <p className="text-red-500 text-sm">{errors.isAvailable}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            City
          </label>
          <input
            name="avail_in_city"
            value={formData.avail_in_city}
            onChange={handleChange}
            type="text"
            className="mt-1 block w-full p-2 border border-gray-300 rounded"
          />
          {errors.farePerKm && (
            <p className="text-red-500 text-sm">{errors.farePerKm}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            State
          </label>
          <input
            name="avail_in_state"
            value={formData.avail_in_state}
            onChange={handleChange}
            type="text"
            className="mt-1 block w-full p-2 border border-gray-300 rounded"
          />
          {errors.farePerKm && (
            <p className="text-red-500 text-sm">{errors.farePerKm}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Fare Per Km
          </label>
          <input
            name="farePerKm"
            value={formData.farePerKm}
            onChange={handleChange}
            type="number"
            className="mt-1 block w-full p-2 border border-gray-300 rounded"
          />
          {errors.farePerKm && (
            <p className="text-red-500 text-sm">{errors.farePerKm}</p>
          )}
        </div>

        <div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Cab Image Upload
            </label>
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleMultipleImageUpload}
              disabled={isUploading}
              className="mt-1 block w-full p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="mt-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {imagesUrls.map((url, index) => (
              <div key={index} className="relative">
                <img
                  src={url}
                  alt={`Uploaded ${index + 1}`}
                  className="w-full h-32 object-cover rounded border"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveImage(index)}
                  className="absolute top-1 right-1 bg-red-600 text-white text-xs px-1 py-1 rounded-full"
                >
                  <FaTimes size={12} />
                </button>
              </div>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            rows="4"
            className="mt-1 block w-full p-2 border border-gray-300 rounded"
          ></textarea>
          {errors.description && (
            <p className="text-red-500 text-sm">{errors.description}</p>
          )}
        </div>

        <div className="col-span-2">
          <label className="block text-sm font-medium text-gray-700">
            Extra Features
          </label>
          <div className="flex">
            <input
              name="extraFeaturesInput"
              value={formData.extraFeaturesInput}
              onChange={handleChange}
              type="text"
              placeholder="Add a feature"
              className="mt-1 block w-full p-2 border border-gray-300 rounded"
            />
            <button
              type="button"
              onClick={handleAddExtraFeatures}
              className="ml-3 bg-gradient-to-r from-yellow-400 to-orange-500 text-white p-2 rounded"
            >
              <MdFormatListBulletedAdd size={20} />
            </button>
          </div>

          <div className="mt-4 space-y-2">
            {extraFeatures.map((feature, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-2 bg-gray-100 rounded shadow-sm"
              >
                <span>{feature}</span>
                <button
                  type="button"
                  onClick={() => handleRemoveFeature(index)}
                  className="text-red-500 hover:text-red-700 text-sm flex items-center"
                >
                  <MdDelete size={20} className="mr-1" />
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="col-span-2 mt-4">
          <button
            type="submit"
            disabled={isSubmitting}
            className="px-4 py-2 w-full sm:w-auto bg-gradient-to-r from-yellow-400 to-orange-500 text-white rounded-lg flex justify-center items-center gap-2"
          >
            {isSubmitting ? "Please wait..." : <>Submit</>}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditCabs;
