import React from 'react';
import img1 from '../../Assets/Common/chaiman.png';

const Chairman = () => {
    return (
        <div className="px-4 sm:px-6 md:px-16 lg:px-20 pb-10">
            <div className="flex flex-col-reverse md:flex-row items-center md:items-stretch space-y-10 md:space-y-0 md:space-x-12">

                {/* Text Section */}
                <div className="flex-1 bg-gradient-to-r from-blue-50 via-white to-white p-6 sm:p-8 rounded-lg shadow-lg flex flex-col justify-between">
                    <div>
                        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-800 mb-4 sm:mb-6 border-b-2 border-[#F94E05] inline-block">
                            Meet Our Chairman
                        </h2>

                        <div className="space-y-4 sm:space-y-5">
                            <div className="flex items-center">
                                <span className="text-gray-800 text-sm sm:text-lg">Diwakar Singh</span>
                            </div>

                            <div className="flex items-center">
                                <span className="text-gray-800 text-sm sm:text-lg">Founder & Chairman</span>
                            </div>

                            <div className="flex items-center">
                                <span className="text-gray-800 text-sm sm:text-lg">
                                    <a
                                        href="mailto:chairman.weekendbhraman@gmail.com"
                                        className="hover:underline"
                                    >
                                        chairman.weekendbhraman@gmail.com
                                    </a>
                                </span>
                            </div>

                            <div className="flex items-center">
                                <span className="text-gray-800 text-sm sm:text-lg">
                                    +91-9540512487 / +91-8076663076
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="text-gray-600 text-sm sm:text-base mt-4 leading-relaxed space-y-2">
                        <p>
                            With over 10 years of experience in the hospitality and tourism sectors, 
                            Diwakar Singh exemplifies a commitment to ethics and discipline, always striving 
                            to lead by example in all aspects of the business.
                        </p>
                        <p>
                            As the Founder and Chairman, he has consistently worked towards creating unique 
                            travel experiences and promoting sustainable tourism. His leadership has been pivotal 
                            in positioning the organization as a trusted name in the industry.
                        </p>
                    </div>
                </div>

                {/* Image Section */}
                <div className="flex-shrink-0 w-full md:w-1/3">
                    <div className="relative h-auto">
                        <img
                            src={img1}
                            alt="Chairman"
                            className="rounded-lg shadow-xl w-full h-[80%] max-w-full md:h-auto object-cover transition-transform transform hover:scale-105"
                        />
                        <div className="absolute inset-0 bg-blue-500 bg-opacity-20 rounded-lg mix-blend-multiply"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chairman;
