import React, { useEffect, useState } from "react";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { FaTimes } from "react-icons/fa";
import toast from 'react-hot-toast';
import axios from 'axios'
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
const AddNewTours = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("First");
    const [isUploading, setIsUploading] = useState(false);
    const openTab = (tabName) => {
        setActiveTab(tabName);
    };

    const isTabActive = (tabName) => {
        if (tabName === "First") return activeTab === "First" || activeTab === "Second" || activeTab === "Third" || activeTab === "Fourth";
        if (tabName === "Second") return activeTab === "Second" || activeTab === "Third" || activeTab === "Fourth";
        if (tabName === "Third") return activeTab === "Third" || activeTab === "Fourth";
        if (tabName === "Fourth") return activeTab === "Fourth";

        return false;
    };

    const [thingsToCarryList, setThingsToCarryList] = useState([]);
    const [descriptionList, setDescriptionList] = useState([]);
    const [sightSeeing, setSightSeeing] = useState([]);
    const [activities, setActivities] = useState([]);
    const [tourDetailOnDayBasis, setTourDetailOnDayBasis] = useState([]);
    const [type_of_packages, setType_Of_Packages] = useState([]);
    const [inclusionsList, setInclusionsList] = useState([]);
    const [exclusionsList, setExclusionsList] = useState([]);
    const [hotelsList, sethotelsList] = useState([]);
    const [errors, setErrors] = useState({});

    const [terms_and_conditions, setTermsAndConditions] = useState([]);
    const [cancellation_policy, setCancellationPolicy] = useState([]);
    const [payment_options, setPaymentOptions] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        departure_place: "",
        tour_route: "",
        arrival_city: "",
        arrival_date: "",
        number_of_travelers: "",
        tours_category: "",
        tour_duration: "",
        meals: "",
        travel_by: "",
        travel_date: "",
        return_location: "",
        departure_time: "",
        image_url: "",
        things_to_carry: "",
        description: [],
        inclusions: [],
        exclusions: [],
        hotels: "",
        type_of_packages: "",
        big_image_url: "",
        small_image_urls: [],
        tourDetailOnDayBasis: [],
        terms_and_conditions: [],
        cancellation_policy: [],
        payment_options: []
    });
    const [tourCategories, setTourCategories] = useState([]);
    const [tourLocations, setTourLocations] = useState([]);
    const getAllToursCategories = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/tours-categories/get-all-categories`,
            headers: {},
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setTourCategories(response?.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        getAllToursCategories();
    }, [])



    const getAllLocations = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/location-categories/get-all-location-categories`,
            headers: {},
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setTourLocations(response?.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        getAllLocations();
    }, [])

    const [tourDetail, setTourDetail] = useState({
        day: "",
        dayHeading: "",
        location: "",
        reportingTime: "",
        departureTime: "",
        lunchBreak: "",
        stay_and_dinner: "",
        reporting_location: "",
        trek_start_time: "",
        arrival_time: "",
        break_fast: "",
        break_fast_time: "",
        how_to_reach: "",
        drop_point: "",
        mode_of_transport: "",
        separate_tour_reporting_location: "",
        sightSeeing: "",
        activities: "",
        daywiseDescription: "",
        location_category: ""
    })

    const [typePackages, setTypePackages] = useState({
        packageName: "",
        location: "",
        type: "",
        price: "",
        seatSharing: "",
    })

    const [hotels, setHotels] = useState({
        nights: "",
        place: "",
        hotel_name_standard: "",
        hotel_name_deluxe: ""
    })

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name.trim()) newErrors.name = 'Tour Name is required';
        if (!formData.tour_route.trim()) newErrors.tour_route = 'Tour Route is required';
        if (!formData.departure_place.trim()) newErrors.departure_place = 'Departure Place is required';
        if (!formData.arrival_city.trim()) newErrors.arrival_city = 'Arrival City is required';
        if (!formData.arrival_date.trim()) newErrors.arrival_date = 'Arrival Date is required';
        if (!formData.number_of_travelers.trim()) newErrors.number_of_travelers = 'Number of Travelers is required';
        if (!formData.tour_duration.trim()) newErrors.tour_duration = 'Tour Duration is required';
        if (!formData.meals.trim()) newErrors.meals = 'Meals information is required';
        if (!formData.travel_by.trim()) newErrors.travel_by = 'Travel By is required';
        if (!formData.travel_date.trim()) newErrors.travel_date = 'Travel Date is required';
        if (!formData.return_location.trim()) newErrors.return_location = 'Return Location is required';
        if (!formData.departure_time.trim()) newErrors.departure_time = 'Departure Time is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // const handleNextClick = () => {
    //     if (validateForm()) {
    //         openTab("Second"); 
    //     }
    // };

    const uploadToCloudinary = async (file) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', 'wjs1zyo5');

            const response = await fetch(
                `https://api.cloudinary.com/v1_1/dnw1ttr02/image/upload`,
                {
                    method: 'POST',
                    body: formData,
                }
            );

            const data = await response.json();
            return data.secure_url;
        } catch (error) {
            console.error('Error uploading to Cloudinary:', error);
            toast.error('Failed to upload image');
            throw error;
        }
    };


    const handleImageUpload = async (e, imageType) => {
        const file = e.target.files[0];
        if (!file) return;

        setIsUploading(true);
        try {
            const imageUrl = await uploadToCloudinary(file);

            setFormData(prev => ({
                ...prev,
                [imageType]: imageUrl
            }));
            toast.success('Image uploaded successfully');
        } catch (error) {
            toast.error('Failed to upload image');
        } finally {
            setIsUploading(false);
        }
    };

    const handleMultipleImageUpload = async (e) => {
        const files = Array.from(e.target.files);
        if (files.length === 0) return;

        setIsUploading(true);
        try {
            const uploadPromises = files.map(file => uploadToCloudinary(file));
            const uploadedUrls = await Promise.all(uploadPromises);

            setFormData(prev => ({
                ...prev,
                small_image_urls: [...prev.small_image_urls, ...uploadedUrls]
            }));
            toast.success('Images uploaded successfully');
        } catch (error) {
            toast.error('Failed to upload some images');
        } finally {
            setIsUploading(false);
        }
    };

    const handleRemoveImage = (indexToRemove) => {
        setFormData(prev => ({
            ...prev,
            small_image_urls: prev.small_image_urls.filter((_, index) => index !== indexToRemove)
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleInputChangeTourDetail = (e) => {
        const { name, value } = e.target;
        setTourDetail(prev => ({
            ...prev,
            [name]: value
        }));
    }

    const handleInputChangeTypePackages = (e) => {
        const { name, value } = e.target;
        setTypePackages(prev => ({
            ...prev,
            [name]: value
        }));
    }

    const handleInputChangehotels = (e) => {
        const { name, value } = e.target;
        setHotels(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleAddThing = () => {
        if (formData.things_to_carry && !thingsToCarryList.includes(formData.things_to_carry)) {
            setThingsToCarryList([...thingsToCarryList, formData.things_to_carry]);
            setFormData({ ...formData, things_to_carry: "" });
        }
        else {
            toast.error('Alredy Exists !')
        }
    };

    const handleAddDescription = () => {
        if (formData.description && !descriptionList.includes(formData.description)) {
            setDescriptionList([...descriptionList, formData.description]);
            setFormData({ ...formData, description: "" });
        }
        else {
            toast.error('Alredy Exists !')
        }
    };

    const handleAddSightSeeing = () => {
        if (tourDetail.sightSeeing && !sightSeeing.includes(tourDetail.sightSeeing)) {
            setSightSeeing([...sightSeeing, tourDetail.sightSeeing]);
            setTourDetail({ ...tourDetail, sightSeeing: "" });
        }
        else {
            toast.error('Alredy Exists !')
        }
    }

    const handleAddActivity = () => {
        if (tourDetail.activities && !activities.includes(tourDetail.activities)) {
            setActivities([...activities, tourDetail.activities]);
            setTourDetail({ ...tourDetail, activities: "" });
        } else {
            toast.error('Already Exists!');
        }
    };

    const handleAddInclusions = () => {
        if (formData.inclusions && !inclusionsList.includes(formData.inclusions)) {
            setInclusionsList([...inclusionsList, formData.inclusions]);
            setFormData({ ...formData, inclusions: "" })
        }
        else {
            toast.error('Already Exists!');
        }
    }

    const handleAddExclusions = () => {
        if (formData.exclusions && !exclusionsList.includes(formData.exclusions)) {
            setExclusionsList([...exclusionsList, formData.exclusions]);
            setFormData({ ...formData, exclusions: "" });
        }
        else {
            toast.error('Already Exists!');
        }
    }

    const handleRemoveItem = (item, type) => {
        if (type === "thingsToCarry") {
            setThingsToCarryList(thingsToCarryList.filter((i) => i !== item));
        } else if (type === "description") {
            setDescriptionList(descriptionList.filter((i) => i !== item));
        }
        else if (type === "sightSeeing") {
            setSightSeeing(sightSeeing.filter((i) => i !== item));
        } else if (type === "activities") {
            setActivities(activities.filter((i) => i !== item));
        } else if (type === "type_of_packages") {
            setType_Of_Packages(type_of_packages.filter((i) => i !== item))
        }
        else if (type === "exclusionsList") {
            setExclusionsList(exclusionsList.filter((i) => i !== item))
        }
        else if (type === "inclusionsList") {
            setInclusionsList(inclusionsList.filter((i) => i !== item))
        }
        else if (type === "tourDetailOnDayBasis") {
            setTourDetailOnDayBasis(tourDetailOnDayBasis.filter((i) => i !== item));
        }
        else if (type === "hotelsList") {
            sethotelsList(hotelsList.filter((i) => i !== item))
        }
    };
    const handleNextClick = () => {
        if (validateForm()) {
            openTab("Second");
        }
        const allData = {
            ...formData,
            things_to_carry: thingsToCarryList,
            description: descriptionList,
        };
    }
    const handleNextClickSecond = () => {
        const allData = {
            ...formData,
            inclusions: inclusionsList,
            exclusions: exclusionsList,
            type_of_packages: type_of_packages,
            things_to_carry: thingsToCarryList,
            description: descriptionList,

        };
    };

    const handleAddPackages = () => {
        setType_Of_Packages((prevPackages) => [
            ...prevPackages,
            typePackages,
        ]);
        setTypePackages({
            packageName: "",
            location: "",
            type: "",
            price: "",
            seatSharing: "",
        });
    };

    const handleAddForDay = () => {
        const newTourDetail = {
            ...tourDetail,
            sightSeeing: [...sightSeeing],
            activities: [...activities],
        };

        setTourDetailOnDayBasis((prevDetails) => [
            ...prevDetails,
            newTourDetail,
        ]);
        console.log('-------->', newTourDetail);

        setTourDetail({
            day: '',
            dayHeading: '',
            location: '',
            reportingTime: '',
            departureTime: '',
            lunchBreak: '',
            stay_and_dinner: '',
            reporting_location: '',
            trek_start_time: '',
            arrival_time: '',
            break_fast: '',
            break_fast_time: '',
            how_to_reach: '',
            sightSeeing: '',
            activities: '',
            daywiseDescription: ''
        });


        setSightSeeing([]);
        setActivities([]);
    };


    const handleAddForHotels = () => {
        sethotelsList((prev) => [
            ...prev,
            { ...hotels }
        ]);
        setHotels({
            nights: "",
            place: "",
            hotel_name_standard: "",
            hotel_name_deluxe: ""
        });
    };

    const generateDays = () => {
        const duration = parseInt(formData.tour_duration, 10);
        if (isNaN(duration) || duration <= 0) return [];
        return Array.from({ length: duration }, (_, i) =>
            `${i + 1} ${i + 1 === 1 ? 'Day' : 'Day'}`
        );
    };

    const handleSubmitData = () => {
        const allData = {
            ...formData,
            inclusions: inclusionsList,
            exclusions: exclusionsList,
            type_of_packages: type_of_packages,
            things_to_carry: thingsToCarryList,
            tourDetailOnDayBasis: tourDetailOnDayBasis,
            description: descriptionList,
            hotels: hotelsList,
            terms_and_conditions: terms_and_conditions,
            cancellation_policy: cancellation_policy,
            payment_options: payment_options,
        };

        const newdata = {
            name: allData.name,
            description: allData.description,
            tour_route: allData.tour_route,
            arrival_city: allData.arrival_city,
            departure_place: allData.departure_place,
            arrival_date: allData.arrival_date,
            number_of_travelers: allData.number_of_travelers,
            tour_duration: allData.tour_duration,
            meals: allData.meals,
            location_category: allData.location_category,
            tours_category: allData.tours_category,
            travel_by: allData.travel_by,
            travel_date: allData.travel_date,
            return_location: allData.return_location,
            departure_time: allData.departure_time,
            image_url: allData.image_url,
            things_to_carry: allData.things_to_carry,
            inclusions: allData.inclusions,
            exclusions: allData.exclusions,
            type_of_packages: allData.type_of_packages,
            image_url_object: {
                big_image: allData.big_image_url,
                small_image: allData.small_image_urls
            },
            tourDetailOnDayBasis: allData.tourDetailOnDayBasis,
            hotels: allData.hotels,
            terms_and_conditions: allData.terms_and_conditions,
            cancellation_policy: allData.cancellation_policy,
            payment_options: allData.payment_options,
        };


        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/new-packages/create-new-package`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: newdata,
        };

        axios.request(config)
            .then((response) => {
                console.log("Response Data:", response.data);
                toast.success('Package created successfully!');
                setFormData(
                    {
                        name: "",
                        departure_place: "",
                        tour_route: "",
                        arrival_city: "",
                        arrival_date: "",
                        number_of_travelers: "",
                        tour_duration: "",
                        tours_category: "",
                        meals: "",
                        travel_by: "",
                        travel_date: "",
                        return_location: "",
                        departure_time: "",
                        image_url: "",
                        things_to_carry: "",
                        description: [],
                        inclusions: [],
                        exclusions: [],
                        hotels: "",
                        type_of_packages: "",
                        big_image_url: "",
                        small_image_urls: [],
                        tourDetailOnDayBasis: []
                    }
                )
                setThingsToCarryList([]);
                setDescriptionList([]);
                setSightSeeing([]);
                sethotelsList([]);
                setExclusionsList([]);
                setInclusionsList([]);
                setType_Of_Packages([]);
                setTourDetailOnDayBasis([]);
                setActivities([]);
                navigate('/tours');
                window.scrollTo(0, 0);
            })
            .catch((error) => {
                console.error("Error:", error);
                toast.error('An error occurred. Please try again!');
            });
    };

    // Handle input change for form fields
    const tcInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Add functions for terms, cancellation policies, and payment options
    const handleAddTerms = () => {
        if (formData.terms_and_conditions) {
            setTermsAndConditions([...terms_and_conditions, formData.terms_and_conditions]);
            setFormData({ ...formData, terms_and_conditions: "" });
        }
    };

    const handleAddCancellation = () => {
        if (formData.cancellation_policy) {
            setCancellationPolicy([...cancellation_policy, formData.cancellation_policy]);
            setFormData({ ...formData, cancellation_policy: "" });
        }
    };

    const handleAddPaymentOption = () => {
        if (formData.payment_options) {
            setPaymentOptions([...payment_options, formData.payment_options]);
            setFormData({ ...formData, payment_options: "" });
        }
    };

    // Remove item from a specific list
    const tcRemoveItem = (item, listName) => {
        if (listName === 'terms_and_conditions') {
            setTermsAndConditions(terms_and_conditions.filter((i) => i !== item));
        } else if (listName === 'cancellation_policy') {
            setCancellationPolicy(cancellation_policy.filter((i) => i !== item));
        } else if (listName === 'payment_options') {
            setPaymentOptions(payment_options.filter((i) => i !== item));
        }
    };

    return (
        <div className="p-8">
            <div className="mb-6 flex justify-between">
                <div className=" tracking-wider">
                    <button
                        className={`flex-1 py-2 px-3 text-center font-medium transition-all ${isTabActive("First")
                            ? "bg-gradient-to-r from-yellow-400 to-orange-500"
                            : "hover:bg-yellow-800 hover:bg-gradient-to-r hover:to-yellow-400 hover:from-orange-500"
                            }`}
                        style={{
                            backgroundColor: isTabActive("First") ? '#3b82f6' : 'white',
                            color: isTabActive("First") ? 'white' : '#374151',
                            border: isTabActive("First") ? 'none' : '1px solid #e5e7eb',
                        }}
                    >
                        Basic Details
                    </button>

                    <button
                        className={`flex-1 py-2 px-3 text-center font-medium transition-all ${isTabActive("Second")
                            ? "bg-gradient-to-r from-yellow-400 to-orange-500"
                            : "hover:bg-yellow-800 hover:bg-gradient-to-r hover:to-yellow-400 hover:from-orange-500"
                            }`}
                        style={{
                            backgroundColor: isTabActive("Second") ? '#3b82f6' : 'white',
                            color: isTabActive("Second") ? 'white' : '#374151',
                            border: isTabActive("Second") ? 'none' : '1px solid #e5e7eb',
                        }}
                    >
                        Package Options
                    </button>

                    <button
                        className={`flex-1 py-2 px-3 text-center font-medium transition-all ${isTabActive("Third")
                            ? "bg-gradient-to-r from-yellow-400 to-orange-500"
                            : "hover:bg-yellow-800 hover:bg-gradient-to-r hover:to-yellow-400 hover:from-orange-500"
                            }`}
                        style={{
                            backgroundColor: isTabActive("Third") ? '#3b82f6' : 'white',
                            color: isTabActive("Third") ? 'white' : '#374151',
                            border: isTabActive("Third") ? 'none' : '1px solid #e5e7eb',
                        }}
                    >
                        Itinerary Details
                    </button>

                    <button
                        className={`flex-1 py-2 px-3 text-center font-medium transition-all ${isTabActive("Fourth")
                            ? "bg-gradient-to-r from-yellow-400 to-orange-500"
                            : "hover:bg-yellow-800 hover:bg-gradient-to-r hover:to-yellow-400 hover:from-orange-500"
                            }`}
                        style={{
                            backgroundColor: isTabActive("Fourth") ? '#3b82f6' : 'white',
                            color: isTabActive("Fourth") ? 'white' : '#374151',
                            border: isTabActive("Fourth") ? 'none' : '1px solid #e5e7eb',
                        }}
                    >
                        Terms & Conditions
                    </button>
                </div>
                <div>
                    <IoArrowBackCircle size={30} color="#ec7e14" onClick={() => { navigate('/tours') }} />
                </div>
            </div>

            {/* Tab Content */}
            <div className="border border-gray-300 border-t-0 p-4 min-h-[200px]">
                {activeTab === "First" && (
                    <div>
                        <form className="grid md:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3">
                            {/* Tour Name */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Tour Name</label>
                                <input
                                    required={true}
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                />
                                {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                            </div>

                            {/* Tour Route */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Tour Route</label>
                                <input
                                    type="text"
                                    name="tour_route"
                                    value={formData.tour_route}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                />
                            </div>


                            <div>
                                <label className="block text-sm font-medium text-gray-700">Departure Place</label>
                                <input
                                    type="text"
                                    name="departure_place"
                                    value={formData.departure_place}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                />
                            </div>

                            {/* Arrival City */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Arrival City</label>
                                <input
                                    type="text"
                                    name="arrival_city"
                                    value={formData.arrival_city}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                />
                            </div>

                            {/* Arrival Date */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Arrival Date</label>
                                <input
                                    type="text"
                                    name="arrival_date"
                                    value={formData.arrival_date}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                />
                            </div>

                            {/* Number of Travelers */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Number of Travelers</label>
                                <input
                                    type="text"
                                    name="number_of_travelers"
                                    value={formData.number_of_travelers}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                />
                            </div>

                            {/* Tour Duration */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Tour Duration</label>
                                <input
                                    type="text"
                                    name="tour_duration"
                                    value={formData.tour_duration}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                />
                            </div>

                            {/* Meals */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Meals</label>
                                <input
                                    type="text"
                                    name="meals"
                                    value={formData.meals}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                />
                            </div>

                            {/* Travel By */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Travel By</label>
                                <input
                                    type="text"
                                    name="travel_by"
                                    value={formData.travel_by}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                />
                            </div>

                            {/* Travel Date */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Travel Date</label>
                                <input
                                    type="text"
                                    name="travel_date"
                                    value={formData.travel_date}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                />
                            </div>

                            {/* Return Location */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Return Location</label>
                                <input
                                    type="text"
                                    name="return_location"
                                    value={formData.return_location}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                />
                            </div>

                            {/* Departure Time */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Departure Time</label>
                                <input
                                    type="text"
                                    name="departure_time"
                                    value={formData.departure_time}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                />
                            </div>

                            {/* Image Upload */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Card Front Image Upload</label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleImageUpload(e, 'image_url')}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                    disabled={isUploading}
                                />
                                {formData.image_url && (
                                    <div className="mt-2">
                                        <img
                                            src={formData.image_url}
                                            alt="Uploaded"
                                            className="h-20 w-20 object-cover rounded"
                                        />
                                    </div>
                                )}
                            </div>

                            {/* Tours Category */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Tours Category
                                </label>
                                <select
                                    name="tours_category"
                                    value={formData.tours_category}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                >
                                    <option value="">Tours Category Select</option>
                                    {
                                        tourCategories.map((category) => (
                                            <option key={category._id} value={category.category_name}>
                                                {category.category_name}
                                            </option>
                                        ))
                                    }

                                </select>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Location Category
                                </label>
                                <select
                                    name="location_category"
                                    value={formData.location_category}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"

                                >
                                    <option value="">Location Category Select</option>
                                    {
                                        tourLocations.map((category) => (
                                            <option key={category._id} value={category.location_name}>
                                                {category.location_name}
                                            </option>
                                        ))
                                    }

                                </select>
                            </div>

                            {/* <div>
                                <label className="block text-sm font-medium text-gray-700">Tours Category</label>
                                <input
                                    type="text"
                                    name="tours_category"
                                    value={formData.tours_category}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                />
                            </div> */}

                            {/* Things to Carry Input */}
                            <div className="col-span-2">
                                <label className="block text-sm font-medium text-gray-700">Things to Carry</label>
                                <div className="flex">
                                    <input
                                        type="text"
                                        name="things_to_carry"
                                        value={formData.things_to_carry}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                    />
                                    <button
                                        type="button"
                                        onClick={handleAddThing}
                                        className="ml-3 bg-gradient-to-r from-yellow-400 to-orange-500 text-white p-2 rounded"
                                    >
                                        <MdFormatListBulletedAdd size={20} />
                                    </button>
                                </div>
                                <div>
                                    <ul>
                                        {thingsToCarryList.map((item, index) => (
                                            <li key={index} className="flex items-center space-x-2">
                                                <span>{item}</span>
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveItem(item, "thingsToCarry")}
                                                    className="text-red-500"
                                                >
                                                    <FaTimes size={14} />
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            {/* Description Input */}
                            <div className="col-span-2">
                                <label className="block text-sm font-medium text-gray-700">Description</label>
                                <div className="flex">
                                    <input
                                        type="text"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                    />
                                    <button
                                        type="button"
                                        onClick={handleAddDescription}
                                        className="ml-3 bg-gradient-to-r from-yellow-400 to-orange-500 text-white p-2 rounded"
                                    >
                                        <MdFormatListBulletedAdd size={20} />
                                    </button>
                                </div>
                                <div>
                                    <ul>
                                        {descriptionList.map((item, index) => (
                                            <li key={index} className="flex items-center space-x-2">
                                                <span>{item}</span>
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveItem(item, "description")}
                                                    className="text-red-500"
                                                >
                                                    <FaTimes size={14} />
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </form>

                        <div className="flex justify-end">
                            <button
                                // onClick={() => {
                                //     handleNextClick();
                                //     openTab("Second");
                                // }}
                                onClick={handleNextClick}
                                className="py-2 px-6 bg-gray-500 mt-4 text-white rounded bg-gradient-to-l from-yellow-400 to-orange-500"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                )}

                {activeTab === "Second" && (
                    <div>
                        <form className="grid md:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3">
                            <div className="col-span-2">
                                <label className="block text-sm font-medium text-gray-700">Inclusions</label>
                                <div className="flex">
                                    <input
                                        type="text"
                                        name="inclusions"
                                        value={formData.inclusions}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                    />
                                    <button
                                        type="button"
                                        onClick={handleAddInclusions}
                                        className="ml-3 bg-gradient-to-r from-yellow-400 to-orange-500 text-white p-2 rounded"
                                    >
                                        <MdFormatListBulletedAdd size={20} />
                                    </button>
                                </div>
                                <div>
                                    <ul>
                                        {inclusionsList.map((item, index) => (
                                            <li key={index} className="flex items-center space-x-2">
                                                <span>{item}</span>
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveItem(item, "inclusionsList")}
                                                    className="text-red-500"
                                                >
                                                    <FaTimes size={14} />
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-span-2">

                                <label className="block text-sm font-medium text-gray-700">Exclusions</label>
                                <div className="flex">
                                    <input
                                        type="text"
                                        name="exclusions"
                                        value={formData.exclusions}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                    />
                                    <button
                                        type="button"
                                        onClick={handleAddExclusions}
                                        className="ml-3 bg-gradient-to-r from-yellow-400 to-orange-500 text-white p-2 rounded"
                                    >
                                        <MdFormatListBulletedAdd size={20} />
                                    </button>
                                </div>
                                <div>
                                    <ul>
                                        {exclusionsList.map((item, index) => (
                                            <li key={index} className="flex items-center space-x-2">
                                                <span>{item}</span>
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveItem(item, "exclusionsList")}
                                                    className="text-red-500"
                                                >
                                                    <FaTimes size={14} />
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-span-2">
                                <h1 className="flex items-start text-xl">Type Of Package</h1>
                                <div className="mt-1">
                                    <label className="block text-sm font-medium text-gray-700">Package Type</label>
                                    <input
                                        type="text"
                                        name="type"
                                        value={typePackages.type}
                                        onChange={handleInputChangeTypePackages}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Package Name</label>
                                    <input
                                        type="text"
                                        name="packageName"
                                        value={typePackages.packageName}
                                        onChange={handleInputChangeTypePackages}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Package Location</label>
                                    <input
                                        type="text"
                                        name="location"
                                        value={typePackages.location}
                                        onChange={handleInputChangeTypePackages}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Package Price</label>
                                    <input
                                        type="text"
                                        name="price"
                                        value={typePackages.price}
                                        onChange={handleInputChangeTypePackages}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Package SeatSharing</label>
                                    <input
                                        type="text"
                                        name="seatSharing"
                                        value={typePackages.seatSharing}
                                        onChange={handleInputChangeTypePackages}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                    />
                                </div>
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleAddPackages();
                                    }}
                                    className="ml-3 bg-gradient-to-r from-yellow-400 to-orange-500 float-right mt-2 text-white p-2 rounded"
                                >
                                    <MdFormatListBulletedAdd size={20} />
                                </button>
                                <div>
                                    <ul>
                                        {type_of_packages.map((item, index) => (
                                            <li key={index} className="flex items-center space-x-2">
                                                <span>Package {index + 1} is Added</span>
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveItem(item, "type_of_packages")}
                                                    className="text-red-500"
                                                >
                                                    <FaTimes size={14} />
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            {/* Image Upload */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Single Image Upload</label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleImageUpload(e, 'big_image_url')}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                    disabled={isUploading}
                                />
                                {formData.big_image_url && (
                                    <div className="mt-2">
                                        <img
                                            src={formData.big_image_url}
                                            alt="Main"
                                            className="h-20 w-20 object-cover rounded"
                                        />
                                    </div>
                                )}
                            </div>
                            {/* Modified Multiple Images Upload */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Additional Images</label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={handleMultipleImageUpload}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                    disabled={isUploading}
                                />
                                <div className="mt-4 grid grid-cols-4 gap-2">
                                    {formData.small_image_urls.map((url, index) => (
                                        <div key={index} className="relative">
                                            <img
                                                src={url}
                                                alt={`Additional ${index + 1}`}
                                                className="h-20 w-20 object-cover rounded"
                                            />
                                            <button
                                                type="button"
                                                onClick={() => handleRemoveImage(index)}
                                                className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
                                            >
                                                <FaTimes size={12} />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </form>

                        <div className="flex justify-end space-x-4 mt-4">
                            <button
                                className="py-2 px-6 bg-gray-500 text-white rounded bg-gradient-to-l from-yellow-400 to-orange-500"
                                onClick={() => openTab("First")}
                            >
                                Back
                            </button>
                            <button
                                className="py-2 px-6 bg-blue-500 text-white rounded bg-gradient-to-r from-yellow-400 to-orange-500"
                                onClick={() => {
                                    handleNextClickSecond();
                                    openTab("Third");
                                }}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                )}

                {activeTab === "Third" && (
                    <div>
                        <form className="grid md:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Day</label>
                                <select
                                    name="day"
                                    value={tourDetail.day}
                                    onChange={handleInputChangeTourDetail}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                >
                                    <option value="">Select a Day</option>
                                    {generateDays().map((day, index) => (
                                        <option key={index} value={day}>
                                            {day}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {/* Day Heading */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Day Heading</label>
                                <input
                                    type="text"
                                    name="dayHeading"
                                    value={tourDetail.dayHeading}
                                    onChange={handleInputChangeTourDetail}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                />
                            </div>

                            {/* Location */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Location</label>
                                <input
                                    type="text"
                                    name="location"
                                    value={tourDetail.location}
                                    onChange={handleInputChangeTourDetail}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                />
                            </div>

                            {/* Reporting Time */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Reporting Time</label>
                                <input
                                    type="text"
                                    name="reportingTime"
                                    value={tourDetail.reportingTime}
                                    onChange={handleInputChangeTourDetail}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                />
                            </div>

                            {/* Departure Time */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Departure Time</label>
                                <input
                                    type="text"
                                    name="departureTime"
                                    value={tourDetail.departureTime}
                                    onChange={handleInputChangeTourDetail}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                />
                            </div>

                            {/* Lunch Break */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Lunch Break</label>
                                <input
                                    type="text"
                                    name="lunchBreak"
                                    value={tourDetail.lunchBreak}
                                    onChange={handleInputChangeTourDetail}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                />
                            </div>

                            {/* Stay and Dinner */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Stay and Dinner</label>
                                <input
                                    type="text"
                                    name="stay_and_dinner"
                                    value={tourDetail.stay_and_dinner}
                                    onChange={handleInputChangeTourDetail}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                />
                            </div>

                            {/* Reporting Location */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Reporting Location</label>
                                <input
                                    type="text"
                                    name="reporting_location"
                                    value={tourDetail.reporting_location}
                                    onChange={handleInputChangeTourDetail}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                />
                            </div>

                            {/* Trek Start Time */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Trek Start Time</label>
                                <input
                                    type="text"
                                    name="trek_start_time"
                                    value={tourDetail.trek_start_time}
                                    onChange={handleInputChangeTourDetail}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                />
                            </div>

                            {/* Arrival Time */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Arrival Time</label>
                                <input
                                    type="text"
                                    name="arrival_time"
                                    value={tourDetail.arrival_time}
                                    onChange={handleInputChangeTourDetail}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                />
                            </div>

                            {/* Breakfast */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Breakfast</label>
                                <input
                                    type="text"
                                    name="break_fast"
                                    value={tourDetail.break_fast}
                                    onChange={handleInputChangeTourDetail}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                />
                            </div>

                            {/* Breakfast Time */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Breakfast Time</label>
                                <input
                                    type="text"
                                    name="break_fast_time"
                                    value={tourDetail.break_fast_time}
                                    onChange={handleInputChangeTourDetail}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                />
                            </div>

                            {/* How to Reach */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">How to Reach</label>
                                <textarea
                                    type="text"
                                    name="how_to_reach"
                                    value={tourDetail.how_to_reach}
                                    onChange={handleInputChangeTourDetail}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                ></textarea>
                            </div>
                            {/* How to Reach */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Day Wise Description
                                </label>
                                <textarea
                                    type="text"
                                    name="daywiseDescription"
                                    value={tourDetail.daywiseDescription}
                                    onChange={handleInputChangeTourDetail}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                ></textarea>
                            </div>
                            {/* Sightseeing Input */}
                            <div className="col-span-2">
                                <label className="block text-sm font-medium text-gray-700">Sightseeing</label>
                                <div className="flex">
                                    <input
                                        type="text"
                                        name="sightSeeing"
                                        value={tourDetail.sightSeeing}
                                        onChange={handleInputChangeTourDetail}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                    />
                                    <button
                                        type="button"
                                        onClick={handleAddSightSeeing}
                                        className="ml-3 bg-gradient-to-r from-yellow-400 to-orange-500 text-white p-2 rounded"
                                    >
                                        <MdFormatListBulletedAdd size={20} />
                                    </button>
                                </div>
                                <div>
                                    <ul>
                                        {sightSeeing.map((item, index) => (
                                            <li key={index} className="flex items-center space-x-2">
                                                <span>{item}</span>
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveItem(item, "sightSeeing")}
                                                    className="text-red-500"
                                                >
                                                    <FaTimes size={14} />
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            {/* Activities Input */}
                            <div className="col-span-2">
                                <label className="block text-sm font-medium text-gray-700">Activities</label>
                                <div className="flex">
                                    <input
                                        type="text"
                                        name="activities"
                                        value={tourDetail.activities}
                                        onChange={handleInputChangeTourDetail}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                    />
                                    <button
                                        type="button"
                                        onClick={handleAddActivity}
                                        className="ml-3 bg-gradient-to-r from-yellow-400 to-orange-500 text-white p-2 rounded"
                                    >
                                        <MdFormatListBulletedAdd size={20} />
                                    </button>
                                </div>
                                <div>
                                    <ul>
                                        {activities.map((item, index) => (
                                            <li key={index} className="flex items-center space-x-2">
                                                <span>{item}</span>
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveItem(item, "activities")}
                                                    className="text-red-500"
                                                >
                                                    <FaTimes size={14} />
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <button
                                    type="button"
                                    className="py-2 px-6 bg-gradient-to-r from-yellow-400 to-orange-500 text-white rounded hover:bg-blue-600 float-right mt-3"
                                    onClick={() => {
                                        handleAddForDay();
                                    }}
                                >
                                    Add for each day
                                </button>
                            </div>

                            <div>
                                <ul>
                                    {tourDetailOnDayBasis.map((item, index) => (
                                        <li key={index} className="flex items-center space-x-2">
                                            <span>Day {index + 1} is Add</span>
                                            <button
                                                type="button"
                                                onClick={() => handleRemoveItem(item, "tourDetailOnDayBasis")}
                                                className="text-red-500"
                                            >
                                                <FaTimes size={14} />
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div>

                            </div>
                            <h1 className="flex items-start text-xl">Night Staying Hotel</h1>
                            <div className="col-span-2 grid grid-cols-2 gap-x-7 gap-y-3">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Night</label>
                                    <input
                                        type="text"
                                        name="nights"
                                        value={hotels.nights}
                                        onChange={handleInputChangehotels}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                    />
                                </div>
                                {/* Day Heading */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Place</label>
                                    <input
                                        type="text"
                                        name="place"
                                        value={hotels.place}
                                        onChange={handleInputChangehotels}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                    />
                                </div>
                                {/* Day Heading */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Hotel Name Standard</label>
                                    <input
                                        type="text"
                                        name="hotel_name_standard"
                                        value={hotels.hotel_name_standard}
                                        onChange={handleInputChangehotels}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                    />
                                </div>
                                {/* Day Heading */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Hotel Name deluxe</label>
                                    <input
                                        type="text"
                                        name="hotel_name_deluxe"
                                        value={hotels.hotel_name_deluxe}
                                        onChange={handleInputChangehotels}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                    />
                                </div>
                                <div className="col-span-2">
                                    <button
                                        type="button"
                                        className="py-2 px-6 bg-gradient-to-r from-yellow-400 to-orange-500 text-white rounded float-right mt-3"
                                        onClick={() => {
                                            handleAddForHotels();
                                        }}
                                    >
                                        Add For Each Night
                                    </button>
                                </div>
                            </div>
                            <div>
                                <ul>
                                    {hotelsList.map((item, index) => (
                                        <li key={index} className="flex items-center space-x-2">
                                            <span>Night {index + 1} is Add</span>
                                            <button
                                                type="button"
                                                onClick={() => handleRemoveItem(item, "hotelsList")}
                                                className="text-red-500"
                                            >
                                                <FaTimes size={14} />
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </form>

                        <div className="flex justify-end space-x-4 mt-4">
                            <button
                                className="mt-4 py-2 px-6 text-white rounded bg-gradient-to-r from-yellow-400 to-orange-500"
                                onClick={() => openTab("Second")}
                            >
                                Back
                            </button>
                            <button
                                className="mt-4 py-2 px-6 bg-gradient-to-l from-yellow-400 to-orange-500 text-white rounded"
                                onClick={() => {
                                    // handleSubmitData();
                                    openTab("Fourth");
                                }}
                            >
                                next
                            </button>
                        </div>
                    </div>
                )}

                {activeTab === "Fourth" && (
                    <div>
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3">
                            {/* Terms and Conditions */}
                            <div className="col-span-2">
                                <label className="block text-sm font-medium text-gray-700">Terms and Conditions</label>
                                <div className="flex">
                                    <input
                                        type="text"
                                        name="terms_and_conditions"
                                        value={formData.terms_and_conditions}
                                        onChange={tcInputChange}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                    />
                                    <button
                                        type="button"
                                        onClick={handleAddTerms}
                                        className="ml-3 bg-gradient-to-r from-yellow-400 to-orange-500 text-white p-2 rounded"
                                    >
                                        <MdFormatListBulletedAdd size={20} />
                                    </button>
                                </div>
                                <div>
                                    <ul>
                                        {terms_and_conditions?.map((item, index) => (
                                            <li key={index} className="flex items-center space-x-2">
                                                <span>{item}</span>
                                                <button
                                                    type="button"
                                                    onClick={() => tcRemoveItem(item, "terms_and_conditions")}
                                                    className="text-red-500"
                                                >
                                                    <FaTimes size={14} />
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            {/* Cancellation Policy */}
                            <div className="col-span-2">
                                <label className="block text-sm font-medium text-gray-700">Cancellation Policy</label>
                                <div className="flex">
                                    <input
                                        type="text"
                                        name="cancellation_policy"
                                        value={formData.cancellation_policy}
                                        onChange={tcInputChange}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                    />
                                    <button
                                        type="button"
                                        onClick={handleAddCancellation}
                                        className="ml-3 bg-gradient-to-r from-yellow-400 to-orange-500 text-white p-2 rounded"
                                    >
                                        <MdFormatListBulletedAdd size={20} />
                                    </button>
                                </div>
                                <div>
                                    <ul>
                                        {cancellation_policy?.map((item, index) => (
                                            <li key={index} className="flex items-center space-x-2">
                                                <span>{item}</span>
                                                <button
                                                    type="button"
                                                    onClick={() => tcRemoveItem(item, "cancellation_policy")}
                                                    className="text-red-500"
                                                >
                                                    <FaTimes size={14} />
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            {/* Payment Options */}
                            <div className="col-span-2">
                                <label className="block text-sm font-medium text-gray-700">Payment Options</label>
                                <div className="flex">
                                    <input
                                        type="text"
                                        name="payment_options"
                                        value={formData.payment_options}
                                        onChange={tcInputChange}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                    />
                                    <button
                                        type="button"
                                        onClick={handleAddPaymentOption}
                                        className="ml-3 bg-gradient-to-r from-yellow-400 to-orange-500 text-white p-2 rounded"
                                    >
                                        <MdFormatListBulletedAdd size={20} />
                                    </button>
                                </div>
                                <div>
                                    <ul>
                                        {payment_options?.map((item, index) => (
                                            <li key={index} className="flex items-center space-x-2">
                                                <span>{item}</span>
                                                <button
                                                    type="button"
                                                    onClick={() => tcRemoveItem(item, "payment_options")}
                                                    className="text-red-500"
                                                >
                                                    <FaTimes size={14} />
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            {/* Submit Button */}
                            <div className="flex justify-end space-x-4 mt-4">
                                <button
                                    className="py-2 px-6 bg-gray-500 text-white rounded bg-gradient-to-l from-yellow-400 to-orange-500"
                                    onClick={() => openTab("Third")}
                                >
                                    Back
                                </button>
                                <button
                                    className="py-2 px-6 bg-blue-500 text-white rounded bg-gradient-to-r from-yellow-400 to-orange-500"
                                    onClick={() => {
                                        handleSubmitData();
                                        // openTab("Fourth");
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
};

export default AddNewTours;