import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import EditBasicDetailsForm from '../../components/HotelsAdmin/EditBasicDetailsForm';
import EditAdditionalDetailsForm from '../../components/HotelsAdmin/EditAdditionalDetailsForm';
import EditTabNavigation from '../../components/HotelsAdmin/EditTabNavigation';

const EditHotel = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('BasicDetails');
    const [isLoading, setIsLoading] = useState(false);
    const [imgUploading, setImgUploading] = useState(false);
    const [amenity, setAmenity] = useState('');
    const [imageFiles, setImageFiles] = useState([]);
    const [errors, setErrors] = useState({});
    const [newHotelData, setNewHotelData] = useState({
        name: "",
        description: '',
        type: '',
        rating: '',
        address: '',
        city: '',
        state: '',
        country: '',
        zipcode: '',
        phone: '',
        email: '',
        amenities: [],
        images: [],
        basePrice: '',
        checkInTime: '',
        checkOutTime: '',
        cancellationPolicy: '',
    });
    const {id} = useParams();

    useEffect(() => {
      getHotelData();
    }, []);

    const getHotelData = ()=>{
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/hotels/hotel-by-id/${id}`,
            headers: {},
            data: "",
        };
        setIsLoading(true);
        axios
            .request(config)
            .then((response) => {
            // console.log("res", response.data);
            setIsLoading(false);

            //Updating newHotelData
            setNewHotelData((prevState) =>({
                ...prevState,
                name:response?.data.name || "",
                description: response?.data.description || "",
                type: response?.data.type || "",
                rating: response?.data.rating || "",
                address: response?.data.address || "",
                city: response?.data.city || "",
                state: response?.data.state || "",
                country: response?.data.country || "",
                zipcode: response?.data.zipcode || "",
                phone: response?.data.phone || "",
                email: response?.data.email || "",
                amenities: response?.data.amenities || [],
                images: response?.data.images || [],
                basePrice: response?.data.basePrice || "",
                checkInTime: response?.data.checkInTime || "",
                checkOutTime: response?.data.checkOutTime || "",
                cancellationPolicy: response?.data.cancellationPolicy || "",
            }))
        })
        .catch((error) => {
            setIsLoading(false);
            console.log(error);
        });
    }

    const tabOrder = ['BasicDetails', 'AdditionalDetails'];

    const isTabActive = (tab) => activeTab === tab;

    const handleTabClick = (tab) => setActiveTab(tab);

    const handleNextClick = () => {
        const currentIndex = tabOrder.indexOf(activeTab);
        if (currentIndex < tabOrder.length - 1) {
            setActiveTab(tabOrder[currentIndex + 1]);
        }
    };

    const handleBackClick = () => {
        const currentIndex = tabOrder.indexOf(activeTab);
        if (currentIndex > 0) {
            setActiveTab(tabOrder[currentIndex - 1]);
        }
        else{
            navigate(-1);
        }
    };

    const handleAddAmenity = () => {
        if (amenity) {
            setNewHotelData({ ...newHotelData, amenities: [...newHotelData.amenities, amenity] });
            setAmenity('');
        }
    };

    const handleRemoveAmenity = (item) => {
        setNewHotelData({
            ...newHotelData,
            amenities: newHotelData.amenities.filter((a) => a !== item),
        });
    };

    const handleImageUpload = async (e) => {
        const files = Array.from(e.target.files);
        setImageFiles([...imageFiles, ...files]);

        const uploadedImageUrls = [];

        // Loop through selected files and upload each one to Cloudinary
        for (let i = 0; i < files.length; i++) {
            const formData = new FormData();
            formData.append('file', files[i]);
            formData.append('upload_preset', 'wjs1zyo5');
            setImgUploading(true);
            try {
                const response = await axios.post(
                    `https://api.cloudinary.com/v1_1/dnw1ttr02/image/upload`,
                    formData
                );
                uploadedImageUrls.push(response.data.secure_url);
                setImgUploading(false);
            } catch (error) {
                console.error('Error uploading image:', error);
                setImgUploading(false);
            }
        }

        // Update the state with the uploaded image URLs
        setNewHotelData((prevData) => ({
            ...prevData,
            images: [...prevData.images, ...uploadedImageUrls], // Add the uploaded image URLs to the state
        }));
    };


    const handleRemoveImage = (index) => {
        const updatedFiles = [...imageFiles];
        updatedFiles.splice(index, 1);
        setImageFiles(updatedFiles);

        const updatedImages = [...newHotelData.images];
        updatedImages.splice(index, 1);
        setNewHotelData({ ...newHotelData, images: updatedImages });
    };

    // Validate the form fields
    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!newHotelData.name) {
            formErrors.name = 'Hotel name is required.';
            isValid = false;
        }

        if (!newHotelData.address) {
            formErrors.address = 'Address is required.';
            isValid = false;
        }

        if (!newHotelData.city) {
            formErrors.city = 'City is required.';
            isValid = false;
        }

        if (!newHotelData.state) {
            formErrors.state = 'State is required.';
            isValid = false;
        }

        if (!newHotelData.country) {
            formErrors.country = 'Country is required.';
            isValid = false;
        }

        if (!newHotelData.zipcode) {
            formErrors.zipcode = 'Zipcode is required.';
            isValid = false;
        }

        if (!newHotelData.phone) {
            formErrors.phone = 'Phone number is required.';
            isValid = false;
        }

        if (!newHotelData.email) {
            formErrors.email = 'Email is required.';
            isValid = false;
        }

        if (!newHotelData.basePrice) {
            formErrors.basePrice = 'Base price is required.';
            isValid = false;
        }

        if (!newHotelData.checkInTime) {
            formErrors.checkInTime = 'Check-in time is required.';
            isValid = false;
        }

        if (!newHotelData.checkOutTime) {
            formErrors.checkOutTime = 'Check-out time is required.';
            isValid = false;
        }

        if (!newHotelData.cancellationPolicy) {
            formErrors.cancellationPolicy = 'Cancellation policy is required.';
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    // Function to handle form submission
    const handleSubmit = async () => {
        if(!validateForm()){
            return;
        }

        setIsLoading(true);
        const config = {
            method: "put",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/hotels/update-hotel/${id}`,
            headers: {
                "Content-Type": "application/json",
            },
            data: newHotelData,
        };
    
        axios
            .request(config)
            .then((response) => {
            // console.log(JSON.stringify(response.data));
            console.log("Hotel updated successfully:", response.data);
            setIsLoading(false);
            
            navigate("/hotel");
        })
        .catch((error) => {
            console.error("Error updating hotel:", error);
            setIsLoading(false);
        });
    };
    
    return (
        <div className="p-6">
            <EditTabNavigation
                tabOrder={tabOrder}
                handleTabClick={handleTabClick}
                isTabActive={isTabActive}
            />

            {/* Content Area */}
            <div>
                {activeTab === 'BasicDetails' && (
                    <EditBasicDetailsForm
                        newHotelData={newHotelData}
                        setNewHotelData={setNewHotelData}
                        errors={errors}
                    />
                )}

                {/* Additional Details */}
                {activeTab === 'AdditionalDetails' && (
                    <EditAdditionalDetailsForm
                        newHotelData={newHotelData}
                        setNewHotelData={setNewHotelData}
                        errors={errors}
                        amenity={amenity}
                        setAmenity={setAmenity}
                        handleAddAmenity={handleAddAmenity}
                        handleRemoveAmenity={handleRemoveAmenity}
                        handleImageUpload={handleImageUpload}
                        imageFiles={imageFiles}
                        handleRemoveImage={handleRemoveImage}
                        imgUploading={imgUploading}
                    />
                )}

                {/* Buttons */}
                <div className="flex gap-4 justify-end items-center mt-6 tracking-wide">
                    <button
                        onClick={handleBackClick}
                        className="bg-gray-300 text-black py-2 px-4 rounded-md"
                    >
                        Back
                    </button>
                    {activeTab === 'AdditionalDetails' ? (
                        <button
                            onClick={handleSubmit}
                            className="bg-gradient-to-r from-yellow-400 to-orange-500 text-white py-2 px-4 rounded-md"
                        >
                            {isLoading ? "Please Wait .." : "Submit"}
                        </button>
                    ) : (
                        <button
                            onClick={handleNextClick}
                            className="bg-gradient-to-r from-yellow-400 to-orange-500 text-white py-2 px-4 rounded-md"
                        >
                            Next
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EditHotel;
