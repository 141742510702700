import React from 'react'
import image from '../../Assets/user-imgs/Home-imgs/VisioBg.png'
const OurVision = () => {
    return (
        <div className="bg-cover md:block hidden bg-center  flex-col items-center mt-10  h-[40rem] w-[100%] justify-center  relative space-y-8" style={{ backgroundImage: `url(${image})`, }}>
            <div className="absolute md:top-10 md:left-16 bg-gradient-to-t from-[#1B4269] to-[#6574A4] text-white p-8 rounded-lg max-w-[40rem] h-[20rem]">
                <h2 className="text-3xl font-bold mb-4">Our Vision</h2>
                <p className="text-lg">
                    Our vision is to be the innovative leader and creative in the concept of travel. We want to make our company identity in the markets within the best tourism companies in Uttarakhand, Himachal and other tourist destinations. We want to be recognized for our professionalism to provide high quality services.
                </p>
            </div>
            <div className="absolute md:bottom-10 md:right-16 bg-gradient-to-t from-[#1B4269] to-[#6574A4] text-white p-8 rounded-lg max-w-[40rem] h-[20rem]">
                <h2 className="text-3xl font-bold mb-4">Our Mission</h2>
                <p className="text-lg">
                    Our mission is to perform and give an excellent quality service to our customers by providing them an unforgettable traveling with full enjoyment and also guaranteed services that exceeds your expectations. Our aim is to earn reputation as “YOUR PREFFERED TRAVEL AGENCY”. All we need is our customer satisfaction, and our very main aim is to save your time and money as well as provide you the best value for your trip.
                </p>
            </div>
        </div>
    )
}

export default OurVision