import React from "react";
import SimpleBanner from "../../components/common/SimpleBanner";
import Carbanner from "../../Assets/Common/CarBooking.png";
import img1 from "../../../src/Assets/Common/CarbookingImages/image (13).png";
import { FaStar } from "react-icons/fa";
import { MdPeopleOutline } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import cabimg from "../../../src/Assets/Common/CarbookingImages/Cab image.png";
import drvrimg from "../../../src/Assets/Common/CarbookingImages/Driverimage.png";
import img2 from "../../../src/Assets/Common/CarbookingImages/image (16).png";
import img3 from "../../../src/Assets/Common/CarbookingImages/image (17).png";
import img4 from "../../../src/Assets/Common/CarbookingImages/image (18).png";
import img5 from "../../../src/Assets/Common/CarbookingImages/image (19).png";
import img6 from "../../../src/Assets/Common/CarbookingImages/image 1071.png";
import { MdArrowForwardIos } from "react-icons/md";

const CabDetailsForm = () => {
  const images = [
    {
      image: img2,
    },
    {
      image: img3,
    },
    {
      image: img4,
    },
    {
      image: img5,
    },
    {
      image: img6,
    },
  ];

  const inclusion = [
    "150 kms",
    "State Taxes",
    "Toll Charges",
    "Parking Charges",
    "Only one pick up and drop",
  ];

  const [num, setNum] = [5];
  return (
    <div>
      <SimpleBanner
        image={Carbanner}
        name="Car Booking"
        path="Cars / Car details"
      />
      <div className="flex justify-between items-start bg-white font-be-vietnam lg:px-14 sm:px-8 px-7 gap-9 flex-col xl:flex-row">
        <div className="xl:w-[70%] w-full">
          <div className="flex flex-col justify-end items-start gap-5 w-full pt-3">
            <div className="lg:p-5 p-3 flex justify-between items-center sm:flex-row flex-col shadow-md  border-[1px] border-gray-300 w-full rounded-lg">
              <div className="flex justify-center sm:flex-row flex-col items-center gap-4 ">
                <div className=" p-1  rounded-lg">
                  <img
                    src={img1}
                    alt="cab image"
                    className="h-[150px] lg:h-[170px] lg:w-[250px] w-[190px] rounded-lg "
                  />
                </div>

                <div>
                  <p className="text-xl font-bold text-[#2D87D0]">
                    Hyundai i20
                  </p>
                  <p className="text-yellow-500 flex justify-start items-start gap-1 lg:mt-2 mt-1">
                    <FaStar size={18} />
                    <FaStar size={18} />
                    <FaStar size={18} />
                    <FaStar size={18} />
                  </p>
                  <div className="flex justify-start gap-5 items-center lg:mt-2 mt-1">
                    <p>HATCHBACK</p>
                    <p>AC</p>
                    <div className="flex justify-center items-center gap-2">
                      <MdPeopleOutline size={22} className="text-[#ABABAB]" />
                      <p>4 Seats</p>
                    </div>
                  </div>
                  <div className="flex flex-col justify-start items-start gap-1  lg:mt-6 mt-3 pl-2 border-l-[2px] border-[#E9E9E9]">
                    <p className="font-semibold lg:text-lg text-md ">
                      Economical car with limited luggage
                    </p>
                    <label className="flex items-center space-x-1">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-blue-600"
                      />
                      <span className="text-gray-800 lg:text-md text-sm">
                        144 kms included. After that ₹14.0/km
                      </span>
                    </label>
                    <label className="flex items-center space-x-1">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-blue-600"
                      />
                      <span className="text-gray-800 lg:text-md text-sm">
                        Free cancellation until 1 hour before pickup
                      </span>
                    </label>
                    <label className="flex items-center space-x-1">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-blue-600"
                      />
                      <span className="text-gray-800 lg:text-md text-sm">
                        Reserve this cab at ₹463 only
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="flex  sm:items-end items-start flex-col w-full sm:w-auto ">
                <div className="flex sm:justify-end justify-between items-start gap-3 mt-2 sm:mt-0 w-full sm:w-auto ">
                  <p className="lg:text-[15px] sm:text-[11px] text-md  ">
                    Customer rating
                  </p>
                  <div className="px-2 py-1 bg-[#60A15B] text-white text-[10px] rounded-lg">
                    <p>4.5</p>
                  </div>
                </div>
                <div className="flex lg:mt-20 sm:mt-14 mt-2 sm:items-end items-start  flex-col w-full sm:w-auto">
                  <div className="flex sm:justify-end justify-start items-center pr-4 sm:pr-0 gap-2">
                    <p className="lg:text-lg text-md">
                      <del className="text-red-500">₹ 2999</del>
                    </p>
                    <p className="lg:text-2xl text-xl">₹ 1499</p>
                  </div>
                  <div className="w-full flex justify-start sm:justify-end items-end">
                    <p className="lg:text-md text-[11px] text-[#878787] mt-1 text-end">
                      + ₹150 Taxes and Charges
                    </p>
                  </div>

                  <button className="flex justify-center items-center lg:px-4 lg:py-2 px-2 py-2 bg-[#2D87D0] rounded-lg mt-2 lg:text-lg text-md text-white w-full sm:w-auto">
                    Select <IoIosArrowForward size={22} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <p className="text-xl mt-4 font-semibold">Cab & Driver Details</p>
          <div className="mt-2 p-3 flex justify-between items-center relative flex-col sm:flex-row">
            <div className="relative">
              <img
                src={drvrimg}
                alt="Driver"
                className="h-[95px] w-[100px]  rounded-full mr-16 sm:mr-0"
              />
              <img
                src={cabimg}
                alt="Cab"
                className="absolute left-16 top-0 bottom-4 h-[100px] w-[100px] "
              />
            </div>

            <div className="text-md flex-1 sm:pl-28 pl-0 text-justify">
              <p className="tracking-wide leading-relaxed">
                <span className="font-semibold">Cab operator</span> will be
                assigned as soon as booking is{" "}
                <span className="font-semibold">complete.</span>
              </p>
              <p className="tracking-wide leading-relaxed">
                Your <span className="font-semibold">cab and driver</span>{" "}
                details will be shared via{" "}
                <span className="font-semibold">WhatsApp & SMS</span> on your
                registered mobile number by{" "}
                <span className="font-semibold">11 Dec 2024 at 9:30 am.</span>
              </p>
            </div>
          </div>
          <p className="pt-3 text-xl font-semibold">
            Images of selected vehicle category
          </p>
          <div className="p-2 rounded-lg bg-[#E9F6FF] mt-3">
            <p className="tracking-wide leading-relaxed text-sm   p-2 text-justify">
              Car images shown here are for representation purpose only. Actual
              car will belong to the same model but may not be the exact one as
              shown in the images
            </p>
          </div>
          <div className="mt-3">
            <div className="flex justify-start items-center sm:gap-7 gap-3 sm:w-[75%] w-full text-md sm:flex-row flex-col">
              <a href="">ALL({num})</a>
              <a href="">EXTERIOR({num - 3})</a>
              <a href="">INTERIOR({num - 2})</a>
              <a href="">BOOT({num - 4})</a>
            </div>
            <div className="flex justify-between items-center lg:gap-5 md:gap-3 gap-2 sm:flex-row flex-col flex-wrap mt-4">
              {images.map((i) => {
                return (
                  <div className="flex justify-between items-center">
                    <img
                      src={i.image}
                      alt="Cab images"
                      className="h-36 sm:w-48 w-full rounded-md"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <p className="font-semibold text-xl mt-4">Inclusion & Exclusion</p>
            <div className="flex justify-between items-start">
              <div className="sm:p-6 p-2 border-r-[2px] border-[#E3E3E3] w-[50%]">
                <p className="text-lg font-semibold">Inclusion</p>
                <div className="space-y-2 mt-3 ">
                  {inclusion.map((model, index) => (
                    <label key={index} className="flex items-center space-x-3">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-blue-600"
                      />
                      <span className="text-[#606060] text-md">{model}</span>
                    </label>
                  ))}
                </div>
              </div>
              <div className="sm:p-6 p-2 w-[50%]">
                <p className="text-lg font-semibold">Exclusion</p>
                <div className="space-y-2 mt-3 text-[#282828] ">
                  <p>Extra charges (based on your usage)</p>
                  <p>
                    <ul>
                      <li>Fare beyond 144 Kms: Rs 14.0/Km</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <form className="pt-1 lg:pl-4 font-be-vietnam">
            <h2 className="font-semibold text-xl mb-6">Provide Your Details</h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* First Name */}
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-md font-semibold text-gray-700"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  className="mt-1 block w-full h-9 border-[1px] px-2 
                    errors.firstName  border-black
                  } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />

                <p className="text-red-500 text-sm"></p>
              </div>

              {/* Last Name */}
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-md font-semibold text-gray-700"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  className="mt-1 block w-full h-9 border-[1px] px-2 
                    errors.lastName  border-black
                   rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />

                <p className="text-red-500 text-sm"></p>
              </div>

              {/* Email Address */}
              <div>
                <label
                  htmlFor="email"
                  className="block text-md font-semibold text-gray-700"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="mt-1 block w-full h-9 border-[1px] px-2 
                    errors.email  border-black
                  } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <p className="text-red-500 text-sm"></p>

                <p className="mt-2 text-xs text-gray-500">
                  Confirmation email goes to this address
                </p>
              </div>

              {/* Mobile Number */}
              <div>
                <label
                  htmlFor="mobile"
                  className="block text-md font-semibold text-gray-700"
                >
                  Mobile Number
                </label>
                <div className="flex items-center mt-1">
                  <select
                    id="countryCode"
                    name="countryCode"
                    className="border-black h-9 md:px-2 px-0 border-[1px] rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mr-1"
                  >
                    <option value="IN">IN +91</option>
                    <option value="US">US +1</option>
                    <option value="UK">UK +44</option>
                  </select>
                  <input
                    type="tel"
                    id="mobile"
                    name="mobile"
                    className="block w-full h-9 border-[1px] px-2 
                        border-black
                     rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ml-1"
                    placeholder="Enter mobile number"
                  />
                </div>

                <p className="text-red-500 text-sm"></p>

                <p className="mt-2 text-xs text-gray-500">
                  Needed by the property to validate your booking
                </p>
              </div>

              {/* Gender */}
              <div className="md:col-span-1">
                <label
                  htmlFor="country"
                  className="block text-md font-semibold text-gray-700"
                >
                  Gender
                </label>
                <select
                  id="country"
                  name="country"
                  className="mt-1 block px-2 md:w-[49%] w-full h-9 border-[1px] 
                      border-black
                   rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="">Select a Gender</option>
                  <option value="IN">Male</option>
                  <option value="US">Female</option>
                </select>

                <p className="text-red-500 text-sm"></p>
              </div>
            </div>
          </form>
        </div>
        <div className="xl:w-[30%] w-full mt-3">
          <div className="p-3  rounded-lg bg-[#E9F6FF]">
            <p className="text-xl font-semibold">Your booking details</p>
            <div className=" font-medium flex justify-between items-center w-full mt-2">
              <div className="mt-2">
                <p className="text-md">Pick Up</p>
                <p className="text-md mt-2 text-gray-600">Bhubaneswar Airpot</p>
              </div>
              <div className="mt-2">
                <p className="text-md">Drop Off</p>
                <p className="text-md mt-2 text-gray-600">
                  Kandagiri, Bhubaneswar
                </p>
              </div>
            </div>
            <div className="text-md font-medium mt-3">
              <p>Total no. of person:</p>
              <p className="mt-2 text-gray-600">10</p>
            </div>
          </div>
          <div className="p-4 bg-[#E9F6FF] mt-4 rounded-lg">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-lg font-semibold">Total Amount</p>
                <p className="text-sm font-medium pt-1 ">Inclusive of GST</p>
              </div>
              <div>
                <p className="text-lg font-semibold">₹ 1,599</p>
                <p className="text-sm font-medium pt-1 ">View details</p>
              </div>
            </div>

            <div className="flex justify-start items-center item ">
              <div className="flex flex-col gap-2 pt-2">
                <label className="flex items-center  text-md font-medium  mt-1">
                  <input type="radio" className="mr-2 text-md font-medium" />I
                  am the Pay ₹1599 Now
                </label>
                <p className="mt-1 pl-5 text-sm text-gray-600">
                  Remaining to driver
                </p>
                <label className="flex items-center text-md font-medium mt-1">
                  <input type="radio" className="mr-2 text-md font-medium" />
                  Pay ₹1599 Now
                </label>
                <p className="mt-1 pl-5 text-sm text-gray-600">
                  Remaining to driver
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CabDetailsForm;
