import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Assets/Logos/logo_wb.svg";
import userProfile from "../../Assets/auth-imgs/user.png";

const AuthNavbar = ({ setIsSidebarOpen }) => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogoClick = () => {
    if (window.innerWidth < 1024) {
      // Only toggle sidebar on mobile screens
      setIsSidebarOpen(prevState => !prevState);
    }
  };

  return (
    <React.Fragment>
      <nav className="bg-[#FFFFFF] shadow-lg fixed top-0 w-full px-3 py-1 text-[black] z-50">
        <div className="px-3 mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <div className="mr-4">
              <img
                src={logo}
                alt="logo"
                className="h-16 w-auto cursor-pointer"
                onClick={handleLogoClick}
              />
            </div>

            <div className="text-sm sm:text-md md:text-xl text-primaryTextColor tracking-wider font-semibold">
              WBTP Admin
            </div>
          </div>

          <div className="flex items-center">
            <button
              id="dropdownDefaultButton"
              onClick={toggleDropdown}
              className="flex items-center"
              type="button"
            >
              <img
                src={userProfile}
                alt="profile-img"
                className="w-12 h-12 rounded-full p-[1px] border bg-white"
              />
              <svg
                className={`w-2.5 h-2.5 ms-3 transform ${isDropdownOpen ? "rotate-180" : ""}`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>

            {/* Dropdown menu */}
            {isDropdownOpen && (
              <div className="z-10 absolute mt-[150px] ml-[-100px] tracking-wider divide-y divide-gray-100 rounded-lg shadow w-44 bg-[#0a1631]">
                <ul className="py-2 text-sm text-primaryTextColor dark:text-gray-200">
                  <li>
                    <Link
                      to="/profile"
                      onClick={toggleDropdown}
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                    >
                      Profile
                    </Link>
                  </li>
                  <li>
                    <div
                      onClick={() => {
                        handleLogout();
                        toggleDropdown();
                      }}
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                    >
                      Sign out
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default AuthNavbar;