import React from 'react'

const SimpleBanner = ({ image, name, path }) => {
    return (
        <div
            className='h-[350px] flex items-center md:justify-normal justify-center'
            style={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}
        >
            <div className='text-[#ffffff] mx-auto'>
                <h1 className='text-4xl md:text-7xl font-serif font-bold tracking-wider capitalize'>{name}</h1>
                <p className='tracking-wider mt-2 capitalize text-md'>{path}</p>
            </div>
        </div>
    )
}

export default SimpleBanner;