import React, { useRef, useState } from 'react';
import { IoMdCloseCircle } from "react-icons/io";
import axios from 'axios';
import toast from 'react-hot-toast';
const cloudname = "dnw1ttr02";
const preset = "wjs1zyo5";

async function uploadToCloudinary(imageFile) {
    try {
        const formDataCloudinary = new FormData();
        formDataCloudinary.append("file", imageFile);
        formDataCloudinary.append("upload_preset", preset);

        const cloudinaryResponse = await axios.post(
            `https://api.cloudinary.com/v1_1/${cloudname}/image/upload`,
            formDataCloudinary
        );

        const cloudinaryUrl = cloudinaryResponse.data.secure_url;
        console.log("Uploaded image URL:", cloudinaryUrl);

        return cloudinaryUrl;
    } catch (error) {
        console.error("Error uploading image to Cloudinary:", error);
    }
}

function FeedbackForm({ onClose,getAllFeekdBack }) {
    const modalRef = useRef();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        image: null,
        feedbackReview: ''
    });
    const [errors, setErrors] = useState({});

    const closeModal = (e) => {
        if (modalRef.current === e.target) {
            onClose();
        }
    };

    const handleChange = (e) => {
        const { id, value, files } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: files ? files[0] : value
        }));
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.name.trim()) errors.name = "Name is required";
        if (!formData.email.trim()) errors.email = "Email is required";
        else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email)) errors.email = "Invalid email format";
        if (!formData.image) errors.image = "Image is required";
        if (!formData.feedbackReview.trim()) errors.feedbackReview = "Feedback is required";
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const userPic = await uploadToCloudinary(formData.image);
                const collectedData = {
                    name: formData.name,
                    email: formData.email,
                    userPic: userPic,
                    feedbackReview: formData.feedbackReview
                };
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_API_URL}/feedback/addFeedback`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: collectedData
                };

                axios.request(config)
                    .then((response) => {
                        console.log(JSON.stringify(response.data));
                        toast.success('Successfully toasted!');
                        getAllFeekdBack();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                console.log('Collected Data:', collectedData);
                onClose();
            } catch (error) {
                console.error("Error uploading image:", error);
            }
        }
    };

    return (
        <div ref={modalRef} className="fixed inset-0 z-50 bg-gray-800 bg-opacity-75 flex items-center justify-center p-4 sm:p-6" onClick={closeModal}>
            <div className="flex flex-col md:flex-row w-[60%] rounded-lg overflow-hidden">
                {/* Left Section */}
                <div className="bg-white  px-6  md:w-1/2 flex flex-col py-10">
                    <h1 className="font-bold mt-1">WHY IS YOUR FEEDBACK IMPORTANT TO US</h1>
                    <p className="text-sm mt-1">
                        At Bharat Booking Holidays, we value and consider our guests as our top-most priority.
                        Your feedback is immensely important to us as we would greatly appreciate your
                        thoughts and views regarding our hospitality services.
                    </p>
                    <div className="ml-4 mt-2 mb-4">
                        <p><b>1</b>. Your feedback can be a suggestion, compliment, complaint, or any other observation.</p>
                        <p><b>2</b>. Your suggestion will help us to improve and better our services in the future.</p>
                        <p><b>3</b>. Your compliment is a boost for our workforce, in appreciation of their hard work and dedication.</p>
                        <p><b>4</b>. Your complaint will be treated with utmost urgency to avoid any repetition.</p>
                        <p><b>5</b>. Your other feedback can be any other observation that you may want to bring to our notice.</p>
                    </div>
                    <p className="mb-4">
                        You can also reach us directly in case of immediate emergency or action. Call: 91-
                        9805063636 E-Mail: help@bharatbooking.com
                    </p>
                    <p>
                        Your comment and suggestions will allow us to improve our standards and also enable
                        us to only meet, but exceed your expectation.
                    </p>
                    {/* Right Section */}
                </div>
                <div className="bg-[#063962] py-2 px-6 md:w-1/2 text-white">
                    <div className="flex justify-end">
                        <IoMdCloseCircle size={25} onClick={onClose} />
                    </div>
                    <h2 className="text-xl font-semibold mb-4">Feedback Form: Share your feedback with us by simply filling up the below form and clicking on "Send Feedback" Option.</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-sm mb-2" htmlFor="name">Name</label>
                            <input
                                className="w-full p-3 rounded bg-[#386181] text-white border border-[#386181]"
                                id="name"
                                type="text"
                                placeholder="Enter Your Name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                            {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm mb-2" htmlFor="email">Email</label>
                            <input
                                className="w-full p-3 rounded bg-[#386181] text-white border border-[#386181]"
                                id="email"
                                type="email"
                                placeholder="Your Email ID"
                                value={formData.email}
                                onChange={handleChange}
                            />
                            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm mb-2" htmlFor="image">Image</label>
                            <input
                                className="w-full p-3 rounded bg-[#386181] text-white border border-[#386181]"
                                id="image"
                                type="file"
                                accept="image/*"
                                onChange={handleChange}
                            />
                            {errors.image && <p className="text-red-500 text-sm">{errors.image}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm mb-2" htmlFor="feedbackReview">Additional Feedback</label>
                            <textarea
                                className="w-full p-3 rounded bg-[#386181] text-white border border-[#386181]"
                                id="feedbackReview"
                                placeholder="Your additional feedback here"
                                rows="4"
                                value={formData.feedbackReview}
                                onChange={handleChange}
                            />
                            {errors.feedbackReview && <p className="text-red-500 text-sm">{errors.feedbackReview}</p>}
                        </div>
                        <div className="text-center">
                            <button className="bg-orange-500 text-white mt-4 font-semibold py-2 px-4 w-full rounded-full" type="submit">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default FeedbackForm;
