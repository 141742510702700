import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import TourBookForm from '../../components/TourDetails/TourBookForm';
import TermsConditions from '../../components/TourDetails/TermsConditions';
import TourHotel from '../../components/TourDetails/TourHotel';
import Inclusion from '../../components/TourDetails/Inclusion';
import Details from '../../components/TourDetails/Details';
import './TourDetails.css';

const TourDetails = () => {
    const params = useParams();
    const tour_id = params?.id;
    const [images, setImages] = useState([]);
    const [tourData, setTourData] = useState(null);
    const [currentBigImage, setCurrentBigImage] = useState("");
    const [selectedPackageType, setSelectedPackageType] = useState("");
    const [selectedPackagePrice, setSelectedPackagePrice] = useState("");
    const [selectedTab, setSelectedTab] = useState("itinerary");
    const [activeTab, setActiveTab] = useState(1);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getTourDetails();
    }, []);

    const getTourDetails = () => {
        const config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/new-packages/new-package-by-id/${tour_id}`,
            headers: {},
        };
        setIsLoading(true);
        axios.request(config)
            .then((response) => {
                setIsLoading(false);
                const data = response.data;
                setTourData(data);
                const { big_image, small_image } = data.image_url_object;
                setImages([big_image, ...small_image]);
                setCurrentBigImage(big_image);

                if (data.type_of_packages && data.type_of_packages.length > 0) {
                    const firstPackage = data.type_of_packages[0];
                    setSelectedPackageType(firstPackage.packageName);
                    setSelectedPackagePrice(firstPackage.price);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error("t-details-error", error);
            });
    };

    const handlePackageTypeChange = (e) => {
        const selectedType = e.target.value;
        setSelectedPackageType(selectedType);

        if (tourData && selectedType) {
            const selectedPackage = tourData.type_of_packages.find(
                (pkg) => pkg.packageName === selectedType
            );
            if (selectedPackage) {
                setSelectedPackagePrice(selectedPackage.price);
            }
        }
    };

    const changeTab = (tab) => {
        setActiveTab(tab);

        // Map activeTab to corresponding tab content
        const tabMapping = {
            1: "itinerary",
            2: "inclusion",
            3: "hotel",
            4: "terms",
        };

        setSelectedTab(tabMapping[tab]);
    };

    return (
        <React.Fragment>
            {isLoading ? (
                <div className="w-full h-screen flex items-center justify-center bg-white">
                    <div className="w-3/4 h-3/4 bg-white rounded-lg shadow-lg p-6 flex flex-col justify-between">
                        {/* Skeleton content here */}
                    </div>
                </div>
            ) : (
                <div className="p-4 md:p-6 bg-white min-h-screen">
                    {images.length > 0 && (
                        <div className="relative mb-6">
                            <img
                                src={currentBigImage}
                                alt="Big Tour"
                                className="w-full h-64 sm:h-80 md:h-[450px] lg:h-[500px] object-cover rounded-lg shadow-md"
                            />
                            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex flex-wrap justify-center gap-2 sm:gap-4">
                                {images.map((img, index) => (
                                    <img
                                        key={index}
                                        src={img}
                                        alt={`Thumbnail ${index + 1}`}
                                        className={`w-12 h-12 sm:w-16 sm:h-16 md:w-20 md:h-20 object-cover rounded-full border-2 ${img === currentBigImage
                                            ? "border-blue-500"
                                            : "border-white"
                                            } shadow-lg cursor-pointer hover:scale-105 transition-transform`}
                                        onClick={() => setCurrentBigImage(img)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}

                    <div className="bg-white border border-[#e2e4e8] shadow-sm p-4 mb-4 rounded-lg flex flex-col md:flex-row justify-between items-center">
                        <div className="text-center md:text-left">
                            <h1 className="text-xl md:text-2xl font-bold text-gray-800 tracking-wider">
                                {tourData?.name || "Tour Name"}
                            </h1>
                            <p className="text-gray-600 capitalize tracking-wide">
                                <strong>Departure from:</strong> {tourData?.departure_place || "N/A"}
                            </p>
                        </div>

                        <div className="mt-4 md:mt-0 flex items-center space-x-4 bg-blue-50 p-3 rounded-md animate-border-lighting">
                            <select
                                className="border border-gray-300 rounded-md p-2"
                                value={selectedPackageType}
                                onChange={handlePackageTypeChange}
                            >
                                <option value="">Select Package Type</option>
                                {tourData?.type_of_packages?.map((pkg, index) => (
                                    <option key={index} value={pkg.packageName}>
                                        {pkg.packageName}
                                    </option>
                                ))}
                            </select>
                            <div className="text-xl font-semibold text-blue-800">
                                {selectedPackagePrice ? `₹${selectedPackagePrice} / P` : "N/A"}
                            </div>
                        </div>

                    </div>


                    {/* <div className="bg-white border border-[#e2e4e8] shadow-sm p-4 mb-4 rounded-lg flex flex-col md:flex-row justify-between items-center">
                        <div className="text-center md:text-left">
                            <h1 className="text-xl md:text-2xl font-bold text-gray-800 tracking-wider">
                                {tourData?.name || "Tour Name"}
                            </h1>
                            <p className="text-gray-600 capitalize tracking-wide">
                                <strong>Departure from:</strong> {tourData?.departure_place || "N/A"}
                            </p>
                        </div>
                        <div className="mt-4 md:mt-0 flex items-center space-x-4">
                            <select
                                className="border border-gray-300 rounded-md p-2"
                                value={selectedPackageType}
                                onChange={handlePackageTypeChange}
                            >
                                <option value="">Select Package Type</option>
                                {tourData?.type_of_packages?.map((pkg, index) => (
                                    <option key={index} value={pkg.packageName}>
                                        {pkg.packageName}
                                    </option>
                                ))}
                            </select>
                            <div className="text-xl font-semibold text-gray-800">
                                {selectedPackagePrice ? `₹${selectedPackagePrice} / P` : "N/A"}
                            </div>
                        </div>
                    </div> */}

                    <div className="flex flex-col md:flex-row">
                        <div className="pl-4 pt-4 rounded-md hidden sm:block">
                            <div className="flex rounded-md">
                                {[{ name: "Details", id: 1 }, { name: "Inclusion", id: 2 }, { name: "Hotels", id: 3 }, { name: "Terms & Conditions", id: 4 }].map((tab) => (
                                    <button
                                        key={tab.id}
                                        className={`py-2 px-8 tracking-wider transition-all ${activeTab === tab.id
                                            ? "bg-gradient-to-r from-[#ff783e] to-[#F94E05] text-white"
                                            : "bg-[#EBEBEB] hover:bg-[#f7c6a2]"
                                            }`}
                                        onClick={() => changeTab(tab.id)}
                                    >
                                        {tab.name}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col sm:flex-row sm:space-x-6 mt-6">
                        <div className="flex-1">
                            {selectedTab === "itinerary" && <Details tourData={tourData} />}
                            {selectedTab === "inclusion" && <Inclusion tourData={tourData} />}
                            {selectedTab === "hotel" && <TourHotel tourData={tourData} />}
                            {selectedTab === "terms" && <TermsConditions tourData={tourData} />}
                        </div>
                        <div className="w-full sm:w-1/3 bg-white p-6 rounded-lg shadow-lg">
                            <TourBookForm
                                tourData={tourData}
                                selectedPackageType={selectedPackageType}
                                selectedPackagePrice={selectedPackagePrice}
                            />
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default TourDetails;