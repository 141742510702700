import React from 'react';
import SimpleBanner from '../../components/common/SimpleBanner';
import DestinationsBanner from '../../Assets/user-imgs/Home-imgs/FeedbackBanner.png';
import DestinationsTours from '../../components/homeComponents/DestinationsTours';

const Destinations = () => {
    return (
        <div className='bg-white'>
            <SimpleBanner
                image={DestinationsBanner}
                name="Destinations"
                path="Home / Destinations"
            />
            <DestinationsTours />
        </div>
    )
}

export default Destinations