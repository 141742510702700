import React, { useEffect, useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { PiWarningCircleDuotone } from "react-icons/pi";

const Details = ({ setDetailsData, detailsData }) => {
  const [preFormDetails, setPreFormDetails] = useState(null);
  const [responseData, setResponseData] = useState({});
  const [uponModal, setUponModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    country: "",
    address: "",
    state: "",
    BookingFor: "own",
    Travelling: "yes",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const clearData = () => {
    setFormData("");
  };

  const [loader, setLoader] = useState(false);

  const stateData = [
    {
      state: "odisha",
    },
    {
      state: "Chhatishgada",
    },
    {
      state: "West Bengal",
    },
    {
      state: "Bihar",
    },
  ];

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("bookingData"));
    setPreFormDetails(data);
    setDetailsData(data);
  }, [setDetailsData]);

  // Form validation
  const validate = () => {
    const newErrors = {};
    if (!formData.firstName.trim()) {
      newErrors.firstName = "First name is required.";
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last name is required.";
    }
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "A valid email is required.";
    }
    if (!formData.mobile.trim() || !/^\d{10}$/.test(formData.mobile)) {
      newErrors.mobile = "A valid 10-digit mobile number is required.";
    }
    if (!formData.address.trim()) {
      newErrors.address = "Address is required.";
    }
    if (!formData.country) {
      newErrors.country = "Country/Region is required.";
    }
    if (!formData.state) {
      newErrors.state = "State is required.";
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // console.log("guest", guest);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const allNewData = {
        ...formData,

        detailsData,
      };

      let data = {
        roomIds: allNewData?.detailsData?.selectedRooms?.map((i) => i._id),
        updates: [
          {
            date: allNewData?.detailsData?.bookingDate,
            isAvailable: false,
          },
        ],
      };

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/rooms/room-availability`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      setLoader(true);

      axios
        .request(config)
        .then((response) => {
          clientDetails();
        })
        .catch((error) => {
          console.log(error);
          toast.error("Booking cancelled");
          setLoader(false);
        });
    }
  };

  const clientDetails = () => {
    let data = JSON.stringify({
      fullName: `${formData?.firstName} ${formData?.lastName}`,
      address: formData?.address,
      phoneNo: formData?.mobile,
      email: formData?.email,
      country: formData?.country,
      state: formData?.state,
      password: "mypassword456",
      totalPersons: preFormDetails?.adultsCount + preFormDetails?.childrenCount,
      children: preFormDetails?.childrenCount,
      adults: preFormDetails?.adultsCount,
      bookingType: "hotel",
      bookingFor: formData.BookingFor,
      professionalWorkBooking: true,
      bookingDetails: {
        totalPrice: preFormDetails?.hotel?.basePrice,
        paymentMode: "offline",
        paymentStatus: "pay later",
        advancePayment: 0,
        hotelDetails: {
          hotelName: preFormDetails?.hotel?.name,
          hotelId: preFormDetails?.hotel?._id,
          bookingDates: preFormDetails?.bookingDate,
          numberOfRooms: preFormDetails?.selectedRooms?.length,
          bookedRoomIds: preFormDetails?.selectedRooms?.map((i) => {
            return i._id;
          }),
        },
      },
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/bookings/create-booking`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log("Api response--------------->", response?.data?.data);
        setResponseData(response?.data?.data);
        setLoader(false);
        clearData();
        navigate("/booking-successful/hotel");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <form onSubmit={handleSubmit} className="py-5 lg:pl-4 font-be-vietnam">
      <h2 className="font-semibold text-xl mb-6">Provide Your Details</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* First Name */}
        <div>
          <label
            htmlFor="firstName"
            className="block text-md font-semibold text-gray-700"
          >
            First Name
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData?.firstName}
            onChange={handleChange}
            className={`mt-1 block w-full h-9 border-[1px] px-2 ${
              errors.firstName ? "border-red-500" : "border-black"
            } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
          />
          {errors.firstName && (
            <p className="text-red-500 text-sm">{errors?.firstName}</p>
          )}
        </div>

        {/* Last Name */}
        <div>
          <label
            htmlFor="lastName"
            className="block text-md font-semibold text-gray-700"
          >
            Last Name
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData?.lastName}
            onChange={handleChange}
            className={`mt-1 block w-full h-9 border-[1px] px-2 ${
              errors.lastName ? "border-red-500" : "border-black"
            } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
          />
          {errors.lastName && (
            <p className="text-red-500 text-sm">{errors?.lastName}</p>
          )}
        </div>

        {/* Email Address */}
        <div>
          <label
            htmlFor="email"
            className="block text-md font-semibold text-gray-700"
          >
            Email Address
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData?.email}
            onChange={handleChange}
            className={`mt-1 block w-full h-9 border-[1px] px-2 ${
              errors.email ? "border-red-500" : "border-black"
            } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
          />
          {errors.email && (
            <p className="text-red-500 text-sm">{errors?.email}</p>
          )}

          <p className="mt-2 text-xs text-gray-500">
            Confirmation email goes to this address
          </p>
        </div>

        {/* Mobile Number */}
        <div>
          <label
            htmlFor="mobile"
            className="block text-md font-semibold text-gray-700"
          >
            Mobile Number
          </label>
          <div className="flex items-center mt-1">
            <select
              id="countryCode"
              name="countryCode"
              className="border-black h-9 md:px-2 px-0 border-[1px] rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mr-1"
            >
              <option value="IN">IN +91</option>
              <option value="US">US +1</option>
              <option value="UK">UK +44</option>
            </select>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              value={formData?.mobile}
              onChange={handleChange}
              className={`block w-full h-9 border-[1px] px-2 ${
                errors.mobile ? "border-red-500" : "border-black"
              } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ml-1`}
              placeholder="Enter mobile number"
            />
          </div>
          {errors.mobile && (
            <p className="text-red-500 text-sm">{errors?.mobile}</p>
          )}
          <p className="mt-2 text-xs text-gray-500">
            Needed by the property to validate your booking
          </p>
        </div>

        <div>
          <label
            htmlFor="address"
            className="block text-md font-semibold text-gray-700"
          >
            Address
          </label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData?.address}
            onChange={handleChange}
            className={`mt-1 block w-full h-9 border-[1px] px-2 ${
              errors.address ? "border-red-500" : "border-black"
            } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
          />
          {errors.address && (
            <p className="text-red-500 text-sm">{errors?.address}</p>
          )}
        </div>

        {/* Country/Region */}
        <div className="md:col-span-1">
          <label
            htmlFor="country"
            className="block text-md font-semibold text-gray-700"
          >
            Country/Region
          </label>
          <select
            id="country"
            name="country"
            value={formData.country}
            onChange={handleChange}
            className={`mt-1 block px-2  w-full h-9 border-[1px] ${
              errors.country ? "border-red-500" : "border-black"
            } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
          >
            <option value="">Select a country</option>
            <option value="IN">India</option>
            <option value="US">United States</option>
            <option value="UK">United Kingdom</option>
          </select>
          {errors.country && (
            <p className="text-red-500 text-sm">{errors.country}</p>
          )}
        </div>
        <div className="md:col-span-1">
          <label
            htmlFor="state"
            className="block text-md font-semibold text-gray-700"
          >
            State
          </label>
          <select
            id="state"
            name="state"
            value={formData.state}
            onChange={handleChange}
            className={`mt-1 block px-2  w-full h-9 border-[1px] ${
              errors.state ? "border-red-500" : "border-black"
            } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
          >
            <option value="">Select Your state</option>
            {stateData.map((i, index) => (
              <option key={index} value={i.state}>
                {i.state}
              </option>
            ))}
          </select>
          {errors.state && (
            <p className="text-red-500 text-sm">{errors.state}</p>
          )}
        </div>

        {/* Who are you booking for */}
        <div>
          <label htmlFor="">Who are you booking for?</label>
          <div className="flex flex-col gap-2 pt-2">
            <label className="flex items-center">
              <input
                type="radio"
                name="BookingFor"
                value="own"
                checked={formData.BookingFor === "own"}
                onChange={() => {
                  setFormData({
                    ...formData,
                    BookingFor: "own",
                  });
                }}
                className="mr-2"
              />
              I am the main guest
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="BookingFor"
                value="for someone"
                checked={formData.BookingFor === "for someone"}
                onChange={() => {
                  setFormData({
                    ...formData,
                    BookingFor: "for someone",
                  });
                }}
                className="mr-2"
              />
              Booking is for someone else
            </label>
          </div>
        </div>

        {/* Are you traveling for work */}
        <div>
          <label htmlFor="">Are you traveling for work?</label>
          <div className="flex gap-4 pt-2">
            <label className="flex items-center">
              <input
                type="radio"
                name="Travelling"
                value="yes"
                checked={formData.Travelling === "yes"}
                onChange={() => {
                  setFormData({
                    ...formData,
                    Travelling: "yes",
                  });
                }}
                className="mr-2"
              />
              Yes
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="Travelling"
                value="no"
                checked={formData.Travelling === "no"}
                onChange={() => {
                  setFormData({
                    ...formData,
                    Travelling: "no",
                  });
                }}
                className="mr-2"
              />
              No
            </label>
          </div>
        </div>
      </div>

      {/* Submit Buttons */}
      <div className="w-full pt-6">
        <div className="flex justify-end items-center flex-col md:flex-row gap-4">
          <button
            type="button"
            onClick={() => setUponModal(true)} // Show modal on click
            className="px-4 py-2 w-full sm:w-auto text-[#2D87D0] text-md border-[1px] border-[#2D87D0] rounded-lg"
          >
            Cancel Booking
          </button>

          <button
            type="submit"
            className="px-4 py-2 w-full sm:w-auto bg-[#2D87D0] text-md border-[1px] border-[#2D87D0] text-white rounded-lg flex justify-center items-center gap-2"
          >
            {loader ? (
              "Please wait..."
            ) : (
              <>
                Confirm Booking <MdArrowForwardIos />
              </>
            )}
          </button>
        </div>

        {uponModal && (
          <DelTourModal
            uponModal={uponModal}
            setUponModal={setUponModal}
            navigate={navigate}
          />
        )}
      </div>
    </form>
  );
};

export default Details;

const DelTourModal = ({ uponModal, setUponModal, navigate }) => (
  <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center font-be-vietnam">
    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-xs">
      <div className="flex justify-center text-red-700 mb-4">
        <PiWarningCircleDuotone size={60} />
      </div>
      <p className="text-center tracking-wide text-lg">
        Are you sure to cancel this booking?
      </p>

      <div className="flex tracking-wide justify-center items-center space-x-4 mt-5">
        <button
          className="py-2 px-4 text-gray-800 border border-gray-300 rounded-lg hover:bg-gray-200"
          onClick={() => setUponModal(false)}
        >
          Cancel
        </button>
        <button
          className="py-2 px-4 text-white bg-orange-500 rounded-lg hover:bg-orange-700"
          onClick={() => {
            toast.success("Booking canceled");
            navigate("/hotel-list");
          }}
        >
          Yes, Cancel
        </button>
      </div>
    </div>
  </div>
);
