import React, { useRef, useState, useEffect } from "react";
import formImage1 from "../../Assets/user-imgs/Home-imgs/newBanner1.png";
import formImage2 from "../../Assets/user-imgs/Home-imgs/newBanner2.png";
import formImage3 from "../../Assets/user-imgs/Home-imgs/newBanner3.png";
import formImage4 from "../../Assets/user-imgs/Home-imgs/newBanner4.png";
import { MdPhone } from "react-icons/md";
import { BsWhatsapp } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { IoMdCloseCircle } from "react-icons/io";
import emailjs from "@emailjs/browser";

const InfoModal = ({ setInfoModalOpen }) => {
    const modalRef = useRef();
    const formRef = useRef();
    const [formData, setFormData] = useState({
        Name: "",
        Email: "",
        Mobile: "",
        Questions: "",
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    const images = [formImage1, formImage2, formImage3, formImage4];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 2000);
        return () => clearInterval(interval);
    }, [images]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        setErrors((prev) => ({ ...prev, [name]: "" }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.Name.trim()) newErrors.Name = "Name is required.";
        if (!formData.Email.trim() || !/\S+@\S+\.\S+/.test(formData.Email))
            newErrors.Email = "A valid email is required.";
        if (!formData.Mobile.trim() || formData.Mobile.length !== 10)
            newErrors.Mobile = "Mobile number must be 10 digits.";
        if (!formData.Questions.trim())
            newErrors.Questions = "Questions field cannot be empty.";
        return newErrors;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setLoading(true);
        try {
            const result = await emailjs.sendForm(
                process.env.REACT_APP_SERVICE_ID, // Service ID
                process.env.REACT_APP_TEMPLATE_ID, // Template ID
                formRef.current, // Form reference
                process.env.REACT_APP_PUBLIC_KEY // Public key
            );
            console.log("Email sent successfully:", result.text);
            clearForm();
            setShowSuccessPopup(true);
        } catch (error) {
            console.error("Error sending email:", error);
            alert("Failed to send the email. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const clearForm = () => {
        setFormData({ Name: "", Email: "", Mobile: "", Questions: "" });
        setErrors({});
    };

    const closeSmallPopup = () => {
        setShowSuccessPopup(false);
        setInfoModalOpen(false)
    }

    return (
        <div
            ref={modalRef}
            className="fixed inset-0 z-50 bg-gray-800 bg-opacity-75 flex items-center justify-center p-4 sm:p-6 overflow-y-auto"
        >
            <div className="flex flex-col md:flex-row w-full max-w-4xl rounded-lg">
                {/* Left Section */}
                <div className="bg-white p-6 md:w-1/2 rounded-t-lg md:rounded-l-lg md:rounded-tr-none hidden lg:block">
                    <h1 className="text-2xl font-bold text-[#386181] mb-4 tracking-wide text-center">
                        Why Book With Us
                    </h1>

                    <div className="space-y-2 mb-4 tracking-wide">
                        {[
                            "Tell us details of your holiday plan.",
                            "Get multiple quotes from expert agents. Compare & customize further.",
                            "Select & Book Best Deal.",
                            "Offer Deals Available On Phone & Email ID.",
                            "Get Perfect Advice From Our Tour Expert.",
                            "100% customized tour quotes for you.",
                        ].map((text, index) => (
                            <div
                                key={index}
                                className="flex items-start gap-2 text-sm text-[#386181] tracking-wide"
                            >
                                <span className="bg-yellow-500 h-2 w-2 rounded-full mt-1"></span>
                                <p>{text}</p>
                            </div>
                        ))}
                    </div>

                    <div className="bg-[#f9f9f9] p-4 rounded-lg shadow-md text-center tracking-wide mb-6">
                        <p className="font-semibold text-lg text-orange-600">Get Best Deals</p>
                        <p className="mt-2 text-[#386181] flex items-center justify-center gap-2">
                            <MdPhone className="text-yellow-500" size={20} /> Call us Now:{" "}
                            <span className="font-medium">+91-8178958591</span>
                        </p>
                        <p className="mt-2 text-[#386181] flex items-center justify-center gap-2">
                            <BsWhatsapp className="text-green-500" size={20} /> Whatsapp Number:{" "}
                            <span className="font-medium">+91-8178958591</span>
                        </p>
                        <p className="mt-2 text-[#386181] flex items-center justify-center gap-2">
                            <HiOutlineMail className="text-[#386181]" size={20} /> Email:{" "}
                            <span className="font-medium">info@weekendbhraman.com</span>
                        </p>
                    </div>

                    <div className="mt-4">
                        <img
                            src={images[currentImageIndex]}
                            alt="Trending Offer"
                            className="w-full rounded-lg"
                        />
                    </div>
                </div>

                {/* Right Section */}
                <div className="bg-gradient-to-t from-[#063A64] to-[#10558C] p-6 md:w-1/2 w-full text-white lg:rounded-r-lg">
                    <div className="flex justify-end cursor-pointer">
                        <IoMdCloseCircle
                            onClick={() => setInfoModalOpen(false)}
                            size={25}
                        />
                    </div>
                    <h2 className="text-xl font-semibold mb-4 tracking-wider">
                        Fill Out the Form Now & Secure the Best Travel Offers
                    </h2>
                    <form ref={formRef} onSubmit={handleSubmit} className="tracking-wide">
                        <InputField
                            label="Name"
                            name="Name"
                            value={formData.Name}
                            error={errors.Name}
                            onChange={handleChange}
                        />
                        <InputField
                            label="Email"
                            name="Email"
                            value={formData.Email}
                            error={errors.Email}
                            onChange={handleChange}
                        />
                        <InputField
                            label="Mobile No"
                            name="Mobile"
                            value={formData.Mobile}
                            error={errors.Mobile}
                            onChange={handleChange}
                        />
                        <TextAreaField
                            label="Questions"
                            name="Questions"
                            value={formData.Questions}
                            error={errors.Questions}
                            onChange={handleChange}
                        />
                        <div className="text-center tracking-wide">
                            <button
                                type="submit"
                                className="bg-orange-500 hover:bg-orange-700 transition duration-200 text-white mt-4 font-semibold py-2 px-4 w-full rounded-full"
                                disabled={loading}
                            >
                                {loading ? "Submitting..." : "Submit"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {/* Success Popup */}
            {showSuccessPopup && (
                <div className="fixed inset-0 z-50 bg-gray-800 bg-opacity-75 flex items-center justify-center p-4 sm:p-6 overflow-y-auto">
                    <div className="bg-white p-6 w-full max-w-sm rounded-lg">
                        <h2 className="text-xl font-semibold tracking-wider text-[#386181] mb-4">
                            Thanks for getting in touch!
                        </h2>
                        <p className="tracking-wide">
                            Your email was sent successfully. Our team will get back to you soon.
                        </p>
                        <div className="text-center mt-6">
                            <button
                                onClick={closeSmallPopup}
                                className="bg-orange-500 hover:bg-orange-700 transition duration-200 tracking-wide text-white font-semibold py-2 px-4 rounded-full"
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const InputField = ({ label, name, value, error, onChange }) => (
    <div className="mb-4">
        <label className="block text-sm mb-2">{label}</label>
        <input
            type="text"
            name={name}
            value={value}
            onChange={onChange}
            placeholder={`Enter your ${label.toLowerCase()}`}
            className="w-full p-2 border rounded-lg text-gray-700"
        />
        {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
    </div>
);

const TextAreaField = ({ label, name, value, error, onChange }) => (
    <div className="mb-4">
        <label className="block text-sm mb-2">{label}</label>
        <textarea
            name={name}
            value={value}
            onChange={onChange}
            rows="4"
            placeholder={`Enter your ${label.toLowerCase()}`}
            className="w-full p-2 border rounded-lg text-gray-700"
        ></textarea>
        {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
    </div>
);

const FeatureItem = ({ img, text }) => (
    <div className="flex flex-col items-center text-center">
        <img src={img} alt={text} className="w-12 h-12 mb-2" />
        <p className="text-sm">{text}</p>
    </div>
);

export default InfoModal;