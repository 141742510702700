import React, { useEffect, useState } from "react";
import axios from "axios";

import { BiSearchAlt } from "react-icons/bi";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { MdOutlineFilterAltOff } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import SkeletonLoaderForCabHistory from "../../screens/auth/SkeletonLoaderForCabHistory";

import { MdOutlineToggleOn } from "react-icons/md";
const Cabshistory = ({
  activeCount,
  totalCount,
  todaysCount,
  finishedCount,
  setActiveCount,
  setFinishedCount,
  setTodaysCount,
  setTotalCount,
  canceledCount,
  setCanceledCount,
}) => {
  const [hotelData, setHotelData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [dropDownData, setDropDownData] = useState("");
  const [dropDownDataHotel, setDropDownDataHotel] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [dateInput, setDateInput] = useState("");
  const [hotelNames, setHotelNames] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [statusModal, setStatusModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const rowsPerPage = 10;

  const navigate = useNavigate();

  useEffect(() => {
    hotelBookingHistory();
    hotelNameData();
  }, []);

  useEffect(() => {
    const filtered = hotelData?.filter((i) => i?.bookingType === "cab");
    setFilterData(filtered);
  }, [hotelData]);

  const hotelBookingHistory = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/bookings/get-all-bookings`,
      headers: {},
    };
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        const reversedData = response?.data?.data.reverse();
        setHotelData(reversedData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const hotelNameData = () => {
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/cabs/get-all-cabs`,
      headers: {},
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setHotelNames(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // {
  //   const sortedData = response?.data?.data.sort((a, b) => {
  //     const dateA = new Date(a?.bookingDetails?.cabDetails?.bookingDates[0]);
  //     const dateB = new Date(b?.bookingDetails?.cabDetails?.bookingDates[0]);
  //     return dateB - dateA; // Sort in descending order
  //   }

  console.log("hotelNames", hotelNames);

  const filteredData = filterData.filter((l) => {
    const bookingDate = l?.bookingDetails?.cabDetails?.bookingDates[0];
    const formattedBookingDate =
      bookingDate && !isNaN(new Date(bookingDate))
        ? new Date(bookingDate).toLocaleDateString("en-CA")
        : null;
    return (
      (dropDownData === "" || l?.bookingStatus === dropDownData) &&
      (searchInput === "" ||
        l?.fullName?.toLowerCase()?.includes(searchInput?.toLowerCase())) &&
      (dateInput === "" || formattedBookingDate === dateInput)
    );
  });

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  // console.log("filtered data", filterData);

  const handlePageChange = (event, value) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentPage(value);
  };

  activeCount = filteredData.filter(
    (item) => item.bookingStatus === "active"
  ).length;
  // console.log("Active count=", activeCount);

  finishedCount = filteredData.filter(
    (item) => item.bookingStatus === "finished"
  ).length;
  // console.log("Finished count=", finishedCount);

  totalCount = filteredData.length;
  // console.log("Total count=", totalCount);

  canceledCount = filteredData.filter(
    (item) => item.bookingStatus === "canceled"
  ).length;

  const today = new Date().toISOString().split("T")[0];

  todaysCount = filteredData.filter((item) => {
    const bookingDate = item?.bookingDetails?.cabDetails?.bookingDates[0];

    const isValidDate = bookingDate && !isNaN(new Date(bookingDate).getTime());

    if (isValidDate) {
      const formattedBookingDate = new Date(bookingDate)
        .toISOString()
        .split("T")[0];
      return formattedBookingDate === today;
    }

    return false;
  }).length;

  // console.log("Today's Booking Count=", todaysCount);

  useEffect(() => {
    setActiveCount(activeCount);
    setTodaysCount(todaysCount);
    setFinishedCount(finishedCount);
    setTotalCount(totalCount);
    setCanceledCount(canceledCount);
  }, [activeCount, finishedCount, todaysCount, totalCount, canceledCount]);

  const clearFilter = () => {
    setDropDownData("");
    setDropDownDataHotel("");
    setSearchInput("");
    setDateInput("");
  };

  const NavigateClientDetails = (id) => {
    navigate(`/cabs-client-details/${id}`);
  };

  const openModal = (user) => {
    setStatusModal(true);
    setSelectedUser(user);
  };

  const handleStatusChange = (id, newStatus) => {
    const updatedData = filteredData.map((item) =>
      item._id === id ? { ...item, bookingStatus: newStatus } : item
    );
    setFilterData(updatedData);

    setStatusModal(false);

    const data = JSON.stringify({
      bookingStatus: newStatus,
    });

    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/bookings/update-booking-by-id/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Booking status updated successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error updating booking status:", error);

        const revertedData = filteredData.map((item) =>
          item._id === id ? { ...item, bookingStatus: "active" } : item
        );
        setFilterData(revertedData);
      });
  };

  console.log("filterData", filterData);
  console.log("FilteredData", filteredData);

  return (
    <div>
      {isLoading ? (
        <SkeletonLoaderForCabHistory />
      ) : (
        <>
          <div className="flex justify-start items-center gap-5 sm:flex-row flex-col font-be-vietnam">
            <select
              value={dropDownData}
              onChange={(e) => setDropDownData(e?.target?.value)}
              className="border-[1px] border-gray-300 rounded-lg h-10 w-full sm:w-auto  text-md px-3 outline-none capitalize"
            >
              <option value="">Filter by Status</option>
              <option value="active">active</option>
              <option value="finished">finished</option>
              <option value="canceled">canceled</option>
            </select>

            <div className="flex justify-center items-center border border-[#C1C1C1] rounded-lg px-3 h-10 w-full sm:w-[70%] focus-within:border-[#95caed] bg-white">
              <input
                type="date"
                className="w-full outline-none text-md text-[#606060]"
                value={dateInput}
                onChange={(e) => setDateInput(e?.target?.value)}
              />
            </div>

            <div className="flex items-center border border-[#C1C1C1] rounded-lg px-3 h-10 w-full sm:w-[70%] focus-within:border-[#95caed] bg-white">
              <input
                type="text"
                className="w-full outline-none text-md  text-[#606060]"
                placeholder="Search by client name"
                value={searchInput}
                onChange={(e) => setSearchInput(e?.target?.value)}
              />
              <BiSearchAlt className="text-[#606060] text-lg" />
            </div>
            <button
              onClick={clearFilter}
              className="flex justify-center items-center gap-2 min-w-40 text-sm  bg-slate-100 hover:bg-slate-300 hover:text-black transition duration-200 tracking-wide border-[1px] border-gray-300 rounded-md px-3 py-2"
            >
              <MdOutlineFilterAltOff size={20} />
              Clear Filters
            </button>
          </div>

          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              <div className="overflow-x-auto">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-3 py-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider   w-auto ">
                        <h1 className="w-[50px]">S.l no</h1>
                      </th>
                      <th className="px-3 py-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider  w-auto ">
                        <h1>Client</h1>
                      </th>
                      <th className="px-3 py-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider  w-auto ">
                        Phone
                      </th>

                      <th className="px-3 py-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider  w-auto ">
                        Cab Name
                      </th>
                      {/* <th className="px-3 py-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider  w-auto ">
                        Rooms
                      </th> */}
                      <th className="px-3 py-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider  w-auto ">
                        date
                      </th>
                      <th className="px-3 py-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider  w-[80px] ">
                        from
                      </th>
                      <th className="px-3 py-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider  w-auto ">
                        destination
                      </th>
                      <th className="px-3 py-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider  w-auto  ">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.length > 0 ? (
                      currentRows.map((i, index) => (
                        <tr key={index}>
                          <td className="px-3 py-4  border-gray-200 border-b bg-white text-sm">
                            <div className="flex">
                              <div className="ml-3">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  {indexOfFirstRow + index + 1}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td
                            className="px-3 pl-3 py-4 border-b bg-white text-sm cursor-pointer hover:bg-gray-200 rounded-lg hover:text-blue-700"
                            onClick={() => {
                              NavigateClientDetails(
                                i?.bookingDetails?.cabDetails?.cabId
                              );
                            }}
                          >
                            <p className="">{i?.fullName}</p>
                          </td>
                          <td className="px-3 py-4 bg-white border-b text-sm">
                            <p className="text-black ">{i?.phoneNo}</p>
                          </td>
                          <td className="px-2 py-4 bg-white text-sm border-b">
                            <div className="w-[100px]">
                              <p className="text-black ">
                                {i?.bookingDetails?.cabDetails?.cabName}
                              </p>
                            </div>
                          </td>
                          {/* <td className="px-3 py-4 bg-white border-b text-sm text-start">
                            <p className="text-black">
                              {i?.bookingDetails?.hotelDetails?.numberOfRooms}
                            </p>
                          </td> */}
                          <td className="px-3 py-3 bg-white border-b text-sm">
                            <div className="w-[100px]">
                              <p className="text-black">
                                {i?.bookingDetails?.cabDetails?.bookingDates[0]}
                              </p>
                              <p className="text-black">
                                {i?.bookingDetails?.cabDetails?.bookingDates[1]}
                              </p>
                            </div>
                          </td>
                          <td className="px-3 py-4 bg-white text-sm text-start border-b w-[80px]">
                            <p className="text-black">
                              {i?.bookingDetails?.cabDetails?.travelStartPlace}
                            </p>
                          </td>
                          <td className="px-3 py-4 bg-white text-sm text-start border-b">
                            <p className="text-black">
                              {i?.bookingDetails?.cabDetails?.travelEndPlace}
                            </p>
                          </td>
                          <td className="px-3 py-4 bg-white text-sm border-b">
                            <div className="flex justify-start items-center ">
                              <button
                                className={`rounded-xl capitalize px-5 py-1 text-white cursor-pointer ${
                                  i?.bookingStatus === "active"
                                    ? "bg-[#20a047] hover:text-black"
                                    : i?.bookingStatus === "finished"
                                    ? "bg-[#3420a9] hover:text-black"
                                    : i?.bookingStatus === "canceled"
                                    ? "bg-[#a12121] hover:text-black"
                                    : "bg-gray-600 hover:text-black"
                                }`}
                                onClick={
                                  i?.bookingStatus === "active"
                                    ? () => openModal(i)
                                    : undefined
                                }
                              >
                                {i?.bookingStatus}
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="8"
                          className="px-3 py-10 text-center text-gray-600 text-lg"
                        >
                          No records found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="py-5 flex justify-center items-center">
                <Stack spacing={2} alignItems="center">
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    variant="outlined"
                    color="primary"
                    shape="rounded"
                    onChange={handlePageChange}
                  />
                </Stack>
              </div>
            </div>
          </div>
        </>
      )}
      {statusModal && (
        <ChangeStatusModal
          statusModal={statusModal}
          setStatusModal={setStatusModal}
          date={selectedUser?.bookingDetails?.cabDetails?.bookingDates[0]}
          status={selectedUser?.bookingStatus}
          handleStatusChange={(newStatus) =>
            handleStatusChange(selectedUser._id, newStatus)
          }
        />
      )}
    </div>
  );
};

const ChangeStatusModal = ({
  statusModal,
  setStatusModal,
  openModal,
  date,
  hotelName,
  status,
  handleStatusChange,
}) => {
  const [selectedStatus, setSelectedStatus] = useState("finished");

  const handleRadioChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleConfirmClick = () => {
    handleStatusChange(selectedStatus);
    setStatusModal(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center font-sans z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md transform transition-transform">
        <div className="flex items-center gap-3 mb-5">
          <div className="p-2 rounded-full bg-blue-100 text-blue-600">
            <MdOutlineToggleOn size={24} />
          </div>
          <h3 className="text-xl font-semibold text-gray-800">Update Status</h3>
        </div>

        <div className="space-y-4">
          <p className="text-gray-700 text-base ">
            Please choose whether to mark this booking as 'Finished' or
            'Canceled'
          </p>

          <div className="bg-gray-100 p-4 rounded-md border border-gray-200">
            <p className="text-sm text-gray-600">
              <span className="font-medium text-gray-800">Date:</span> {date}
            </p>

            <p className="text-sm text-gray-600 mt-1">
              <span className="font-medium text-gray-800">Current Status:</span>{" "}
              <span
                className={`font-medium uppercase ${
                  status === "active" ? "text-green-500" : "text-red-500"
                }`}
              >
                {status}
              </span>
            </p>
          </div>
        </div>

        <div className="mt-6">
          <p className="text-gray-700 text-sm font-semibold">Change status:</p>
          <div className="flex items-center space-x-6 mt-2">
            <label className="flex items-center space-x-2">
              <input
                type="radio"
                value="finished"
                checked={selectedStatus === "finished"}
                onChange={handleRadioChange}
                className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
              />
              <span className="text-gray-800">Finished</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="radio"
                value="canceled"
                checked={selectedStatus === "canceled"}
                onChange={handleRadioChange}
                className="h-4 w-4 text-red-600 border-gray-300 focus:ring-red-500"
              />
              <span className="text-gray-800">Canceled</span>
            </label>
          </div>
        </div>

        <div className="flex justify-end items-center space-x-4 mt-6">
          <button
            onClick={() => setStatusModal(false)}
            className="px-4 py-2 rounded-lg bg-gray-200 text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirmClick}
            className="px-6 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cabshistory;
