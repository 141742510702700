import React, { useState, useEffect } from "react";
import axios from "axios";
import SimpleBanner from "../../components/common/SimpleBanner";
import HeadBanner from "../../Assets/Common/HotelHeader.png";
import { BiSearchAlt } from "react-icons/bi";
import { FiFilter } from "react-icons/fi";
import AllHotelsList from "../../components/HotelsUserSide/AllHotelsList";
import HotelFilters from "../../components/HotelsUserSide/HotelFilters";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { MdOutlineFilterAltOff } from "react-icons/md";

const ITEMS_PER_PAGE = 4;

const HotelsList = () => {
  const [hotelList, setHotelList] = useState([]);
  const [filteredHotels, setFilteredHotels] = useState([]);
  const [selectedPrices, setSelectedPrices] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedRatings, setSelectedRatings] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [likedItems, setLikedItems] = useState({});
  const [availableCities, setAvailableCities] = useState([]);
  const [availableStates, setAvailableStates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllHotels();
  }, []);

  const getAllHotels = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/hotels/get-all-hotels`)
      .then((response) => {
        setHotelList(response?.data);
        setFilteredHotels(response?.data);

        // Extracting unique cities and states
        const cities = [
          ...new Set(response?.data?.map((hotel) => hotel?.city)),
        ];
        const states = [
          ...new Set(response?.data?.map((hotel) => hotel?.state)),
        ];
        setAvailableCities(cities);
        setAvailableStates(states);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const toggleHeart = (index) => {
    setLikedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const applyFilters = () => {
    let filtered = hotelList;

    // Filter by price
    if (selectedPrices.length > 0) {
      filtered = filtered?.filter((hotel) => {
        const [min, max] = selectedPrices[0].split("-").map(Number);
        return hotel?.basePrice >= min && (!max || hotel?.basePrice <= max);
      });
    }

    // Filter by type
    if (selectedTypes.length > 0) {
      filtered = filtered?.filter((hotel) =>
        selectedTypes?.includes(hotel?.type)
      );
    }

    // Filter by ratings
    if (selectedRatings?.length > 0) {
      filtered = filtered?.filter((hotel) =>
        selectedRatings?.includes(Math?.floor(hotel?.rating))
      );
    }

    // Filter by city
    if (selectedCity) {
      filtered = filtered?.filter((hotel) =>
        hotel?.city?.toLowerCase()?.includes(selectedCity?.toLowerCase())
      );
    }

    // Filter by state
    if (selectedState) {
      filtered = filtered?.filter((hotel) =>
        hotel?.state?.toLowerCase()?.includes(selectedState?.toLowerCase())
      );
    }

    // Filter by search input
    if (searchText) {
      filtered = filtered?.filter((hotel) =>
        hotel?.name?.toLowerCase()?.includes(searchText?.toLowerCase())
      );
    }

    setFilteredHotels(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [
    selectedPrices,
    selectedTypes,
    selectedRatings,
    searchText,
    selectedCity,
    selectedState,
  ]);

  const hotelTypes = [
    { standard: "standard", luxury: "luxury", delux: "deluxe" },
  ];

  const handlePriceChange = (price) => {
    setSelectedPrices((prev) => {
      if (prev?.includes(price)) {
        return prev?.filter((item) => item !== price);
      } else {
        return [...prev, price];
      }
    });
  };

  const handleTypeChange = (type) => {
    setSelectedTypes((prev) => {
      if (prev?.includes(type)) {
        return prev?.filter((item) => item !== type);
      } else {
        return [...prev, type];
      }
    });
  };

  const handleRatingChange = (rating) => {
    setSelectedRatings((prev) => {
      if (prev?.includes(rating)) {
        return prev?.filter((item) => item !== rating);
      } else {
        return [...prev, rating];
      }
    });
  };

  // Calculate paginated hotels
  const totalPages = Math.ceil(filteredHotels.length / ITEMS_PER_PAGE);
  const hotelsToDisplay = filteredHotels.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
    console.log(value);
  };

  const clearFilters = () => {
    setSelectedPrices([]);
    setSelectedTypes([]);
    setSelectedRatings([]);
    setSearchText("");
    setSelectedCity("");
    setSelectedState("");
    setFilteredHotels(hotelList);
  };

  return (
    <React.Fragment>
      <div className="bg-white font-be-vietnam">
        <SimpleBanner
          image={HeadBanner}
          name="Hotel Booking"
          path="WB Group / Hotel Booking"
        />

        {isLoading ? (
          <div>
            {Array?.from({ length: 4 })?.map((_, index) => (
              <SkeletonLoader key={index} />
            ))}
          </div>
        ) : (
          <div className="lg:grid lg:grid-cols-4 gap-4 xl:px-16 lg:px-8 md:px-9 px-4">
            <HotelFilters
              selectedPrices={selectedPrices}
              handlePriceChange={handlePriceChange}
              hotelTypes={hotelTypes}
              selectedTypes={selectedTypes}
              handleTypeChange={handleTypeChange}
              selectedRatings={selectedRatings}
              handleRatingChange={handleRatingChange}
              setSelectedPrices={setSelectedPrices}
              setSelectedTypes={setSelectedTypes}
              setSelectedRatings={setSelectedRatings}
            />

            <div className="col-span-3">
              <div className="block md:flex items-center md:space-x-4 mb-6 space-y-2 md:space-y-0">
                {/* Search Input */}
                <div className="flex items-center border border-[#C1C1C1] rounded-md px-3 py-2 w-full focus-within:border-[#95caed]">
                  <input
                    type="text"
                    className="w-full outline-none text-xs md:text-base text-[#606060]"
                    placeholder="Search by name"
                    onChange={(e) => setSearchText(e?.target?.value)}
                  />
                  <BiSearchAlt className="text-[#606060] text-lg" />
                </div>

                <div className="flex gap-4 justify-between sm:flex-row flex-col">
                  {/* Filter for State */}
                  <div className="flex grow items-center border border-[#C1C1C1] rounded-md px-3 py-2">
                    <FiFilter className="text-[#606060] text-xs md:text-base mr-2" />
                    <select
                      className="outline-none bg-transparent text-xs md:text-base text-[#606060] w-full sm:w-auto"
                      value={selectedState}
                      onChange={(e) => setSelectedState(e?.target?.value)}
                    >
                      <option value="">State</option>
                      {availableStates?.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Filter for City */}
                  <div className="flex grow items-center border border-[#C1C1C1] rounded-md px-3 py-2">
                    <FiFilter className="text-[#606060] text-xs md:text-base mr-2" />
                    <select
                      className="outline-none bg-transparent text-xs md:text-base text-[#606060] w-full sm:w-auto"
                      value={selectedCity}
                      onChange={(e) => setSelectedCity(e?.target?.value)}
                    >
                      <option value="">City</option>
                      {availableCities?.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>

                  <button
                    onClick={clearFilters}
                    className="flex justify-center items-center gap-2 min-w-40 text-sm font-medium bg-slate-300 hover:bg-slate-500 hover:text-white transition duration-200 tracking-wide rounded-md px-3 py-2"
                  >
                    <MdOutlineFilterAltOff size={20} />
                    Clear Filters
                  </button>
                </div>
              </div>

              <div>
                <AllHotelsList
                  filteredHotels={hotelsToDisplay}
                  likedItems={likedItems}
                  toggleHeart={toggleHeart}
                />
              </div>

              {/* Pagination for 4 items per page */}
              <div className="flex justify-end items-center mt-6 ">
                <Stack spacing={2}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    variant="outlined"
                    color="primary"
                    onChange={handlePageChange}
                  />
                </Stack>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default HotelsList;

const SkeletonLoader = () => {
  return (
    <>
      <div className="flex justify-between items-center gap-3  bg-gray-300 h-auto rounded-lg shadow-md  animate-pulse md:px-14 px-4">
        <div className=" w-[20%] rounded-lg hidden lg:block">
          <div>
            <div className="h-6 w-[60%] bg-gray-400 "></div>
            <div className="h-4 w-[70%] bg-gray-400 mt-4"></div>
            <div className="h-3 w-[40%] bg-gray-400 mt-2"></div>
            <div className="h-3 w-[40%] bg-gray-400 mt-2"></div>
            <div className="h-3 w-[40%] bg-gray-400 mt-2"></div>
          </div>
        </div>
        <div className="bg-gray-300 lg:w-[80%] w-full h-auto rounded-lg shadow-md  animate-pulse  gap-3 ">
          <div className="p-3 flex justify-between items-center gap-5 md:flex-row flex-col">
            <div className="bg-gray-400 h-full md:w-[25%] w-full  ">
              <div className="bg-gray-400 h-[150px] rounded-lg md:w-auto  ">
                {" "}
              </div>
            </div>
            <div className="h-auto md:w-[50%] w-full">
              <div className="h-6 w-[40%] bg-gray-400  mt-3"></div>
              <div className="h-3 w-[60%] bg-gray-400 mt-3"></div>
              <div className="h-3 mt-6 w-[20%] bg-gray-400"></div>
              <div className="h-3 w-full bg-gray-400 mt-3"></div>
              <div className="h-3 w-[70%] bg-gray-400 mt-3"></div>
            </div>
            <div className="h-auto md:w-[25%] w-full flex flex-col md:justify-end md:items-end justify-start items-start">
              <div className="h-6 w-full flex justify-between items-center gap-3">
                <div className="h-4 w-[70%] bg-gray-400"></div>
                <div className="h-6 w-[20%] bg-gray-400"></div>
              </div>
              <div className="mt-4 flex justify-end items-center gap-1 ">
                <div className="h-5 w-5 rounded-full bg-gray-400"></div>
                <div className="h-5 w-5 rounded-full bg-gray-400"></div>
                <div className="h-5 w-5 rounded-full bg-gray-400"></div>
                <div className="h-5 w-5 rounded-full bg-gray-400"></div>
              </div>
              <div className="h-4 w-[50%] bg-gray-400 mt-4"></div>

              <div className="h-3 w-full bg-gray-400 mt-3"></div>
              <div className="h-7 w-[60%] bg-gray-400 mt-4"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
