import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaArrowRight } from "react-icons/fa";
import { MdArrowBackIos } from "react-icons/md";
import RoomsList from "../../components/HotelsAdmin/RoomsList";
import { PiWarningCircleDuotone } from "react-icons/pi";

const HotelDetailsAdmin = () => {
  const { id } = useParams();
  const [hotelData, setHotelData] = useState(null);
  const navigate = useNavigate();
  const [deleteLoader, setDeleteLoader] = useState(false);
  // const [selectMultiple, setSelectMultiple] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [hotelID, setHotelID] = useState("");

  useEffect(() => {
    hotelsDataById();
  }, []);

  // console.log("hotelID hotelID hotelID", hotelID);

  const handleDelete = (htls_id) => {
    let config = {
      method: "delete",
      maxBodyLength: 7,
      url: `${process.env.REACT_APP_API_URL}/hotels/delete-hotel/${htls_id}`,
      headers: {},
      data: "",
    };

    setDeleteLoader(true);
    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        setDeleteLoader(false);
        navigate("/hotel");
      })
      .catch((error) => {
        setDeleteLoader(false);
        console.log("delete Err", error);
      });
    // }
  };

  const openModal = (id) => {
    setHotelID(id);
    setShowModal(true);
  };

  const hotelsDataById = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/hotels/hotel-by-id/${id}`,
      headers: {},
      data: "",
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response?.data));
        setHotelData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (!hotelData) {
    return (
      <div className="p-8 font-be-vietnam mb-5 ">
        <div className="flex justify-between sm:items-center items-start flex-col sm:flex-row animate-pulse">
          <div className="bg-gray-400 py-5 w-4/5 md:w-1/2 mb-3"></div>
          <div className="flex justify-center items-center gap-4 text-md">
            <div className="bg-gray-400 py-5 px-10 rounded-md"></div>
            <div className="bg-gray-400 py-5 px-10 rounded-md"></div>
          </div>
        </div>

        <div className="flex flex-col mt-3 animate-pulse">
          <div className="flex justify-between items-center sm:flex-row flex-col gap-3 sm:gap-0 h-[25%] ">
            <div className="xl:h-[200px] xl:w-[250px] lg:h-[120px] lg:w-[180px] sm:h-[150px] sm:w-[170px] h-[200px] w-full  rounded-lg bg-gray-400"></div>
            <div className="xl:h-[200px] xl:w-[250px] lg:h-[120px] lg:w-[180px] sm:h-[150px] sm:w-[170px] h-[200px] w-full  rounded-lg bg-gray-400"></div>
            <div className="xl:h-[200px] xl:w-[250px] lg:h-[120px] lg:w-[180px] sm:h-[150px] sm:w-[170px] h-[200px] w-full  rounded-lg bg-gray-400"></div>
            <div className="xl:h-[200px] xl:w-[250px] lg:h-[120px] lg:w-[180px] sm:h-[150px] sm:w-[170px] h-[200px] w-full  rounded-lg bg-gray-400"></div>
          </div>
          <div className="flex justify-center gap-3 items-center sm:flex-row flex-col h-[25%] mt-3">
            <div className="xl:h-[200px] xl:w-[250px] lg:h-[120px] lg:w-[180px] sm:h-[150px] sm:w-[170px] h-[200px] w-full  rounded-lg bg-gray-400"></div>
            <div className="xl:h-[200px] xl:w-[250px] lg:h-[120px] lg:w-[180px] sm:h-[150px] sm:w-[170px] h-[200px] w-full  rounded-lg bg-gray-400"></div>
            <div className="xl:h-[200px] xl:w-[250px] lg:h-[120px] lg:w-[180px] sm:h-[150px] sm:w-[170px] h-[200px] w-full  rounded-lg bg-gray-400"></div>
          </div>
        </div>

        <div className="mt-4 animate-pulse">
          <div className="bg-gray-400 py-4 w-1/2 md:w-1/3 mb-3"></div>
          <div className="flex justify-between items-start flex-col sm:flex-row text-md mt-3 ">
            <div className="flex justify-start items-center gap-2 bg-gray-400 py-3 px-16 rounded-md"></div>
            <div className="flex justify-start items-center gap-2 bg-gray-400 py-3 px-16 rounded-md"></div>
            <div className="flex justify-start items-center gap-2 bg-gray-400 py-3 px-16 rounded-md"></div>
            <div className="flex justify-start items-center gap-2 bg-gray-400 py-3 px-16 rounded-md"></div>
            <div className="flex justify-start items-center gap-2 bg-gray-400 py-3 px-16 rounded-md"></div>
            <div className="flex justify-start items-center gap-2 bg-gray-400 py-3 px-16 rounded-md"></div>
          </div>
        </div>

        <div className="mt-4 animate-pulse">
          <div className="bg-gray-400 py-4 w-1/2 md:w-1/3 mb-3"></div>
          <div className="bg-gray-400 py-3 mb-3"></div>
        </div>

        <div className="mt-4 animate-pulse">
          <div className="bg-gray-400 py-4 w-1/2 md:w-1/3 mb-3"></div>
          <div className="bg-gray-400 py-3 mb-3"></div>
        </div>

        <div className="flex justify-between items-center mt-3 sm:flex-row flex-col p-4 bg-slate-100 rounded-lg animate-pulse gap-10">
          <div className="flex flex-col justify-center items-start gap-2 text-sm w-full md:w-1/2 ">
            <div className="bg-gray-400 py-3 w-3/5"></div>
            <div className="bg-gray-400 py-3 w-3/4"></div>
            <div className="bg-gray-400 py-3 w-4/5"></div>
            <div className="bg-gray-400 py-3 w-4/5"></div>
            <div className="bg-gray-400 py-3 w-2/3"></div>
            <div className="bg-gray-400 py-3 w-1/2"></div>
            <div className="bg-gray-400 py-3 w-4/5"></div>
          </div>
          <div className="flex flex-col justify-center items-start gap-2 text-sm w-full md:w-1/2 mt-3 md:mt-0 ">
            <div className="bg-gray-400 py-3 w-3/5"></div>
            <div className="bg-gray-400 py-3 w-2/3"></div>
            <div className="bg-gray-400 py-3 w-4/5"></div>
            <div className="bg-gray-400 py-3 w-2/3"></div>
            <div className="bg-gray-400 py-3 w-2/3"></div>
            <div className="bg-gray-400 py-3 w-1/3"></div>
            <div className="bg-gray-400 py-3 w-1/2"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-8 font-be-vietnam">
      <div className="mb-5">
        <div className="flex justify-between sm:items-center items-start flex-col sm:flex-row ">
          <div className="flex justify-center items-center gap-2">
            <MdArrowBackIos
              className="cursor-pointer"
              onClick={() => navigate(-1)}
              size={24}
            />
            <h1 className="text-2xl font-semibold ">{hotelData?.name}</h1>
          </div>
          <div className="flex justify-center items-center gap-4 text-[15px]">
            <button
              onClick={() => navigate(`/edit-hotel/${hotelData?._id}`)}
              className="bg-gray-300 text-black py-2 px-5 rounded-md"
            >
              Edit
            </button>

            <button
              className="bg-gradient-to-r from-yellow-400 to-orange-500 text-white py-2 px-5 rounded-md"
              // onClick={() => handleDelete(hotelData?._id)}
              onClick={() => {
                openModal(hotelData?._id);
              }}
            >
              Delete
            </button>
          </div>
        </div>

        <div className="flex flex-col mt-3">
          {hotelData?.images?.length > 0 && (
            <>
              <div className="flex justify-between items-center sm:flex-row flex-col gap-3 sm:gap-0 h-[25%] ">
                <img
                  src={hotelData?.images[0]}
                  alt=""
                  className="xl:h-[200px] xl:w-[250px] lg:h-[120px] lg:w-[180px] sm:h-[150px] sm:w-[170px] h-[200px] w-full  rounded-lg"
                />
                <img
                  src={hotelData?.images[1]}
                  alt=""
                  className="xl:h-[200px] xl:w-[250px] lg:h-[120px] lg:w-[180px] sm:h-[150px] sm:w-[170px] h-[200px] w-full rounded-lg"
                />
                <img
                  src={hotelData?.images[2]}
                  alt=""
                  className="xl:h-[200px] xl:w-[250px] lg:h-[120px] lg:w-[180px] sm:h-[150px] sm:w-[170px] h-[200px] w-full rounded-lg"
                />
                <img
                  src={hotelData?.images[3]}
                  alt=""
                  className="xl:h-[200px] xl:w-[250px] lg:h-[120px] lg:w-[180px] sm:h-[150px] sm:w-[170px] h-[200px] w-full rounded-lg"
                />
              </div>
              <div className="flex justify-center gap-3 items-center sm:flex-row flex-col h-[25%] mt-3">
                <img
                  src={hotelData?.images[4]}
                  alt=""
                  className="xl:h-[200px] xl:w-[250px] lg:h-[120px] lg:w-[180px] sm:h-[150px] sm:w-[170px] h-[200px] w-full rounded-lg"
                />
                <img
                  src={hotelData?.images[5]}
                  alt=""
                  className="xl:h-[200px] xl:w-[250px] lg:h-[120px] lg:w-[180px] sm:h-[150px] sm:w-[170px] h-[200px] w-full rounded-lg"
                />
                <img
                  src={hotelData?.images[6]}
                  alt=""
                  className="xl:h-[200px] xl:w-[250px] lg:h-[120px] lg:w-[180px] sm:h-[150px] sm:w-[170px] h-[200px] w-full rounded-lg"
                />
              </div>
            </>
          )}
        </div>
        <div className="mt-3">
          <h1 className="text-xl font-bold">Amenities</h1>
          <div className="flex justify-between items-start flex-col sm:flex-row tracking-wide text-[15px] mt-3 ">
            <p className="flex justify-start items-center gap-2">
              <FaArrowRight className="text-yellow-400" />
              <span>{hotelData?.amenities[0]}</span>
            </p>
            <p className="flex justify-start items-center gap-2">
              <FaArrowRight className="text-red-400" />
              <span>{hotelData?.amenities[1]}</span>
            </p>
            <p className="flex justify-start items-center gap-2">
              <FaArrowRight className="text-purple-400" />
              <span>{hotelData?.amenities[2]}</span>
            </p>
            <p className="flex justify-start items-center gap-2">
              <FaArrowRight className="text-orange-400" />
              <span>{hotelData?.amenities[3]}</span>
            </p>
            <p className="flex justify-start items-center gap-2">
              <FaArrowRight className="text-green-400" />
              <span>{hotelData?.amenities[4]}</span>
            </p>
            <p className="flex justify-start items-center gap-2">
              <FaArrowRight className="text-blue-400" />
              <span>{hotelData?.amenities[5]}</span>
            </p>
          </div>
        </div>
        <div className="mt-3">
          <h1 className="text-xl font-bold ">Description</h1>
          <div className="flex justify-start items-center text-[16px] mt-3 ">
            <p className="text-justify tracking-wide leading-relaxed">
              {hotelData?.description}
            </p>
          </div>
        </div>
        <div className="mt-3">
          <h1 className="text-xl font-bold">Cancellation Policy</h1>
          <div className="flex justify-start items-center text-md mt-3 ">
            <p className="tracking-wide text-[15px]">
              {hotelData?.cancellationPolicy}
            </p>
          </div>
        </div>

        <div className=" bg-[#f1f0ed] rounded-lg p-4 mt-4 shadow-md">
          <h1 className="text-xl tracking-wide font-semibold  ">
            Additional information
          </h1>
          <div className="flex justify-between items-start  sm:flex-row flex-col  text-justify tracking-wide text-[15px] mt-3">
            <div className="flex flex-col justify-center items-start gap-2   ">
              <p>
                <span className="font-bold ">Phone:</span>
                <span>{hotelData?.phone}</span>
              </p>
              <p>
                <span className="font-bold ">Email:</span>{" "}
                <span>{hotelData?.email}</span>
              </p>
              <p>
                <span className="font-bold ">State:</span>{" "}
                <span>{hotelData?.state}</span>
              </p>
              <p>
                <span className="font-bold ">Country:</span>{" "}
                <span>{hotelData?.country}</span>
              </p>
              <p>
                <span className="font-bold ">Address:</span>{" "}
                <span>{hotelData?.address}</span>
              </p>
              <p>
                <span className="font-bold ">City:</span>{" "}
                <span>{hotelData?.city}</span>
              </p>

              <p>
                <span className="font-bold ">Zip code:</span>{" "}
                <span>{hotelData?.zipcode}</span>
              </p>
            </div>
            <div className="flex flex-col items-start gap-2 h-full w-auto  ">
              <p>
                <span className="font-bold ">Price:</span>{" "}
                <span>{hotelData?.basePrice}</span>
              </p>
              <p>
                <span className="font-bold ">Rating:</span>{" "}
                <span>{hotelData?.rating}</span>
              </p>
              <p>
                <span className="font-bold ">Type:</span>{" "}
                <span>{hotelData?.type}</span>
              </p>
              <p>
                <span className="font-bold ">CheckInTime:</span>{" "}
                <span>{hotelData?.checkInTime}</span>
              </p>
              <p>
                <span className="font-bold ">CheckOutTime:</span>{" "}
                <span>{hotelData?.checkOutTime}</span>
              </p>
            </div>
          </div>
        </div>

        <RoomsList
          hotelData={hotelData}
          refreshRooms={hotelsDataById}
          htlName={hotelData?.name}
        />
      </div>
      {showModal && (
        <Modal
          setShowModal={setShowModal}
          handleDelete={handleDelete}
          hotelID={hotelID}
          htlName={hotelData?.name}
          deleteLoader={deleteLoader}
        />
      )}
    </div>
  );
};

export default HotelDetailsAdmin;

const Modal = ({
  setShowModal,
  handleDelete,
  hotelID,
  htlName,
  deleteLoader,
}) => (
  <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center font-be-vietnam">
    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-xs">
      <div className="flex justify-center text-red-700 mb-4">
        <PiWarningCircleDuotone size={60} />
      </div>

      <p className="text-center tracking-wide text-lg">
        Are you sure to delete the
        <br /> <span className="font-bold text-orange-600">{htlName}</span>?
      </p>

      <div className="flex tracking-wide justify-center items-center space-x-4 mt-5">
        <button
          onClick={() => setShowModal(false)}
          className="py-2 px-4 text-gray-800 border border-gray-300 rounded-lg hover:bg-gray-200"
        >
          Cancel
        </button>
        <button
          className="py-2 px-4 text-white bg-orange-500 rounded-lg hover:bg-orange-700"
          onClick={() => handleDelete(hotelID)}
        >
          {deleteLoader ? "Deleteing.." : "Yes, Delete"}
        </button>
      </div>
    </div>
  </div>
);
