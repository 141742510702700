import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaEdit, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import roomImg from "../../Assets/user-imgs/Home-imgs/interior-design.png";
import toast from "react-hot-toast";
import { PiWarningCircleDuotone } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { Import } from "lucide-react";
import RoomDetails from "./RoomDetails";

const RoomsList = ({ hotelData, refreshRooms, htlName }) => {
  const [showModal, setShowModal] = useState(false);
  const [roomData, setRoomData] = useState({});
  const [assignRoomLoading, setAssignRoomLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [roomID, setRoomID] = useState("");
  const [hotelName, setHotelName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setHotelName(htlName);
  });

  // Handle Edit Button Click
  const handleEditClick = (room) => {
    setRoomData(room); // Pre-fill modal with room data
    setShowModal(true); // Open the modal
  };

  // Handle API Call to Edit Room
  const handleEditRoom = async () => {
    setAssignRoomLoading(true);

    try {
      const config = {
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/rooms/update-room/${roomData._id}`,
        headers: { "Content-Type": "application/json" },
        data: roomData,
      };

      const response = await axios.request(config);
      //   console.log("Room Updated:", response.data);
      refreshRooms();
      setShowModal(false);
      refreshRooms();
    } catch (error) {
      console.error("Error updating room:", error);
    } finally {
      setAssignRoomLoading(false);
    }
  };

  const handleDelete = (id) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/rooms/delete-room/${id}`,
      headers: {},
      data: "",
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        toast.success("Room Successfully Deleted!");
        refreshRooms();
        setDeleteModal(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openModal = (id) => {
    setRoomID(id);
    setDeleteModal(true);
  };

  const handleNavigate = (id, title) => {
    navigate(`/room-details/${id}/${title}`);
  };

  return (
    <div className="mt-8 px-4">
      <p className="text-3xl font-extrabold text-gray-800 text-center mb-8">
        Available Rooms
      </p>
      {hotelData?.rooms && hotelData.rooms.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {hotelData.rooms.map((room) => (
            <div
              key={room.roomNo}
              className="bg-white shadow-md rounded-lg hover:shadow-lg transition-shadow duration-300 overflow-hidden"
            >
              <div className="flex flex-col items-center p-4">
                <div className="mb-2">
                  <img
                    src={roomImg}
                    alt="Room"
                    className="w-16 h-16 object-cover rounded-md shadow-sm"
                  />
                </div>
                <div className="text-center tracking-wide text-lg capitalize font-semibold text-gray-800 mb-2">
                  {room.name || "Room"}
                </div>

                {/* Room Details: Type, Bed Count, Price */}
                <div className="bg-[#f5f4f3] rounded-md p-2 space-y-1 w-full mb-2 text-xs tracking-wide text-gray-60">
                  <div className="flex justify-between w-full">
                    <div className="">Type</div>
                    <div>{room.type || "N/A"}</div>
                  </div>

                  <div className="flex justify-between w-full">
                    <div className="">Beds</div>
                    <div>{room.bedCount || "N/A"}</div>
                  </div>

                  <div className="flex justify-between w-full">
                    <div className="">Price</div>
                    <div>₹ {room.price || "N/A"}</div>
                  </div>
                </div>

                <div className="text-center tracking-wide text-xs font-normal text-gray-500">
                  {room.description?.length > 60
                    ? room.description.substring(0, 60) + "..."
                    : room.description}
                </div>
              </div>

              <div className="bg-gray-100 px-4 py-2 flex justify-center gap-8 items-center">
                <div
                  className="flex items-center justify-center w-8 h-8 bg-green-100 text-green-600 rounded-full cursor-pointer hover:bg-green-200 hover:scale-110 transition-all duration-300 shadow-md hover:shadow-lg"
                  onClick={() => handleEditClick(room)}
                >
                  <FaEdit size={16} />
                </div>
                <div
                  className="flex items-center justify-center w-8 h-8 bg-orange-100 text-orange-600 rounded-full cursor-pointer hover:bg-orange-200 hover:scale-110 transition-all duration-300 shadow-md hover:shadow-lg"
                  onClick={() => handleNavigate(room?._id, hotelData?.name)}
                >
                  <FaEye size={16} />
                </div>
                <div
                  onClick={() => {
                    setDeleteModal(true);
                    openModal(room?._id);
                  }}
                  className="flex items-center justify-center w-8 h-8 bg-red-100 text-red-600 rounded-full cursor-pointer hover:bg-red-200 hover:scale-110 transition-all duration-300 shadow-md hover:shadow-lg"
                >
                  <MdDelete size={16} />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="mt-6 text-center">
          <p className="text-lg font-medium text-gray-600">
            No rooms available at the moment.
          </p>
        </div>
      )}
      {showModal && (
        <EditModal
          showModal={showModal}
          setShowModal={setShowModal}
          roomData={roomData}
          setRoomData={setRoomData}
          handleCreateRoom={handleEditRoom}
          assignRoomLoading={assignRoomLoading}
        />
      )}
      {deleteModal && (
        <DelModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          roomData={roomData}
          handleDelete={handleDelete}
          openModal={openModal}
          roomID={roomID}
        />
      )}
    </div>
  );
};

const EditModal = ({
  setShowModal,
  roomData,
  setRoomData,
  handleCreateRoom,
  assignRoomLoading,
}) => (
  <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Edit Room</h2>
      <input
        type="text"
        name="name"
        value={roomData.name}
        onChange={(e) => setRoomData({ ...roomData, name: e.target.value })}
        className="w-full p-2 border border-gray-300 rounded-md mb-4"
        placeholder="Room Name"
      />
      <input
        type="text"
        name="price"
        value={roomData.price}
        onChange={(e) => setRoomData({ ...roomData, price: e.target.value })}
        className="w-full p-2 border border-gray-300 rounded-md mb-4"
        placeholder="Price"
      />
      <input
        type="number"
        min={1}
        name="bedCount"
        value={roomData.bedCount}
        onChange={(e) => setRoomData({ ...roomData, bedCount: e.target.value })}
        className="w-full p-2 border border-gray-300 rounded-md mb-4"
        placeholder="Bed Count"
      />
      <select
        name="roomType"
        value={roomData.type}
        onChange={(e) => setRoomData({ ...roomData, roomType: e.target.value })}
        className="w-full p-2 border border-gray-300 rounded-md mb-4"
      >
        <option value="Single">Single</option>
        <option value="Double">Double</option>
        <option value="Suite">Suite</option>
      </select>
      <textarea
        name="description"
        value={roomData.description}
        onChange={(e) =>
          setRoomData({ ...roomData, description: e.target.value })
        }
        className="w-full p-2 border border-gray-300 rounded-md mb-4"
        placeholder="Description"
      />
      <div className="flex justify-end space-x-4">
        <button
          onClick={() => setShowModal(false)}
          className="py-2 px-4 text-gray-800 border border-gray-300 rounded-lg hover:bg-gray-200"
        >
          Cancel
        </button>
        <button
          onClick={handleCreateRoom}
          className="py-2 px-4 text-white bg-orange-500 rounded-lg hover:bg-orange-700"
        >
          {assignRoomLoading ? "Please Wait..." : "Save"}
        </button>
      </div>
    </div>
  </div>
);

const DelModal = ({
  setDeleteModal,

  handleDelete,
  roomID,
}) => (
  <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center font-be-vietnam">
    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-xs">
      <div className="flex justify-center text-red-700 mb-4">
        <PiWarningCircleDuotone size={60} />
      </div>

      <p className="text-center tracking-wide text-lg">
        Are you sure to delete this Room?
      </p>

      <div className="flex tracking-wide justify-center items-center space-x-4 mt-5">
        <button
          onClick={() => setDeleteModal(false)}
          className="py-2 px-4 text-gray-800 border border-gray-300 rounded-lg hover:bg-gray-200"
        >
          Cancel
        </button>
        <button
          className="py-2 px-4 text-white bg-orange-500 rounded-lg hover:bg-orange-700"
          onClick={() => handleDelete(roomID)}
        >
          Yes, Delete
        </button>
      </div>
    </div>
  </div>
);

export default RoomsList;

// import React from "react";
// import { FaEdit, FaTrash, FaEye } from "react-icons/fa"; // Font Awesome icons
// import roomImg from "../../Assets/user-imgs/Home-imgs/interior-design.png";
// import { MdDelete, MdDeleteSweep } from "react-icons/md";

// const RoomsList = ({ hotelData }) => {
//     return (
//         <div className="mt-8 px-4">
//             <p className="text-3xl font-extrabold text-gray-800 text-center mb-8">
//                 Available Rooms
//             </p>
//             {hotelData?.rooms && hotelData.rooms.length > 0 ? (
//                 <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
//                     {hotelData.rooms.map((room) => (
//                         <div
//                             key={room.roomNo}
//                             className="bg-white shadow-md rounded-lg hover:shadow-lg transition-shadow duration-300 overflow-hidden"
//                         >
//                             <div className="flex flex-col items-center p-6">
//                                 {/* Room Image */}
//                                 <div className="mb-4">
//                                     <img
//                                         src={roomImg}
//                                         alt="Room"
//                                         className="w-20 h-20 object-cover rounded-md shadow-sm"
//                                     />
//                                 </div>
//                                 {/* Room Details */}
//                                 <div className="text-center tracking-wide">
//                                     <p className="text-xl capitalize font-semibold text-gray-800 mb-2">
//                                         {room?.name || "Room"}
//                                     </p>
//                                     <p className="text-sm text-gray-500">
//                                         {room?.description?.length > 60
//                                             ? room?.description.substring(0, 60) + "..."
//                                             : room.description}
//                                     </p>
//                                 </div>
//                             </div>

//                             <div className="bg-gray-100 px-4 py-2 flex justify-center gap-8 items-center">
//                                 <div
//                                     className="flex items-center justify-center w-8 h-8 bg-green-100 text-green-600 rounded-full cursor-pointer hover:bg-green-200 hover:scale-110 transition-all duration-300 shadow-md hover:shadow-lg"
//                                 // onClick={() => navigate(`/updateTours/${tour._id}`)}
//                                 >
//                                     <FaEdit size={16} />
//                                 </div>

//                                 <div
//                                     className="flex items-center justify-center w-8 h-8 bg-orange-100 text-orange-600 rounded-full cursor-pointer hover:bg-orange-200 hover:scale-110 transition-all duration-300 shadow-md hover:shadow-lg"
//                                 // onClick={() => handleTrendUp(tour._id)}
//                                 >
//                                     <FaEye size={16} />
//                                 </div>

//                                 <div
//                                     className="flex items-center justify-center w-8 h-8 bg-red-100 text-red-600 rounded-full cursor-pointer hover:bg-red-200 hover:scale-110 transition-all duration-300 shadow-md hover:shadow-lg"
//                                 // onClick={() => handleDelete(tour._id)}
//                                 >
//                                     <MdDelete size={16} />
//                                 </div>
//                             </div>

//                         </div>
//                     ))}
//                 </div>
//             ) : (
//                 <div className="mt-6 text-center">
//                     <p className="text-lg font-medium text-gray-600">
//                         No rooms available at the moment.
//                     </p>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default RoomsList;

// const EditModal = ({
//     showModal,
//     setShowModal,
//     roomData,
//     setRoomData,
//     handleCreateRoom,
//     assignRoomLoading,
//   }) => (
//     <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
//       <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
//         <h2 className="text-2xl font-semibold mb-4 text-gray-800">Edit Room</h2>
//         <input
//           type="text"
//           name="name"
//           value={roomData.name}
//           onChange={(e) => setRoomData({ ...roomData, name: e.target.value })}
//           className="w-full p-2 border border-gray-300 rounded-md mb-4"
//           placeholder="Room Name"
//         />
//         <input
//           type="text"
//           name="price"
//           value={roomData.price}
//           onChange={(e) => setRoomData({ ...roomData, price: e.target.value })}
//           className="w-full p-2 border border-gray-300 rounded-md mb-4"
//           placeholder="Price"
//         />
//         <input
//           type="number"
//           min={1}
//           name="bedCount"
//           value={roomData.bedCount}
//           onChange={(e) => setRoomData({ ...roomData, bedCount: e.target.value })}
//           className="w-full p-2 border border-gray-300 rounded-md mb-4"
//           placeholder="Bed Count"
//         />
//         <select
//           name="roomType"
//           value={roomData.roomType}
//           onChange={(e) => setRoomData({ ...roomData, roomType: e.target.value })}
//           className="w-full p-2 border border-gray-300 rounded-md mb-4"
//         >
//           <option value="Single">Single</option>
//           <option value="Double">Double</option>
//           <option value="Suite">Suite</option>
//         </select>
//         <textarea
//           name="description"
//           value={roomData.description}
//           onChange={(e) =>
//             setRoomData({ ...roomData, description: e.target.value })
//           }
//           className="w-full p-2 border border-gray-300 rounded-md mb-4"
//           placeholder="Description"
//         />
//         <div className="flex justify-end space-x-4">
//           <button
//             onClick={() => setShowModal(false)}
//             className="py-2 px-4 text-gray-800 border border-gray-300 rounded-lg hover:bg-gray-200"
//           >
//             Cancel
//           </button>
//           <button
//             onClick={handleCreateRoom}
//             className="py-2 px-4 text-white bg-orange-500 rounded-lg hover:bg-orange-700"
//           >
//             {assignRoomLoading ? "Please Wait..." : "Edit"}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
