import React, { useState, useEffect } from "react";
import UserNavbar from "../../components/common/UserNavbar";
import UserFooter from "../../components/common/UserFooter";
import FirstModal from '../../components/common/FirstModal';
import whatsAppImg from '../../Assets/Common/whatsapp.png';
import ChatImg from '../../Assets/Common/chat.png';
import InfoModal from "../../components/common/InfoModal";
import './UserLayout.css';

const UserLayout = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      console.log("UserLayout mounted 1");
      setIsModalOpen(true);
    }, 3000);
  }, []);

  const MessageButton = () => {
    const openWhatsApp = () => {
      const phoneNumber = "918178958591";
      const whatsappUrl = `https://wa.me/${phoneNumber}`;
      window.open(whatsappUrl, "_blank");
    };

    return (
      <div className="movable-buttons fixed right-4 flex flex-col items-center gap-3 pr-4">
        {/* Chat Icon */}
        <button
          onClick={() => setInfoModalOpen(true)}
          className="transition-transform transform hover:scale-110 focus:outline-none ease-in-out duration-300"
        >
          <img
            src={ChatImg}
            alt="Chat Icon"
            className="w-12 h-12 md:w-12 md:h-12"
          />
        </button>

        {/* WhatsApp Icon */}
        <button
          onClick={openWhatsApp}
          className="transition-transform transform hover:scale-110 focus:outline-none ease-in-out duration-300"
        >
          <img
            src={whatsAppImg}
            alt="Chat Icon"
            className="w-12 h-12 md:w-12 md:h-12"
          />
        </button>
      </div>

    );
  };

  return (
    <React.Fragment>
      <UserNavbar />
      {props.children}
      <UserFooter />

      <MessageButton />

      {
        isModalOpen
        &&
        <FirstModal
          setIsModalOpen={setIsModalOpen}
        />
      }

      {
        infoModalOpen
        &&
        <InfoModal
          setInfoModalOpen={setInfoModalOpen}
        />
      }
    </React.Fragment>
  );
};

export default UserLayout;