import React, { useEffect, useState } from "react";
import axios from "axios";
import rating from "../../../Assets/user-imgs/Home-imgs/rating.svg";
import location from "../../../Assets/user-imgs/Home-imgs/loction.svg";
import duration from "../../../Assets/user-imgs/Home-imgs/days.svg";
import TourSkeletonLoader from "../../homeComponents/TourSkeletonLoader";
import { FaArrowTrendUp } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import PermissionsModal from "./TrendUp"; // Import the modal component
import TrendUp from "./TrendUp";
import { PiWarningCircleDuotone } from "react-icons/pi";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const AllTours = () => {
  const [AllTours, setAllTours] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTourId, setSelectedTourId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [roomID, setRoomID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const totalPages = Math.ceil(AllTours.length / itemsPerPage);
  const displayedTours = AllTours.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePagination = (event, page) => {
    setCurrentPage(page);
  };

  const handleTrendUp = (tourId) => {
    setSelectedTourId(tourId); // Set the tour ID to pass to the modal
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
    setSelectedTourId(null); // Clear the selected tour ID
  };

  const getAllTours = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/new-packages/get-all-new-packages`,
      headers: {},
      data: "",
    };

    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        // console.log("Response", response.data);
        setAllTours(response?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };
  const openModal = (id) => {
    setRoomID(id);
    setDeleteModal(true);
  };

  useEffect(() => {
    getAllTours();
  }, []);

  const handleDelete = (id) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/new-packages/delete-new-package/${id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        toast.success("Successfully Delete Tours!");
        getAllTours();
        setDeleteModal(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      {isLoading ? (
        <TourSkeletonLoader />
      ) : (
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-5">
            {displayedTours.map((tour, index) => (
              <div
                className="bg-white border border-[#e2e4e8] rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl"
                key={index}
              >
                <img
                  src={tour.image_url}
                  alt={tour.name}
                  className="w-full h-72 p-4 object-cover rounded-lg"
                />
                <div className="px-4 pb-4 space-y-4">
                  <h3 className="text-md font-semibold text-gray-900 tracking-wider">
                    {tour.name}
                  </h3>
                  <div className="p-2 flex items-center justify-between tracking-wide text-gray-600 border-t-2 border-b-2 border-gray-200">
                    <div className="flex gap-2 items-center">
                      <img src={location} alt="location" className="w-5 h-5" />
                      <span>{tour.arrival_city}</span>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={duration} alt="duration" className="w-4 h-4" />
                      <span>{tour.tour_duration}</span>
                    </div>
                  </div>
                  <div className="flex justify-around items-center w-full text-center gap-6">
                    <div
                      className="flex flex-col items-center cursor-pointer p-3 rounded-lg transition-all duration-300 hover:bg-orange-100 hover:shadow-md"
                      onClick={() => navigate(`/updateTours/${tour._id}`)}
                    >
                      <FaEdit size={16} color="#ff7951" />
                      <span className="text-xs tracking-wide text-gray-700 mt-1">
                        Edit
                      </span>
                    </div>
                    <div
                      className="flex flex-col items-center cursor-pointer p-3 rounded-lg transition-all duration-300 hover:bg-orange-100 hover:shadow-md"
                      onClick={() => handleTrendUp(tour._id)}
                    >
                      <FaArrowTrendUp size={16} color="#ff7951" />
                      <span className="text-xs tracking-wide text-gray-700 mt-1">
                        Trending
                      </span>
                    </div>
                    <div
                      className="flex flex-col items-center cursor-pointer p-3 rounded-lg transition-all duration-300 hover:bg-red-100 hover:shadow-md"
                      onClick={() => {
                        setDeleteModal(true);
                        openModal(tour._id);
                      }}
                    >
                      <MdDelete size={16} color="#ff4c4c" />
                      <span className="text-xs tracking-wide text-gray-700 mt-1">
                        Delete
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center items-center">
            <Stack spacing={2} className="mt-8 flex justify-center">
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePagination}
                variant="outlined"
                shape="rounded"
                color="primary"
              />
            </Stack>
          </div>

          {/* Render the trending modal if isModalOpen is true */}
          {isModalOpen && (
            <TrendUp
              onClose={handleCloseModal}
              data={{ _id: selectedTourId }}
            />
          )}
        </div>
      )}

      {deleteModal && (
        <DelTourModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          handleDelete={handleDelete}
          openModal={openModal}
          roomID={roomID}
        />
      )}
    </div>
  );
};

const DelTourModal = ({ setDeleteModal, openModal, handleDelete, roomID }) => (
  <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center font-be-vietnam">
    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-xs">
      <div className="flex justify-center text-red-700 mb-4">
        <PiWarningCircleDuotone size={60} />
      </div>

      <p className="text-center tracking-wide text-lg">
        Are you sure to delete this Tour?
      </p>

      <div className="flex tracking-wide justify-center items-center space-x-4 mt-5">
        <button
          onClick={() => setDeleteModal(false)}
          className="py-2 px-4 text-gray-800 border border-gray-300 rounded-lg hover:bg-gray-200"
        >
          Cancel
        </button>
        <button
          className="py-2 px-4 text-white bg-orange-500 rounded-lg hover:bg-orange-700"
          onClick={() => handleDelete(roomID)}
        >
          Yes, Delete
        </button>
      </div>
    </div>
  </div>
);
export default AllTours;
