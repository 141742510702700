import React, { useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { RxStopwatch } from "react-icons/rx";
import { IoFastFoodOutline } from "react-icons/io5";
import { PiSteeringWheelDuotone } from "react-icons/pi";
import { LuCalendarDays } from "react-icons/lu";
import { FaCalendarAlt, FaCameraRetro, FaCheckCircle, FaMapMarkerAlt } from "react-icons/fa";

const Details = ({ tourData }) => {
  const [activeDay, setActiveDay] = useState(null);
  console.log('tourData-tourData', tourData)
  // Toggle function for accordion
  const toggleDay = (index) => {
    setActiveDay(activeDay === index ? null : index);
  };

  const TourDetailsSkeleton = () => (
    <div className="animate-pulse p-4">
      {/* Skeleton Structure for loading states */}
      <div className="bg-gray-200 h-6 w-2/3 mb-4"></div>
      <div className="mb-4">
        <div className="bg-gray-200 h-4 w-1/3 mb-2"></div>
        <div className="bg-gray-200 h-4 w-1/2"></div>
      </div>
    </div>
  );

  return (
    <>
      {tourData ? (
        <React.Fragment>
          {!tourData?._id && <TourDetailsSkeleton />}

          {/* Tour Summary Section */}
          <div className="flex flex-wrap mb-4 tracking-wider">
            {tourData?._id && (
              <>
                <div className="flex items-center w-[180px] pl-2 mt-2 border p-4 rounded-t-md sm:rounded-l-md sm:rounded-t-none">
                  <RxStopwatch size={30} color="#30aadd" />
                  <p className="ml-4 sm:ml-6 font-semibold tracking-wider">
                    {tourData?.tour_duration}
                  </p>
                </div>

                <div className="flex items-center w-[180px] pl-2 mt-2 border p-4 sm:border-l-0">
                  <IoFastFoodOutline size={30} color="#30aadd" />
                  <p className="ml-4 sm:ml-6 font-semibold tracking-wider">
                    {tourData?.meals}
                  </p>
                </div>

                <div className="flex items-center w-[180px] pl-2 mt-2 border p-4 sm:border-l-0">
                  <PiSteeringWheelDuotone size={30} color="#30aadd" />
                  <p className="ml-4 sm:ml-6 font-semibold tracking-wider">
                    {tourData?.travel_by}
                  </p>
                </div>
              </>
            )}
          </div>

          <div className="border rounded-md p-4">
            <h2 className="text-lg font-bold text-[#051836]">{tourData?.name}</h2>
            <p className="text-sm text-[#8592A6] mt-2">{tourData?.description?.join(" ")}</p>

            {/* Tour Route */}
            <div className="flex justify-between items-center my-2">
              <div className="text-md font-semibold mb-2">Tour Route</div>
              <p className="text-sm text-[#8592A6]">{tourData?.tour_route}</p>
            </div>

            {/* Tour Category */}
            <div className="flex justify-between items-center my-2">
              <div className="text-md font-semibold mb-2">
                Tours Category
              </div>
              <p className="text-sm text-[#8592A6] capitalize">
                {tourData?.tours_category}
              </p>
            </div>
            <div className="w-full h-px bg-gray-300 mb-2"></div>

            {/* Arrival / Departure City */}
            <div className="flex justify-between items-center my-2">
              <div className="text-md font-semibold mb-2">Arrival City</div>
              <p className="text-sm text-[#8592A6]">{tourData?.arrival_city}</p>
            </div>
            <div className="w-full h-px bg-gray-300 mb-2"></div>

            <div className="flex justify-between items-center my-2">
              <div className="text-md font-semibold mb-2">Departure Place</div>
              <p className="text-sm text-[#8592A6]">{tourData?.departure_place}</p>
            </div>
            <div className="w-full h-px bg-gray-300 mb-2"></div>

            {/* Departure Date */}
            <div className="flex justify-between items-center my-2">
              <div className="text-md font-semibold mb-2">Departure Date</div>
              <p className="text-sm text-[#8592A6]">{tourData?.arrival_date}</p>
            </div>
            <div className="w-full h-px bg-gray-300 mb-2"></div>

            {/* Things to Carry */}
            {tourData?.things_to_carry && (
              <div className="flex flex-col md:flex-row justify-between items-center mt-4 mb-2 bg-gray-200 rounded-md p-4">
                <div className="text-md font-bold mb-2 w-full md:w-1/3 text-center text-[#ff783e]">
                  Things to carry
                </div>
                <div className="grid grid-cols-2 justify-center font-semibold items-center gap-4 text-md w-1/1">
                  {tourData?.things_to_carry.map((item, index) => (
                    <div key={index} className="flex items-center">
                      <FaCheck color="#30aadd" />
                      <p className="ml-2">{item}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Tour Details on Day Basis */}
            <div className="mt-4">
              {tourData?.tourDetailOnDayBasis?.map((dayDetail, index) => (
                <div key={index} className="mb-4 border-b">
                  <div
                    className="flex justify-between items-center cursor-pointer px-4 md:px-6"
                    onClick={() => toggleDay(index)}
                  >
                    <div className="font-semibold text-base md:text-lg">
                      {`${dayDetail.day}: ${dayDetail.dayHeading}`}
                    </div>
                    <div className="text-sm text-[#ff783e]">
                      {activeDay === index ? "Hide Details" : "View Details"}
                    </div>
                  </div>

                  {activeDay === index && (
                    <div className="mt-4 px-4 md:px-6">
                      {/* Day's Activities */}
                      <div className="bg-[#ff783e] text-white tracking-wider font-semibold w-full p-3 rounded mb-4">
                        <p>{dayDetail?.dayHeading}</p>
                      </div>

                      <div className="text-sm md:text-base text-justify mb-4">
                        {dayDetail?.daywiseDescription}
                      </div>

                      {/* Reporting Location */}
                      {dayDetail?.reporting_location && (
                        <div className="mt-4">
                          <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-2">
                            <div className="text-md font-semibold">Reporting Location</div>
                            <p className="text-sm text-[#8592A6]">{dayDetail?.reporting_location}</p>
                          </div>
                          <div className="w-full h-px bg-gray-300"></div>
                        </div>
                      )}

                      {/* Departure Time */}
                      {dayDetail?.departure_time && (
                        <div className="mt-4">
                          <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-2">
                            <div className="text-md font-semibold">Departure Time</div>
                            <p className="text-sm text-[#8592A6]">{dayDetail?.departure_time}</p>
                          </div>
                          <div className="w-full h-px bg-gray-300"></div>
                        </div>
                      )}

                      {/* Reporting Time */}
                      {dayDetail?.reporting_time && (
                        <div className="mt-4">
                          <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-2">
                            <div className="text-md font-semibold">Reporting Time</div>
                            <p className="text-sm text-[#8592A6]">{dayDetail?.reporting_time}</p>
                          </div>
                          <div className="w-full h-px bg-gray-300"></div>
                        </div>
                      )}

                      {/* Lunch Break */}
                      {dayDetail?.lunch_break && (
                        <div className="mt-4">
                          <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-2">
                            <div className="text-md font-semibold">Lunch Break</div>
                            <p className="text-sm text-[#8592A6]">{dayDetail?.lunch_break}</p>
                          </div>
                          <div className="w-full h-px bg-gray-300"></div>
                        </div>
                      )}

                      {/* How to Reach */}
                      {dayDetail?.how_to_reach && (
                        <div className="mt-4">
                          <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-2">
                            <div className="text-md font-semibold w-full md:w-[70%]">How to Reach</div>
                            <p className="text-sm text-[#8592A6]">{dayDetail?.how_to_reach}</p>
                          </div>
                          <div className="w-full h-px bg-gray-300"></div>
                        </div>
                      )}

                      {dayDetail?.activities && dayDetail?.activities.length > 0 && (
                        <div className="mt-6">
                          <div className="flex items-center space-x-2 mb-4">
                            <FaCalendarAlt size={20} color="#ff783e" />
                            <div className="text-lg font-semibold text-[#2c3e50]">Activities</div>
                          </div>
                          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                            {dayDetail.activities.map((activity, idx) => (
                              <div
                                key={idx}
                                className="bg-white shadow-lg rounded-md p-4 hover:bg-[#f2f8fb] transition duration-300 ease-in-out flex items-center"
                              >
                                <FaCheckCircle className="text-[#2c3e50] mr-2" />
                                <div className="font-semibold tracking-wider text-[#2c3e50]">
                                  {activity}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {/* Sightseeing */}
                      {dayDetail?.sightSeeing && dayDetail?.sightSeeing.length > 0 && (
                        <div className="mt-6">
                          <div className="flex items-center space-x-2 mb-4">
                            <FaCameraRetro size={20} color="#ff783e" />
                            <div className="text-lg font-semibold text-[#2c3e50]">Sightseeing</div>
                          </div>
                          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                            {dayDetail.sightSeeing.map((place, idx) => (
                              <div
                                key={idx}
                                className="bg-white shadow-lg rounded-md p-4 hover:bg-[#f2f8fb] transition duration-300 ease-in-out flex items-center"
                              >
                                <FaMapMarkerAlt className="text-[#2c3e50] mr-2" />
                                <div className="font-semibold tracking-wider text-[#2c3e50]">
                                  {place}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {/* Stay & Dinner */}
                      {dayDetail?.stay_and_dinner && (
                        <div className="mt-4">
                          <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-2">
                            <div className="text-md font-semibold">Stay & Dinner</div>
                            <p className="text-sm text-[#8592A6]">{dayDetail?.stay_and_dinner}</p>
                          </div>
                          <div className="w-full h-px bg-gray-300"></div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>

          </div>
        </React.Fragment>
      ) : (
        <TourDetailsSkeleton />
      )}
    </>
  );
};

export default Details;
