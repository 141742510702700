import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { GiHamburgerMenu } from "react-icons/gi";
import CabBooking from "../../Assets/user-imgs/logo/cabbooking.svg";
import HotelBooking from "../../Assets/user-imgs/logo/hotelbooking.svg";
import login from "../../Assets/user-imgs/logo/login.svg";
import logowb2 from "../../Assets/user-imgs/logo/new-logo.svg";
import { FaArrowRight, FaCommentDots, FaImages } from "react-icons/fa6";
import logo from "../../Assets/Logos/logo_wb.svg";
import {
  FaHome,
  FaInfoCircle,
  FaMapMarkedAlt,
  FaPhoneAlt,
} from "react-icons/fa";
import whatsappIcon from "../../Assets/Common/social.png";
import messageIcon from "../../Assets/Common/message (2).png";
import InfoModal from "../common/InfoModal";

const BottomNavbar = () => {
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const menuRef = useRef(null);
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
        setActiveMenuItem(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeMenuItem]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleItemClick = (path) => {
    navigate(path);
    setMenuOpen(false);
  };

  const menuItems = [
    { title: "Home", path: "/", icon: <FaHome /> },
    { title: "Destinations", path: "/destinations", icon: <FaMapMarkedAlt /> },
    { title: "About", path: "/about", icon: <FaInfoCircle /> },
    { title: "Gallery", path: "/gallery", icon: <FaImages /> },
    { title: "Contact", path: "/contact", icon: <FaPhoneAlt /> },
    { title: "Feedback", path: "/feedback", icon: <FaCommentDots /> },
  ];

  const openWhatsApp = () => {
    const phoneNumber = "918178958591";
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <React.Fragment>
      {infoModalOpen && <InfoModal setInfoModalOpen={setInfoModalOpen} />}

      <div className="relative">
        <img
          src={logowb2}
          alt="img"
          className="absolute xl:w-auto w-24 object-cover xl:flex hidden top-0"
        />

        <div className="w-full bg-gradient-to-r hidden md:flex items-center justify-end py-1 from-[#063A64] to-[#1b7ccb]">
          <div>
            <p className="flex md:flex-row flex-col space-x-3 tracking-wider items-center text-white justify-center">
              <span
                className="text-[#E9FF92]"
                style={{ fontFamily: "Satisfy" }}
              >
                Call Us on
              </span>
              <FaArrowRight />
              <p>+91 8178958591, +91 9310366002</p>
            </p>
          </div>

          <div className="flex items-center gap-2 ml-8">
            <img
              onClick={() => setInfoModalOpen(true)}
              src={messageIcon}
              alt="wicons"
              className="h-7 w-auto cursor-pointer mt-0.5"
            />

            <img
              onClick={openWhatsApp}
              src={whatsappIcon}
              alt="micons"
              className="h-7 w-auto cursor-pointer"
            />
          </div>

          <div className="text-md tracking-wide mr-6 text-orange-300 ml-8">
            V.2.0.0
          </div>
        </div>

        <div className="bg-white py-[0.6rem] md:px-16 xl:flex hidden items-center justify-end shadow-md">
          <div className="flex gap-4 lg:gap-8 items-center ">
            {menuItems.map((item, index) => (
              <div
                key={index}
                className={`flex items-center text-md tracking-wider font-semibold gap-1 cursor-pointer relative hover:text-[#F88F26]
                                ${
                                  location.pathname === item.path
                                    ? "text-[#F88F26] border-b-2 border-[#F88F26]"
                                    : ""
                                }`}
                onClick={() => {
                  handleItemClick(item.path);
                  window.scrollTo(500, 0);
                }}
              >
                {item.title}
              </div>
            ))}
            <div
              onClick={() => navigate("/hotel-list")}
              className="flex flex-col items-center cursor-pointer"
            >
              <img src={HotelBooking} alt="img" />
              <p className="font-bold text-xs">Hotel Booking</p>
            </div>

            <div
              onClick={() => navigate("/cab-list")}
              className="flex flex-col items-center cursor-pointer"
            >
              <img src={CabBooking} alt="img" />
              <p className="font-bold text-xs">Cab Booking</p>
            </div>
            <button
              onClick={() => {
                navigate("/login");
                window.scrollTo(0, 0);
              }}
              className="bg-gradient-to-r flex items-center space-x-5 from-yellow-400 to-orange-500 hover:bg-yellow-800 text-white px-8 py-2 rounded-md text-sm hover:bg-gradient-to-r hover:to-yellow-400 hover:from-orange-500"
            >
              Login
              <img src={login} alt="img/" />
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        <div className="bg-white px-5 py-3 xl:hidden flex items-center justify-between shadow-lg">
          <div>
            <img src={logo} alt="logo" className="w-16" />
          </div>
          <div onClick={toggleMenu}>
            <GiHamburgerMenu size={28} />
          </div>
        </div>
        <AnimatePresence>
          {menuOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-40 flex justify-end z-50">
              <motion.div
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: "0%" }}
                exit={{ opacity: 0, x: "100%" }}
                transition={{ duration: 0.3 }}
                ref={menuRef}
                className="bg-white shadow-lg text-primaryTextColor p-5 w-[80%] h-full flex flex-col"
              >
                {/* Menu Items */}
                <div className="flex-1 overflow-y-auto">
                  {menuItems.map((item, index) => (
                    <div
                      key={index}
                      className={`py-2 text-lg font-medium flex items-center gap-3 cursor-pointer 
            ${
              location.pathname === item.path
                ? "text-primaryBtnColor border-b-2 border-primaryBtnColor"
                : "text-gray-800"
            }`}
                      onClick={() => {
                        handleItemClick(item.path);
                        window.scrollTo(0, 0);
                      }}
                    >
                      {item.icon}
                      {item.title}
                    </div>
                  ))}

                  <div
                    onClick={() => navigate("/hotel-list")}
                    className="py-2 text-lg font-medium flex items-center gap-3 cursor-pointer"
                  >
                    <img src={HotelBooking} alt="hotel" className="w-6 h-6" />
                    Hotel Booking
                  </div>
                  <div
                    onClick={() => navigate("/cab-booking")}
                    className="py-2 text-lg font-medium flex items-center gap-3 cursor-pointer"
                  >
                    <img src={CabBooking} alt="cab" className="w-6 h-6" />
                    Cab Booking
                  </div>
                </div>

                {/* Actions */}
                <div className="flex justify-between items-center mt-5">
                  <button
                    className="bg-gradient-to-r flex items-center space-x-3 from-yellow-400 to-orange-500 text-white px-4 py-2 rounded-md"
                    onClick={() => {
                      navigate("/login");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Login
                    <img src={login} alt="login" className="w-5 h-5" />
                  </button>
                  <button
                    className="bg-gray-400 text-white px-4 py-2 rounded-md"
                    onClick={() => setMenuOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      </div>
    </React.Fragment>
  );
};

export default BottomNavbar;
