import React from 'react';

const BasicDetailsForm = ({
    newHotelData,
    setNewHotelData,
    errors
}) => {

    return (
        <div>
            <h2 className="text-xl font-semibold mb-4">
                Basic Details
            </h2>

            <div className="grid grid-cols-2 gap-4">
                {/* Input fields for basic details */}
                {[
                    { label: 'Hotel Name', name: 'name', type: 'text' },
                    { label: 'Description', name: 'description', type: 'textarea' },
                    { label: 'Address', name: 'address', type: 'text' },
                    { label: 'City', name: 'city', type: 'text' },
                    { label: 'State', name: 'state', type: 'text' },
                    { label: 'Country', name: 'country', type: 'text' },
                    { label: 'Zipcode', name: 'zipcode', type: 'text' },
                    { label: 'Phone', name: 'phone', type: 'text' },
                    { label: 'Email', name: 'email', type: 'email' },
                ].map((field, index) => (
                    <div key={index}>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            {field.label}
                        </label>
                        {field.type === 'textarea' ? (
                            <textarea
                                name={field.name}
                                value={newHotelData[field.name]}
                                onChange={(e) =>
                                    setNewHotelData({ ...newHotelData, [field.name]: e.target.value })
                                }
                                className={`w-full p-2 border border-gray-300 rounded-md resize-none ${errors[field.name] ? 'border-red-500' : ''
                                    }`}
                                rows="3"
                            />
                        ) : (
                            <input
                                type={field.type}
                                name={field.name}
                                value={newHotelData[field.name]}
                                onChange={(e) =>
                                    setNewHotelData({ ...newHotelData, [field.name]: e.target.value })
                                }
                                className={`w-full p-2 border border-gray-300 rounded-md ${errors[field.name] ? 'border-red-500' : ''
                                    }`}
                            />
                        )}
                        {errors[field.name] && (
                            <p className="text-red-500 text-xs mt-1">{errors[field.name]}</p>
                        )}
                    </div>
                ))}

                {/* Dropdown for Type */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">Type</label>
                    <select
                        name="type"
                        value={newHotelData.type}
                        onChange={(e) =>
                            setNewHotelData({ ...newHotelData, type: e.target.value })
                        }
                        className={`w-full p-2 border border-gray-300 rounded-md ${errors.type ? 'border-red-500' : ''
                            }`}
                    >
                        <option value="">Select Type</option>
                        <option value="luxury">Luxury</option>
                        <option value="standard">Standard</option>
                        <option value="deluxe">Deluxe</option>
                    </select>
                    {errors.type && <p className="text-red-500 text-xs mt-1">{errors.type}</p>}
                </div>

                {/* Dropdown for Rating */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">Rating</label>
                    <select
                        name="rating"
                        value={newHotelData.rating}
                        onChange={(e) =>
                            setNewHotelData({ ...newHotelData, rating: e.target.value })
                        }
                        className={`w-full p-2 border border-gray-300 rounded-md ${errors.rating ? 'border-red-500' : ''
                            }`}
                    >
                        <option value="">Select Rating</option>
                        {[1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5].map((rating) => (
                            <option key={rating} value={rating}>
                                {rating}
                            </option>
                        ))}
                    </select>
                    {errors.rating && <p className="text-red-500 text-xs mt-1">{errors.rating}</p>}
                </div>
            </div>
        </div>
    )
}

export default BasicDetailsForm