import React from 'react';
import tcImg from '../../Assets/user-imgs/Home-imgs/contract.png';

const TermsConditions = ({ tourData }) => {
  const terms_and_conditions = tourData?.terms_and_conditions;
  const cancellation_policy = tourData?.cancellation_policy;
  const payment_options = tourData?.payment_options;

  const allEmpty =
    (
      !terms_and_conditions?.length &&
      !cancellation_policy?.length &&
      !payment_options?.length
    );

  return (
    <div className="container mx-auto px-4 py-6">
      {allEmpty ? (
        <React.Fragment>
          <p className="text-center text-gray-500 text-lg">
            No terms & conditions available at the moment.
          </p>

          <div className='w-full flex justify-center'>
            <img
              src={tcImg}
              alt="tcImg"
              className='h-auto w-1/3'
            />
          </div>
        </React.Fragment>
      ) : (
        <>
          {/* Terms and Conditions */}
          <h1 className="text-3xl font-semibold text-center mb-6">
            Terms and Conditions
          </h1>
          
          <div className="space-y-4 tracking-wider">
            {/* Terms List with Gradient Left Border */}
            <div className="pl-4 border-l-4 border-[#F94E05]">
              <div className="space-y-2">
                {
                  terms_and_conditions?.map((terms) => {
                    return (
                      <div className="pl-4 border-l-4 border-slate-400">
                        <ul className="list-inside list-disc">
                          <li>
                            {terms}
                          </li>
                        </ul>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>

          {/* Cancellation Policy */}
          <h2 className="text-2xl font-semibold text-center my-6 tracking-wider">
            Cancellation Policy
          </h2>

          <div className="space-y-4 tracking-wider">
            {/* Terms List with Gradient Left Border */}
            <div className="pl-4 border-l-4 border-[#F94E05]">
              <div className="space-y-2">
                {
                  cancellation_policy?.map((terms) => {
                    return (
                      <div className="pl-4 border-l-4 border-slate-400">
                        <ul className="list-inside list-disc">
                          <li>
                            {terms}
                          </li>
                        </ul>
                      </div>
                    )
                  })
                }
              </div>
            </div>

            <p className="italic font-semibold text-green-600 tracking-wider">
              * We will make every effort to minimize cancellation charges.
            </p>
          </div>

          {/* Payment Terms */}
          <h2 className="text-2xl font-semibold text-center my-6 tracking-wider">
            Payment Terms and Conditions
          </h2>

          <div className="space-y-4 tracking-wider">
            {/* Terms List with Gradient Left Border */}
            <div className="pl-4 border-l-4 border-[#F94E05]">
              <div className="space-y-2">
                {
                  payment_options?.map((terms) => {
                    return (
                      <div className="pl-4 border-l-4 border-slate-400">
                        <ul className="list-inside list-disc">
                          <li>
                            {terms}
                          </li>
                        </ul>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TermsConditions;