import React from 'react'
import AllFeedbacksAdmin from '../../components/AdminSideComponents/Feedbacks/AllFeedbacksAdmin'

const Feedbacks = () => {
  return (
    <div className="p-8">
      <h1 className="text-3xl font-semibold mb-6">
        Feedbacks
      </h1>
      <AllFeedbacksAdmin />
    </div>
  )
}

export default Feedbacks
