import React from "react";
import checkGif from "../../Assets/user-imgs/Common/successCheck.gif"
import { useNavigate, useParams } from "react-router-dom";

const BookingSuccess = () => {
    const navigate = useNavigate();
    const { bookingType } = useParams();

    return (
        <div className="bg-white shadow-xl rounded-xl p-5 max-w-md mx-auto text-center my-10">
            <div className="w-52 h-auto mx-auto mb-4 flex items-center">
                <img
                    src={checkGif}
                    alt="check-gif"
                />
            </div>

            <h1 className="text-xl font-extrabold tracking-wide text-gray-800 mb-4">
                {bookingType === "tour" && <span>Your Tour Package<br /> is Confirmed!</span>}

                {bookingType === "hotel" && <span>Your Hotel Reservation<br /> is Confirmed!</span>}

                {bookingType === "cab" && <span>Your Cab Booking<br /> is Confirmed!</span>}
            </h1>

            <p className="text-gray-600 tracking-wide leading-normal text-lg mb-4">
                Thank you for choosing us! Your booking is confirmed.
            </p>

            <div className="h-1 bg-gradient-to-r from-blue-400 to-green-400 rounded-full w-24 mx-auto mb-6"></div>

            <div className="space-y-4 tracking-wider font-semibold">
                <button
                    onClick={() => {
                        const routes = {
                            tour: "/destinations",
                            cab: "/cab-list",
                            hotel: "/hotel-list",
                        };

                        if (routes[bookingType]) {
                            navigate(routes[bookingType]);
                        }
                    }}
                    // onClick={() => navigate('/hotel-list')}
                    className="w-full px-6 py-3 bg-orange-600 text-white rounded-lg shadow-lg hover:bg-orange-700 transition">
                    Let's Go For More Bookings
                </button>

                <button
                    onClick={() => navigate('/')}
                    className="w-full px-6 py-3 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transition">
                    No, Go Back to Home
                </button>
            </div>
        </div>
    );
};

export default BookingSuccess;
