import React from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const Inclusion = ({ tourData }) => {
  // Extract inclusions and exclusions from tourData
  const { inclusions, exclusions } = tourData;

  return (
    <div className="container mx-auto px-6 py-8">
      {/* Title */}
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-12">Inclusions & Exclusions</h1>

      {/* Content Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
        {/* Inclusions Section */}
        <div>
          <h2 className="text-2xl font-semibold text-green-600 mb-6">Inclusions</h2>
          {inclusions && inclusions.length > 0 ? (
            inclusions.map((item, index) => (
              <div key={index} className="bg-white shadow-lg rounded-xl p-6 mb-6 border border-gray-300 hover:shadow-2xl transition-shadow duration-300">
                <div className="flex items-start space-x-3">
                  <FaCheckCircle className="text-green-500 text-2xl" />
                  <span className="text-lg text-gray-700">{item}</span>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500">No inclusions available.</p>
          )}
        </div>

        {/* Exclusions Section */}
        <div>
          <h2 className="text-2xl font-semibold text-red-600 mb-6">Exclusions</h2>
          {exclusions && exclusions.length > 0 ? (
            exclusions.map((item, index) => (
              <div key={index} className="bg-white shadow-lg rounded-xl p-6 mb-6 border border-gray-300 hover:shadow-2xl transition-shadow duration-300">
                <div className="flex items-start space-x-3">
                  <FaTimesCircle className="text-red-500 text-2xl" />
                  <span className="text-lg text-gray-700">{item}</span>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500">No exclusions available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Inclusion;
