import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const TourBookForm = ({ tourData, selectedPackageType, selectedPackagePrice }) => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        departureCity: "",
        date: "",
        adultsCount: 0,
        childrenCount: 0,
    });

    const [formErrors, setFormErrors] = useState({
        nameError: false,
        emailError: false,
        phoneError: false,
        departureCityError: false,
        dateError: false,
        adultsError: false,
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleIncrement = (field) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: prevData[field] + 1,
        }));
    };

    const handleDecrement = (field) => {
        if (formData[field] > 0) {
            setFormData((prevData) => ({
                ...prevData,
                [field]: prevData[field] - 1,
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Basic validation
        let errors = {
            nameError: !formData.name,
            emailError: !formData.email,
            phoneError: !formData.phone,
            departureCityError: !formData.departureCity,
            dateError: !formData.date,
            adultsError: formData.adultsCount === 0,
        };

        setFormErrors(errors);

        if (!Object.values(errors).includes(true)) {
            // Save the form data, tour data, and selected package details to localStorage
            const bookingData = {
                formData,
                tourData,
                selectedPackageType,
                selectedPackagePrice,
            };

            localStorage.setItem("bookingData", JSON.stringify(bookingData));

            // Navigate to the payment summary page
            navigate("/paymentSummary");
        }
    };

    // console.log('tourData', tourData)

    return (
        <div className="flex items-center justify-center">
            <div className="w-full max-w-lg">
                <h2 className="text-3xl font-bold text-center text-[#F94E05] mb-6">
                    Book Your Tour
                </h2>
                <form onSubmit={handleSubmit} className="space-y-6">
                    {/* Full Name */}
                    <div>
                        <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700 tracking-wide"
                        >
                            Full Name
                        </label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            className={`mt-2 w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#F94E05] ${formErrors.nameError ? "border-red-500" : ""}`}
                        />
                    </div>

                    {/* Email Address */}
                    <div>
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700 tracking-wide"
                        >
                            Email Address
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className={`mt-2 w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#F94E05] ${formErrors.emailError ? "border-red-500" : ""}`}
                        />
                    </div>

                    {/* Phone Number */}
                    <div>
                        <label
                            htmlFor="phone"
                            className="block text-sm font-medium text-gray-700 tracking-wide"
                        >
                            Phone Number
                        </label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                            className={`mt-2 w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#F94E05] ${formErrors.phoneError ? "border-red-500" : ""}`}
                        />
                    </div>

                    {/* Departure City */}
                    <div>
                        <label
                            htmlFor="departureCity"
                            className="block text-sm font-medium text-gray-700 tracking-wide"
                        >
                            Departure City
                        </label>
                        <input
                            type="text"
                            id="departureCity"
                            name="departureCity"
                            value={formData.departureCity}
                            onChange={handleChange}
                            required
                            className={`mt-2 w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#F94E05] ${formErrors.departureCityError ? "border-red-500" : ""}`}
                        />
                    </div>

                    {/* Tour Date */}
                    <div>
                        <label
                            htmlFor="date"
                            className="block text-sm font-medium text-gray-700 tracking-wide"
                        >
                            Tour Date
                        </label>
                        <input
                            type="date"
                            id="date"
                            name="date"
                            value={formData.date}
                            onChange={handleChange}
                            required
                            className={`mt-2 w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#F94E05] ${formErrors.dateError ? "border-red-500" : ""}`}
                        />
                    </div>

                    {/* Adults Counter */}
                    <div className="flex justify-between items-center mb-4">
                        <div className="text-md font-semibold mb-2 tracking-wide">
                            No. of Adults
                        </div>
                        <div className="flex items-center space-x-6">
                            <button
                                type="button"
                                onClick={() => handleDecrement("adultsCount")}
                                className="w-8 h-8 flex justify-center items-center text-2xl rounded-full font-bold text-white bg-gradient-to-r from-[#FF5C00] to-[#FF9900] hover:scale-105 transition-all duration-300 shadow-md focus:outline-none"
                            >
                                -
                            </button>
                            <div className="text-xl font-semibold text-gray-700 w-16 h-12 flex justify-center items-center border border-gray-300 rounded-md bg-gray-50">
                                {formData.adultsCount}
                            </div>
                            <button
                                type="button"
                                onClick={() => handleIncrement("adultsCount")}
                                className="w-8 h-8 flex justify-center items-center text-2xl rounded-full font-bold text-white bg-gradient-to-r from-[#FF5C00] to-[#FF9900] hover:scale-105 transition-all duration-300 shadow-md focus:outline-none"
                            >
                                +
                            </button>
                        </div>
                    </div>

                    {/* Children Counter */}
                    <div className="flex justify-between items-center mb-4">
                        <div className="text-md font-semibold mb-2 tracking-wide">
                            No. of Children
                        </div>
                        <div className="flex items-center space-x-6">
                            <button
                                type="button"
                                onClick={() => handleDecrement("childrenCount")}
                                className="w-8 h-8 flex justify-center items-center text-2xl rounded-full font-bold text-white bg-gradient-to-r from-[#FF5C00] to-[#FF9900] hover:scale-105 transition-all duration-300 shadow-md focus:outline-none"
                            >
                                -
                            </button>
                            <div className="text-xl font-semibold text-gray-700 w-16 h-12 flex justify-center items-center border border-gray-300 rounded-md bg-gray-50">
                                {formData.childrenCount}
                            </div>
                            <button
                                type="button"
                                onClick={() => handleIncrement("childrenCount")}
                                className="w-8 h-8 flex justify-center items-center text-2xl rounded-full font-bold text-white bg-gradient-to-r from-[#FF5C00] to-[#FF9900] hover:scale-105 transition-all duration-300 shadow-md focus:outline-none"
                            >
                                +
                            </button>
                        </div>
                    </div>

                    {/* Submit Button */}
                    <div className="text-center">
                        <button
                            type="submit"
                            className="w-full py-3 px-6 bg-[#F94E05] text-white font-semibold rounded-lg hover:bg-[#D24E02] transition duration-300"
                        >
                            Book Now
                        </button>
                    </div>
                </form>

                {/* Contact Information Section */}
                <div className="mt-5 text-center text-gray-700">
                    <p className="text-lg font-semibold tracking-wider mb-3">Any Query! Call Us.</p>
                    <p className="text-md tracking-wide">24*7 Services</p>
                    <p className="mt-2 tracking-wide">
                        <strong>Phone:</strong> +91 8178958591, +91 9310366002
                    </p>
                    <p className="mt-2 tracking-wide">
                        <strong>Tollfree No.:</strong> 1800-532-3636, 1800-212-0136
                    </p>
                    <p className="mt-2 tracking-wide">
                        <strong>Email:</strong> <a href="mailto:info@weekendbhraman.com" className="text-[#F94E05]">
                            info@weekendbhraman.com
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TourBookForm;