import React, { useState, useEffect } from "react";
import { FaArrowAltCircleRight } from "react-icons/fa";

function Aminities({ amenities, description }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const colors = [
    "#F94E05",
    "#3498DB",
    "#2ECC71",
    "#9B59B6",
    "#34495E",
    "#F1C40F",
    "#95A5A6",
  ];

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <h1 className="text-lg sm:text-xl font-bold pt-5 font-be-vietnam">
        Amenities
      </h1>
      <div className="flex flex-wrap flex-col md:flex-row justify-between gap-5 lg:gap-10 items-cente text-sm sm:text-base pt-4">
        {amenities.map((item, index) => (
          <div
            key={index}
            className="col-span-1 flex justify-start items-center gap-2"
          >
            <FaArrowAltCircleRight
              className="text-[#F94E05] text-sm md:text-base lg:text-lg xl:text-xl"
              style={{ color: colors[index % colors.length] }}
            />
            <span className="sm:text-base xl:text-md text-[#606060] ml-2 tracking-wide">
              {amenities[index]}
            </span>
          </div>
        ))}
      </div>
      <div className="flex flex-col justify-start text-justify items-start gap-3 sm:text-base xl:text-md text-[#323131] mt-4 tracking-wide">
        <p>{description}</p>
      </div>
    </>
  );
}

export default Aminities;
