/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
const Map = () => {
    return (
        <React.Fragment>
            <div>
                <div className=" h-80 lg:h-[500px] mx-6 md:mx-16 pb-6 rounded-lg">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d112070.3243596951!2d76.88109759726562!3d28.62383820000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d055b69539177%3A0x28c8db08080f4255!2sWeekend%20Bhraman%20Tour%20Planner!5e0!3m2!1sen!2sin!4v1731578883808!5m2!1sen!2sin"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        className='rounded-lg'
                    />
                </div>
            </div>
           
        </React.Fragment>
    )
}

export default Map
