// import React, { useEffect, useState } from "react";
// import { SiPhonepe, SiRazorpay } from "react-icons/si";
// import { FcGoogle } from "react-icons/fc";
// import axios from "axios"; // Import axios for API requests

// const PaymentsForm = ({ bookingData }) => {
//     const [isPayTotal, setIsPayTotal] = useState(true);
//     const [adultsPrice, setAdultsPrice] = useState(0);
//     const [childrenPrice, setChildrenPrice] = useState(0);
//     const [finalPrice, setFinalPrice] = useState(0);
//     const [totalMemberPrice, setTotalMemberPrice] = useState(0);
//     const [gstPrice, setGstPrice] = useState(0);

//     const convertPriceToNumber = (priceString) => {
//         if (!priceString) return 0;
//         const priceNumber = parseFloat(priceString.replace(/,/g, ""));
//         return !isNaN(priceNumber) ? priceNumber : 0;
//     };

//     useEffect(() => {
//         if (bookingData) {
//             const packagePrice = convertPriceToNumber(bookingData?.selectedPackagePrice);
//             if (packagePrice) {
//                 const totalAdultsPrice = bookingData?.formData?.adultsCount * packagePrice;
//                 const totalChildrenPrice = bookingData?.formData?.childrenCount * packagePrice;
//                 const baseTotalPrice = totalAdultsPrice + totalChildrenPrice;

//                 setAdultsPrice(totalAdultsPrice);
//                 setChildrenPrice(totalChildrenPrice);
//                 setTotalMemberPrice(baseTotalPrice);

//                 const gstAmount = (5 / 100) * baseTotalPrice;
//                 setGstPrice(gstAmount);
//                 setFinalPrice(baseTotalPrice + gstAmount);
//             }
//         }
//     }, [bookingData]);

//     const handlePaymentOptionChange = (e) => {
//         setIsPayTotal(e.target.value === "payTotal");
//     };

//     const payLaterTotalPrice = (bookingData?.formData?.adultsCount + bookingData?.formData?.childrenCount) * 2000;
//     const payLaterGst = (5 / 100) * payLaterTotalPrice;

//     const priceDetails = {
//         Total_price: Number(finalPrice.toFixed(2)),
//         gst: Number(gstPrice.toFixed(2)),
//         Total_Member_Price: totalMemberPrice,
//         Total_Adult_Price: adultsPrice,
//         Total_Children_Price: childrenPrice,
//     };

//     // Function to initiate Razorpay payment
//     const handlePayment = async () => {
//         try {
//             const orderData = {
//                 amount: isPayTotal ? finalPrice : payLaterTotalPrice, // Send the appropriate amount
//                 name: bookingData?.formData?.name,
//                 email: bookingData?.formData?.email,
//                 number: bookingData?.formData?.number,
//                 address: bookingData?.formData?.address,
//                 product: bookingData?.selectedPackage,
//                 profile_name: bookingData?.formData?.profileName,
//             };

//             // Make a request to your backend to create the Razorpay order
//             const response = await axios.post("http://localhost:5001/razorpay/orders", orderData); // Use the correct endpoint
//             const { order } = response.data;

//             if (order) {
//                 const options = {
//                     key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Razorpay public key from environment variable
//                     amount: order.amount,
//                     currency: "INR",
//                     name: "Weekend Bhraman",
//                     description: "Payment for tour booking",
//                     image: "https://your-logo-url.com/logo.png", // Optional logo
//                     order_id: order.id,
//                     handler: async (response) => {
//                         const paymentDetails = {
//                             razorpay_signature: response.razorpay_signature,
//                             razorpay_payment_id: response.razorpay_payment_id,
//                             razorpay_order_id: response.razorpay_order_id,
//                         };
//                         // Send the payment confirmation to backend
//                         await axios.post("http://localhost:5001/razorpay/payment-callback", paymentDetails); // Use the correct endpoint
//                         alert("Payment Successful");
//                     },
//                     prefill: {
//                         name: bookingData?.formData?.name,
//                         email: bookingData?.formData?.email,
//                         contact: bookingData?.formData?.number,
//                     },
//                     theme: {
//                         color: "#30aadd",
//                     },
//                 };

//                 // Initialize Razorpay
//                 const razorpay = new window.Razorpay(options);
//                 razorpay.open();
//             }
//         } catch (error) {
//             console.error("Error during payment process", error);
//             alert("Payment failed. Please try again.");
//         }
//     };

//     return (
//         <div className="col-span-full px-4 py-6 lg:col-span-5">
//             <div className="mx-auto w-full max-w-lg">
//                 <h1 className="relative text-2xl font-medium text-gray-700 sm:text-3xl">
//                     Secure Payment
//                     <span className="mt-2 block h-1 w-full bg-[#30aadd] mb-4"></span>
//                 </h1>

//                 <p className="text-lg font-semibold text-[#051836] text-opacity-80 mt-4">
//                     Choose your Payment Method
//                 </p>

//                 {/* Razorpay */}
//                 <div className="flex items-center justify-center mt-4 text-opacity-80">
//                     <div className="container border border-[#30aadd] p-4 flex items-center rounded-md justify-between">
//                         <div className="flex items-center">
//                             <SiRazorpay color="#0d94fb" size={22} />
//                             <p className="text-xl font-bold ml-4 text-[#051836]">Razor Pay</p>
//                         </div>
//                         <input
//                             className="text-xl"
//                             type="radio"
//                             name="paymentMethod"
//                             checked
//                             aria-label="Select Razorpay"
//                         />
//                     </div>
//                 </div>

//                 {/* Payment Options */}
//                 <div className="mt-20 border p-4 rounded-md flex justify-between">
//                     <label className="flex items-center">
//                         <input
//                             onChange={handlePaymentOptionChange}
//                             type="radio"
//                             name="paymentOption"
//                             value="payTotal"
//                             className="mr-2"
//                             checked={isPayTotal}
//                         />
//                         <span className="text-[#051836]">Pay Total</span>
//                     </label>

//                     <label className="flex items-center ml-4">
//                         <input
//                             onChange={handlePaymentOptionChange}
//                             type="radio"
//                             name="paymentOption"
//                             value="payNow"
//                             className="mr-2"
//                         />
//                         <span className="text-[#051836]">
//                             Pay Later (Pay Now - min ₹2,000/person)
//                         </span>
//                     </label>
//                 </div>

//                 {/* Payment Summary */}
//                 <div className="space-y-2 mt-4 border p-4 rounded-md">
//                     {isPayTotal ? (
//                         <>
//                             <p className="flex justify-between text-lg font-bold text-[#051836]">
//                                 <span>Total price:</span>
//                                 <span>₹ {finalPrice.toFixed(2)}</span>
//                             </p>
//                             <p className="flex justify-between text-sm font-medium text-[#051836]">
//                                 <span>GST (5%):</span>
//                                 <span>₹ {gstPrice.toFixed(2)}</span>
//                             </p>
//                             <p className="flex justify-between text-sm font-medium text-[#051836]">
//                                 <span>Total Member Price:</span>
//                                 <span>₹ {totalMemberPrice}</span>
//                             </p>
//                             <p className="flex justify-between text-sm font-medium text-[#051836]">
//                                 <span>Total Adult Price:</span>
//                                 <span>₹ {adultsPrice}</span>
//                             </p>
//                             {childrenPrice > 0 && (
//                                 <p className="flex justify-between text-sm font-medium text-[#051836]">
//                                     <span>Total Children Price:</span>
//                                     <span>₹ {childrenPrice}</span>
//                                 </p>
//                             )}
//                         </>
//                     ) : (
//                         <>
//                             <p className="flex justify-between text-lg font-bold text-[#051836]">
//                                 <span>Total price:</span>
//                                 <span>₹ {payLaterTotalPrice + payLaterGst}</span>
//                             </p>
//                             <p className="flex justify-between text-sm font-medium text-[#051836]">
//                                 <span>GST (5%):</span>
//                                 <span>₹ {payLaterGst}</span>
//                             </p>
//                         </>
//                     )}
//                 </div>

//                 {/* Payment Button */}
//                 <div className="mt-8">
//                     <button
//                         onClick={handlePayment}
//                         className="w-full py-3 px-4 bg-[#30aadd] text-white font-bold text-lg rounded-md hover:bg-[#2886a7] focus:outline-none"
//                     >
//                         Proceed to Payment
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default PaymentsForm;















import React, { useEffect, useState } from "react";
import { SiPhonepe, SiRazorpay } from "react-icons/si";
import { FcGoogle } from "react-icons/fc";
import { RazorPays } from "./RazorPays";

const PaymentsForm = ({ bookingData }) => {
    document.title = "Weekend Bhraman | Payment Summary";

    const [isPayTotal, setIsPayTotal] = useState(true);
    const [adultsPrice, setAdultsPrice] = useState(0);
    const [childrenPrice, setChildrenPrice] = useState(0);
    const [finalPrice, setFinalPrice] = useState(0);
    const [totalMemberPrice, setTotalMemberPrice] = useState(0);
    const [gstPrice, setGstPrice] = useState(0);

    const convertPriceToNumber = (priceString) => {
        if (!priceString) return 0;
        const priceNumber = parseFloat(priceString.replace(/,/g, ""));
        return !isNaN(priceNumber) ? priceNumber : 0;
    };

    useEffect(() => {
        if (bookingData) {
            const packagePrice = convertPriceToNumber(bookingData?.selectedPackagePrice);
            if (packagePrice) {
                const totalAdultsPrice = bookingData?.formData?.adultsCount * packagePrice;
                const totalChildrenPrice = bookingData?.formData?.childrenCount * packagePrice;
                const baseTotalPrice = totalAdultsPrice + totalChildrenPrice;

                setAdultsPrice(totalAdultsPrice);
                setChildrenPrice(totalChildrenPrice);
                setTotalMemberPrice(baseTotalPrice);

                const gstAmount = (5 / 100) * baseTotalPrice;
                setGstPrice(gstAmount);
                setFinalPrice(baseTotalPrice + gstAmount);
            }
        }
    }, [bookingData]);

    const handlePaymentOptionChange = (e) => {
        setIsPayTotal(e.target.value === "payTotal");
    };

    const payLaterTotalPrice = (bookingData?.formData?.adultsCount + bookingData?.formData?.childrenCount) * 2000;
    const payLaterGst = (5 / 100) * payLaterTotalPrice;

    const priceDetails = {
        Total_price: Number(finalPrice.toFixed(2)),
        gst: Number(gstPrice.toFixed(2)),
        Total_Member_Price: totalMemberPrice,
        Total_Adult_Price: adultsPrice,
        Total_Children_Price: childrenPrice,
    };

    return (
        <div className="col-span-full px-4 py-6 lg:col-span-5">
            <div className="mx-auto w-full max-w-lg">
                <h1 className="relative text-2xl font-medium text-gray-700 sm:text-3xl">
                    Secure Payment
                    <span className="mt-2 block h-1 w-full bg-[#30aadd] mb-4"></span>
                </h1>

                <p className="text-lg font-semibold text-[#051836] text-opacity-80 mt-4">
                    Choose your Payment Method
                </p>

                {/* Razorpay */}
                <div className="flex items-center justify-center mt-4 text-opacity-80">
                    <div className="container border border-[#30aadd] p-4 flex items-center rounded-md justify-between">
                        <div className="flex items-center">
                            <SiRazorpay color="#0d94fb" size={22} />
                            <p className="text-xl font-bold ml-4 text-[#051836]">Razor Pay</p>
                        </div>
                        <input
                            className="text-xl"
                            type="radio"
                            name="paymentMethod"
                            checked
                            aria-label="Select Razorpay"
                        />
                    </div>
                </div>

                {/* PhonePe */}
                <div className="flex items-center justify-center mt-4 opacity-60">
                    <div className="container border p-4 flex items-center rounded-md justify-between">
                        <div className="flex items-center">
                            <SiPhonepe className="text-[#5f259f]" size={22} />
                            <p className="text-xl font-bold ml-4 text-[#5f259f]">Phone Pe</p>
                        </div>
                        <input
                            className="text-xl"
                            type="radio"
                            name="paymentMethod"
                            disabled
                            aria-label="PhonePe is disabled"
                        />
                    </div>
                </div>

                {/* Pay */}
                <div className="flex items-center justify-center mt-4 opacity-60">
                    <div className="container border p-4 flex items-center rounded-md justify-between">
                        <div className="flex items-center">
                            <FcGoogle size={22} />
                            <p className="text-xl font-bold ml-4 text-[#5c6065]">Pay</p>
                        </div>
                        <input
                            className="text-xl"
                            type="radio"
                            name="paymentMethod"
                            disabled
                            aria-label="Google Pay is disabled"
                        />
                    </div>
                </div>

                {/* Payment Options */}
                <div className="mt-20 border p-4 rounded-md flex justify-between">
                    <label className="flex items-center">
                        <input
                            onChange={handlePaymentOptionChange}
                            type="radio"
                            name="paymentOption"
                            value="payTotal"
                            className="mr-2"
                            checked={isPayTotal}
                        />
                        <span className="text-[#051836]">Pay Total</span>
                    </label>

                    <label className="flex items-center ml-4">
                        <input
                            onChange={handlePaymentOptionChange}
                            type="radio"
                            name="paymentOption"
                            value="payNow"
                            className="mr-2"
                        />
                        <span className="text-[#051836]">
                            Pay Later (Pay Now - min ₹2,000/person)
                        </span>
                    </label>
                </div>

                {/* Payment Summary */}
                <div className="space-y-2 mt-4 border p-4 rounded-md">
                    {isPayTotal ? (
                        <>
                            <p className="flex justify-between text-lg font-bold text-[#051836]">
                                <span>Total price:</span>
                                <span>₹ {finalPrice.toFixed(2)}</span>
                            </p>
                            <p className="flex justify-between text-sm font-medium text-[#051836]">
                                <span>GST (5%):</span>
                                <span>₹ {gstPrice.toFixed(2)}</span>
                            </p>
                            <p className="flex justify-between text-sm font-medium text-[#051836]">
                                <span>Total Member Price:</span>
                                <span>₹ {totalMemberPrice}</span>
                            </p>
                            <p className="flex justify-between text-sm font-medium text-[#051836]">
                                <span>Total Adult Price:</span>
                                <span>₹ {adultsPrice}</span>
                            </p>
                            {childrenPrice > 0 && (
                                <p className="flex justify-between text-sm font-medium text-[#051836]">
                                    <span>Total Children Price:</span>
                                    <span>₹ {childrenPrice}</span>
                                </p>
                            )}
                        </>
                    ) : (
                        <>
                            <p className="flex justify-between text-lg font-bold text-[#051836]">
                                <span>Total price:</span>
                                <span>₹ {payLaterTotalPrice + payLaterGst}</span>
                            </p>
                            <p className="flex justify-between text-sm font-medium text-[#051836]">
                                <span>GST (5%):</span>
                                <span>₹ {payLaterGst}</span>
                            </p>
                        </>
                    )}
                </div>

                {/* Razorpay Component */}
                <RazorPays
                    bookingData={bookingData}
                    amount={isPayTotal ? finalPrice : payLaterTotalPrice + payLaterGst}
                    PriceDetails={priceDetails}
                />

                <p className="mt-5 text-center text-sm font-semibold text-gray-500">
                    By placing this order you agree to the{" "}
                    <span className="text-[#30aadd]">Terms and Conditions</span>
                </p>
            </div>
        </div>
    );
};

export default PaymentsForm;


















// import React, { useEffect, useState } from "react";
// import { SiPhonepe } from "react-icons/si";
// import { SiRazorpay } from "react-icons/si";
// import { FcGoogle } from "react-icons/fc";
// import { Razorpay } from "./Razorpay";

// const PaymentsForm = ({ bookingData }) => {
//     document.title = "Weekend Bhraman | Payment Summary";

//     const [isPayTotal, setIsPayTotal] = useState(true);

//     const [adultsPrice, setAdultsPrice] = useState(0);

//     const [childrenPrice, setChildrenPrice] = useState(0);

//     const [finalPrice, setFinalPrice] = useState(0);
//     const [totalMemberPrice, setTotalMemberPrice] = useState(0);
//     const [gstPrice, setGstPrice] = useState(0);

//     function convertPriceToNumber(priceString) {
//         // Remove commas and convert to number
//         const priceNumber = parseFloat(priceString?.replace(/,/g, ""));

//         // Check if the conversion was successful
//         if (!isNaN(priceNumber)) {
//             return priceNumber;
//         } else {
//             console.error(`Error converting price: ${priceString}`);
//             return null;
//         }
//     }



//     // Update final price whenever package or counts change
//     useEffect(() => {
//         if (bookingData) {
//             const packagePrice = convertPriceToNumber(
//                 bookingData?.selectedPackagePrice
//             );

//             if (packagePrice) {
//                 const totalAdultsPrice = bookingData?.formData?.adultsCount * packagePrice;
//                 setAdultsPrice(totalAdultsPrice);
//                 const totalChildrenPrice = bookingData?.formData?.childrenCount * packagePrice;
//                 setChildrenPrice(totalChildrenPrice);

//                 const baseTotalPrice = totalAdultsPrice + totalChildrenPrice;
//                 setTotalMemberPrice(baseTotalPrice);
//                 const gstAmount = (5 / 100) * baseTotalPrice;
//                 setGstPrice(gstAmount);

//                 const totalPrice = baseTotalPrice + gstAmount;

//                 console.log("totalPrice", totalPrice);
//                 setFinalPrice(totalPrice);
//             }
//         }
//     }, [bookingData]);

//     const handlePaymentOptionChange = (e) => {
//         const selectedValue = e.target.value;
//         console.log("Selected Payment Option:", selectedValue);
//         setIsPayTotal(selectedValue === "payTotal");
//     };

//     const payLaterTotalPrice = (bookingData?.formData?.adultsCount + bookingData?.formData?.childrenCount) * 2000;
//     const payLaterGst = payLaterTotalPrice * (5 / 100);

//     const PriceDetails = {
//         Total_price: Number(finalPrice.toFixed(2)),
//         gst: Number(gstPrice.toFixed(2)),
//         Total_Member_Price: totalMemberPrice,
//         Total_Adult_Price: adultsPrice,
//         Total_Childrent_Price: childrenPrice
//     }



//     return (
//         <React.Fragment>
//             <div class="col-span-full px-4 py-6 lg:col-span-5">
//                 <div class="mx-auto w-full max-w-lg">
//                     <h1 class="relative text-2xl font-medium w-full text-gray-700 sm:text-3xl">
//                         Secure Payment
//                         <span class="mt-2 block h-1 w-full bg-[#30aadd] mb-4"></span>
//                     </h1>

//                     <p class="text-lg font-semibold text-[#051836] text-opacity-80 mt-4">
//                         Choose your Payment Method
//                     </p>

//                     {/* Razor Pay */}
//                     <div class="flex items-center justify-center mt-4 text-opacity-80">
//                         <div class="container border border-[#30aadd] p-4 flex items-center rounded-md justify-between">
//                             <div className="flex justify-start items-center">
//                                 <SiRazorpay color="#0d94fb" size={22} />
//                                 <p class="text-xl font-bold ml-4 text-[#051836]">Razor Pay</p>
//                             </div>
//                             <input class="text-xl" checked type="radio" />
//                         </div>
//                     </div>

//                     {/* Phone Pe */}
//                     <div class="flex items-center justify-center mt-4 opacity-60">
//                         <div class="container border p-4 flex items-center rounded-md justify-between">
//                             <div className="flex justify-start items-center">
//                                 <div class="text-[#5f259f]">
//                                     <SiPhonepe size={22} />
//                                 </div>
//                                 <p class="text-xl font-bold ml-4 text-[#5f259f] ">Phone Pe </p>
//                             </div>
//                             <input disabled class="text-xl" type="radio" />
//                         </div>
//                     </div>

//                     {/* Pay */}
//                     <div class="flex items-center justify-center mt-4 opacity-60">
//                         <div class="container border p-4 flex items-center rounded-md justify-between">
//                             <div className="flex justify-start items-center">
//                                 <div class="text-opacity-50 ">
//                                     <FcGoogle size={22} />
//                                 </div>
//                                 <p class="text-xl font-bold ml-4 text-[#5c6065] ">Pay</p>
//                             </div>
//                             <input disabled class="text-xl" type="radio" />
//                         </div>
//                     </div>

//                     {/* Payment Options */}
//                     <div class="mt-20 border p-4 rounded-md flex justify-between">
//                         <label>
//                             <input
//                                 onChange={(e) => handlePaymentOptionChange(e)}
//                                 type="radio"
//                                 name="paymentOption"
//                                 value="payTotal"
//                                 class="mr-2"
//                                 checked={isPayTotal}
//                             />
//                             <span class="text-[#051836]">Pay Total</span>
//                         </label>

//                         <label class="ml-4">
//                             {" "}
//                             {/* Add some left margin for spacing */}
//                             <input
//                                 onChange={(e) => handlePaymentOptionChange(e)}
//                                 type="radio"
//                                 name="paymentOption"
//                                 value="payNow"
//                                 class="mr-2"
//                             />
//                             <span class="text-[#051836]">Pay Later (Pay Now - min ₹2,000/person)</span>
//                         </label>
//                     </div>

//                     {/* Payment Summary */}
//                     <div class="space-y-2 mt-4 border p-4 rounded-md">
//                         {isPayTotal && (
//                             <>
//                                 <p class="flex justify-between text-lg font-bold text-[#051836]">
//                                     <span>Total price:</span>
//                                     <span>₹ {finalPrice.toFixed(2)}</span>
//                                 </p>
//                                 <p class="flex justify-between text-sm font-medium text-[#051836]">
//                                     <span>GST (5%):</span>
//                                     <span>₹ {gstPrice.toFixed(2)}</span>
//                                 </p>
//                                 <p class="flex justify-between text-sm font-medium text-[#051836]">
//                                     <span>Total Member Price:</span>
//                                     <span>₹ {totalMemberPrice}</span>
//                                 </p>
//                                 <p class="flex justify-between text-sm font-medium text-[#051836]">
//                                     <span>Total Adult Price:</span>
//                                     <span>₹ {adultsPrice}</span>
//                                 </p>
//                                 {childrenPrice > 0 && (
//                                     <>
//                                         <p class="flex justify-between text-sm font-medium text-[#051836]">
//                                             <span>Total Children Price:</span>
//                                             <span>₹ {childrenPrice}</span>
//                                         </p>
//                                     </>
//                                 )}
//                             </>
//                         )}

//                         {!isPayTotal && (
//                             <>
//                                 <p class="flex justify-between text-lg font-bold text-[#051836]">
//                                     <span>Total price:</span>
//                                     <span>₹ {payLaterTotalPrice + payLaterGst}</span>
//                                 </p>

//                                 <p class="flex justify-between text-sm font-medium text-[#051836]">
//                                     <span>GST (5%):</span>
//                                     <span>₹ {payLaterGst}</span>
//                                 </p>
//                             </>
//                         )}
//                     </div>

//                     {
//                         isPayTotal
//                         &&
//                         <Razorpay
//                             userData={userData}
//                             amount={finalPrice}
//                             packageData={packageData}
//                             PriceDetails={PriceDetails}
//                         />

//                     }

//                     {
//                         !isPayTotal
//                         &&
//                         <Razorpay
//                             userData={userData}
//                             amount={payLaterTotalPrice + payLaterGst}
//                             packageData={packageData}
//                             PriceDetails={PriceDetails}
//                         />
//                     }

//                     <p class="mt-5 text-center text-sm font-semibold text-gray-500">
//                         By placing this order you agree to the &nbsp;
//                         <span class="text-[#30aadd]">Terms and Conditions</span>
//                     </p>
//                 </div>
//             </div>
//         </React.Fragment>
//     );
// };

// export default PaymentsForm;
