import React, { useState } from "react";

const Hotel_booking_faqs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "Do you offer 24-hour room service?",
      answer: "Yes, room service is available round the clock.",
    },
    {
      question: "Can I organize events or meetings at the hotel?",
      answer: "Yes, we offer event spaces for various types of gatherings.",
    },
    {
      question: "Do you offer laundry services?",
      answer: "Yes, laundry services are available upon request.",
    },
    {
      question: "Do you have accessible rooms for guests with disabilities?",
      answer: "Yes, we provide accessible rooms for guests with disabilities.",
    },
    {
      question: "What is your policy on smoking?",
      answer:
        "Our hotel is a non-smoking facility, except in designated smoking areas.",
    },
  ];

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="lg:py-10 py-4 font-be-vietnam tracking-wide">
      <h2 className="text-xl font-bold mb-6">Hotel Booking FAQs</h2>
      <div>
        {faqs.map((faq, index) => (
          <div key={index} className="border-b mb-4">
            <div
              className="flex justify-between items-center py-3 cursor-pointer"
              onClick={() => handleToggle(index)}
            >
              <h3 className="sm:text-base xl:text-md font-semibold">
                {faq.question}
              </h3>
              <div className="px-3 py-1 rounded-lg bg-[#FFF4E5]">
                <span className="text-2xl">
                  {openIndex === index ? "-" : "+"}
                </span>
              </div>
            </div>
            <div
              className={`overflow-hidden transition-all duration-1000 ease-in-out
                ${
                  openIndex === index
                    ? "max-h-[200px] opacity-100 scale-100"
                    : "max-h-0 opacity-0 scale-50"
                }`}
            >
              {openIndex === index && (
                <p className="text-gray-600 py-2">{faq.answer}</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Hotel_booking_faqs;
