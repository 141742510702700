import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { MdFormatListBulletedAdd } from 'react-icons/md';

const EditAdditionalDetailsForm = ({
    newHotelData,
    setNewHotelData,
    errors,
    amenity,
    setAmenity,
    handleAddAmenity,
    handleRemoveAmenity,
    handleImageUpload,
    imageFiles,
    handleRemoveImage,
    imgUploading
}) => {
    return (
        <div>
            <h2 className="text-xl font-semibold mb-4">
                Additional Details
            </h2>

            {/* Additional Fields */}
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">Base Price</label>
                    <input
                        type="number"
                        name="basePrice"
                        value={newHotelData.basePrice}
                        onChange={(e) =>
                            setNewHotelData({ ...newHotelData, basePrice: e.target.value })
                        }
                        className={`w-full p-2 border border-gray-300 rounded-md ${errors.basePrice ? 'border-red-500' : ''
                            }`}
                    />
                    {errors.basePrice && (
                        <p className="text-red-500 text-xs mt-1">{errors.basePrice}</p>
                    )}
                </div>

                {/* Check-in and Check-out Times */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">Check-in Time</label>
                    <input
                        type="time"
                        name="checkInTime"
                        value={newHotelData.checkInTime}
                        onChange={(e) =>
                            setNewHotelData({ ...newHotelData, checkInTime: e.target.value })
                        }
                        className={`w-full p-2 border border-gray-300 rounded-md ${errors.checkInTime ? 'border-red-500' : ''
                            }`}
                    />
                    {errors.checkInTime && (
                        <p className="text-red-500 text-xs mt-1">{errors.checkInTime}</p>
                    )}
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">Check-out Time</label>
                    <input
                        type="time"
                        name="checkOutTime"
                        value={newHotelData.checkOutTime}
                        onChange={(e) =>
                            setNewHotelData({ ...newHotelData, checkOutTime: e.target.value })
                        }
                        className={`w-full p-2 border border-gray-300 rounded-md ${errors.checkOutTime ? 'border-red-500' : ''
                            }`}
                    />
                    {errors.checkOutTime && (
                        <p className="text-red-500 text-xs mt-1">{errors.checkOutTime}</p>
                    )}
                </div>

                {/* Cancellation Policy */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">Cancellation Policy</label>
                    <textarea
                        name="cancellationPolicy"
                        value={newHotelData.cancellationPolicy}
                        onChange={(e) =>
                            setNewHotelData({ ...newHotelData, cancellationPolicy: e.target.value })
                        }
                        className={`w-full p-2 border border-gray-300 rounded-md resize-none ${errors.cancellationPolicy ? 'border-red-500' : ''
                            }`}
                        rows="3"
                    />
                    {errors.cancellationPolicy && (
                        <p className="text-red-500 text-xs mt-1">{errors.cancellationPolicy}</p>
                    )}
                </div>
            </div>

            {/* Amenities */}
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Amenities</label>
                <div className="flex mb-4">
                    <input
                        type="text"
                        value={amenity}
                        onChange={(e) => setAmenity(e.target.value)}
                        className="p-2 border border-gray-300 rounded-md"
                        placeholder="Add an amenity"
                    />
                    <button
                        type="button"
                        onClick={handleAddAmenity}
                        className="ml-2 p-2 bg-gradient-to-r from-yellow-400 to-orange-500 text-white rounded-md"
                    >
                        <MdFormatListBulletedAdd size={20} />
                    </button>
                </div>
                <ul className="list-disc pl-5 flex items-center gap-4">
                    {newHotelData.amenities.map((item, index) => (
                        <li key={index} className="flex gap-2 justify-between items-center bg-[#ebe8e8] p-2 rounded-md">
                            <span>{item}</span>
                            <FaTimes
                                className="text-red-500 cursor-pointer"
                                onClick={() => handleRemoveAmenity(item)}
                            />
                        </li>
                    ))}
                </ul>
            </div>

            {/* Upload Images */}
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Upload Images</label>
                <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleImageUpload}
                    className="w-full p-2 border border-gray-300 rounded-md"
                />
                {
                    imgUploading
                        ?
                        <div className='text-lg text-red-700 tracking-wide font-semibold mt-4 text-center'>
                            Don't Submit.. Your images are uploading..
                        </div>
                        :
                        <div className="mt-2">
                            {imageFiles.map((file, index) => (
                                <div key={index} className="flex items-center space-x-2">
                                    <p className="text-sm">{file.name}</p>
                                    <FaTimes
                                        className="text-red-500 cursor-pointer"
                                        onClick={() => handleRemoveImage(index)}
                                    />
                                </div>
                            ))}
                        </div>
                }
            </div>
        </div>
    )
}

export default EditAdditionalDetailsForm