import React from 'react'
import SimpleBanner from '../../components/common/SimpleBanner'
import AboutBanner from '../../Assets/user-imgs/Home-imgs/FeedbackBanner.png'
import WhoWeAre from '../../components/AboutComponents/WhoWeAre'
import OurVision from '../../components/AboutComponents/OurVision'
import OurCommitments from '../../components/AboutComponents/OurCommitments'
import Chairman from '../../components/AboutComponents/Chairman'
import OfficeDetails from '../../components/AboutComponents/OfficeDetails'
const About = () => {
  return (
    <div className='bg-white' style={{ fontFamily: "'Be Vietnam Pro', sans-serif" }}>
      <SimpleBanner
        image={AboutBanner}
        name="About"
        path="Home / About"
      />
      <WhoWeAre />
      <OurVision />
      <OurCommitments />
      <Chairman />
      <OfficeDetails />
    </div>
  )
}

export default About
