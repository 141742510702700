import React from 'react'
import { IoArrowBackCircle } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom';

const TabNavigation = ({ tabOrder, handleTabClick, isTabActive }) => {
    const navigate = useNavigate();

    return (
        <div className="mb-6 flex justify-between items-center">
            <div className="tracking-wider text-md">
                {tabOrder.map((tab) => (
                    <button
                        key={tab}
                        onClick={() => handleTabClick(tab)}
                        className={`flex-1 py-2 px-3 text-center font-medium transition-all ${isTabActive(tab)
                                ? 'bg-gradient-to-r from-yellow-400 to-orange-500 text-white'
                                : 'hover:bg-gradient-to-r hover:from-yellow-400 hover:to-orange-500 hover:text-white text-gray-800'
                            }`}
                        style={{
                            border: isTabActive(tab) ? 'none' : '1px solid #e5e7eb',
                        }}
                    >
                        {tab.replace(/([A-Z])/g, ' $1').trim()}
                    </button>
                ))}
            </div>
            <div>
                <IoArrowBackCircle
                    size={30}
                    color="#ec7e14"
                    onClick={() => navigate('/hotel')}
                    className="cursor-pointer"
                />
            </div>
        </div>
    )
}

export default TabNavigation