import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Swiper.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import img1 from '../../Assets/user-imgs/Home-imgs/newBanner1.png';
import img2 from '../../Assets/user-imgs/Home-imgs/newBanner2.png';
import img3 from '../../Assets/user-imgs/Home-imgs/newBanner3.png';
import img4 from '../../Assets/user-imgs/Home-imgs/newBanner4.png';
import { useNavigate } from 'react-router-dom';

const HeroSection = () => {
  const navigate = useNavigate();

  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
        onClick={() => {
          navigate("/destinations");
          window.scrollTo(600, 0);
        }}
      >
        <SwiperSlide>
          <img
            src={img1}
            alt='hero-banner'
            className='cursor-pointer'
          />

        </SwiperSlide>

        <SwiperSlide>
          <img
            src={img2}
            alt='hero-banner'
            className='cursor-pointer'
          />

        </SwiperSlide>

        <SwiperSlide>
          <img
            src={img3}
            alt='hero-banner'
            className='cursor-pointer'
          />

        </SwiperSlide>

        <SwiperSlide>
          <img
            src={img4}
            alt='hero-banner'
            className='cursor-pointer'
          />

        </SwiperSlide>
      </Swiper>
    </>)
}

export default HeroSection