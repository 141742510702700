import React from 'react';
import bgImg from '../../Assets/auth-imgs/bgImg.png';
import logo from '../../Assets/Logos/logoWhite.png';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const navigate = useNavigate();

  return (
    <div
      className="flex h-screen w-full items-center justify-center bg-cover bg-center"
      style={{
        backgroundImage: `url(${bgImg})`
      }}
    >
      <div className="w-full max-w-sm sm:max-w-md md:max-w-lg p-6 sm:p-8 bg-gray-800 bg-opacity-70 rounded-2xl shadow-xl backdrop-blur-md">
        <div className="text-white">
          {/* Logo and Header */}
          <div className="mb-6 flex flex-col items-center">
            <img
              src={logo}
              width="100"
              alt="wb-Logo"
              className='h-20 md:h-28 w-auto'
            />
            <h1 className="mb-2 text-2xl sm:text-3xl mt-6 font-semibold tracking-wide">
              Forgot Your Password
            </h1>
          </div>

          {/* Form Section */}
          <form>
            <div className="mb-6">
              <input
                type="email"
                placeholder="id@email.com"
                className="w-full rounded-full bg-yellow-400 bg-opacity-30 tracking-wide px-6 py-3 text-center text-gray-100 placeholder-slate-200 shadow-lg outline-none backdrop-blur-md focus:bg-opacity-50"
              />
            </div>
            <div className="mb-6">
              <input
                type="password"
                placeholder="*********"
                className="w-full rounded-full bg-yellow-400 bg-opacity-30 tracking-wide px-6 py-3 text-center text-gray-100 placeholder-slate-200 shadow-lg outline-none backdrop-blur-md focus:bg-opacity-50"
              />
            </div>
            <div className="mt-8 flex justify-center">
              <button
                className="w-full md:w-1/2 tracking-wider rounded-full bg-[#F88F26] font-bold px-10 py-3 text-white shadow-xl backdrop-blur-md transition duration-300 hover:bg-[#FF5901] focus:ring-4 focus:ring-yellow-300"
              >
                Forgot
              </button>
            </div>
          </form>

          {/* Additional Links */}
          <div className="mt-6 flex justify-center tracking-wider text-sm">
            <div className='space-x-4'>
              <button
                onClick={() => navigate('/')}
                className="hover:text-[#F88F26] transition duration-200"
              >
                Go to Home
              </button>
              <button
                onClick={() => navigate('/login')}
                className="hover:text-[#F88F26] transition duration-200"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
