import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SkeletonLoader from './SkeletonLoader';
import right from '../../Assets/user-imgs/Home-imgs/right.svg'
import { BiSolidQuoteAltRight } from 'react-icons/bi';
import { IoStar, IoStarHalf } from 'react-icons/io5';
import defaultImg from "../../Assets/Common/profile.png";
import left from '../../Assets/user-imgs/Home-imgs/left.svg'
const Testimonials = () => {
    const [AllFeedBack, setFeedBack] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const itemsPerPage = 4;

    const getAllFeedback = () => {
        setLoading(true);
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/feedback/allFeedback`,
            headers: {
                "Content-Type": "application/json",
            },
        };

        axios
            .request(config)
            .then((response) => {
                setFeedBack(response.data.allFeedback);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getAllFeedback();
    }, []);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentFeedback = AllFeedBack.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(AllFeedBack.length / itemsPerPage);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : totalPages));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : 1));
    };

    return (
        <div className="md:mx-16 mx-6 pb-4 mt-10">
            <div className='flex justify-between mb-4'>
                <div className="">
                    <h2
                        className="text-[#F94E05] text-lg tracking-wide"
                        style={{ fontFamily: "'Times New Roman', serif" }}>
                        Our Testimonials
                    </h2>
                    <h1
                        className="font-bold text-[#051836] text-2xl md:text-4xl tracking-wider md:leading-tight mb-4"
                        style={{ fontFamily: "'Be Vietnam Pro', sans-serif" }}>
                        What Our Clients Says
                    </h1>
                </div>
                <div className="flex justify-center space-x-4">
                    <img src={left} className='w-11' alt='img' onClick={handlePreviousPage} />
                    <img src={right} className='w-11' alt='img' onClick={handleNextPage} />
                </div>
            </div>
            
            <div className="grid lg:grid-cols-2 xl:grid-cols-4 gap-6 md:grid-cols-2 grid-cols-1 pb-6">
                {loading
                    ? [...Array(4)].map((_, index) => <SkeletonLoader key={index} />)
                    : currentFeedback.map((testimonial, index) => (
                        <div
                            className="flex flex-col justify-between h-full bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105"
                            key={index}
                        >
                            <div>
                                <div className="flex justify-end px-6 pt-4 text-[#02276E]">
                                    <BiSolidQuoteAltRight size={28} />
                                </div>
                                <div className="text-[#bbb829] mb-4 flex items-center gap-1 px-6">
                                    {[...Array(4)].map((_, i) => (
                                        <IoStar key={i} size={16} />
                                    ))}
                                    <IoStarHalf size={16} />
                                </div>
                                <p
                                    className="text-gray-600 mb-4 text-justify tracking-wide overflow-hidden px-6"
                                    style={{
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: 6,
                                        overflow: "hidden",
                                    }}
                                    title={testimonial.feedbackReview}
                                >
                                    {testimonial.feedbackReview || "No review provided."}
                                </p>
                            </div>

                            <div className="flex items-center gap-4 bg-[#f6f6d9] rounded-b-lg px-6 py-3">
                                <img
                                    className="w-14 h-14 rounded-full border-2 border-[#bbb829]"
                                    src={testimonial.userPic || defaultImg}
                                    alt="user-avatar"
                                    onError={(e) => {
                                        e.target.src = defaultImg;
                                        e.target.onerror = null;
                                    }}
                                />
                                <h3 className="text-orange-500 text-md tracking-wider font-semibold">
                                    {testimonial.name}
                                </h3>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default Testimonials;
