import React from 'react';
import fullstar from '../../Assets/user-imgs/hotels/star.png';

const HotelFilters = ({
    selectedPrices,
    handlePriceChange,
    hotelTypes,
    selectedTypes,
    handleTypeChange,
    selectedRatings,
    handleRatingChange,
    setSelectedPrices,
    setSelectedTypes,
    setSelectedRatings,
}) => {
    return (
        <div className="col-span-1">
            {/* Price Filter */}
            <h1 className="text-xl font-bold mb-4">
                Filter by:
            </h1>

            <div className="hidden lg:flex lg:flex-col space-y-6">
                {/* Price Filter */}
                <div>
                    <h2 className="font-bold text-lg mb-2">
                        Your spending planzz
                    </h2>
                    <div className="flex flex-col space-y-2">
                        <label className="flex items-center space-x-2 cursor-pointer">
                            <input
                                type="checkbox"
                                checked={selectedPrices.includes("100-500")}
                                onChange={() => handlePriceChange("100-500")}
                            />
                            <span>₹100 - ₹500</span>
                        </label>
                        <label className="flex items-center space-x-2 cursor-pointer">
                            <input
                                type="checkbox"
                                checked={selectedPrices.includes("300-500")}
                                onChange={() => handlePriceChange("300-500")}
                            />
                            <span>₹300 - ₹500</span>
                        </label>
                        <label className="flex items-center space-x-2 cursor-pointer">
                            <input
                                type="checkbox"
                                checked={selectedPrices.includes("500")}
                                onChange={() => handlePriceChange("500")}
                            />
                            <span>₹500+</span>
                        </label>
                    </div>
                </div>

                {/* Type Filter */}
                <div>
                    <h2 className="font-bold text-lg mb-2">Your Stay Preference</h2>
                    <div className="flex flex-col space-y-2">
                        {hotelTypes.map((item, index) => (
                            <div key={index}>
                                {Object.entries(item).map(([key, value], i) => (
                                    <label key={i} className="flex items-center space-x-2 cursor-pointer">
                                        <input
                                            type="checkbox"
                                            checked={selectedTypes.includes(value)}
                                            onChange={() => handleTypeChange(value)}
                                        />
                                        <span className='capitalize'>{value}</span>
                                    </label>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>

                {/* Rating Filter */}
                <div>
                    <h2 className="font-bold text-lg mb-2">Filter by Customer Rating</h2>
                    <div className="flex flex-col space-y-2">
                        {[1, 2, 3, 4, 5].map((rating) => (
                            <label
                                key={rating}
                                className="flex items-center space-x-2 cursor-pointer hover:text-orange-500 transition-colors"
                            >
                                <input
                                    type="checkbox"
                                    checked={selectedRatings.includes(rating)}
                                    onChange={() => handleRatingChange(rating)}
                                    className="w-4 h-4 accent-orange-500 cursor-pointer"
                                />
                                <span className="flex items-center">
                                    <span className="font-medium">{rating}</span>
                                    <span className="text-yellow-500 ml-1 flex gap-1 items-center">
                                        {Array.from({ length: Math.floor(rating) }, (_, index) => (
                                            <img
                                                key={index}
                                                className="h-4 w-auto"
                                                src={fullstar}
                                                alt="fullstar"
                                            />
                                        ))}
                                    </span>
                                </span>
                            </label>
                        ))}
                    </div>
                </div>

            </div>

            {/* Mobile View */}
            <div className="lg:hidden flex justify-start items-center gap-4 sm:flex-row flex-col w-full md:w-auto mb-6 md:px-0">

                {/* Price Filter for Smaller Screens */}
                <div className="flex flex-col space-y-2 w-full md:w-auto">
                    <label htmlFor="priceFilter" className="text-sm">
                        Your spending planzz:
                    </label>
                    <select
                        className="p-2 border border-gray-300 rounded-md"
                        onChange={(e) =>
                            setSelectedPrices(e.target.value ? [e.target.value] : [])
                        }
                    >
                        <option value="">All Prices</option>
                        <option value="100-300">₹100-₹300</option>
                        <option value="300-500">₹300-₹500</option>
                        <option value="500">₹500+</option>
                    </select>
                </div>

                {/* Type wise filter */}
                <div className="flex flex-col space-y-2 w-full md:w-auto">
                    <label htmlFor="priceFilter" className="text-sm">
                        Stay Preference:
                    </label>
                    <select
                        className="p-2 border border-gray-300 rounded-md"
                        onChange={(e) =>
                            setSelectedTypes(e.target.value ? [e.target.value] : [])
                        }
                    >
                        <option value="">All Types</option>
                        <option value="Standard">Standard</option>
                        <option value="deluxe">Deluxe</option>
                        <option value="Luxury">Luxury</option>
                    </select>
                </div>

                {/* ratings wise filter */}
                <div className="flex flex-col space-y-2 w-full md:w-auto">
                    <label htmlFor="priceFilter" className="text-sm">
                        Customers Ratings:
                    </label>

                    <select
                        className="p-2 border border-gray-300 rounded-md"
                        onChange={(e) =>
                            setSelectedRatings(e.target.value ? [Number(e.target.value)] : [])
                        }
                    >
                        <option value="">All Ratings</option>
                        {[1, 2, 3, 4, 5].map((rating) => (
                            <option key={rating} value={rating}>
                                {rating} {rating * "⭐"}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    )
}

export default HotelFilters