import React, { useState, useEffect, useRef } from "react";
import SimpleBanner from "../../components/common/SimpleBanner";
import HeadBanner from "../../Assets/Common/HotelHeader.png";
import enjoy from "../../Assets/user-imgs/Home-imgs/enjoy.svg";
import { FaLocationDot } from "react-icons/fa6";
import { ImExit, ImEnter } from "react-icons/im";
import { FaPersonWalkingLuggage } from "react-icons/fa6";
import { RiSearchEyeLine } from "react-icons/ri";
import { TbZoomReset } from "react-icons/tb";
import { BiReset } from "react-icons/bi";

const HotelBooking = () => {
  const [city, setCity] = useState("");
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [adults, setAdults] = useState(0);
  const [child, setChild] = useState(0);
  const [rooms, setRooms] = useState(0);
  const [pets, setPets] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isPlaceDropdownOpen, setIsPlaceDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);
  const placeDropdownRef = useRef(null);

  const places = [
    { name: "Puri", country: "India" },
    { name: "Delhi", country: "India" },
    { name: "Goa", country: "India" },
    { name: "Ooty", country: "India" },
    { name: "Mumbai", country: "India" },
  ];

  const handlePlaceSelect = (placeName) => {
    setCity(placeName);
    setIsPlaceDropdownOpen(false);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        isDropdownOpen
      ) {
        setIsDropdownOpen(false);
      }

      if (
        placeDropdownRef.current &&
        !placeDropdownRef.current.contains(event.target) &&
        isPlaceDropdownOpen
      ) {
        setIsPlaceDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen, isPlaceDropdownOpen]);

  return (
    <div className="bg-white font-be-vietnam">
      <SimpleBanner image={HeadBanner} name="Hotel Booking" path="WB Group / Hotel Booking" />

      <div className="xl:mx-16 lg:mx-10 mx-6 mt-10 pb-10">
        <div className="rounded-lg shadow-lg mt-10 mx-auto w-full">
          {/* Header Section */}
          <div className="bg-gradient-to-t from-blue-500 to-blue-400 flex justify-between items-center px-6 py-5 rounded-t-lg">
            <div>
              <h1 className="text-2xl font-bold text-yellow-400">
                Hotel Booking
              </h1>
              <p className="text-gray-100 tracking-wide font-medium mt-1">
                Search low prices on hotels, homes and much more...
              </p>
            </div>
            <img src={enjoy} alt="Enjoy" className="w-14" />
          </div>

          {/* Filter Section */}
          <div className="bg-white xl:p-6 lg:p-4 sm:p-4 p-3 rounded-b-lg">
            <div className="grid grid-cols-1 xl:grid-cols-7 lg:grid-cols-6 md:grid-cols-6 md:grid-rows-2 lg:grid-rows-2 xl:grid-rows-1 sm:grid:cols-3 gap-2">
              {/* Column 1: City */}
              <div
                className="mb-4 relative mt-5 md:col-span-2 lg:col-span-2 xl:col-span-1 col-span-1"
                ref={placeDropdownRef}
              >
                <label className="flex items-center font-semibold text-gray-700 mb-2 gap-2">
                  <FaLocationDot className="text-xl text-orange-600" />
                  City
                </label>
                <div
                  className="w-full p-3 border rounded cursor-pointer sm:mt-9"
                  onClick={() => setIsPlaceDropdownOpen(!isPlaceDropdownOpen)}
                >
                  {city || "Filter by Place"}
                </div>
                {isPlaceDropdownOpen && (
                  <div className="absolute top-full mt-2 p-4 border border-gray-300 rounded bg-white shadow-lg w-full z-10">
                    <p className="text-gray-700 font-medium mb-4">
                      Popular nearby destinations
                    </p>
                    {places.map((place, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-5 py-2 px-3 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handlePlaceSelect(place.name)}
                      >
                        <FaLocationDot className="text-lg text-gray-600" />
                        <div>
                          <p className="text-gray-800 font-medium">
                            {place.name}
                          </p>
                          <p className="text-sm text-gray-500">
                            {place.country}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Column 2: Check In Date */}
              <div className="mb-4 mt-5 md:col-span-2 lg:col-span-2 xl:col-span-1 col-span-1">
                <label className="flex items-center font-semibold text-gray-700 mb-2 gap-2">
                  <ImEnter className="text-xl text-orange-600" />
                  Check In
                </label>
                <input
                  type="date"
                  className="w-full p-3 border rounded focus:ring-2 focus:ring-orange-400 sm:mt-7"
                  value={checkIn}
                  onChange={(e) => setCheckIn(e.target.value)}
                />
              </div>

              {/* Column 3: Check Out Date */}
              <div className="mb-4 mt-5 md:col-span-2 lg:col-span-2 xl:col-span-1 col-span-1">
                <label className="flex items-center font-semibold text-gray-700 mb-2 gap-2">
                  <ImExit className="text-xl text-orange-600" />
                  Check Out
                </label>
                <input
                  type="date"
                  className="w-full p-3 border rounded focus:ring-2 focus:ring-orange-400 sm:mt-7"
                  value={checkOut}
                  onChange={(e) => setCheckOut(e.target.value)}
                />
              </div>

              {/* Column 4: Number of Guests and Rooms */}
              
              <div className="mb-4 md:col-span-3 xl:col-span-2 lg:col-span-3 relative mt-5" >
                <div className="flex flex-col justify-center items-center">
                  <p className="flex items-center text-gray-700 mb-2 gap-2">
                    <FaPersonWalkingLuggage className="text-xl text-orange-600" />
                    {adults} Adults • {child} Children • {rooms} Rooms
                  </p>
                  <div
                    className="flex justify-center items-center ml-1 md:px-3 px-1 py-3 border-[1px] border-gray-400 rounded-lg w-[100%] xl:w-[70%] cursor-pointer xl:mt-7 lg:mt-6 md:mt-6"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    ref={dropdownRef}
                  >
                    {adults} Adults • {child} Children • {rooms} Rooms
                  </div>
                </div>

                {isDropdownOpen && (
                  <div className="absolute top-full mt-2 xl:ml-14 p-4 border border-gray-400 rounded-lg bg-white shadow-lg w-full xl:w-[70%] z-10" ref={placeDropdownRef}>
                    {/* Adults */}
                    <div className="flex justify-between items-center mb-4">
                      <p className="text-gray-700 font-medium">Adults</p>
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => setAdults(adults - 1)}
                          className="px-2 py-1 border rounded-full text-gray-500 disabled:opacity-50"
                          disabled={adults <= 0}
                        >
                          -
                        </button>
                        <p className="font-medium">{adults}</p>
                        <button
                          onClick={() => setAdults(adults + 1)}
                          className="px-2 py-1 border rounded-full text-gray-500"
                        >
                          +
                        </button>
                      </div>
                    </div>

                    {/* Children */}
                    <div className="flex justify-between items-center mb-4">
                      <p className="text-gray-700 font-medium">Children</p>
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => setChild(child - 1)}
                          className="px-2 py-1 border rounded-full text-gray-500 disabled:opacity-50"
                          disabled={child <= 0}
                        >
                          -
                        </button>
                        <p className="font-medium">{child}</p>
                        <button
                          onClick={() => setChild(child + 1)}
                          className="px-2 py-1 border rounded-full text-gray-500"
                        >
                          +
                        </button>
                      </div>
                    </div>

                    {/* Rooms */}
                    <div className="flex justify-between items-center mb-4">
                      <p className="text-gray-700 font-medium">Rooms</p>
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => setRooms(rooms - 1)}
                          className="px-2 py-1 border rounded-full text-gray-500 disabled:opacity-50"
                          disabled={rooms <= 0}
                        >
                          -
                        </button>
                        <p className="font-medium">{rooms}</p>
                        <button
                          onClick={() => setRooms(rooms + 1)}
                          className="px-2 py-1 border rounded-full text-gray-500"
                        >
                          +
                        </button>
                      </div>
                    </div>

                    {/* Pets */}
                    <div className="flex justify-between items-center mb-6">
                      <p className="text-gray-700 font-medium">
                        Traveling with pets?
                      </p>
                      <div
                        className={`relative inline-block w-10 h-6 ${
                          pets ? "bg-green-500" : "bg-gray-300"
                        } rounded-full transition-colors duration-300 cursor-pointer`}
                        onClick={() => setPets(!pets)}
                      >
                        <div
                          className={`absolute top-1 left-1 w-4 h-4 bg-white rounded-full shadow-md transform transition-transform duration-300 ${
                            pets ? "translate-x-4" : ""
                          }`}
                        ></div>
                      </div>
                    </div>

                    {/* Done Button */}
                    <button
                      onClick={() => setIsDropdownOpen(false)}
                      className="w-full py-2 text-center font-medium text-blue-600 border border-blue-400 rounded-lg hover:bg-blue-50"
                    >
                      Done
                    </button>
                  </div>
                )}
              </div>

              {/* Column 5: Search and Reset Buttons */}
              <div className="xl:col-span-2 lg:col-span-3 md:col-span-3 ">
                <div className="flex lg:flex-col  flex-col justify-between items-start gap-4">
                  {/* Search Button */}
                  <div className="w-full">
                    <label className="flex items-center text-gray-700 mb-2 gap-2">
                      {/* <MdTour className="text-xl text-orange-600" />
                                                  Want to go for your ideal tour? */}
                    </label>

                    <div className="w-full bg-gradient-to-r from-orange-500 to-yellow-500 xl:p-[2px]  rounded">
                      <button className="flex items-center justify-center bg-white font-semibold xl:px-6 lg:px-1 py-2.5 rounded w-full bg-gradient-to-r from-orange-500 to-yellow-500 hover:to-orange-500 hover:from-yellow-500 text-white transition duration-200">
                        <RiSearchEyeLine size={22} className="mr-2" />
                        Please, Search the Tour
                      </button>
                    </div>
                  </div>

                  {/* Reset Button */}
                  <div className="w-full">
                    <label className="flex items-center text-gray-700 mb-2 gap-2 justify-center">
                      {/* <TbZoomReset className="text-xl text-orange-600" />
                                                  Do you want reset selected filters? */}
                    </label>

                    <div className="w-full bg-gradient-to-r from-gray-500 to-blue-700 xl:p-[2px] lg:p-[1px] rounded">
                      <button className="flex items-center justify-center bg-white font-semibold xl:px-6 lg:px-1 py-2.5 rounded w-full bg-gradient-to-r from-gray-500 to-blue-700 hover:to-gray-500 hover:from-blue-700 text-white transition duration-200 ">
                        <BiReset size={22} className="mr-2" />
                        Yes, Reset the Filters
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelBooking;