import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { RiMenu4Line } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import { MdCategory, MdDashboard, MdFeedback, MdTour } from "react-icons/md";
import { RiHotelFill } from "react-icons/ri";
import { FaCar } from "react-icons/fa";
const Sidebar = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const navigate = useNavigate();
  const path = useLocation();
  const [expandedMenu, setExpandedMenu] = useState(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const menuItems = [
    { label: "Dashboard", icon: <MdDashboard size={20} />, path: "/dashboard" },
    { label: "Tours", icon: <MdTour size={20} />, path: "/tours" },
    {
      label: "Tour & Location Type",
      icon: <MdCategory size={20} />,
      path: "/tours&loc-categories",
    },
    {
      label: "Hotel",
      icon: <RiHotelFill size={20} />,
      subMenu: [
        { label: "Hotels List", path: "/hotel" },
        { label: "Hotel Bookings", path: "/hotel-booking-history" },
      ],
    },
    {
      label: "Cabs",
      icon: <FaCar size={20} />,
      subMenu: [
        { label: "Cabs List", path: "/cab" },
        { label: "Cabs Bookings", path: "/cab-booking-history" },
      ],
    },
    { label: "Feedbacks", icon: <MdFeedback size={20} />, path: "/feedbacks" },
  ];

  const handleMenuClick = (item) => {
    if (item.subMenu) {
      // Toggle the submenu
      setExpandedMenu(expandedMenu === item.label ? null : item.label);
    } else {
      // Close any open submenu and navigate
      setExpandedMenu(null);
      navigate(item.path);
    }
  };

  const handleSubMenuClick = (subItem) => {
    // Keep the parent menu expanded and navigate
    navigate(subItem.path);
  };

  const renderMenuItems = () => (
    <ul className="space-y-2 tracking-wide">
      {menuItems.map((item, index) => (
        <li key={index}>
          {/* Main menu item */}
          <div
            onClick={() => handleMenuClick(item)}
            className={`${
              path.pathname === item.path || expandedMenu === item.label
                ? "bg-[#F88F26] text-white font-semibold"
                : "text-gray-300 hover:bg-yellow-400 hover:bg-opacity-30"
            } flex items-center cursor-pointer p-3 text-base rounded-lg transition duration-300`}
          >
            {item.icon}
            <span className="ml-3">{item.label}</span>
            {item.subMenu && (
              <span className="ml-auto text-xl">
                {expandedMenu === item.label ? "-" : "+"}
              </span>
            )}
          </div>

          {/* Nested submenu */}
          {item.subMenu && expandedMenu === item.label && (
            <ul className="pl-8 mt-2 space-y-1">
              {item.subMenu.map((subItem, subIndex) => (
                <li key={subIndex}>
                  <div
                    onClick={() => handleSubMenuClick(subItem)}
                    className={`${
                      path.pathname === subItem.path
                        ? "bg-[#F88F26] text-white font-semibold"
                        : "text-gray-300 hover:bg-yellow-400 hover:bg-opacity-30"
                    } cursor-pointer p-2 text-sm rounded-lg transition duration-300`}
                  >
                    {subItem.label}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );

  return (
    <React.Fragment>
      {/* Large sidebar */}
      <div className="lg:block hidden flex-shrink-0" aria-label="Sidebar">
        <div className="bg-[#0a1631] bg-opacity-90 text-gray-100 h-screen shadow-2xl px-6 pt-8">
          <div className="mb-8 flex flex-col items-center">
            <h2 className="text-2xl font-bold text-[#F88F26] tracking-wider">
              Menu
            </h2>
          </div>
          {renderMenuItems()}
        </div>
      </div>

      {/* Mobile sidebar */}
      <div className="block tracking-wide lg:hidden">
        <button
          onClick={toggleSidebar}
          className="absolute text-white top-4 left-4"
        >
          {isSidebarOpen ? <IoClose size={30} /> : <RiMenu4Line size={30} />}
        </button>
        <AnimatePresence>
          {isSidebarOpen && (
            <motion.div
              className="h-full w-2/3 bg-[#0a1631] absolute z-50 shadow-2xl"
              initial={{ opacity: 0, x: "-100%" }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: "-100%" }}
              transition={{ duration: 0.3 }}
            >
              <div className="pt-8 px-6 h-full">
                <div className="mb-8 flex flex-col items-center">
                  <h2 className="text-2xl font-bold text-[#F88F26]">Menu</h2>
                </div>
                {renderMenuItems()}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
