import React, { useEffect, useState } from "react";
import location from "../../Assets/user-imgs/Home-imgs/loction.svg";
import duration from "../../Assets/user-imgs/Home-imgs/days.svg";
import rating from "../../Assets/user-imgs/Home-imgs/rating.svg";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const OurTours = () => {
  const navigate = useNavigate();
  const [AllTours, setAllTours] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAllTours = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/new-packages/get-all-new-packages`,
      headers: {},
      data: "",
    };

    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        setAllTours(response?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllTours();
  }, []);

  const trendingTours = AllTours.filter((tour) => tour.tour_is_on_trend);

  return (
    <div className="md:mx-16 mx-6 mt-10">
      <div className="flex md:flex-row flex-col gap-4 justify-between">
        <div>
          <h2
            className="text-[#F94E05] tracking-wide text-lg"
            style={{ fontFamily: "'Times New Roman', serif" }}
          >
            Our Destinations
          </h2>
          <h1
            className="text-2xl md:text-4xl tracking-wide font-bold text-[#051836] mt-2"
            style={{ fontFamily: "'Be Vietnam Pro', sans-serif" }}
          >
            Most Trending Tours!
          </h1>
        </div>
        <div>
          <button
            className="md:block bg-gradient-to-r tracking-wide from-yellow-400 to-orange-500 text-white py-2 px-4 rounded-full hover:bg-gradient-to-r hover:to-yellow-400 hover:from-orange-500"
            onClick={() => {
              navigate("/destinations");
              window.scrollTo(600, 0);
            }}
          >
            View All Tours
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-4">
        {isLoading
          ? Array(3)
              .fill(null)
              .map((_, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-lg animate-pulse"
                >
                  <div className="w-full h-72 bg-gray-300 rounded-t-lg"></div>
                  <div className="p-4 space-y-4">
                    <div className="h-6 bg-gray-300 rounded-md w-3/4"></div>
                    <div className="flex justify-between">
                      <div className="w-1/4 h-5 bg-gray-300 rounded-md"></div>
                      <div className="w-1/4 h-5 bg-gray-300 rounded-md"></div>
                      <div className="w-1/4 h-5 bg-gray-300 rounded-md"></div>
                    </div>
                    <div className="h-8 bg-gray-300 rounded-full w-1/2 mx-auto"></div>
                  </div>
                </div>
              ))
          : trendingTours.map((tour, index) => (
              <div
                className="bg-white border border-[#e2e4e8] rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl"
                key={index}
              >
                <img
                  src={tour.image_url}
                  alt={tour.name}
                  className="w-full h-72 p-4 object-cover rounded-lg"
                />
                <div className="px-4 pb-4 space-y-4">
                  <h3 className="text-xl font-semibold text-gray-900 tracking-wider">
                    {tour.name}
                  </h3>
                  <div className="p-2 flex items-center justify-between tracking-wide text-gray-600 border-t-2 border-b-2 border-gray-200">
                    <div className="flex gap-2 items-center">
                      <img
                        src={location}
                        alt="location"
                        className="w-5 h-5"
                      />
                      <span>{tour.arrival_city}</span>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img
                        src={duration}
                        alt="duration"
                        className="w-5 h-5"
                      />
                      <span>{tour.tour_duration}</span>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img src={rating} alt="rating" className="w-5 h-5" />
                      <span>5 ⭐ Ratings</span>
                    </div>
                  </div>
                  <div className="text-center mt-4">
                    <button
                      onClick={() => navigate(`/tour-details/${tour?._id}`)}
                      className="tracking-wider text-lg gap-2 font-normal rounded-full flex items-center justify-center mx-auto"
                    >
                      <span>Explore Now</span>
                      <IoIosArrowDroprightCircle size={28} color="#ff7951" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default OurTours;















// import React, { useEffect, useState } from "react";
// import location from "../../Assets/user-imgs/Home-imgs/loction.svg";
// import duration from "../../Assets/user-imgs/Home-imgs/days.svg";
// import rating from "../../Assets/user-imgs/Home-imgs/rating.svg";
// import { IoIosArrowDroprightCircle } from "react-icons/io";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// const OurTours = () => {
//   const navigate = useNavigate();
//   const [AllTours, setAllTours] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);

//   const getAllTours = () => {
//     let config = {
//       method: "get",
//       maxBodyLength: Infinity,
//       url: `${process.env.REACT_APP_API_URL}/new-packages/get-all-new-packages`,
//       headers: {},
//       data: "",
//     };

//     setIsLoading(true);
//     axios
//       .request(config)
//       .then((response) => {
//         setAllTours(response?.data);
//         setIsLoading(false);
//       })
//       .catch((error) => {
//         console.log("error", error);
//         setIsLoading(false);
//       });
//   };

//   useEffect(() => {
//     getAllTours();
//   }, []);

//   return (
//     <div className="md:mx-16 mx-6 mt-10">
//       <div className="flex md:flex-row flex-col gap-4 justify-between">
//         <div>
//           <h2
//             className="text-[#F94E05] tracking-wide text-lg"
//             style={{ fontFamily: "'Times New Roman', serif" }}
//           >
//             Our Destinations
//           </h2>
//           <h1
//             className="text-2xl md:text-4xl tracking-wide font-bold text-[#051836] mt-2"
//             style={{ fontFamily: "'Be Vietnam Pro', sans-serif" }}
//           >
//             Most Treanding Tours!
//           </h1>
//         </div>
//         <div>
//           <button
//             className="md:block bg-gradient-to-r tracking-wide from-yellow-400 to-orange-500  text-white py-2 px-4 rounded-full hover:bg-gradient-to-r hover:to-yellow-400 hover:from-orange-500"
//             onClick={() => {
//               navigate("/destinations");
//               window.scrollTo(600, 0);
//             }}
//           >
//             View All Tours
//           </button>
//         </div>
//       </div>
//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-4">
//         {isLoading
//           ? Array(3)
//             .fill(null)
//             .map((_, index) => (
//               <div
//                 key={index}
//                 className="bg-white rounded-lg shadow-lg animate-pulse"
//               >
//                 <div className="w-full h-72 bg-gray-300 rounded-t-lg"></div>
//                 <div className="p-4 space-y-4">
//                   <div className="h-6 bg-gray-300 rounded-md w-3/4"></div>
//                   <div className="flex justify-between">
//                     <div className="w-1/4 h-5 bg-gray-300 rounded-md"></div>
//                     <div className="w-1/4 h-5 bg-gray-300 rounded-md"></div>
//                     <div className="w-1/4 h-5 bg-gray-300 rounded-md"></div>
//                   </div>
//                   <div className="h-8 bg-gray-300 rounded-full w-1/2 mx-auto"></div>
//                 </div>
//               </div>
//             ))
//           : AllTours.slice(0, 3).map((tour, index) => (
//             <div
//               className="bg-white border border-[#e2e4e8] rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl"
//               key={index}
//             >
//               <img
//                 src={tour.image_url}
//                 alt={tour.name}
//                 className="w-full h-72 p-4 object-cover rounded-lg"
//               />
//               <div className="px-4 pb-4 space-y-4">
//                 <h3 className="text-xl font-semibold text-gray-900 tracking-wider">{tour.name}</h3>
//                 <div className="p-2 flex items-center justify-between tracking-wide text-gray-600 border-t-2 border-b-2 border-gray-200">
//                   <div className="flex gap-2 items-center">
//                     <img src={location} alt="location" className="w-5 h-5" />
//                     <span>{tour.arrival_city}</span>
//                   </div>
//                   <div className="flex gap-2 items-center">
//                     <img src={duration} alt="duration" className="w-5 h-5" />
//                     <span>{tour.tour_duration}</span>
//                   </div>
//                   <div className="flex gap-2 items-center">
//                     <img src={rating} alt="rating" className="w-5 h-5" />
//                     <span>5 ⭐ Ratings</span>
//                   </div>
//                 </div>

//                 <div className="text-center mt-4">
//                   <button
//                     onClick={() => navigate(`/tour-details/${tour?._id}`)}
//                     className="tracking-wider text-lg gap-2 font-normal rounded-full flex items-center justify-center mx-auto">
//                     <span>
//                       Explore Now
//                     </span>
//                     <IoIosArrowDroprightCircle
//                       size={28}
//                       color="#ff7951"
//                     />
//                   </button>
//                 </div>
//               </div>
//             </div>
//           ))}
//       </div>
//     </div>
//   );
// };

// export default OurTours;
