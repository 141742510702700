import React from "react";
import {
    IoMdCall,
    IoIosPerson,
} from "react-icons/io";
import { MdEmail, MdOutlineLocationCity, MdTour } from "react-icons/md";
import { GiCheckMark } from "react-icons/gi";
import { FaChildren, FaPerson, FaUsers } from "react-icons/fa6";
import { BsCalendarDateFill } from "react-icons/bs";

const BookingDetails = ({ bookingData }) => {

    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        return isNaN(dateObject.getTime())
            ? "Invalid Date"
            : `${String(dateObject.getDate()).padStart(2, "0")}/${String(
                dateObject.getMonth() + 1
            ).padStart(2, "0")}/${dateObject.getFullYear()}`;
    };

    console.log('bookingData', bookingData)

    return (
        <div className="relative col-span-full flex flex-col py-6 pl-8 pr-4 lg:col-span-5">
            {/* Background */}
            <div>
                <img
                    src="https://e0.pxfuel.com/wallpapers/453/232/desktop-wallpaper-kedarnath-temple-thumbnail.jpg"
                    alt="Background"
                    className="absolute inset-0 h-full w-full object-cover"
                />
                <div className="absolute inset-0 h-full w-full bg-gradient-to-tr from-[#30aadd] via-[#507584] to-[#30aadd] opacity-95"></div>
            </div>

            {/* Customer Details */}
            <div className="relative">
                <SectionHeader title="Customer Details" />
                <DetailList>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <DetailItem icon={IoIosPerson} label={bookingData?.formData?.name} />
                        <DetailItem icon={MdEmail} label={bookingData?.formData?.email} />
                        <DetailItem icon={IoMdCall} label={bookingData?.formData?.phone} />
                        <DetailItem
                            icon={BsCalendarDateFill}
                            label={`Departure Date - ${formatDate(bookingData?.formData?.date)}`}
                        />
                        <DetailItem
                            icon={MdOutlineLocationCity}
                            label={`Departure City - ${bookingData?.formData?.departureCity}`}
                        />
                        <DetailItem
                            icon={FaPerson}
                            label={`Number of Adults - ${bookingData?.formData?.adultsCount}`}
                        />
                        <DetailItem
                            icon={FaChildren}
                            label={`Number of Children - ${bookingData?.formData?.childrenCount}`}
                        />
                        <DetailItem
                            icon={FaUsers}
                            label={`Total Members - ${(bookingData?.formData?.adultsCount || 0) + (bookingData?.formData?.childrenCount || 0)
                                }`}
                        />
                    </div>
                </DetailList>

                {/* Package Details */}
                <SectionHeader title="Package Details" />
                <DetailList>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {/* Full-width row for package name */}
                        <div className="col-span-1 md:col-span-2 font-bold">
                            <div className="flex items-center">
                                <MdTour size={24} color="#F94E05" />
                                <p className="text-lg tracking-wider text-[#000000] font-bold text-opacity-80 ml-2">
                                    {bookingData?.tourData?.name?.toUpperCase()}
                                </p>
                            </div>
                        </div>

                        {/* Two columns for the remaining details */}
                        <DetailItem
                            icon={GiCheckMark}
                            label={`Type of Package - ${bookingData?.selectedPackageType}`}
                        />
                        <DetailItem
                            icon={GiCheckMark}
                            label={`Departure Time - ${bookingData?.tourData?.departure_time}`}
                        />
                        <DetailItem
                            icon={GiCheckMark}
                            label={`Meals - ${bookingData?.tourData?.meals}`}
                        />
                        <DetailItem
                            icon={GiCheckMark}
                            label={`Tour Duration - ${bookingData?.tourData?.tour_duration}`}
                        />
                    </div>
                </DetailList>

                <div className="my-4 h-0.5 w-full bg-white bg-opacity-30"></div>
            </div>

            {/* Support Section */}
            <div className="flex justify-center items-center text-center tracking-wider">
                <div className="relative text-white space-y-4">
                    <h3 className="mb-2 text-lg font-bold">Support</h3>
                    <p className="text-sm font-semibold">+91-8178958591, +91-9310366002</p>
                    <p className="text-sm font-semibold">
                        +91-9540512487, +91-8076663076 <span>(if urgent)</span>
                    </p>
                    <p className="mt-1 text-sm font-semibold">info@weekendbhraman.com</p>
                    <p className="mt-2 text-xs font-medium">
                        Call us now for payment-related issues
                    </p>
                </div>
            </div>
        </div>
    );
};

export default BookingDetails;

// Helper Components
const SectionHeader = ({ title }) => (
    <h1 className="relative text-2xl font-medium tracking-wider text-white sm:text-3xl mt-4">
        {title}
        <span className="mt-2 block h-1 w-full bg-white mb-4"></span>
    </h1>
);

const DetailList = ({ children }) => <ul className="space-y-5">{children}</ul>;

const DetailItem = ({ icon: Icon, label }) => (
    <li className="flex items-center">
        <Icon size={18} color="black" />
        <p className="text-md font-medium tracking-wider text-white text-opacity-80 ml-2">
            {label || "N/A"}
        </p>
    </li>
);












// import React from "react";
// import { IoMdCall } from "react-icons/io";
// import { MdEmail } from "react-icons/md";
// import { MdOutlineLocationCity } from "react-icons/md";
// import { IoIosPerson } from "react-icons/io";
// import { MdTour } from "react-icons/md";
// import { GiCheckMark } from "react-icons/gi";
// import { FaChildren } from "react-icons/fa6";
// import { BsCalendarDateFill } from "react-icons/bs";
// import { FaPerson } from "react-icons/fa6";
// import { FaUsers } from "react-icons/fa";

// const BookingDetails = ({ bookingData }) => {
//   // Destructure bookingData into userData and packageData
//   const { userData, packageData } = bookingData || {};

//   const formatDate = (dateString) => {
//     const dateObject = new Date(dateString);
//     if (isNaN(dateObject.getTime())) {
//       return "Invalid Date";
//     }
//     return `${String(dateObject.getDate()).padStart(2, "0")}/${String(
//       dateObject.getMonth() + 1
//     ).padStart(2, "0")}/${dateObject.getFullYear()}`;
//   };

//   console.log("BookingDetails", bookingData);

//   return (
//     <React.Fragment>
//       <div className="relative col-span-full flex flex-col py-6 pl-8 pr-4 lg:col-span-5">
//         <div>
//           <img
//             src="https://e0.pxfuel.com/wallpapers/453/232/desktop-wallpaper-kedarnath-temple-thumbnail.jpg"
//             alt=""
//             className="absolute inset-0 h-full w-full object-cover"
//           />
//           <div className="absolute inset-0 h-full w-full bg-gradient-to-tr from-[#30aadd] via-[#507584] to-[#30aadd] opacity-95"></div>
//         </div>

//         <div className="relative">
//           <h1 className="relative text-2xl font-medium tracking-wider text-white sm:text-3xl">
//             Customer Details
//             <span className="mt-2 block h-1 w-full bg-white mb-4"></span>
//           </h1>

//           <ul className="space-y-5">
//             <li className="flex justify-between">
//               <div className="w-full">
//                 <div className="flex items-between">
//                   <div className="">
//                     <IoIosPerson size={34} color="#000" />
//                   </div>
//                   <p className="text-lg capitalize font-bold tracking-wider text-white ml-2">
//                     {userData?.name}
//                   </p>
//                 </div>
//                 <span className="mt-1 block h-0.5 w-full md:w-1/2 bg-white mb-4"></span>

//                 <div className="grid grid-cols-10 w-full">
//                   <div className="md:col-span-5 col-span-full">
//                     <div className="mt-2 flex items-between">
//                       <MdEmail size={18} color="black" />
//                       <div className="text-md font-medium text-white tracking-wide text-opacity-80 ml-2">
//                         {userData?.email}
//                       </div>
//                     </div>

//                     <div className="mt-2 flex items-between">
//                       <IoMdCall size={18} color="black" />
//                       <div className="text-md font-medium text-white tracking-wide text-opacity-80 ml-2">
//                         {userData?.phone}
//                       </div>
//                     </div>

//                     <div className="mt-2 flex items-between">
//                       <BsCalendarDateFill size={16} color="black" />
//                       <div className="text-md font-medium text-white tracking-wide text-opacity-80 ml-2">
//                         Departure Date - {formatDate(userData?.date)}
//                       </div>
//                     </div>

//                     <div className="mt-2 flex items-between">
//                       <MdOutlineLocationCity size={18} color="black" />
//                       <div className="text-md tracking-wide capitalize font-medium text-white text-opacity-80 ml-2">
//                         Departure City - {userData?.departureCity}
//                       </div>
//                     </div>
//                   </div>

//                   <div className="md:col-span-5 col-span-full">
//                     <div className="mt-2 flex items-between">
//                       <FaPerson size={18} color="black" />
//                       <div className="text-md font-medium tracking-wide text-white text-opacity-80 ml-2">
//                         Number of Adults - {userData?.adultsCount}
//                       </div>
//                     </div>

//                     <div className="mt-2 flex items-between">
//                       <FaChildren size={18} color="black" />
//                       <div className="text-md font-medium tracking-wide text-white text-opacity-80 ml-2">
//                         Number of Children - {userData?.childrenCount}
//                       </div>
//                     </div>

//                     <div className="mt-2 flex items-between">
//                       <FaUsers size={18} color="black" />
//                       <div className="text-md font-medium tracking-wide text-white text-opacity-80 ml-2">
//                         Total Members - {userData?.adultsCount + userData?.childrenCount}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </li>
//           </ul>

//           <h1 className="relative text-2xl font-medium tracking-wider text-white sm:text-3xl mt-4">
//             Package Details
//             <span className="mt-2 block h-1 w-full bg-white mb-4"></span>
//           </h1>

//           <ul className="space-y-5">
//             <li className="flex justify-between">
//               <div className="w-full">
//                 <div className="flex items-between">
//                   <div className="">
//                     <MdTour size={34} color="#000" />
//                   </div>
//                   <p className="text-lg font-bold uppercase text-white ml-2">
//                     {packageData?.package?.name}
//                   </p>
//                 </div>
//                 <span className="mt-1 block h-0.5 w-full md:w-1/2 bg-white mb-4"></span>

//                 <div className="grid grid-cols-10 w-full">
//                   <div className="md:col-span-5 col-span-full">
//                     <div className="mt-2 flex items-between">
//                       <GiCheckMark size={18} color="black" />
//                       <div className="text-md capitalize font-medium tracking-wide text-white text-opacity-80 ml-2">
//                         Type of Package - {packageData?.packageCategory?.type}
//                       </div>
//                     </div>

//                     <div className="mt-2 flex items-between">
//                       <GiCheckMark size={18} color="black" />
//                       <div className="text-md capitalize font-medium tracking-wide text-white text-opacity-80 ml-2">
//                         Departure Time - {packageData?.package?.departure_time}
//                       </div>
//                     </div>
//                   </div>

//                   <div className="md:col-span-5 col-span-full">
//                     <div className="mt-2 flex items-between">
//                       <GiCheckMark size={18} color="black" />
//                       <div className="text-md capitalize font-medium tracking-wide text-white text-opacity-80 ml-2">
//                         {packageData?.package?.meals}
//                       </div>
//                     </div>

//                     <div className="mt-2 flex items-between">
//                       <GiCheckMark size={18} color="black" />
//                       <div className="text-md capitalize font-medium tracking-wide text-white text-opacity-80 ml-2">
//                         {packageData?.package?.tour_duration}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </li>
//           </ul>

//           <div className="my-4 h-0.5 w-full bg-white bg-opacity-30"></div>
//         </div>

//         <div className="flex justify-center w-full items-center tracking-wider">
//           <div className="relative text-white text-center">
//             <h3 className="mb-2 text-lg font-bold">Support</h3>
//             <p className="text-sm font-semibold">
//               +91-8178958591, +91-9310366002
//             </p>
//             <p className="text-sm font-semibold">
//               +91-9540512487, +91-8076663076 <span>(if urgent)</span>
//             </p>
//             <p className="mt-1 text-sm mailto:font-semibold">
//               Info@weekendbhraman.com
//             </p>
//             <p className="mt-2 text-xs font-medium">
//               Call us now for payment related issues
//             </p>
//           </div>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// };

// export default BookingDetails;
