import React, { useEffect } from "react";
import roomImg from "../../Assets/user-imgs/Home-imgs/interior-design.png";
import { IoBookmarkSharp } from "react-icons/io5";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const RoomDetails = () => {
  const [adultCount, setAdultCount] = useState(0);
  const [childrenCount, setChildrenCount] = useState(0);
  const { id, title } = useParams();
  const [roomData, setRoomData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    handleRoomData();
  }, []);

  const handleRoomData = () => {
    let data = "";
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/rooms/get-room-by-id/${id}`,
      headers: {},
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setRoomData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(roomData);

  return (
    <div className="mt-5 font-be-vietnam px-6 mb-5">
      <div className="flex justify-start items-center gap-3">
        <MdOutlineArrowBackIos size={24} onClick={() => navigate(-1)} />
        <p className="text-2xl font-bold ">Room Details</p>
      </div>
      <div className="w-full  pb-3 mt-3">
        <div className=" bg-[#b9bcc3] rounded-lg shadow-lg flex justify-start sm:items-center   flex-col sm:flex-row w-full gap-16 p-5">
          <div className="flex justify-start  items-center p-8 rounded-lg bg-slate-200">
            <img
              src={roomImg}
              alt="Room image"
              className="h-[100px] w-[100px]  "
            />
          </div>

          <div className="py-2 px-3">
            <div className="text-lg font-bold capitalize mb-2">
              {roomData.name}
            </div>

            <p className="mb-2">
              <span className="font-semibold">Hotel:</span>{" "}
              <span className="text-md font-semibold">{title}</span>
            </p>
            <p className="mb-2">
              <span className="font-semibold">Type:</span>{" "}
              <span className="text-md font-semibold">{roomData.type}</span>
            </p>
            <p className="mb-2">
              <span className="font-semibold">Price: </span>{" "}
              <span className="text-md font-semibold">{roomData.price}</span>
            </p>
            <p className="mb-2">
              <span className="text-md font-semibold">
                {roomData.description}
              </span>
            </p>
          </div>
        </div>
      </div>

      {/* <div className="flex justify-start items-center mt-3 ">
        <div className="w-full ">
          <p className="text-xl font-bold">Book For Someone:</p>
          <div className="mt-3 ">
            <div className="flex justify-between items-center lg:flex-row flex-col gap-5">
              <div className="flex justify-between items-center xl:gap-44 lg:gap-28 gap-32 ">
                <p className="text-lg font-semibold mt-4"> No. Of Adult:</p>{" "}
                <div className="flex items-center sm:space-x-6 sm:w-[200px] w-[100px] justify-between mt-4">
                  <button
                    type="button"
                    className="w-7 h-7 flex justify-center items-center text-2xl rounded-full font-bold text-white bg-gradient-to-r from-[#FF5C00] to-[#FF9900] hover:scale-105 transition-all duration-300 shadow-md focus:outline-none"
                    onClick={() => {
                      if (adultCount > 0) {
                        setAdultCount(adultCount - 1);
                      }
                    }}
                  >
                    -
                  </button>

                  <div className="text-xl font-semibold text-gray-700 w-8 h-8 flex justify-center items-center border border-gray-300 rounded-md bg-gray-50">
                    {adultCount}
                  </div>
                  <button
                    type="button"
                    className="w-7 h-7 flex justify-center items-center text-2xl rounded-full font-bold text-white bg-gradient-to-r from-[#FF5C00] to-[#FF9900] hover:scale-105 transition-all duration-300 shadow-md focus:outline-none"
                    onClick={() => setAdultCount(adultCount + 1)}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="flex justify-center items-center xl:gap-44 lg:gap-20 gap-28">
                <p className="text-lg font-semibold mt-4 pl-1">
                  No. Of Children:
                </p>
                <div className="flex items-center sm:space-x-6 sm:w-[200px] w-[100px] justify-between mt-4">
                  <button
                    type="button"
                    className="w-7 h-7 flex justify-center items-center text-2xl rounded-full font-bold text-white bg-gradient-to-r from-[#FF5C00] to-[#FF9900] hover:scale-105 transition-all duration-300 shadow-md focus:outline-none "
                    onClick={() => {
                      if (childrenCount > 0) {
                        setChildrenCount(childrenCount - 1);
                      }
                    }}
                  >
                    -
                  </button>

                  <div className="text-xl font-semibold text-gray-700 w-8 h-8 flex justify-center items-center border border-gray-300 rounded-md bg-gray-50">
                    {childrenCount}
                  </div>
                  <button
                    type="button"
                    className="w-7 h-7 flex justify-center items-center text-2xl rounded-full font-bold text-white bg-gradient-to-r from-[#FF5C00] to-[#FF9900] hover:scale-105 transition-all duration-300 shadow-md focus:outline-none"
                    onClick={() => setChildrenCount(childrenCount + 1)}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <form action="" className="mt-3 w-full">
              <div className="flex justify-center md:flex-row flex-col items-center gap-4 w-full">
                <div className="w-full">
                  <label
                    htmlFor="firstName"
                    className="block text-md font-semibold text-gray-700"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="Enter your first name"
                    className="mt-1 block w-full h-9 xl:h-10 lg:h-9 border-[1px] px-2 
                   border-black rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="lastName"
                    className="block text-md font-semibold text-gray-700"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Enter your Last name"
                    className="mt-1 block w-full h-9 xl:h-10 lg:h-9 border-[1px] px-2 
                  border-black
                 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="flex justify-center md:flex-row flex-col items-center gap-4 w-full mt-3">
                <div className="w-full">
                  <label
                    htmlFor="email"
                    className="block text-md font-semibold text-gray-700"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter your email"
                    className="mt-1 block w-full h-9 xl:h-10 lg:h-9 border-[1px] px-2 
                  border-black
                 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="mobile"
                    className="block text-md font-semibold text-gray-700"
                  >
                    Mobile Number
                  </label>
                  <div className="flex items-center mt-1">
                    <select
                      id="countryCode"
                      name="countryCode"
                      className="border-black h-9 xl:h-10 lg:h-9 md:px-2 px-0 border-[1px] rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mr-1"
                    >
                      <option value="IN">IN +91</option>
                      <option value="US">US +1</option>
                      <option value="UK">UK +44</option>
                    </select>
                    <input
                      type="tel"
                      id="mobile"
                      name="mobile"
                      className="block w-full h-9 xl:h-10 lg:h-9 border-[1px] px-2 
                     border-black
                  rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ml-1"
                      placeholder="Enter mobile number"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full mt-3 flex md:flex-row flex-col justify-center items-center">
                <div className="md:w-[50%] w-full">
                  <label
                    htmlFor="country"
                    className="block text-md font-semibold text-gray-700"
                  >
                    Country/Region
                  </label>
                  <select
                    id="country"
                    name="country"
                    className="mt-1 block px-2  w-full h-9 xl:h-10 lg:h-9 border-[1px] 
                  errors.country  border-black
                rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="">Select a country</option>
                    <option value="IN">India</option>
                    <option value="US">United States</option>
                    <option value="UK">United Kingdom</option>
                  </select>
                </div>
              </div>
              <div className="mt-3 w-full flex justify-end items-center">
                <button className="flex justify-center items-center text-md  py-2 px-[75px] rounded-lg bg-[#2D87D0] font-semibold  md:w-[20%] w-full">
                  Book
                </button>
              </div>
            </form>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default RoomDetails;
