import React from 'react';

const SkeletonLoader = () => {
    return (
        <div className="flex flex-col justify-between h-full bg-white rounded-lg shadow-lg animate-pulse">
            <div>
                <div className="flex justify-end px-6 pt-4">
                    <div className="bg-gray-300 rounded-full h-7 w-7"></div>
                </div>
                <div className="flex items-center gap-1 px-6 mt-4">
                    {[...Array(5)].map((_, i) => (
                        <div
                            key={i}
                            className="bg-gray-300 rounded-full h-4 w-4"
                        ></div>
                    ))}
                </div>
                <div className="bg-gray-300 h-5 mt-4 mx-6 rounded"></div>
                <div className="bg-gray-300 h-5 mt-2 mx-6 rounded"></div>
                <div className="bg-gray-300 h-5 mt-2 mx-6 rounded"></div>
            </div>
            <div className="flex items-center gap-4 bg-gray-100 rounded-b-lg px-6 py-3 mt-4">
                <div className="bg-gray-300 rounded-full h-14 w-14"></div>
                <div className="bg-gray-300 h-5 w-32 rounded"></div>
            </div>
        </div>
    );
};

export default SkeletonLoader;
