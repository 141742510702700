import React, { useEffect, useState } from "react";
import location from "../../Assets/user-imgs/Home-imgs/loction.svg";
import duration from "../../Assets/user-imgs/Home-imgs/days.svg";
import rating from "../../Assets/user-imgs/Home-imgs/rating.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TourSkeletonLoader from "./TourSkeletonLoader";
import { IoIosArrowDroprightCircle } from "react-icons/io";

const DestinationsTours = () => {
  const navigate = useNavigate();
  const [AllTours, setAllTours] = useState([]);
  const [filteredTours, setFilteredTours] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  // States for sorting and filtering
  const [priceFilter, setPriceFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");

  const getAllTours = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/new-packages/get-all-new-packages`,
      headers: {},
      data: "",
    };

    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        setAllTours(response?.data);
        setFilteredTours(response?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllTours();
  }, []);

  // Filter tours based on search and selected filters
  useEffect(() => {
    let filtered = AllTours.filter((tour) =>
      tour.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Apply price filter
    if (priceFilter) {
      filtered = filtered.filter((tour) => {
        const price = tour.type_of_packages[0]?.price;
        if (priceFilter === "low") return price < 30000;
        if (priceFilter === "high") return price >= 30000;
        return true;
      });
    }

    // Apply location filter (now "Tour Start From Location")
    if (locationFilter) {
      filtered = filtered.filter((tour) => {
        const startingCity = tour.departure_place || ""; // Ensure it's always a string
        return startingCity.toLowerCase().includes(locationFilter.toLowerCase());
      });
    }

    // Apply package type filter
    if (typeFilter) {
      filtered = filtered.filter((tour) =>
        tour.type_of_packages.some(
          (pkg) => pkg.type.toLowerCase() === typeFilter.toLowerCase()
        )
      );
    }

    setFilteredTours(filtered);
  }, [searchQuery, priceFilter, locationFilter, typeFilter, AllTours]);

  // Function to clear all filters
  const clearFilters = () => {
    setPriceFilter("");
    setLocationFilter("");
    setTypeFilter("");
    setSearchQuery("");
  };

  return (
    <div className="md:mx-16 mx-6 pb-10">
      <div className="flex md:flex-row flex-col gap-4 justify-between">
        <div>
          <h2 className="text-[#F94E05] tracking-wide text-lg font-serif">
            Our Tours
          </h2>
          <h1 className="text-2xl md:text-4xl tracking-wider font-bold text-[#051836] mt-2 font-sans">
            Treading Tour Packages!
          </h1>
        </div>
      </div>

      {/* Filters and Search Bar */}
      <div className="flex flex-col md:flex-row md:justify-between gap-4 mt-6">
        {/* Filters */}
        <div className="flex gap-4 w-full md:w-auto">
          <select
            className="p-2 rounded-md border w-full md:w-auto"
            value={priceFilter}
            onChange={(e) => setPriceFilter(e.target.value)}
          >
            <option value="">Filter by Price</option>
            <option value="low">Under ₹30,000</option>
            <option value="high">₹30,000 and above</option>
          </select>

          <select
            className="p-2 rounded-md border w-full md:w-auto"
            value={locationFilter}
            onChange={(e) => setLocationFilter(e.target.value)}
          >
            <option value="">Filter by Tour Start From Location</option>
            <option value="Delhi">Delhi</option>
            <option value="Haridwar">Haridwar</option>
            <option value="Uttarakhand">Uttarakhand</option>
            <option value="Mumbai">Mumbai</option>
            <option value="Bangalore">Bangalore</option>
            <option value="Goa">Goa</option>
            <option value="Jaipur">Jaipur</option>
            <option value="Kolkata">Kolkata</option>
            <option value="Chennai">Chennai</option>
            <option value="Hyderabad">Hyderabad</option>
            <option value="Rishikesh">Rishikesh</option>
            <option value="Agra">Agra</option>
            <option value="Varanasi">Varanasi</option>
            <option value="Pune">Pune</option>
            <option value="Shimla">Shimla</option>
          </select>

          <select
            className="p-2 rounded-md border w-full md:w-auto"
            value={typeFilter}
            onChange={(e) => setTypeFilter(e.target.value)}
          >
            <option value="">Filter by Package Type</option>
            <option value="Standard">Standard</option>
            <option value="deluxe">deluxe</option>
          </select>

          {/* Clear Filters Button */}
          <button
            onClick={clearFilters}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md font-medium hover:bg-gray-400 transition"
          >
            Clear Filters
          </button>
        </div>

        {/* Search Bar */}
        <div className="flex w-full md:w-auto">
          <div className="flex border-2 border-orange-500 rounded-full overflow-hidden shadow-md w-full">
            <input
              type="text"
              placeholder="Search tours.."
              className="w-full px-4 py-2 text-gray-700 focus:outline-none"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button className="bg-orange-500 text-white px-6 text-sm font-medium hover:bg-orange-600 transition">
              Search
            </button>
          </div>
        </div>
      </div>

      {isLoading ? (
        <TourSkeletonLoader />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-7">
          {filteredTours.map((tour, index) => (
            <div
              className="bg-white border border-[#e2e4e8] rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl"
              key={index}
            >
              <img
                src={tour.image_url}
                alt={tour.name}
                className="w-full h-72 p-4 object-cover rounded-lg"
              />
              <div className="px-4 pb-4 space-y-4">
                <h3 className="text-xl font-semibold text-gray-900 tracking-wider">
                  {tour.name}
                </h3>
                <div className="p-2 flex items-center justify-between tracking-wide text-gray-600 border-t-2 border-b-2 border-gray-200">
                  <div className="flex gap-2 items-center">
                    <img src={location} alt="location" className="w-5 h-5" />
                    <span>{tour.arrival_city}</span>
                  </div>
                  <div className="flex gap-2 items-center">
                    <img src={duration} alt="duration" className="w-5 h-5" />
                    <span>{tour.tour_duration}</span>
                  </div>
                  <div className="flex gap-2 items-center">
                    <img src={rating} alt="rating" className="w-5 h-5" />
                    <span>5 ⭐ Ratings</span>
                  </div>
                </div>

                <div className="text-center mt-4">
                  <button
                    onClick={() => navigate(`/tour-details/${tour?._id}`)}
                    className="tracking-wider text-lg gap-2 font-normal rounded-full flex items-center justify-center mx-auto"
                  >
                    <span>Explore Now</span>
                    <IoIosArrowDroprightCircle size={28} color="#ff7951" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DestinationsTours;