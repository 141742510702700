import React from 'react';
import AllTours from '../../components/AdminSideComponents/Tours/AllTours';
import { useNavigate } from 'react-router-dom';
const Tours = () => {
  const navigate = useNavigate();
  const stats = [
    { title: 'Total Tours', value: 15, color: 'text-blue-600' },
    { title: 'Total Bookings', value: 120, color: 'text-green-600' },
    { title: 'Total Revenue', value: '$15,000', color: 'text-yellow-600' },
    { title: 'Pending Payments', value: 5, color: 'text-red-600' },
  ];

  return (
    <div className="p-8">
      <div className='flex justify-between'>
        <h1 className="text-3xl font-semibold mb-2">
          Tour List
        </h1>
        <button
          onClick={() => navigate('/addNewTours')}
          className='bg-gradient-to-r text-lg flex items-center from-yellow-400 to-orange-500 hover:bg-yellow-800 text-white px-5 tracking-wide rounded-md hover:bg-gradient-to-r hover:to-yellow-400 hover:from-orange-500'>
          Add New Tour
        </button>
      </div>
      
      <AllTours />
    </div>
  );
};

export default Tours;
