import React from 'react';
import bgImg from '../../Assets/auth-imgs/mountains-with-fog.jpg';
import founder from '../../Assets/auth-imgs/FoundersImg.png';

const Profile = () => {
  return (
    <React.Fragment>
      <main className="profile-page">
        {/* Banner Section */}
        <section className="relative block h-[500px]">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                `url(${bgImg})`,
            }}
          >
            <span className="w-full h-full absolute opacity-50 bg-black"></span>
          </div>
          <div className="top-auto bottom-0 left-0 right-0 w-full absolute overflow-hidden h-[70px] pointer-events-none">
            <svg
              className="absolute bottom-0 w-full"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              viewBox="0 0 2560 100"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>

        {/* Profile Info Section */}
        <section className="relative py-16 bg-blueGray-200">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col bg-white w-full mb-6 shadow-xl rounded-lg -mt-64 p-6">
              <div className="flex justify-center">
                {/* Profile Image */}
                <div className="relative">
                  <img
                    alt="profile-img"
                    src={founder}
                    className="shadow-xl rounded-full h-auto border-none -m-16 -ml-20 lg:-ml-16 max-w-[150px]"
                  />
                </div>
              </div>
              <div className="text-center mt-8">
                <h3 className="text-4xl font-semibold text-blueGray-700 mt-12">
                  Diwakar Singh
                </h3>
                <div className="text-blueGray-400 text-sm font-bold mt-2">
                  <i className="fas fa-map-marker-alt mr-2"></i>Founder & Chairman
                </div>
                <div className="text-blueGray-600 mt-4">
                  <i className="fas fa-briefcase mr-2"></i>chairman.weekendbhraman@gmail.com
                </div>
                <div className="text-blueGray-600 mt-2">
                  <i className="fas fa-university mr-2"></i>+91-9540512487 / +91-8076663076
                </div>
              </div>

              <div className="text-center mt-8">
                <button className="bg-[#F88F26] hover:bg-[#FF5901] text-white font-bold px-6 py-2 rounded-full transition">
                  Change Password
                </button>
              </div>

              <div className="mt-8 py-6 border-t border-blueGray-200 text-center">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-9/12 px-4">
                    <p className="mb-4 text-lg text-blueGray-700">
                      Working with our professional experience of 10+ years expierence in Hospitality and tourism sector, He exemplifies under weaving commitment to ethics decipline.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};

export default Profile;
