import React, { useState } from "react";
import SimpleBanner from "../../components/common/SimpleBanner";
import HeadBanner from "../../Assets/Common/HotelHeader.png";
import Details from "../../components/Booking/Details";
import { useNavigate } from "react-router-dom";

const BookingForm = () => {
  const navigate = useNavigate();
  const [detailsData, setDetailsData] = useState({});

  return (
    <div className="bg-white">
      {/* Banner Section */}
      <div>
        <SimpleBanner
          image={HeadBanner}
          name="Hotel Booking"
          path="WB Group / Hotel Booking"
        />
      </div>

      <div className="grid lg:grid-cols-8 grid-cols-1 font-be-vietnam  lg:px-12 px-4 sm:px-7 tracking-wide">
        <div className="col-span-2 ">
          <div className="flex flex-col justify-start items-start md:gap-3 lg:gap-0 md:flex-row lg:flex-col ">
            <div className="flex flex-col justify-start items-start gap-2 text-md w-auto md:w-[60%] lg:w-auto bg-[#f3efef] rounded-lg p-3 shadow-md">
              <div className="flex justify-between items-center gap-2">
                <p className="text-sm text-orange-600 font-semibold">
                  Customers rating
                </p>
                <div className="bg-[#2a7225] rounded-lg text-xs px-3 py-1 text-white font-semibold">
                  {detailsData?.hotel?.rating?.toString().includes(".")
                    ? detailsData?.hotel?.rating
                    : detailsData?.hotel?.rating + ".0"}
                </div>
              </div>
              <p className="text-xl font-semibold">
                {detailsData?.hotel?.name}
              </p>
              <p className="text-[#676767]">
                {" "}
                {detailsData?.hotel?.address} , {detailsData?.hotel?.city},{" "}
                {detailsData?.hotel?.country}
              </p>
              <div className="flex justify-start items-center gap-3 text-[#676767]">
                <p>{detailsData?.hotel?.name}</p>
              </div>
            </div>

            <div className="mt-2 flex flex-col justify-center items-start gap-2 text-md   rounded-lg p-3 w-full">
              <h1 className="text-xl font-semibold">Booking details</h1>

              <div className="flex flex-col justify-start items-start gap-4">
                {/* Room Selection Summary */}
                <div className="flex justify-between items-start gap-4 flex-col">
                  <div>
                    <p className="font-semibold text-md">
                      Selected Rooms:{" "}
                      <span className="font-semibold text-blue-600">
                        {detailsData?.selectedRooms?.length} Room(s)
                      </span>
                    </p>

                    <div className="rounded-md flex flex-wrap justify-start items-center mb-4 gap-1">
                      {detailsData?.selectedRooms?.map((room, index) => (
                        <span
                          key={index}
                          className="font-medium text-md text-orange-600 inline-block sm:mr-2 capitalize"
                        >
                          {index + 1}. {room?.name}
                          {index < detailsData?.selectedRooms.length - 1 && ","}
                        </span>
                      ))}
                    </div>

                    <div className="font-semibold text-sm space-y-1">
                      <p className="font-semibold text-md">Guest Information</p>
                      <p className="text-orange-600">
                        {detailsData?.adultsCount} Adult(s)
                      </p>

                      <p className="text-orange-600">
                        {detailsData?.childrenCount} Child(ren)
                      </p>
                    </div>
                  </div>

                  {/* Booking Date and Time */}
                  <div className="space-y-1">
                    <p className="font-semibold text-md text-gray-800">
                      Date:{" "}
                      <span className="font-normal text-gray-600">
                        {detailsData?.bookingDate}
                      </span>
                    </p>
                    <div className="flex items-center gap-2 text-[#212121]">
                      <p className="text-sm font-semibold">Check-in Time:</p>
                      <p className="text-sm font-normal">
                        {detailsData?.hotel?.checkInTime}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Change Selection Button */}
                <div className="pt-3">
                  <div
                    onClick={() => {
                      localStorage.clear();
                      navigate(-1);
                    }}
                    className="text-[#2D87D0] hover:text-[#423eb4] font-semibold underline hover:cursor-pointer"
                  >
                    Modify Your Selection
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-6">
          <div>
            <Details
              detailsData={detailsData}
              setDetailsData={setDetailsData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingForm;
