import React from "react";
// import heroImg from '../Assets/Gallery.webp';
import images from "../../JsonPackages/galleryImages.json";
import GalleryBanner from '../../Assets/user-imgs/Home-imgs/GalleryBanner.png'
import SimpleBanner from "../../components/common/SimpleBanner";

function Gallery() {
  return (
    <div className="bg-white">
      <SimpleBanner
        image={GalleryBanner}
        name="Our Gallery"
        path="Home / Our Gallery"
      />

      <div className="flex-grow mt-0 mx-6 md:mx-16 mb-6">
        <div className="text-center text-[#051836] text-2xl md:text-4xl font-semibold">
          Wander Through Our Journeys
        </div>
      </div>
      
      <div className="mx-auto px-4">
        <section className="md:py-8 md:px-4">
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 md:px-4 mb-4 md:mb-0">
              <iframe
                className="rounded w-full h-48 md:h-64 lg:h-72"
                src="https://res.cloudinary.com/dzqhg9qan/video/upload/v1705561934/20230902_204654_1_dav8x0.mp4"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>

            <div className="w-full md:w-1/2 md:px-4 mb-4 md:mb-0">
              <iframe
                className="rounded w-full h-48 md:h-64 lg:h-72 "
                src="https://res.cloudinary.com/dzqhg9qan/video/upload/v1705562467/IMG_2332.MOV_1_dvvm9m.mp4"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </section>

        <section className=" md:px-4">
          <div className="flex flex-wrap">
            {images?.img_row_1.map((img, k) => {
              return (
                <div key={k} className="w-full sm:w-1/2 md:px-4 mb-8">
                  <img
                    className="rounded shadow-md w-full h-auto"
                    src={img}
                    alt="1st-img-row"
                  />
                </div>
              );
            })}
          </div>
        </section>

        <section className="md:px-4">
          <div className="flex flex-wrap">
            {images?.img_row_2.map((img, l) => {
              return (
                <div
                  key={l}
                  className="w-full sm:w-1/2 md:w-1/3 md:px-4 mb-8"
                >
                  <img
                    className="rounded shadow-md w-full h-auto"
                    src={img}
                    alt="2nd-img-row"
                  />
                </div>
              );
            })}
          </div>
        </section>

        <section className="md:px-4">
          <div className="flex flex-wrap">
            {images?.img_row_3.map((img, m) => {
              return (
                <div
                  key={m}
                  className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:px-4 mb-8"
                >
                  <img
                    className="rounded shadow-md w-full h-auto"
                    src={img}
                    alt="3rd-img-row"
                  />
                </div>
              );
            })}
          </div>
        </section>
      </div>

    </div>
  );
}

export default Gallery;
