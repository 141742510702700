import React from 'react';
import Chart from 'react-apexcharts';  // Make sure to install this package

const Dashboard = () => {
  const stats = [
    { title: 'Total Tours', value: 15, color: 'text-blue-600' },
    { title: 'Total Bookings', value: 120, color: 'text-green-600' },
    { title: 'Total Revenue', value: '$15,000', color: 'text-yellow-600' },
    { title: 'Pending Payments', value: 5, color: 'text-red-600' },
  ];

  // Sample chart data for bookings in the last 7 days
  const chartOptions = {
    chart: {
      type: 'line',
      height: 350,
    },
    xaxis: {
      categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'], // Days of the week
    },
    colors: ['#34D399'],
    stroke: {
      width: 3,
    },
    title: {
      text: 'Bookings in the Last 7 Days',
      align: 'center',
      style: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#333',
      },
    },
  };

  const chartSeries = [
    {
      name: 'Bookings',
      data: [10, 15, 20, 30, 25, 40, 35],
    },
  ];

  return (
    <div className="p-8">
      <h1 className="text-3xl font-semibold mb-6">
        Dashboard
      </h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {stats.map((stat) => (
          <div key={stat.title} className="bg-white p-6 rounded-lg shadow-md text-center">
            <h3 className="text-xl font-medium">{stat.title}</h3>
            <p className={`text-2xl font-semibold ${stat.color} mt-2`}>{stat.value}</p>
          </div>
        ))}
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <Chart options={chartOptions} series={chartSeries} type="line" height={350} />
      </div>
    </div>
  );
};

export default Dashboard;
