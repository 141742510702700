import axios from "axios";
import React, { useEffect, useState } from "react";
import { MdTour } from "react-icons/md";
import { PiWarningCircleDuotone } from "react-icons/pi";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const ToursCategoriesContent = () => {
  const [allTCategories, setAllTCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [editCategoryData, setEditCategoryData] = useState({});
  const [editLoader, setEditLoader] = useState(false);
  const [deletingCategoryId, setDeletingCategoryId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newCategoryData, setNewCategoryData] = useState({
    category_name: "",
    img_url: "",
  }); // New category data
  const [addLoader, setAddLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [categotyID, setCategoryID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const totalPages = Math.ceil(allTCategories.length / itemsPerPage);
  const displayedTourCategory = allTCategories.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePagination = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getAllToursCategories();
  }, []);

  const getAllToursCategories = () => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/tours-categories/get-all-categories`,
      headers: {},
    };

    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        setAllTCategories(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching categories:", error);
      });
  };
  const openModal = (id) => {
    setCategoryID(id);
    setDeleteModal(true);
  };

  const handleEdit = (category) => {
    setEditCategoryId(category._id); // Set the category being edited
    setEditCategoryData({
      category_name: category.category_name,
      applied_for: category.applied_for,
      img_url: category.img_url, // Added img_url
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditCategoryData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = (categoryId) => {
    const updatedData = {
      ...editCategoryData,
      category_name: editCategoryData.category_name.toLowerCase(),
    };

    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/tours-categories/update-category/${categoryId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(updatedData),
    };

    setEditLoader(true);
    axios
      .request(config)
      .then((response) => {
        console.log("Category updated:", response.data);
        setEditCategoryId(null);
        getAllToursCategories();
        setEditLoader(false);
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        setEditLoader(false);
      });
  };

  const handleDelete = (categoryId) => {
    const config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/tours-categories/delete-category/${categoryId}`,
      headers: {},
    };

    setDeletingCategoryId(categoryId); // Set the category being deleted
    axios
      .request(config)
      .then((response) => {
        console.log("Category deleted:", response.data);
        setDeletingCategoryId(null);
        getAllToursCategories(); // Refresh categories
        setDeleteModal(false);
      })
      .catch((error) => {
        setDeletingCategoryId(null);
        console.error("Error deleting category:", error);
      });
  };

  const handleAddCategory = () => {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/tours-categories/create-category`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(newCategoryData),
    };

    setAddLoader(true);
    axios
      .request(config)
      .then((response) => {
        console.log("Category added:", response.data);
        setShowModal(false); // Close modal
        setNewCategoryData({ category_name: "", img_url: "" }); // Reset input
        getAllToursCategories(); // Refresh categories
        setAddLoader(false);
      })
      .catch((error) => {
        console.error("Error adding category:", error);
        setAddLoader(false);
      });
  };

  const handleCancel = () => {
    setEditCategoryId(null); // Exit edit mode without saving
  };

  return (
    <div className="p-6 bg-white shadow-md rounded-lg">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-semibold mb-6 text-gray-800">
          Tours Categories Management
        </h1>

        <button
          onClick={() => setShowModal(true)}
          className="bg-gradient-to-r text-lg flex items-center from-yellow-400 to-orange-500 hover:bg-yellow-800 text-white px-4 tracking-wide h-10 rounded-md hover:bg-gradient-to-r hover:to-yellow-400 hover:from-orange-500"
        >
          <MdTour size={22} />
          <span className="ml-1">New Category</span>
        </button>
      </div>

      {isLoading ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {Array(6)
            .fill("")
            .map((_, index) => (
              <div
                key={index}
                className="p-4 bg-gray-100 border border-gray-200 shadow-sm rounded-lg animate-pulse"
              >
                <div className="h-6 bg-gray-300 rounded-md mb-4"></div>
                <div className="h-4 bg-gray-300 rounded-md mb-2"></div>
                <div className="h-4 bg-gray-300 rounded-md mb-4"></div>
                <div className="flex space-x-2">
                  <div className="h-5 w-14 bg-gray-300 rounded-md"></div>
                  <div className="h-5 w-16 bg-gray-300 rounded-md"></div>
                </div>
              </div>
            ))}
        </div>
      ) : allTCategories.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {displayedTourCategory.map((category) => (
            <div
              key={category._id}
              className="p-4 tracking-wide bg-gray-100 border border-gray-200 shadow-sm rounded-lg hover:shadow-md transition-shadow"
            >
              {editCategoryId === category._id ? (
                <>
                  <input
                    type="text"
                    name="category_name"
                    value={editCategoryData.category_name}
                    onChange={handleInputChange}
                    className="w-full mb-2 p-2 border border-gray-300 rounded-md"
                  />
                  <input
                    type="text"
                    name="img_url"
                    value={editCategoryData.img_url}
                    onChange={handleInputChange}
                    className="w-full mb-2 p-2 border border-gray-300 rounded-md"
                    placeholder="Image URL"
                  />
                  <div className="flex justify-end items-center space-x-2">
                    {editLoader ? (
                      <button className="py-1 px-4 text-sm font-medium text-white bg-slate-500 rounded-lg">
                        Please Wait...
                      </button>
                    ) : (
                      <>
                        <button
                          onClick={() => handleSave(category._id)}
                          className="py-1 px-4 text-sm font-medium text-white bg-yellow-500 rounded-lg hover:bg-yellow-600"
                        >
                          Save
                        </button>

                        <button
                          onClick={handleCancel}
                          className="py-1 px-4 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-700"
                        >
                          Cancel
                        </button>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="flex items-center">
                    <div>
                      {category.img_url && (
                        <img
                          src={category.img_url}
                          alt={category.category_name}
                          className="w-20 h-20 rounded-full mr-4"
                        />
                      )}
                    </div>

                    <div>
                      <h2 className="text-lg font-medium text-gray-800 mb-2 capitalize">
                        {category.category_name}
                      </h2>
                      <p className="text-sm text-gray-600 mb-2">
                        Applied For:{" "}
                        <span className="font-semibold capitalize">
                          {category.applied_for}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="mt-3 flex justify-end items-center space-x-2">
                    <button
                      onClick={() => handleEdit(category)}
                      className="py-1 px-4 text-sm font-medium text-white bg-orange-500 rounded-lg hover:bg-orange-700"
                    >
                      Edit
                    </button>

                    <button
                      onClick={() => {
                        setDeleteModal(true);
                        openModal(category._id);
                      }}
                      className="py-1 px-4 text-sm font-medium text-white bg-slate-400 rounded-lg hover:bg-slate-500"
                    >
                      Delete
                    </button>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="text-gray-600 text-center">
          No categories available.
        </div>
      )}
      <>
        <div className="flex justify-center items-center">
          <Stack spacing={2} className="mt-8 flex justify-center">
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePagination}
              variant="outlined"
              shape="rounded"
              color="primary"
            />
          </Stack>
        </div>
      </>

      {/* Modal for adding a new category */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              Add New Tour Category
            </h2>
            <input
              type="text"
              name="category_name"
              value={newCategoryData.category_name}
              onChange={(e) =>
                setNewCategoryData({
                  ...newCategoryData,
                  category_name: e.target.value,
                })
              }
              className="w-full p-2 border border-gray-300 rounded-md mb-4"
              placeholder="Category Name"
            />
            <input
              type="text"
              name="img_url"
              value={newCategoryData.img_url}
              onChange={(e) =>
                setNewCategoryData({
                  ...newCategoryData,
                  img_url: e.target.value,
                })
              }
              className="w-full p-2 border border-gray-300 rounded-md mb-4"
              placeholder="Image URL"
            />
            <div className="flex justify-end space-x-4">
              {addLoader ? (
                <button
                  className="py-2 px-4 text-white bg-gray-500 rounded-lg"
                  disabled
                >
                  Adding...
                </button>
              ) : (
                <>
                  <button
                    onClick={() => setShowModal(false)}
                    className="py-2 px-4 text-gray-800 border border-gray-300 rounded-lg hover:bg-gray-200"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleAddCategory}
                    className="py-2 px-4 text-white bg-orange-500 rounded-lg hover:bg-orange-700"
                  >
                    Add
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {deleteModal && (
        <DelTourModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          handleDelete={handleDelete}
          openModal={openModal}
          categotyID={categotyID}
        />
      )}
    </div>
  );
};

const DelTourModal = ({
  setDeleteModal,
  openModal,
  handleDelete,
  categotyID,
}) => (
  <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center font-be-vietnam">
    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-xs">
      <div className="flex justify-center text-red-700 mb-4">
        <PiWarningCircleDuotone size={60} />
      </div>

      <p className="text-center tracking-wide text-lg">
        Are you sure to delete thi Tour?
      </p>

      <div className="flex tracking-wide justify-center items-center space-x-4 mt-5">
        <button
          onClick={() => setDeleteModal(false)}
          className="py-2 px-4 text-gray-800 border border-gray-300 rounded-lg hover:bg-gray-200"
        >
          Cancel
        </button>
        <button
          className="py-2 px-4 text-white bg-orange-500 rounded-lg hover:bg-orange-700"
          onClick={() => handleDelete(categotyID)}
        >
          Yes, Delete
        </button>
      </div>
    </div>
  </div>
);

export default ToursCategoriesContent;
