import React from "react";

const ClientDetailsSkeliton = () => {
  return (
    <>
      <div className="animate-pulse px-7">
        <div className="flex justify-between items-center">
          <div>
            <div className="h-7 w-[200px] bg-gray-400 mt-2 rounded-lg "></div>
            <div className="h-7 w-[160px] bg-gray-400 mt-2 rounded-lg "></div>
            <div className="h-7 w-[200px] bg-gray-400 mt-2 rounded-lg "></div>
          </div>
          <div>
            <div className="h-7 w-[200px] bg-gray-400 mt-2 rounded-lg "></div>
            <div className="h-7 w-[200px] bg-gray-400 mt-2 rounded-lg "></div>
          </div>
        </div>
        <div className="h-[250px] w-full bg-gray-400 mt-2 "></div>
        <div className="flex justify-start items-center mt-2  gap-3">
          <div className="h-7 w-[100px] bg-gray-400 mt-2  rounded-lg"></div>
          <div className="h-7 w-[100px] bg-gray-400 mt-2  rounded-lg"></div>
        </div>
        <div className="h-7 w-[70px] bg-gray-400 mt-2  rounded-lg"></div>
        <div className="h-4 full bg-gray-400 mt-2  rounded-lg"></div>
        <div className="h-4 full bg-gray-400 mt-1  rounded-lg"></div>
        <div className="h-4 full bg-gray-400 mt-1  rounded-lg"></div>

        <div className="h-7 w-[70px] bg-gray-400 mt-2  rounded-lg"></div>
        <div className="h-[50px] full bg-gray-400 mt-2  rounded-lg"></div>
      </div>
    </>
  );
};

export default ClientDetailsSkeliton;
