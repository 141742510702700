import React from 'react';
import Guideslogo from '../../Assets/user-imgs/Home-imgs/Guideslogo.svg';
import Experiencelogo from '../../Assets/user-imgs/Home-imgs/Experiencelogo.svg';
import Driverslogo from '../../Assets/user-imgs/Home-imgs/Driverslogo.svg';
import Customerlogo from '../../Assets/user-imgs/Home-imgs/Customerlogo.svg';

import Guidesimg from '../../Assets/user-imgs/Home-imgs/tt 1.svg';
import Experienceimg from '../../Assets/user-imgs/Home-imgs/puriDham.svg';
import Driversimg from '../../Assets/user-imgs/Home-imgs/tempo.svg';
import Customerimg from '../../Assets/user-imgs/Home-imgs/tourist.svg';
import whyChooseImg from '../../Assets/user-imgs/Home-imgs/Why Choose Us.svg';

const WhyChooseUs = () => {
  const cardsData = [
    {
      img: Guidesimg,
      logo: Guideslogo,
      text: '500+ Our Statewide Guides',
    },
    {
      img: Experienceimg,
      logo: Experiencelogo,
      text: '12+ Years of Travel Experience',
    },
    {
      img: Driversimg,
      logo: Driverslogo,
      text: 'Hygienic Vehicle & Professional Drivers',
    },
    {
      img: Customerimg,
      logo: Customerlogo,
      text: '100% Customer Satisfaction',
    },
  ];

  return (
    <React.Fragment>
      <div className="md:mx-16 mx-6 mt-10">
        <h2
          className="text-[#F94E05] text-lg text-center tracking-wide"
          style={{ fontFamily: "'Times New Roman', serif" }}
        >
          Why Choose Us!
        </h2>
        <h1
          className="text-2xl md:text-4xl tracking-wide text-center font-bold text-[#051836] mt-2"
          style={{ fontFamily: "'Be Vietnam Pro', sans-serif" }}
        >
          Why Travel with Weekend Bhraman?
        </h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8 mt-6">
          {cardsData.map((card, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg hover:shadow-xl overflow-hidden transition-shadow duration-300"
            >
              <img
                src={card.img}
                alt="card-visual"
                className="w-full h-48 object-cover"
              />
              <div className="relative px-6 pt-6 pb-6">
                <div className="absolute -top-10 left-1/2 transform -translate-x-1/2">
                  <img
                    src={card.logo}
                    alt="card-logo"
                    className="w-20 h-20 rounded-full"
                  />
                </div>
                <p className="text-center text-lg font-medium text-gray-800 tracking-wide mt-4">
                  {card.text}
                </p>
              </div>
            </div>
          ))}
        </div>

        <img
          src={whyChooseImg}
          alt="whyChooseImg"
          className="w-full h-auto mt-8"
        />
      </div>
    </React.Fragment>
  );
};

export default WhyChooseUs;